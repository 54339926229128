/**
 * ! DO NOT USE Sheets component here. I faced the following issues:
 * ! - If I add Select component in the form, the sheet would open, then close and then open again
 * ! - I could not disable event propagation if I clicked on any of the action column buttons (Note that
 * !   the entire row in Account table also has a click handler on it - both would trigger). This is how
 * !   Portals behave apparently, but event.stopPropogation() did not help
 */
import { useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import clsx from "clsx";
import { XCircle } from "lucide-react";
import React from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Textarea,
} from "src/components/RadixWrapper";
import { Create_Account_Mutation, Update_Account_Mutation } from "src/gql";
import { AccountInterface } from "src/types/Account";
import * as z from "zod";
import { useToast } from "../RadixWrapper/UseToast";

const formSchema = z.object({
  name: z.string().min(1),
});

type CreateAccountFormType = {
  showAccount: boolean;
  toggleShowAccount: () => void;
  account?: AccountInterface;
};

export function AccountForm({
  showAccount,
  toggleShowAccount,
  account,
}: CreateAccountFormType) {
  const { toast } = useToast();
  const [createAccountMutation] = useMutation(Create_Account_Mutation);
  const [updateAccount] = useMutation(Update_Account_Mutation);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: account
      ? {
          name: account.name,
        }
      : {
          name: "",
        },
  });
  // const { pubSubInstance } = usePubSubInstance();

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    toggleShowAccount();

    toast({
      title: account
        ? "Updating the account with the following values:"
        : "Creating a account with the following values:",
      description: (
        <pre className="mt-2 w-[340px] whitespace-pre-wrap rounded-md bg-slate-950 p-4">
          <code className="text-white">{JSON.stringify(values, null, 2)}</code>
        </pre>
      ),
    });

    try {
      if (account) {
        // const { data } = await updateAccount({
        await updateAccount({
          variables: {
            pk_columns: { id: account.id },
            _set: {
              name: values.name,
            },
          },
        });

        // pubSubInstance?.publish({
        //   channel: "text_channel",
        //   message: {
        //     account: data.update_accounts_by_pk,
        //     type: "account_updated",
        //   },
        // });
      } else {
        await createAccountMutation({
          variables: {
            object: {
              name: values.name,
            },
          },
        });
      }

      toast({
        title: "SUCCESS",
        description: account
          ? "Account updated successfully"
          : "New account created successfully",
      });

      form.reset();
    } catch (err) {
      if (account) {
        console.log("An error occurred during account update");
        console.log(err);

        toast({
          variant: "destructive",
          title: "FAILED",
          description: `Could not update the account. Try again.`,
        });
      } else {
        console.log("An error occurred during account creation");
        console.log(err);

        toast({
          variant: "destructive",
          title: "FAILED",
          description: `Could not create the account. Try again.`,
        });
      }
    }
  };

  return (
    <div
      className={clsx({
        "fixed inset-0 z-10 h-full w-full": true,
        invisible: !showAccount,
      })}
    >
      <div
        onClick={toggleShowAccount}
        className={clsx({
          "absolute inset-0 h-full w-full bg-gray-900": true,
          "transition-all duration-500 ease-out": true,
          "opacity-0": !showAccount,
          "opacity-50": showAccount,
        })}
      ></div>
      <div
        className={clsx({
          "absolute right-0 h-full w-96 bg-white": true,
          "transition-all duration-300 ease-out": true,
          "translate-x-full": !showAccount,
        })}
      >
        <div className="flex flex-col space-y-2 px-6 pt-6">
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-semibold text-foreground">
              {account ? "Edit Account" : "Create Account"}
            </h2>
            <Button
              variant="ghost"
              size="icon"
              className="rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 disabled:pointer-events-none"
              onClick={toggleShowAccount}
            >
              <XCircle className="h-4 w-4" />
            </Button>
          </div>
          <p className="text-sm text-muted-foreground">
            Here you can{" "}
            {account ? "edit an existing account" : "create a new account"}
          </p>
        </div>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-8 p-6"
          >
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Enter the account name</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="eg. Phil's Discount Furniture"
                      rows={4}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button type="submit">{account ? "Save" : "Create Account"}</Button>
          </form>
        </Form>
      </div>
    </div>
  );
}
