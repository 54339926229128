import { useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { format, isValid } from "date-fns";
import { CalendarDays, Plus } from "lucide-react";
import React, { Suspense, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import FormFieldSkeleton from "src/components/Events/components/Skeleton";
import {
  Button,
  Calendar,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Textarea,
} from "src/components/RadixWrapper";
import { useToast } from "src/components/RadixWrapper/UseToast";
import {
  GetEventHostsQuery,
  SearchHostsQuery,
  useGetEventByIdQuery,
  useGetEventHostsQuery,
} from "src/generated/graphql";
import {
  Create_Event_Mutation,
  EventStatusDropdownValues,
  EventStatusEnum,
  Update_Event_Mutation,
} from "src/gql";
import { useAuth } from "src/hooks/useAuth";
import { usePubSubInstance } from "src/store";
import { z } from "zod";
import AccountsSelect from "../Accounts/AccountsSelect";
import { HostSelection } from "../Hosts/HostSelection";
import LocationsSelect from "../Locations/LocationsSelect";
import RegionsSelect from "../Regions/RegionsSelect";
import HostEditTable from "./HostEditTable";
import {
  ADD_EVENT_HOST,
  REMOVE_EVENT_HOST,
  UPDATE_EVENT_HOST,
} from "src/gql/eventHosts";

type CreateEditEventType = {
  eventId?: string;
  onCreateAccount: () => void;
  onCreateLocation: () => void;
};

type EventHostRateType = {
  rate: number;
  type: "HOST" | "SCORING";
};

type ManageEventHostsType = GetEventHostsQuery["eventHosts"][number] & {
  isAdded?: boolean;
  isRemoved?: boolean;
  isUpdated?: boolean;
};

const ITEMS_PER_PAGE = 10;

const formSchema = z.object({
  name: z.string().min(1, { message: "Event name is required" }),
  date: z.string().refine(
    (value) => {
      return isValid(new Date(value));
    },
    {
      message: "Provide a valid date",
    }
  ),
  accountId: z.string().min(1, { message: "An account is required" }),
  locationId: z.string().min(1, { message: "A location is required" }),
  regionId: z.string().min(1, { message: "A region is required" }),
  status: z.string().optional(),
  publicNotes: z.string().optional(),
  hostNotes: z.string().optional(),
  adminNotes: z.string().optional(),

  cost: z.coerce
    .number({
      required_error: "Price is required",
      invalid_type_error: "Price should be a valid number",
    })
    .min(0, { message: "Price cannot be negative" }),
});

export function CreateEditEvent({
  eventId,
  onCreateAccount,
  onCreateLocation,
}: CreateEditEventType) {
  const [eventDate, setEventDate] = useState(format(new Date(), "P"));
  const [hostsForEvent, setHostsForEvent] = useState(
    [] as ManageEventHostsType[]
  );
  const [createEventMutation, { loading: creatingEvent }] = useMutation(
    Create_Event_Mutation
  );
  const [updateEventMutation, { loading: updatingEvent }] = useMutation(
    Update_Event_Mutation
  );
  const [insertEventHostMutation, { loading: insertingEventHost }] =
    useMutation(ADD_EVENT_HOST);
  const [updateEventHostMutation, { loading: updatingEventHost }] =
    useMutation(UPDATE_EVENT_HOST);
  const [removeEventHostMutation, { loading: removingEventHost }] =
    useMutation(REMOVE_EVENT_HOST);

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const { toast } = useToast();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { pubSubInstance } = usePubSubInstance();

  const {
    data: eventHosts,
    // loading: loadingHosts,
    // refetch,
    // fetchMore,
  } = useGetEventHostsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: ITEMS_PER_PAGE,
      offset: 0,
      where: {
        // nickname: {
        //   _ilike: `%${searchText}%`,
        // },
        event_id: { _eq: eventId! },
      },
    },
    skip: !eventId,
    fetchPolicy: "cache-and-network",
  });

  const { data: eventData, loading: loadingEvent } = useGetEventByIdQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      id: eventId!,
    },
    fetchPolicy: "network-only",
    skip: !eventId,
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      date: new Date().toISOString(),
      accountId: "",
      locationId: "",
      regionId: "",
      status: EventStatusEnum.DRAFT,
      cost: 0,
    },
  });

  // Populate form fields for editing an event
  useEffect(() => {
    const event = eventData?.events_by_pk;

    if (event) {
      form.reset({
        name: event.name,
        date: new Date(event.eventDateTime).toISOString(),
        accountId: event.account?.id,
        locationId: event.location?.id,
        regionId: event.region?.id,
        status: event.status,
        cost: Number(event.cost) || 0,
      });

      setEventDate(format(event.eventDateTime, "P"));
    }
  }, [eventData, form]);

  useEffect(() => {
    const hosts = eventHosts?.eventHosts;

    if (hosts) {
      setHostsForEvent(hosts);
    }
  }, [eventHosts]);

  // Notify edit and its cancellation
  useEffect(() => {
    if (eventData?.events_by_pk?.id) {
      pubSubInstance?.publish({
        channel: "text_channel",
        message: { event: eventData.events_by_pk, type: "editing_event_form" },
      });

      return () => {
        pubSubInstance?.publish({
          channel: "text_channel",
          message: {
            event: eventData.events_by_pk,
            type: "editing_event_canceled",
          },
        });
      };
    }
  }, [pubSubInstance, eventData]);

  const onSelectHost = (host: SearchHostsQuery["hosts"][number]) => {
    const found = hostsForEvent.find((h) => h.host_id === host.id);

    if (found && !found.isRemoved) {
      return;
    } else if (found?.isRemoved) {
      setHostsForEvent((prev) =>
        prev.map((eventHost) =>
          eventHost.host_id === host.id
            ? {
                ...eventHost,
                isRemoved: false,
              }
            : eventHost
        )
      );

      return;
    }

    const rate = (host.rates as EventHostRateType[]).find(
      (r) => r.type === "HOST"
    )?.rate;

    setHostsForEvent((prev) => [
      ...prev,
      {
        id: `new-${host.id}`,
        type: "HOST",
        pay: rate,
        event_id: eventId!,
        user_id: user?.id || "",
        host_id: host.id,
        org_id: user?.orgId,
        host: {
          id: host.id,
          nickname: host.nickname,
          googleAccountEmail: host.googleAccountEmail,
          rates: host.rates,
        },
        isAdded: true,
      },
    ]);
  };

  const onChangeHostType = (
    host: GetEventHostsQuery["eventHosts"][number]["host"],
    type: string
  ) => {
    const rate = (host.rates as EventHostRateType[]).find(
      (r) => r.type === type
    )?.rate;

    setHostsForEvent((prev) =>
      prev.map((eventHost) =>
        eventHost.host_id === host.id
          ? {
              ...eventHost,
              type,
              pay: rate,
              isUpdated: true,
            }
          : eventHost
      )
    );
  };

  const onChangeHostPay = (hostId: string, pay: string) => {
    setHostsForEvent((prev) =>
      prev.map((eventHost) =>
        eventHost.host_id === hostId
          ? {
              ...eventHost,
              pay: parseInt(pay, 10),
              isUpdated: true,
            }
          : eventHost
      )
    );
  };

  const onRemoveHost = (hostId: string) => {
    if (confirm("Are you sure you want to remove this host?")) {
      setHostsForEvent((prev) =>
        prev.map((eventHost) =>
          eventHost.host_id === hostId
            ? {
                ...eventHost,
                isRemoved: true,
              }
            : eventHost
        )
      );
    }
  };

  const manageEventHost = async () => {
    toast({
      description: "Managing updates to event hosts (if any)...",
    });

    for (let i = 0; i < hostsForEvent.length; i++) {
      const currentEventHost = hostsForEvent[i];

      // An existing event host was removed
      if (currentEventHost.isRemoved && !currentEventHost.isAdded) {
        await removeEventHostMutation({
          variables: {
            id: currentEventHost.id,
          },
        });

        continue;
      }

      if (currentEventHost.isAdded) {
        delete currentEventHost.isAdded;
        delete currentEventHost.isUpdated;

        await insertEventHostMutation({
          variables: {
            object: {
              ...currentEventHost,
              id: undefined,
              host: undefined,
            },
          },
        });

        continue;
      }

      if (currentEventHost.isUpdated) {
        delete currentEventHost.isUpdated;

        await updateEventHostMutation({
          variables: {
            pk_columns: {
              id: currentEventHost.id,
            },
            _set: {
              host_id: currentEventHost.host_id,
              pay: currentEventHost.pay,
            },
          },
        });
      }
    }

    toast({
      description: "Event hosts in sync",
    });
  };

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    if (eventId) {
      toast({
        description: "Updating event...",
      });

      const { data } = await updateEventMutation({
        variables: {
          pk_columns: { id: eventId },
          _set: {
            region_id: values.regionId,
            account_id: values.accountId,
            location_id: values.locationId,
            name: values.name,
            cost: values.cost,
            status: values.status,
            eventDateTime: new Date(values.date).toISOString(),
            org_id: user?.orgId,
          },
        },
      });

      pubSubInstance?.publish({
        channel: "text_channel",
        message: {
          event: data.update_events_by_pk,
          type: "event_updated",
        },
      });

      toast({
        description: "Event updated successfully",
      });
    } else {
      toast({
        description: "Creating event...",
      });

      await createEventMutation({
        variables: {
          object: {
            region_id: values.regionId,
            account_id: values.accountId,
            location_id: values.locationId,
            name: values.name,
            cost: values.cost,
            status: values.status,
            eventDateTime: new Date(values.date).toISOString(),
            org_id: user?.orgId,
          },
        },
      });

      toast({
        description: "Event created successfully",
      });
    }

    await manageEventHost();

    navigate("/events");
  };

  const onEventDateInputChange = (
    e: React.ChangeEvent<HTMLInputElement> | Date | undefined
  ) => {
    if (e) {
      if (e instanceof Date) {
        setEventDate(format(e, "P"));
        form.setValue("date", e.toISOString());
      } else {
        setEventDate(e.target.value);

        if (isValid(new Date(e.target.value))) {
          form.setValue("date", new Date(e.target.value).toISOString());
        } else {
          form.setValue("date", "");
        }
      }
    }
  };

  const isRequestInProgress = () => {
    return (
      creatingEvent ||
      updatingEvent ||
      insertingEventHost ||
      updatingEventHost ||
      removingEventHost
    );
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <div className="flex flex-row space-x-8">
          <div className="min-w-40">
            <FormField
              control={form.control}
              name="regionId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Region</FormLabel>
                  <Suspense fallback={<FormFieldSkeleton />}>
                    <RegionsSelect
                      value={field.value}
                      onValueSelect={field.onChange}
                    />
                  </Suspense>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <FormField
            control={form.control}
            name="accountId"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Account</FormLabel>
                <div className="flex w-64 flex-row justify-between">
                  <Suspense fallback={<FormFieldSkeleton />}>
                    <AccountsSelect
                      value={field.value}
                      onValueSelect={field.onChange}
                    />
                  </Suspense>
                  <Button
                    variant="ghost"
                    size="icon"
                    type="button"
                    onClick={onCreateAccount}
                  >
                    <Plus className="h-4 w-4" />
                  </Button>
                </div>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="locationId"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Location</FormLabel>
                <div className="flex w-60 flex-row justify-between">
                  <Suspense fallback={<FormFieldSkeleton />}>
                    <LocationsSelect
                      value={field.value}
                      onValueSelect={field.onChange}
                    />
                  </Suspense>
                  <Button
                    variant="ghost"
                    size="icon"
                    type="button"
                    onClick={onCreateLocation}
                  >
                    <Plus className="h-4 w-4" />
                  </Button>
                </div>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex flex-row space-x-8">
          <FormField
            control={form.control}
            name="cost"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Event Cost</FormLabel>
                {loadingEvent && <FormFieldSkeleton />}
                {!loadingEvent && (
                  <FormControl>
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span className="text-gray-500 sm:text-sm">$</span>
                      </div>

                      <Input
                        {...field}
                        autoFocus
                        className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </FormControl>
                )}
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Event Name</FormLabel>
                {loadingEvent && <FormFieldSkeleton />}
                {!loadingEvent && (
                  <FormControl>
                    <Input
                      placeholder="The Oscars' after party"
                      {...field}
                      autoFocus
                    />
                  </FormControl>
                )}
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="status"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Event Status</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Choose one" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {EventStatusDropdownValues.map((c) => (
                      <SelectItem key={c.value} value={c.value}>
                        {c.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={form.control}
          name="date"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel>Event date</FormLabel>
              {loadingEvent && <FormFieldSkeleton />}
              {!loadingEvent && (
                <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
                  <FormControl>
                    <div className="flex gap-2">
                      <Input
                        placeholder="MM/DD/YYYY"
                        className="w-32"
                        value={eventDate}
                        onChange={onEventDateInputChange}
                      />
                      <PopoverTrigger asChild>
                        <Button
                          variant="outline"
                          size="icon"
                          className="border-input bg-transparent text-black"
                        >
                          <CalendarDays className="h-4 w-4 opacity-50" />
                        </Button>
                      </PopoverTrigger>
                    </div>
                  </FormControl>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={new Date(field.value)}
                      onSelect={(e) => {
                        onEventDateInputChange(e);
                        setIsCalendarOpen(false);
                      }}
                      fromDate={new Date()}
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
              )}
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex flex-row space-x-8">
          <FormField
            control={form.control}
            name="publicNotes"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Public Notes</FormLabel>
                {loadingEvent && <FormFieldSkeleton />}
                {!loadingEvent && (
                  <FormControl>
                    <Textarea
                      placeholder="Enter public notes..."
                      {...field}
                      autoFocus
                    />
                  </FormControl>
                )}
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="hostNotes"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Host Notes</FormLabel>
                {loadingEvent && <FormFieldSkeleton />}
                {!loadingEvent && (
                  <FormControl>
                    <Textarea
                      placeholder="Enter host notes..."
                      {...field}
                      autoFocus
                    />
                  </FormControl>
                )}
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="adminNotes"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Admin Notes</FormLabel>
                {loadingEvent && <FormFieldSkeleton />}
                {!loadingEvent && (
                  <FormControl>
                    <Textarea
                      placeholder="Enter admin notes..."
                      {...field}
                      autoFocus
                    />
                  </FormControl>
                )}
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        {eventId && (
          <div>
            <HostSelection onValueSelect={onSelectHost} />
            <HostEditTable
              eventHosts={hostsForEvent.filter((h) => !h.isRemoved)}
              onHostTypeChange={onChangeHostType}
              onHostPayChange={onChangeHostPay}
              onRemoveHost={onRemoveHost}
            />
          </div>
        )}

        <div className="flex justify-between">
          {!eventId && (
            <Button type="submit" disabled={isRequestInProgress()}>
              {isRequestInProgress() ? "Creating..." : "Create"}
            </Button>
          )}
          {eventId && (
            <Button type="submit" disabled={isRequestInProgress()}>
              {isRequestInProgress() ? "Updating..." : "Update"}
            </Button>
          )}
          <Button
            type="button"
            variant="secondary"
            className="cursor-pointer"
            title="Cancel"
            asChild
          >
            <Link to="/events">Cancel</Link>
          </Button>
        </div>
      </form>
    </Form>
  );
}
