import { createSlice } from "@reduxjs/toolkit";
import { NotebookInterface } from "src/types/Notebook";

export interface NoteReducerInterface {
  currentNote: NotebookInterface | null;
  notes: NotebookInterface[];
}

const initialState: NoteReducerInterface = {
  currentNote: null,
  notes: [],
};

const noteSlice = createSlice({
  name: "note",
  initialState,
  reducers: {
    setCurrentNote(state, action) {
      state.currentNote = action?.payload?.currentNote;
    },
    setNotes(state, action) {
      state.notes = action.payload.notes;
    },
    resetNoteState(state) {
      state.notes = [];
      state.currentNote = null;
    },
  },
});

export const { setCurrentNote, setNotes, resetNoteState } = noteSlice.actions;
export const noteReducer = noteSlice.reducer;
