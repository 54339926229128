import React from "react";
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/RadixWrapper";

function getArray(count: number) {
  return new Array(count).fill(0);
}

export function FilterSelectSkeleton() {
  return (
    <div className="rounded-md border">
      <Skeleton className="h-9 w-full" />
    </div>
  );
}

export function ListFiltersSkeleton() {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-3/4">Name</TableHead>
          <TableHead className="w-1/4">Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {getArray(5).map((a, i) => (
          <TableRow key={i}>
            <TableCell className="w-3/4">
              <Skeleton className="h-7 w-full" />
            </TableCell>
            <TableCell className="flex w-1/4 gap-4">
              <Skeleton className="h-5 w-full" />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
