import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
  split,
} from "@apollo/client";
import { HASURA } from "./config/default";
import { setContext } from "@apollo/client/link/context";
import { fetchAuthSession } from "aws-amplify/auth";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";

const httpLink = createHttpLink({
  uri: HASURA.URL,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: HASURA.WSS,
    connectionParams: async () => {
      const { tokens } = await fetchAuthSession();

      if (tokens?.idToken) {
        const token = tokens.idToken.toString();

        return {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      }

      return {};
    },
  })
);

const authLink = setContext(async (_, { headers }) => {
  try {
    const { tokens } = await fetchAuthSession();

    if (tokens?.idToken) {
      const token = tokens.idToken.toString();

      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }

    return {
      headers,
    };
  } catch (err) {
    console.log("Error in apollo authLink", err);
  }
});

const composedLink = ApolloLink.from([authLink, httpLink]);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  composedLink
);

export const apolloClient = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});
