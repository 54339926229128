import clsx from "clsx";
import React, { Suspense } from "react";
import { Link, useParams } from "react-router-dom";
import { useGetEventByIdQuery } from "src/generated/graphql";
import { Button } from "../RadixWrapper";
import { EventQuestionList } from "./EventQuestionList";
import { EventsTableSkeleton } from "./Skeleton";

type DefinitionItemType = {
  type: string;
  data: string;
  isEven?: boolean;
};

function DefinitionItem({ type, data, isEven }: DefinitionItemType) {
  return (
    <div
      className={clsx({
        "grid grid-cols-3 gap-4 px-3 py-6": true,
        "bg-gray-50": isEven,
      })}
    >
      <dt className="text-sm font-medium leading-6 text-gray-900">{type}</dt>
      <dd className="col-span-2 mt-0 text-sm leading-6 text-gray-700">
        {data}
      </dd>
    </div>
  );
}
export default function EventDetails() {
  const { id } = useParams();
  const { data: eventData } = useGetEventByIdQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      id: id!,
    },
    fetchPolicy: "network-only",
    skip: !id,
  });

  return (
    <div className="container">
      <div className="my-4 flex w-full items-center justify-between">
        <h1 className="text-xl font-bold">Event Details</h1>
        <Button className="cursor-pointer">
          <Link to="/events">View all events</Link>
        </Button>
      </div>

      <Suspense fallback={<EventsTableSkeleton />}>
        {/* <div>{JSON.stringify(eventData?.events_by_pk)}</div> */}
        {eventData?.events_by_pk && (
          <section>
            <dl>
              <DefinitionItem type="Name" data={eventData.events_by_pk.name} />
              <DefinitionItem
                type="Account Name"
                data={eventData.events_by_pk.account?.name || ""}
                isEven
              />
            </dl>
          </section>
        )}
        {id && <EventQuestionList eventId={id} />}
      </Suspense>
    </div>
  );
}
