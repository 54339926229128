import React from "react";
import {
  EventQuestions,
  useGetEventQuestionsQuery,
} from "src/generated/graphql";
import { EventQuestionListItem } from "./EventQuestionListItem";
import { LoadingAnimation } from "../Common/LoadingAnimation";
import { useToast } from "../RadixWrapper/UseToast";

interface EventQuestionListProps {
  eventId: string;
}

export const EventQuestionList: React.FC<EventQuestionListProps> = ({
  eventId,
}: EventQuestionListProps) => {
  const { toast } = useToast()
  const onQuestionDeleted = (id: string) => {
    toast({
      description: `Question with id ${id} deleted`
    })

    refetch();
  };
  const { data: questions, refetch } = useGetEventQuestionsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      where: {
        event_id: { _eq: eventId },
      },
    },
    fetchPolicy: "network-only",
    skip: !eventId,
  });
  return (
    <div className="mt-8 flex flex-col p-5">
      <div className="flex flex-grow border-b border-gray-300 pb-4">
        <div className="w-1/10 flex md:w-full">
          <div>#</div>
        </div>

        <div className="flex w-1/2 md:w-full">
          <div>Question</div>
        </div>
        <div className="flex w-2/5 md:w-full">
          <div></div>
        </div>
      </div>
      <div className="flex w-full flex-col">
        {questions?.eventQuestions ? (
          questions.eventQuestions.map((item) => (
            <EventQuestionListItem
              eventQuestion={item as EventQuestions}
              onDeleteQuestion={onQuestionDeleted}
              key={item.id}
            />
          ))
        ) : (
          <LoadingAnimation />
        )}
        {/* <div>{JSON.stringify(questions)}</div> */}
      </div>
    </div>
  );
};
