// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader_loader__1AOoV {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
}
.loader_loader__1AOoV::after,
.loader_loader__1AOoV::before {
  content: "";
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid rgb(37 99 235);
  position: absolute;
  left: 0;
  top: 0;
  animation: loader_animloader__TzITZ 2s linear infinite;
}
.loader_loader__1AOoV::after {
  animation-delay: 1s;
}

@keyframes loader_animloader__TzITZ {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Loader/loader.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,qBAAqB;EACrB,kBAAkB;AACpB;AACA;;EAEE,WAAW;EACX,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,gCAAgC;EAChC,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,sDAAwC;AAC1C;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE;IACE,mBAAmB;IACnB,UAAU;EACZ;EACA;IACE,mBAAmB;IACnB,UAAU;EACZ;AACF","sourcesContent":[".loader {\n  width: 48px;\n  height: 48px;\n  display: inline-block;\n  position: relative;\n}\n.loader::after,\n.loader::before {\n  content: \"\";\n  box-sizing: border-box;\n  width: 48px;\n  height: 48px;\n  border-radius: 50%;\n  border: 2px solid rgb(37 99 235);\n  position: absolute;\n  left: 0;\n  top: 0;\n  animation: animloader 2s linear infinite;\n}\n.loader::after {\n  animation-delay: 1s;\n}\n\n@keyframes animloader {\n  0% {\n    transform: scale(0);\n    opacity: 1;\n  }\n  100% {\n    transform: scale(1);\n    opacity: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `loader_loader__1AOoV`,
	"animloader": `loader_animloader__TzITZ`
};
export default ___CSS_LOADER_EXPORT___;
