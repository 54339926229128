import { startOfDay, subYears } from "date-fns";
import { StoredEntityType, TIMELINE } from "./CreateEdit/types";

interface EventQuestionsFilterInterface {
  event?:
    | {
        account: {
          id:
            | {
                _in: string[];
              }
            | {
                _nin: string[];
              };
        };
        eventDateTime: {
          _gte: Date;
        };
      }
    | {
        location: {
          id:
            | {
                _in: string[];
              }
            | {
                _nin: string[];
              };
        };
        eventDateTime: {
          _gte: Date;
        };
      }
    | {
        id: {
          _in: string[];
        };
      }
    | {
        id: {
          _nin: string[];
        };
      };
}

interface ComplexFilterInterface {
  eventQuestions?: {
    _and?: EventQuestionsFilterInterface[];
  };
}

function getTimelineDate(timeline: TIMELINE | undefined) {
  let yearsToSubtract = 100;

  if (timeline === TIMELINE.ONEYEAR) {
    yearsToSubtract = 1;
  }

  if (timeline === TIMELINE.THREEYEARS) {
    yearsToSubtract = 3;
  }

  const today = new Date();
  const nYearsAgo = subYears(today, yearsToSubtract);
  const nYearsAgoStartOfDay = startOfDay(nYearsAgo);
  return nYearsAgoStartOfDay;
}

export function prepareComplextFilterQuery(entities: StoredEntityType) {
  const handInAnd: EventQuestionsFilterInterface[] = [];

  let includeUnusedQuestions = true;
  const unusedQuestions = {
    _not: {
      eventQuestions: {},
    },
  };

  if (Object.keys(entities).length === 0) {
    return [{}];
  }

  if (entities.accounts.in.length > 0) {
    const ids: string[] = [];

    entities.accounts.in.forEach((a) => a.id && ids.push(a.id));

    handInAnd.push({
      event: {
        account: {
          id: {
            _in: ids,
          },
        },
        eventDateTime: {
          _gte: getTimelineDate(entities.accounts.since),
        },
      },
    });

    includeUnusedQuestions = false;
  }

  if (entities.accounts.notIn.length > 0) {
    const ids: string[] = [];

    entities.accounts.notIn.forEach((a) => a.id && ids.push(a.id));

    handInAnd.push({
      event: {
        account: {
          id: {
            _nin: ids,
          },
        },
        eventDateTime: {
          _gte: getTimelineDate(entities.accounts.notSince),
        },
      },
    });
  }

  if (entities.locations.in.length > 0) {
    const ids: string[] = [];

    entities.locations.in.forEach((a) => a.id && ids.push(a.id));

    handInAnd.push({
      event: {
        location: {
          id: {
            _in: ids,
          },
        },
        eventDateTime: {
          _gte: getTimelineDate(entities.locations.since),
        },
      },
    });

    includeUnusedQuestions = false;
  }

  if (entities.locations.notIn.length > 0) {
    const ids: string[] = [];

    entities.locations.notIn.forEach((a) => a.id && ids.push(a.id));

    handInAnd.push({
      event: {
        location: {
          id: {
            _nin: ids,
          },
        },
        eventDateTime: {
          _gte: getTimelineDate(entities.locations.notSince),
        },
      },
    });
  }

  if (entities.events.in.length > 0) {
    const ids: string[] = [];

    entities.events.in.forEach((a) => a.id && ids.push(a.id));

    handInAnd.push({
      event: {
        id: {
          _in: ids,
        },
      },
    });

    includeUnusedQuestions = false;
  }

  if (entities.events.notIn.length > 0) {
    const ids: string[] = [];

    entities.events.notIn.forEach((a) => a.id && ids.push(a.id));

    handInAnd.push({
      event: {
        id: {
          _nin: ids,
        },
      },
    });
  }

  const finalFilter: ComplexFilterInterface = {};

  if (handInAnd.length > 0) {
    finalFilter.eventQuestions = {
      _and: handInAnd,
    };
  }

  if (includeUnusedQuestions) {
    // Unused questions => Questions that are not used in ANY event, location or accounts.
    // For example, if my filter asks for questions NOT USED IN a location,
    // then it needs to return questions NOT USED IN that location AND questions NOT USED IN
    // ANY location
    // The data structure of unusedQuestions includes such questions
    return [{ ...finalFilter }, unusedQuestions];
  }

  return [{ ...finalFilter }];
}
