import React from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../RadixWrapper";

export type RightSidebarNavItem = {
  id: string;
  icon: React.ReactNode;
  tooltip: string;
};

type RightSidebarProps = {
  items: RightSidebarNavItem[];
  onItemClick?: (id: string) => void;
};

export default function RightSidebar({
  items,
  onItemClick,
}: RightSidebarProps) {
  if (items.length === 0) {
    // return null
  }

  const handleItemClick = (id: string) => {
    if (onItemClick) {
      onItemClick(id);
    }
  };

  return (
    <div className="fixed bottom-0 right-0 top-0 z-10 w-16 bg-blue-950 text-blue-200">
      <div className="flex h-full flex-col justify-between">
        <ul className="my-2 flex h-full flex-col items-stretch justify-center gap-4">
          {items.map((item) => (
            <TooltipProvider key={item.id}>
              <Tooltip>
                <TooltipTrigger>
                  <li
                    className="mx-3 flex h-10 w-10 gap-2 rounded-full p-2 hover:cursor-pointer hover:bg-blue-600"
                    onClick={() => handleItemClick(item.id)}
                  >
                    {item.icon}
                  </li>
                </TooltipTrigger>
                <TooltipContent side="left" sideOffset={8}>
                  <p>{item.tooltip}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ))}
        </ul>
      </div>
    </div>
  );
}
