/**
 * https://ui.shadcn.com/docs/components/data-table
 * * Modified to consider additional features
 * * such as update and delete rows and manual pagination
 */
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { DialogTitle, DialogTrigger } from "@radix-ui/react-dialog";
import {
  ColumnDef,
  OnChangeFn,
  PaginationState,
  Row,
  RowData,
  Table as TanstackTable,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import clsx from "clsx";
import { DocumentNode } from "graphql";
import { Settings } from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/RadixWrapper";
import { usePubSubInstance } from "src/store";
import { AccountsTableSkeleton } from "../Accounts/Skeleton";
import { Checkbox } from "./Checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from "./Dialog";

export enum SpecificFunctionTypes {
  NM_CLOSE_DIALOG = "NM_CLOSE_DIALOG",
  CATEGORY_MERGE_DIALOG_OPEN = "CATEGORY_MERGE_DIALOG_OPEN",
}

declare module "@tanstack/react-table" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    type?: string;
    options?: string[];
    optionsQuery?: DocumentNode;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    onDeleteRow: (id: string, name: string) => Promise<void>;
    onUpdateRow: (
      rowIndex: number,
      params: Record<string, unknown>,
      updateAttrs: Record<string, unknown>
    ) => Promise<void>;
    editedRows: Record<string, boolean>;
    setEditedRows: React.Dispatch<
      React.SetStateAction<Record<string, boolean>>
    >;
    cancelEdit: (rowIndex: number) => void;
    trackEdit: (rowIndex: number, columnId: string, value: string) => void;
    rawData: unknown[];
    onTriggerNonGenericFunction: (
      type: SpecificFunctionTypes,
      params?: Record<string, unknown>
    ) => void;
  }
}

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  pageIndex: number;
  pageSize: number;
  totalCount: number;
  onPaginationChange: OnChangeFn<PaginationState>;
  loadingData?: boolean;
  onDeleteRow?: (id: string, name: string) => Promise<void>;
  onUpdateRow?: (params: Record<string, unknown>) => Promise<void>;
  isRowDraggable?: boolean;
  showToggleVisibility?: boolean;
  columnVisibility?: Record<string, boolean>;
  setColumnVisibility?: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  isCVOpen?: boolean;
  onCVOpenChange?: (open: boolean) => void;
  // Cannot create a function for each specific use case
  // Hence a catch all function type
  onTriggerNonGenericFunction?: (
    type: SpecificFunctionTypes,
    params?: Record<string, unknown>
  ) => void;
}

interface TableRowWrapperProps<TData> {
  isDraggable?: boolean;
  children: React.ReactNode;
  row: Row<TData>;
  table: TanstackTable<TData>;
}

function TableRowWrapper<TData>({
  isDraggable = false,
  children,
  row,
  table,
}: TableRowWrapperProps<TData>) {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const { pubSubInstance } = usePubSubInstance();

  const handleDrag = (event: React.MouseEvent<EventTarget>) => {
    setPosition({ x: event.screenX, y: event.screenY });
  };

  const handleDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    item: unknown
  ) => {
    event.dataTransfer.clearData();
    event.dataTransfer.setData("text/plain", JSON.stringify(item));
    setIsDragging(true);
  };

  const handleDblClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    const meta = table.options.meta;

    if (!isDragging) {
      meta?.setEditedRows((old) => {
        pubSubInstance?.publish({
          channel: "text_channel",
          message: { type: "editing_form", question: row.original },
        });

        return {
          ...old,
          [row.id]: true,
        };
      });
    }
  };

  if (!isDraggable) {
    return (
      <TableRow data-state={row.getIsSelected() && "selected"}>
        {children}
      </TableRow>
    );
  }

  return (
    <TableRow
      data-state={row.getIsSelected() && "selected"}
      style={{ top: `${position.y}px`, left: `${position.x}px` }}
      onDrag={handleDrag}
      onDragStart={(event) => handleDragStart(event, row.original)}
      draggable={true}
      onDoubleClick={handleDblClick}
      onDragEnd={() => setIsDragging(false)}
    >
      {children}
    </TableRow>
  );
}

export function DataTable<TData, TValue>({
  columns,
  data,
  pageIndex,
  pageSize,
  totalCount,
  onPaginationChange,
  loadingData = false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onDeleteRow = async (id: string, name: string) => await Promise.resolve(),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onUpdateRow = async (params: Record<string, unknown>) =>
    await Promise.resolve(),
  isRowDraggable = false,
  showToggleVisibility = false,
  columnVisibility = { id: false },
  setColumnVisibility = (
    value: React.SetStateAction<Record<string, boolean>>
  ) => {
    console.log(value);
  },
  isCVOpen = false,
  onCVOpenChange,
  onTriggerNonGenericFunction = () =>
    // type: SpecificFunctionTypes,
    // params?: Record<string, unknown>
    null,
}: DataTableProps<TData, TValue>) {
  const [parentForAnimate] = useAutoAnimate();
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );
  const defaultData: TData[] = useMemo(() => [], []);
  // Track the rows that are being edited
  const [editedRows, setEditedRows] = useState<Record<string, boolean>>({});
  // Track the data to show in our table, to be able to modify it w/o a refresh
  // !NOTE - We JSON.stringify() and JSON.parse() here because if data is having nested objects
  // !NOTE - then there is a chance updating the nested object will affect originalData too
  // !NOTE - because React performs shallow copy. Hence, to truly disassociate / de-reference
  // !NOTE - the nested objects, we need to carry this out
  const [items, setItems] = useState<TData[]>(JSON.parse(JSON.stringify(data)));
  // Track the data once again, this time, to restore any modifications, if user cancels their action
  const [originalData, setOriginalData] = useState<TData[]>(
    JSON.parse(JSON.stringify(data))
  );

  useEffect(() => {
    setItems(JSON.parse(JSON.stringify(data)));
    setOriginalData(JSON.parse(JSON.stringify(data)));
  }, [data]);

  const table = useReactTable({
    data: items ?? defaultData,
    columns,
    pageCount: -1,
    state: {
      pagination,
      columnVisibility,
    },
    onPaginationChange: onPaginationChange,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    meta: {
      onDeleteRow,
      onUpdateRow: async (
        rowIndex: number,
        params: Record<string, unknown>,
        updateAttrs: Record<string, unknown>
      ) => {
        await onUpdateRow(params);

        setOriginalData((old) => {
          return old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...items[rowIndex],
                ...updateAttrs,
              };
            }

            return row;
          });
        });
      },
      editedRows,
      setEditedRows,
      trackEdit: (rowIndex: number, columnId: string, value: string) => {
        setItems((old) => {
          return old.map((row, index) => {
            if (index === rowIndex) {
              const updatedRow = { ...row } as Record<string, unknown>;
              let currentObj = updatedRow;
              const keys = columnId.split("_");

              for (let i = 0; i < keys.length - 1; i++) {
                const currentKey = keys[i];
                currentObj[currentKey] = currentObj[currentKey] || {};
                currentObj = currentObj[currentKey] as Record<string, unknown>;
              }

              const finalKey = keys[keys.length - 1];
              currentObj[finalKey] = value;

              return updatedRow;
            }

            return row;
          }) as TData[];
        });
      },
      cancelEdit: (rowIndex: number) => {
        setItems((old) => {
          return old.map((row, index) => {
            if (index === rowIndex) {
              return originalData[rowIndex];
            }

            return row;
          });
        });
      },
      rawData: originalData as unknown[],
      onTriggerNonGenericFunction,
    },
  });

  if (loadingData) {
    return <AccountsTableSkeleton />;
  }

  return (
    <div>
      <div className="border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      style={{
                        width:
                          header.getSize() === Number.MAX_SAFE_INTEGER
                            ? "auto"
                            : header.getSize(),
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRowWrapper
                  key={row.id}
                  row={row}
                  isDraggable={isRowDraggable}
                  table={table}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      className={clsx({
                        "bg-violet-6": editedRows[row.id],
                      })}
                      style={{
                        width:
                          cell.column.getSize() === Number.MAX_SAFE_INTEGER
                            ? "auto"
                            : cell.column.getSize(),
                      }}
                      ref={parentForAnimate}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRowWrapper>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No items to show
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-between py-4">
        <section>
          {showToggleVisibility && (
            <div className="hidden">
              <Dialog open={isCVOpen} onOpenChange={onCVOpenChange}>
                <DialogTrigger asChild>
                  <Button variant="secondary" size="icon">
                    <Settings className="h-4 w-4" />
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Toggle Column Visiblity</DialogTitle>
                    <DialogDescription>
                      Hide / show columns based on your preference
                    </DialogDescription>
                  </DialogHeader>
                  {table.getAllLeafColumns().map((column) => {
                    return (
                      <div
                        key={column.id}
                        className="flex items-center space-x-2"
                      >
                        <Checkbox
                          {...{
                            checked: column.getIsVisible(),
                            onCheckedChange: () => column.toggleVisibility(),
                            id: column.id,
                          }}
                        />
                        <label
                          htmlFor={column.id}
                          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        >
                          {column.id}
                        </label>
                      </div>
                    );
                  })}
                </DialogContent>
              </Dialog>
            </div>
          )}
          {!showToggleVisibility && <div className="invisible"></div>}
        </section>
        <section className="flex items-center space-x-2">
          <div className="text-xs text-gray-500">Total: {totalCount}</div>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={
              !table.getCanNextPage() ||
              !table.getRowModel().rows?.length ||
              table.getRowModel().rows?.length < 10
            }
          >
            Next
          </Button>
        </section>
      </div>
    </div>
  );
}
