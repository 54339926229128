import React, { useMemo } from "react";
import { useSuspenseQuery } from "@apollo/client";
import { Get_QuestionTypes } from "src/gql";
import Select from "react-select";
import { GetQuestionTypesQuery } from "src/generated/graphql";

type TypeFilterType = {
  value: string | undefined;
  onValueSelect: (id: string, value: string) => void;
};

export function TypeFilter({ value, onValueSelect }: TypeFilterType) {
  const { data: typesData } =
    useSuspenseQuery<GetQuestionTypesQuery>(Get_QuestionTypes);

  const types = useMemo(() => {
    const c: typeof typesData.questionTypes = JSON.parse(
      JSON.stringify(typesData.questionTypes)
    );
    const sortedTypes = c.sort((a, b) =>
      a.name.localeCompare(b.name, "en", { sensitivity: "base" })
    );
    return sortedTypes.map((sc) => ({ label: sc.name, value: sc.id }));
  }, [typesData]);

  const selectedValue = useMemo(() => {
    const c: typeof typesData.questionTypes = JSON.parse(
      JSON.stringify(typesData.questionTypes)
    );

    const selectedType = c.find((i) => i.id === value);

    if (!selectedType) {
      return null;
    }

    return { label: selectedType?.name, value: selectedType?.id };
  }, [typesData, value]);

  const onChange = (option: { label: string; value: string } | null) => {
    if (option) {
      onValueSelect("type_id", option.value);
    } else {
      onValueSelect("type_id", "");
    }
  };

  return (
    <Select
      className="w-50"
      name="type"
      onChange={onChange}
      options={types}
      placeholder="All types"
      value={selectedValue}
      isSearchable
      isClearable
    />
  );
}
