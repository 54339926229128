import { createSlice } from "@reduxjs/toolkit";
import { QuestionsInterface } from "src/types/Questions";
export interface QuestionData {
  id: string | null;
}

export interface QuestionStateInterface {
  isEditing: boolean;
  editingQuestions: QuestionsInterface[];
  questions?: QuestionsInterface[];
}

const initialState: QuestionStateInterface = {
  isEditing: false,
  editingQuestions: [],
  questions: [],
};

const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {
    triggerEdit(state, action) {
      state.isEditing = action?.payload?.isEditing;
      state.editingQuestions.push(action?.payload?.question);
    },
    editingCanceled(state, action) {
      state.editingQuestions = state.editingQuestions.filter(
        (question) => question.id !== action.payload.id
      );
    },
    setQuestions(state, action) {
      state.questions = action.payload.questions;
    },
    resetQuestionState(state) {
      state.editingQuestions = [];
      state.isEditing = false;
      state.questions = [];
    },
  },
});

export const {
  triggerEdit,
  editingCanceled,
  setQuestions,
  resetQuestionState,
} = questionSlice.actions;
export const questionReducer = questionSlice.reducer;
