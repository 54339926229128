import React from "react";
import { Chart } from "react-google-charts";

export const Home: React.FC = () => {
  const eventData = [
    ["Status", "Count"],
    ["Draft", 10],
    ["Ready", 20],
    ["Pending Approval", 15],
  ];
  const incomeData = [
    ["Week", "Income"],
    ["June 1st", 4000],
    ["June 8th", 3800],
    ["June 15th", 3950],
  ];

  const eventsPerWeekData = [
    ["Week", "Events"],
    ["June 1st", 35],
    ["June 8th", 34],
    ["June 15th", 30],
  ];
  return (
    <>
      <div className="m-4 grid grid-cols-3 gap-4">
        <div className="p-4">
          <Chart
            width={"100%"}
            height={"300px"}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={eventData}
            options={{
              title: "Event Status",
              pieSliceText: "value", // Add this line to show number counts instead of percentages
            }}
          />
        </div>
        <div className="p-4">
          <Chart
            width={"100%"}
            height={"300px"}
            chartType="ColumnChart" // Change chartType to "BarChart"
            loader={<div>Loading Chart</div>}
            data={incomeData}
            options={{
              title: "Gross Income per Week", // Change title to "Gross Income per Week"
            }}
          />
        </div>
        <div className="p-4">
          <Chart
            width={"100%"}
            height={"300px"}
            chartType="ColumnChart" // Change chartType to "BarChart"
            loader={<div>Loading Chart</div>}
            data={eventsPerWeekData}
            options={{
              title: "Num of Events per Week", // Change title to "Gross Income per Week"
            }}
          />
        </div>
      </div>
      <div className="m-4 grid grid-cols-2 gap-4">
        <div className="p-4">
          <h2 className="text-lg font-semibold text-foreground">
            Events Without Hosts
          </h2>
          <table className="w-full">
            <thead>
              <tr>
                <th className="px-4 py-2">Event Name</th>
                <th className="px-4 py-2">Hosts</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b">
                <td className="px-4 py-2">Event 1</td>
                <td className="px-4 py-2">
                  <div className="flex items-end">
                    <div className="h-6 w-6 rounded-full bg-blue-500"></div>
                    <div className="ml-2 h-6 w-6 rounded-full bg-red-500"></div>
                  </div>
                </td>
              </tr>
              <tr className="border-b">
                <td className="px-4 py-2">Event 2</td>
                <td className="px-4 py-2">
                  <div className="flex">
                    <div className="h-6 w-6 rounded-full bg-green-500"></div>
                    <div className="ml-2 h-6 w-6 rounded-full bg-yellow-500"></div>
                  </div>
                </td>
              </tr>
              <tr className="border-b">
                <td className="px-4 py-2">Event 3</td>
                <td className="px-4 py-2">
                  <div className="flex">
                    <div className="h-6 w-6 rounded-full bg-purple-500"></div>
                    <div className="ml-2 h-6 w-6 rounded-full bg-pink-500"></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="p-4">
          <h2 className="text-lg font-semibold text-foreground">
            Draft Questions for Review
          </h2>
          <table className="w-full">
            <thead>
              <tr>
                <th className="px-4 py-2">Question</th>
                <th className="px-4 py-2">Answer</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b">
                <td className="px-4 py-2">Question 1</td>
                <td className="px-4 py-2">Answer 1</td>
                <td className="px-4 py-2">{/* Actions for Question 1 */}</td>
              </tr>
              <tr className="border-b">
                <td className="px-4 py-2">Question 2</td>
                <td className="px-4 py-2">Answer 2</td>
                <td className="px-4 py-2">{/* Actions for Question 2 */}</td>
              </tr>
              <tr className="border-b">
                <td className="px-4 py-2">Question 3</td>
                <td className="px-4 py-2">Answer 3</td>
                <td className="px-4 py-2">{/* Actions for Question 3 */}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="p-4">{/* Placeholder for table */}</div>
        <div className="p-4">{/* Placeholder for table */}</div>
      </div>
    </>
  );
};
