import React, { Suspense } from "react";
import { EditNotebook } from "src/components/Questions/Notebooks/Edit";

export const NotebookEdit = () => {
  return (
    <Suspense fallback={<span>Loading...</span>}>
      <section className="p-4">
        <EditNotebook />
      </section>
    </Suspense>
  );
};
