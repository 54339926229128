import clsx from "clsx";
import {
  CalendarRange,
  ChevronsLeft,
  ChevronsRight,
  Contact2,
  HelpCircle,
  Home,
  List,
  LogOut,
  MapPin,
  PersonStanding,
  UserRound,
} from "lucide-react";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "src/components/RadixWrapper";
import { useAuth } from "src/hooks/useAuth";
import {
  resetEventState,
  resetNoteState,
  resetQuestionState,
  useAppDispatch,
} from "src/store";

type NavItem = {
  label: string;
  to: string;
  icon: React.ReactNode;
  adminOnly?: boolean;
  break?: boolean;
};

const navItems: NavItem[] = [
  {
    label: "Dashboard",
    to: "home",
    icon: <Home className="h-4 w-4" />,
  },
  {
    label: "Questions",
    to: "questions",
    icon: <HelpCircle className="h-4 w-4" />,
  },
  {
    label: "Categories",
    to: "categories",
    icon: <List className="h-4 w-4" />,
  },
  {
    label: "",
    to: "",
    icon: <></>,
    break: true,
  },
  {
    label: "Events",
    to: "events",
    icon: <CalendarRange className="h-4 w-4" />,
  },
  {
    label: "Hosts",
    to: "hosts",
    icon: <PersonStanding className="h-4 w-4" />,
  },
  {
    label: "Accounts",
    to: "accounts",
    icon: <UserRound className="h-4 w-4" />,
  },
  {
    label: "Locations",
    to: "locations",
    icon: <MapPin className="h-4 w-4" />,
  },
  {
    label: "",
    to: "",
    icon: <></>,
    break: true,
  },
  {
    label: "Users",
    to: "users",
    icon: <Contact2 className="h-4 w-4" />,
    adminOnly: true,
  },
];

type SidebarProps = {
  collapsed: boolean;
  setCollapsed(collapsed: boolean): void;
};

export default function LeftSidebar({ collapsed, setCollapsed }: SidebarProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, signOut } = useAuth();

  const toggleIcon = collapsed ? (
    <ChevronsRight className="h-4 w-4" />
  ) : (
    <ChevronsLeft className="h-4 w-4" />
  );

  const isLinkActive = (pathname: string) =>
    `/${pathname}` === location.pathname;

  // !TODO - Need to remove this code after refactoring the state
  // !TODO - Copied as is from src/components/Layout/Navbar.tsx (now deleted)
  const dispatch = useAppDispatch();
  const signoutDispatch = () => {
    localStorage.clear();

    dispatch(resetEventState());
    dispatch(resetNoteState());
    dispatch(resetQuestionState());
  };
  // !TODO - END

  const handleSignOut = async () => {
    await signOut();

    signoutDispatch();

    navigate("/login");
  };

  const isAdminUser = () => {
    if (user?.roles.includes(process.env.REACT_APP_ADMIN_ROLE_NAME as string)) {
      return true;
    }
  };

  return (
    <div className="bg-blue-950 text-blue-200">
      <div className="flex h-full flex-col justify-between">
        {/* Logo and collapse buttons */}
        <div
          className={clsx({
            "flex items-center border-b border-b-blue-800": true,
            "justify-between p-4": !collapsed,
            "justify-center py-4": collapsed,
          })}
        >
          {!collapsed && (
            <span className="whitespace-nowrap text-2xl font-bold text-blue-300">
              WhatTrivia
            </span>
          )}
          <Button
            className="grid cursor-pointer place-content-center"
            onClick={() => setCollapsed(!collapsed)}
            variant="ghost"
          >
            {toggleIcon}
          </Button>
        </div>

        {/* Navigation Items */}
        <nav className="flex-grow">
          <ul className="my-2 flex flex-col items-stretch gap-2">
            {navItems.map((item) => {
              if (item.adminOnly) {
                if (!isAdminUser()) {
                  return null;
                }
              }
              if (item.break) {
                return (
                  <li
                    key={item.label}
                    className="border-b border-t border-blue-800"
                  ></li>
                );
              }
              return (
                <li
                  key={item.label}
                  className={clsx({
                    "flex hover:bg-blue-600": true,
                    "transition-colors duration-300": true,
                    "mx-3 gap-4 rounded-md p-2": !collapsed,
                    "mx-3 h-10 w-10 gap-2 rounded-full p-2": collapsed,
                    "bg-blue-800": isLinkActive(item.to),
                  })}
                  title={item.label}
                >
                  <Link
                    to={item.to}
                    className={clsx({
                      "flex w-full items-center": true,
                      "justify-center": collapsed,
                      "gap-2": !collapsed,
                    })}
                  >
                    {item.icon} <span>{!collapsed && item.label}</span>
                  </Link>
                </li>
              );
            })}
            <li
              className={clsx({
                "mt-10": true,
                "flex hover:bg-blue-600": true,
                "transition-colors duration-300": true,
                "mx-3 gap-4 rounded-md p-2": !collapsed,
                "mx-3 h-10 w-10 gap-2 rounded-full p-2": collapsed,
              })}
              onClick={handleSignOut}
            >
              <a
                href="#"
                className={clsx({
                  "flex w-full items-center": true,
                  "justify-center": collapsed,
                  "gap-2": !collapsed,
                })}
              >
                <LogOut className="h-4 w-4" />{" "}
                <span>{!collapsed && "Logout"}</span>
              </a>
            </li>
          </ul>
        </nav>

        {/* Footer */}
        <div className="grid place-content-stretch p-4 text-xs text-violet-dark-12">
          {!collapsed && (
            <div className="flex flex-col items-center">
              <span>&#169; 2023 whattrivia.com</span>
              <span>All rights reserved</span>
              <div className="mt-8 flex w-full justify-around">
                <span>Terms</span>
                <span>Contact</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
