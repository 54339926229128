import React, { JSXElementConstructor, ReactElement } from "react";
import { FullPageLayout } from "./components/Layout/FullPageLayout";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import {
  AccountsPage,
  ResetPassword,
  Events,
  ForgotPassword,
  LocationsPage,
  Login,
  NotebookEdit,
  Page404,
  Questions,
  Users,
  ForceChangePassword,
  Home,
} from "./pages";
import { StandaloneLayout } from "./components/Layout/Standalone";
import { useAuth } from "./hooks/useAuth";
import PageLoader from "./components/Loader";
import AccountDetailsPage from "./pages/Accounts/view";
import { CategoriesPage } from "./pages/Categories";
import CategoryDetailsPage from "./pages/Categories/view";
import EventDetailsPage from "./pages/Events/view";
import { EventForm } from "./pages/Events/form";
import LocationDetailsPage from "./pages/Locations/view";
import { HostsPage } from "./pages/Hosts";
import HostDetailsPage from "./pages/Hosts/view";

function ProtectedRoute({
  component,
}: {
  component: ReactElement<
    JSXElementConstructor<string>,
    JSXElementConstructor<string>
  >;
}) {
  const location = useLocation();
  const { loading, isAuthenticated } = useAuth();

  if (loading) {
    return <PageLoader />;
  }

  if (!isAuthenticated) {
    return (
      <Navigate
        to="/login"
        replace={true}
        state={{ referer: location?.pathname, search: location?.search }}
      />
    );
  }

  return component;
}

function UnProtectedRoute({
  component,
}: {
  component: ReactElement<
    JSXElementConstructor<string>,
    JSXElementConstructor<string>
  >;
}) {
  const { loading, isAuthenticated } = useAuth();

  if (loading) {
    return <PageLoader />;
  }

  if (isAuthenticated) {
    return <Navigate to="/home" replace={true} />;
  }

  return component;
}

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<FullPageLayout />}>
          <Route index element={<UnProtectedRoute component={<Login />} />} />
          <Route
            path="login"
            element={<UnProtectedRoute component={<Login />} />}
          />
          <Route
            path="home"
            element={<ProtectedRoute component={<Home />} />}
          />
          <Route
            path="questions"
            element={<ProtectedRoute component={<Questions />} />}
          />
          <Route
            path="locations"
            element={<ProtectedRoute component={<LocationsPage />} />}
          />
          <Route
            path="locations/:id"
            element={<ProtectedRoute component={<LocationDetailsPage />} />}
          />

          <Route
            path="hosts"
            element={<ProtectedRoute component={<HostsPage />} />}
          />
          <Route
            path="hosts/:id"
            element={<ProtectedRoute component={<HostDetailsPage />} />}
          />
          <Route
            path="accounts"
            element={<ProtectedRoute component={<AccountsPage />} />}
          />
          <Route
            path="accounts/:id"
            element={<ProtectedRoute component={<AccountDetailsPage />} />}
          />

          <Route
            path="categories"
            element={<ProtectedRoute component={<CategoriesPage />} />}
          />
          <Route
            path="categories/:id"
            element={<ProtectedRoute component={<CategoryDetailsPage />} />}
          />
          <Route
            path="events"
            element={<ProtectedRoute component={<Events />} />}
          />
          <Route
            path="events/new"
            element={<ProtectedRoute component={<EventForm />} />}
          />
          <Route
            path="events/:id/edit"
            element={<ProtectedRoute component={<EventForm />} />}
          />
          <Route
            path="events/:id/view"
            element={<ProtectedRoute component={<EventDetailsPage />} />}
          />
          <Route
            path="users"
            element={<ProtectedRoute component={<Users />} />}
          />
          <Route
            path="forgot-password"
            element={<UnProtectedRoute component={<ForgotPassword />} />}
          />
          <Route
            path="reset-password"
            element={<UnProtectedRoute component={<ResetPassword />} />}
          />
          <Route
            path="force-change-password"
            element={<UnProtectedRoute component={<ForceChangePassword />} />}
          />
          <Route path="*" element={<Page404 />} />
        </Route>

        <Route path="/notebooks" element={<StandaloneLayout />}>
          <Route
            path=":id/edit"
            element={<ProtectedRoute component={<NotebookEdit />} />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
