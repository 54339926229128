import { gql } from "@apollo/client";
import { string_comparison_exp } from "./queries";

export interface tags_bool_exp {
  name: string_comparison_exp;
}

export const SEARCH_TAGS_QUERY = gql`
  query SearchTags($where: tags_bool_exp, $limit: Int) {
    tags(where: $where, limit: $limit) {
      id
      name
    }
  }
`;

export const SUGGEST_TAGS_QUERY = gql`
  query SuggestTags($question: String!) {
    suggestQuestionTags(question: $question) {
      tags
    }
  }
`;

export const INSERT_TAGS_MUTATION = gql`
  mutation InsertTagsMutation($objects: [tags_insert_input!]!) {
    insert_tags(objects: $objects) {
      affected_rows
      returning {
        id
        name
      }
    }
  }
`;
