import {
  ColumnDef,
  OnChangeFn,
  PaginationState,
  createColumnHelper,
} from "@tanstack/react-table";
import { Pencil } from "lucide-react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, DataTable } from "src/components/RadixWrapper";
import { Accounts } from "src/generated/graphql";
import { AccountForm } from "./Form";

type AccountsPropsType = {
  accounts: Accounts[] | undefined;
  pageIndex: number;
  pageSize: number;
  totalCount: number;
  onPaginationChange: OnChangeFn<PaginationState>;
  loadingData: boolean;
  onDeleteRow: (id: string, name: string) => Promise<void>;
  onUpdateRow: (params: Record<string, unknown>) => Promise<void>;
};

const columnHelper = createColumnHelper<Accounts>();

const defaultColumns: ColumnDef<Accounts, any>[] = [
  columnHelper.accessor("id", {
    header: "ID",
  }),
  columnHelper.accessor("name", {
    header: () => <span className="pl-4">Name</span>,
    cell: (props) => (
      <Button variant="link">
        <Link to={`/accounts/${props.row.getValue("id")}`}>
          {props.getValue()}
        </Link>
      </Button>
    ),
    size: Number.MAX_SAFE_INTEGER,
  }),
  columnHelper.display({
    id: "actions",
    header: () => (
      <span className="inline-flex w-full justify-center">Actions</span>
    ),
    size: 96,
    cell: function ActionCell({ row }) {
      const [showEditDialog, setShowEditDialog] = useState(false);

      const toggleAccountFormModal = () => {
        setShowEditDialog((wasEditing) => {
          return !wasEditing;
        });
      };

      return (
        <div className="flex justify-center gap-2" key="read-mode">
          <Button variant="ghost" size="icon">
            <Pencil className="h-4 w-4" onClick={toggleAccountFormModal} />
          </Button>
          {/* <Button variant="ghost" size="icon">
            <Trash2
              className="h-4 w-4"
              onClick={() =>
                table.options.meta?.onDeleteRow(accountId, accountName)
              }
            />
          </Button> */}
          {showEditDialog && (
            <AccountForm
              showAccount={true}
              toggleShowAccount={toggleAccountFormModal}
              account={row.original}
            />
          )}
        </div>
      );
    },
  }),
];

export function AccountsList({
  accounts,
  pageIndex,
  pageSize,
  totalCount,
  onPaginationChange,
  loadingData,
  onDeleteRow,
  onUpdateRow,
}: AccountsPropsType) {
  return (
    <DataTable
      columns={defaultColumns}
      data={accounts ?? []}
      pageIndex={pageIndex}
      pageSize={pageSize}
      totalCount={totalCount}
      onPaginationChange={onPaginationChange}
      loadingData={loadingData}
      onDeleteRow={onDeleteRow}
      onUpdateRow={onUpdateRow}
    />
  );
}
