import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import FormFieldSkeleton from "src/components/Events/components/Skeleton";
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from "src/components/RadixWrapper";
import { useAuth } from "src/hooks/useAuth";
import { z } from "zod";

export const ResetPassword = () => {
  const { confirmForgotPassword } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const formSchema = z.object({
    resetCode: z.string().min(6),
    newPassword: z.string(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      resetCode: "",
    },
  });

  useEffect(() => {
    setUserEmail(location?.state?.email);
  }, [location?.state?.email]);

  const handleResetPassword = async (data: z.infer<typeof formSchema>) => {
    setIsLoading(true);

    const success = await confirmForgotPassword(
      userEmail,
      data.newPassword,
      data.resetCode
    );

    if (success) {
      setIsLoading(false);
      navigate(`/login`);
    }
  };

  return (
    <div className="container mx-auto">
      <div className="mx-80 my-10">
        <img src="/what_trivia_logo_blue_rgb.png" width={200} />
        <h2 className="mt-4 text-lg">Reset Password</h2>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleResetPassword)}
            className="space-y-8 p-6"
          >
            <FormField
              control={form.control}
              name="resetCode"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Reset Code</FormLabel>
                  {isLoading && <FormFieldSkeleton />}
                  {!isLoading && (
                    <FormControl>
                      <Input {...field} autoFocus />
                    </FormControl>
                  )}
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="newPassword"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>New Password</FormLabel>
                  {isLoading && <FormFieldSkeleton />}
                  {!isLoading && (
                    <FormControl>
                      <Input {...field} type="password" />
                    </FormControl>
                  )}
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button type="submit" disabled={isLoading}>
              {isLoading ? "Submitting..." : "Submit"}
            </Button>
          </form>
        </Form>
      </div>
    </div>
  );
};
