import { zodResolver } from "@hookform/resolvers/zod";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import FormFieldSkeleton from "src/components/Events/components/Skeleton";
import {
  Button,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Form,
} from "src/components/RadixWrapper";
import { useAuth } from "src/hooks/useAuth";
import { z } from "zod";

export const ForgotPassword = () => {
  const formSchema = z.object({
    email: z.string().email(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });

  const { forgotPassword } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleFormSubmit = async (data: z.infer<typeof formSchema>) => {
    try {
      setIsLoading(true);

      await forgotPassword(data.email);

      setIsLoading(false);

      navigate(`/reset-password`, { state: { email: data.email } });
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto">
      <div className="mx-80 my-10">
        <img src="/what_trivia_logo_blue_rgb.png" width={200} />
        <h2 className="mt-4 text-lg">Send Password Reset Link</h2>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleFormSubmit)}
            className="space-y-8 p-6"
          >
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email Address</FormLabel>
                  {isLoading && <FormFieldSkeleton />}
                  {!isLoading && (
                    <FormControl>
                      <Input {...field} autoFocus />
                    </FormControl>
                  )}
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button type="submit" disabled={isLoading}>
              {isLoading ? "Sending..." : "Send Link"}
            </Button>
          </form>
        </Form>
      </div>
    </div>
  );
};
