import React from "react";
import {
  ColumnDef,
  OnChangeFn,
  PaginationState,
  createColumnHelper,
} from "@tanstack/react-table";
import { NotebookInterface } from "src/types/Notebook";
import { Archive, ExternalLink, Info, Star, Trash } from "lucide-react";
import { format } from "date-fns";
import { useMutation } from "@apollo/client";
import { Update_NoteBook_Mutation } from "src/gql";
import { useToast } from "src/components/RadixWrapper/UseToast";
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  DataTable,
  SpecificFunctionTypes,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "src/components/RadixWrapper";

const POPUP_BLOCKED_INFO =
  "Your browser settings prevent opening a new window. Change your browser settings and try again";

type NotebooksProps = {
  notes: NotebookInterface[] | null;
  pageIndex: number;
  pageSize: number;
  onPaginationChange: OnChangeFn<PaginationState>;
  loading: boolean;
  onUpdateRow: (params: Record<string, unknown>) => Promise<void>;
  closeDialog: () => void;
};

const columnHelper = createColumnHelper<NotebookInterface>();

const defaultColumns: ColumnDef<NotebookInterface, any>[] = [
  columnHelper.accessor("id", {
    header: "ID",
  }),
  columnHelper.accessor("name", {
    header: "Name",
    cell: ({ row, table }) => {
      const id = row.getValue("id") as string;

      const editNotebook = () => {
        const w = window.open(
          `${window.location.origin}/notebooks/${id}/edit`,
          "notebook-manager",
          "popup"
        );

        if (!w) {
          alert(POPUP_BLOCKED_INFO);
        } else {
          setTimeout(() => {
            w.document.title = `Notebook - ${row.original.name}`;

            table.options.meta?.onTriggerNonGenericFunction(
              SpecificFunctionTypes.NM_CLOSE_DIALOG
            );
          }, 500);
        }
      };

      return (
        <span
          className="w-full cursor-pointer text-primary hover:underline"
          onClick={editNotebook}
        >
          {row.original.name}
        </span>
      );
    },
    size: Number.MAX_SAFE_INTEGER,
  }),
  columnHelper.display({
    header: "Questions",
    cell: ({ row }) => {
      const q = row.original.notebooks_notebookQuestions.length;

      return (
        <span className="inline-flex w-full items-center justify-center">
          {q}
        </span>
      );
    },
    size: 84,
  }),
  columnHelper.display({
    id: "favorite",
    header: () => (
      <span className="inline-flex w-full justify-center">Favorite</span>
    ),
    cell: function FavoriteCell({ row, table }) {
      const notebookId = row.getValue("id") as string;
      const meta = table.options.meta;

      return (
        <div className="flex items-center justify-center">
          <Star
            fill={row.original.isStarred ? "yellow" : "none"}
            strokeWidth={1}
            className="h-4 w-4 cursor-pointer"
            onClick={() => meta?.onUpdateRow(row.index, { notebookId }, {})}
          />
        </div>
      );
    },
    size: 84,
  }),
  columnHelper.accessor("updated_at", {
    header: () => (
      <span className="inline-flex w-full justify-center">Last updated</span>
    ),
    cell: ({ getValue }) => {
      const updatedAt = getValue() as string;

      return (
        <span className="inline-flex w-full justify-center">
          {format(new Date(updatedAt), "MMM do @ h:mm aaa")}
        </span>
      );
    },
    size: 200,
  }),
  columnHelper.display({
    id: "actions",
    header: () => (
      <span className="inline-flex w-full justify-center">Actions</span>
    ),
    cell: function ActionCell({ row, table }) {
      const id = row.getValue("id") as string;
      const [updateNotebookMutation] = useMutation(Update_NoteBook_Mutation);
      const { toast } = useToast();

      const editNotebook = () => {
        const w = window.open(
          `${window.location.origin}/notebooks/${id}/edit`,
          "notebook-manager",
          "popup"
        );

        if (!w) {
          alert(POPUP_BLOCKED_INFO);
        } else {
          setTimeout(() => {
            w.document.title = `Notebook - ${row.original.name}`;

            table.options.meta?.onTriggerNonGenericFunction(
              SpecificFunctionTypes.NM_CLOSE_DIALOG
            );
          }, 500);
        }
      };

      const archiveNotebook = async () => {
        if (!row.original.isArchived) {
          if (
            window.confirm(
              `Are you sure you want to archive notebook with name "${row.original.name}"`
            )
          ) {
            toast({
              description: `Archiving notebook with name "${row.original.name}"`,
            });

            await updateNotebookMutation({
              variables: {
                pk_columns: { id: id },
                _set: { isArchived: true },
              },
            });

            toast({
              title: "Success",
              description: `Notebook with name "${row.original.name}" successfully archived`,
            });
          }
        } else {
          window.alert("Notebook is already archived!");
        }
      };

      return (
        <div className="flex gap-2" key="read-mode">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant="ghost" size="icon" onClick={editNotebook}>
                  <ExternalLink className="h-4 w-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent className="text-center">
                Open notebook <br /> in a new window
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant="ghost" size="icon" onClick={archiveNotebook}>
                  <Archive className="h-4 w-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Archive notebook</TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <Button variant="ghost" size="icon" disabled>
            <Trash className="h-4 w-4" />
          </Button>
        </div>
      );
    },
    size: 100,
  }),
];

export default function NotebookListToTableAdapter({
  notes,
  pageIndex,
  pageSize,
  onPaginationChange,
  loading,
  onUpdateRow,
  closeDialog,
}: NotebooksProps) {
  const handleSpecificFunction = (type: string) => {
    if (type === SpecificFunctionTypes.NM_CLOSE_DIALOG) {
      closeDialog();
    }
  };

  if ((!notes || !notes.length) && !loading) {
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <div>
          <Alert>
            <Info />
            <AlertTitle>No notebooks</AlertTitle>
            <AlertDescription>
              You don&apos;t have any notebooks yet. Go ahead and create one and
              it will display here.
            </AlertDescription>
          </Alert>
        </div>
      </div>
    );
  }

  return (
    <DataTable
      columns={defaultColumns}
      data={notes ?? []}
      pageIndex={pageIndex}
      pageSize={pageSize}
      totalCount={0}
      onPaginationChange={onPaginationChange}
      loadingData={loading}
      onUpdateRow={onUpdateRow}
      onTriggerNonGenericFunction={handleSpecificFunction}
    />
  );
}
