import React, { FunctionComponent, useState } from "react"; // importing FunctionComponent
import { Outlet } from "react-router-dom";
import clsx from "clsx";
import LeftSidebar from "./LeftSidebar";
import { useAuth } from "src/hooks/useAuth";

type LayoutProps = {
  // title: string;
  // paragraph: string;
};

export const FullPageLayout: FunctionComponent<LayoutProps> = () => {
  const { isAuthenticated } = useAuth();
  const [isCollapsed, setIsCollapsed] = useState(false);

  if (isAuthenticated) {
    return (
      <div
        className={clsx({
          "grid min-h-screen": true,
          "grid-cols-sidebar": !isCollapsed,
          "grid-cols-sidebar-collapsed": isCollapsed,
          "transition-[grid-template-columns] duration-300 ease-in-out": true,
        })}
      >
        <LeftSidebar
          collapsed={isCollapsed}
          setCollapsed={() => setIsCollapsed((prev) => !prev)}
        />
        <main>
          <Outlet />
        </main>
      </div>
    );
  }

  return (
    <div className="grid min-h-screen">
      <main>
        <Outlet />
      </main>
    </div>
  );
};
