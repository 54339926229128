import React from "react";
import { Input, Label } from "src/components/RadixWrapper";
import { EntityStateType } from "./";
import { EntityStateItemType, QueryItem, STEPTYPES, TIMELINE } from "./types";

type SummaryType = {
  finalState: EntityStateType;
  onUpdateName: (arg0: { name: string }) => void;
  isReadOnly?: boolean;
};

type DescriptionTextType<T> = {
  items: T[];
  period?: TIMELINE;
  type: "in" | "notIn";
};

type EntitySummaryType<T> = {
  entityState: EntityStateItemType<T>;
  label: string;
};

function getTimelineText(period: TIMELINE) {
  if (period === TIMELINE.ONEYEAR) {
    return "in the last one year";
  }

  if (period === TIMELINE.THREEYEARS) {
    return "in the last 3 years";
  }

  return "ever";
}

function UsageText<T extends QueryItem>({
  items,
  period,
  type,
}: DescriptionTextType<T>) {
  return (
    <li>
      {type === "in" ? "Used in" : "Not used in"}{" "}
      {items.map((i) => i.name).join(", ")}{" "}
      {period ? getTimelineText(period) : ""}
    </li>
  );
}

function EntitySummary<T extends QueryItem>({
  entityState,
  label,
}: EntitySummaryType<T>) {
  return (
    <>
      <p className="font-semibold">{label}</p>
      <ul className="my-6 ml-6 list-disc [&>li]:mt-2">
        {entityState.notIn.length > 0 && (
          <UsageText
            items={entityState.notIn}
            period={entityState.notSince}
            type="notIn"
          />
        )}

        {entityState.in.length > 0 && (
          <UsageText
            items={entityState.in}
            period={entityState.since}
            type="in"
          />
        )}

        {entityState.notIn.length === 0 && entityState.in.length === 0 && (
          <li>No items</li>
        )}
      </ul>
    </>
  );
}

export function Summary({
  finalState,
  onUpdateName,
  isReadOnly = false,
}: SummaryType) {
  const handleNameChange = (name: string) => {
    onUpdateName({
      name,
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-2">
        <Label className="w-32">Name your filter</Label>
        <Input
          placeholder="The one with uber difficulty"
          className="w-96"
          value={finalState[STEPTYPES.COMPLETE].name}
          onChange={(e) => handleNameChange(e.target.value)}
          disabled={isReadOnly}
          autoFocus
        />
      </div>

      <hr />

      <div>
        <Label>Summary</Label>
        <section className="mt-4">
          <EntitySummary
            entityState={finalState[STEPTYPES.ACCOUNTS]}
            label="Accounts"
          />

          <EntitySummary
            entityState={finalState[STEPTYPES.LOCATIONS]}
            label="Locations"
          />

          <EntitySummary
            entityState={finalState[STEPTYPES.EVENTS]}
            label="Events"
          />
        </section>
      </div>
    </div>
  );
}
