import React from "react";
import {
  Skeleton,
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "../RadixWrapper";

function TableRowSkeleton() {
  return (
    <TableRow>
      <TableCell>
        <Skeleton className="h-9 w-full" />
      </TableCell>
      <TableCell>
        <Skeleton className="h-9 w-full" />
      </TableCell>
      <TableCell>
        <Skeleton className="h-9 w-full" />
      </TableCell>
      <TableCell>
        <Skeleton className="h-9 w-full" />
      </TableCell>
      <TableCell>
        <Skeleton className="h-9 w-full" />
      </TableCell>
      <TableCell>
        <Skeleton className="h-9 w-full" />
      </TableCell>
    </TableRow>
  );
}

export function ApplicationUsersTableSkeleton() {
  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-1/6">Email</TableHead>
            <TableHead className="w-1/6">Is email verified?</TableHead>
            <TableHead className="w-1/6">Is active?</TableHead>
            <TableHead className="w-1/6">Status</TableHead>
            <TableHead className="w-1/6">Roles</TableHead>
            <TableHead className="w-1/6">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {Array(5)
            .fill(0)
            .map((item, index) => (
              <TableRowSkeleton key={index} />
            ))}
        </TableBody>
      </Table>
    </div>
  );
}
