import { configureStore } from "@reduxjs/toolkit";
import { questionReducer, noteReducer, eventReducer } from "./slices";

export const store = configureStore({
  reducer: {
    questionReducer,
    noteReducer,
    eventReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
