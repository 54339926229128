import { ShieldQuestion } from "lucide-react";
import React, { Suspense } from "react";
import RightSidebar, {
  RightSidebarNavItem,
} from "src/components/Common/RightSidebar";
import EventDetails from "src/components/Events/Details";

const SidebarItems: RightSidebarNavItem[] = [
  {
    id: "DUMMY",
    icon: <ShieldQuestion />,
    tooltip: "Dummy action",
  },
];

export const EventDetailsPage = () => {
  return (
    <>
      <Suspense fallback={<span>Loading...</span>}>
        <EventDetails />
      </Suspense>
      {/* <EventQuestions /> */}
      <RightSidebar items={SidebarItems} />
    </>
  );
};

export default EventDetailsPage;
