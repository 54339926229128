import { format } from "date-fns";
import React, { useState } from "react";
import { Checkbox, ScrollArea } from "src/components/RadixWrapper";
import { useGetEventsQuery } from "src/generated/graphql";

type EventListType = {
  dateRange: {
    startDate: string;
    endDate: string;
  };
  updateCheckedEvents: (eventId: string, checked: boolean) => void;
  warnAboutSelection: (eventId: string, isActive: boolean) => void;
};

export function EventList({
  dateRange,
  updateCheckedEvents,
  warnAboutSelection,
}: EventListType) {
  const [checkedEvents, setCheckedEvents] = useState(
    {} as Record<string, boolean>
  );
  const { data } = useGetEventsQuery({
    variables: {
      limit: 10,
      where: {
        status: { _eq: "DRAFT" },
        eventDateTime: {
          _gte: new Date(dateRange.startDate),
          _lte: new Date(dateRange.endDate),
        },
      },
    },
  });

  const updateCheckedStatus = (
    eventId: string,
    checked: boolean,
    count: number | undefined
  ) => {
    updateCheckedEvents(eventId, checked);
    setCheckedEvents((prev) => ({ ...prev, [eventId]: checked }));

    if (count && count > 0) {
      warnAboutSelection(eventId, checked);
    } else {
      warnAboutSelection(eventId, false);
    }
  };

  const getText = (count: number | undefined) => {
    if (typeof count === "number") {
      if (count === 1) {
        return "1 question";
      }

      return `${count} questions`;
    }

    return "";
  };

  return (
    <ScrollArea className="h-64" type="auto">
      <div className="flex w-full flex-col gap-2">
        {/* <div>{data && JSON.stringify(data)}</div> */}
        {data && data.events.length === 0 && (
          <div className="flex h-64 items-center justify-center text-sm">
            There are no events to display
          </div>
        )}
        {data &&
          data.events.map((e) => (
            <div key={e.id} className="flex items-center gap-2">
              <div className="w-4 text-sm">
                <Checkbox
                  checked={!!checkedEvents[e.id]}
                  onCheckedChange={(checked) =>
                    updateCheckedStatus(
                      e.id,
                      !!checked,
                      e.eventQuestions_aggregate?.aggregate?.count
                    )
                  }
                />
              </div>
              <div className="w-32 text-sm">
                {format(new Date(e.eventDateTime), "E, M/d/y")}
              </div>
              <div className="flex-1 text-sm">{e.name}</div>
              <div className="w-24 text-sm">
                <span>
                  {getText(e.eventQuestions_aggregate?.aggregate?.count)}
                </span>
              </div>
            </div>
          ))}
      </div>
    </ScrollArea>
  );
}
