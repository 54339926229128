import { useMutation } from "@apollo/client";
import { Trash2 } from "lucide-react";
import { FC, default as React } from "react";
import { EventQuestions } from "src/generated/graphql";
import { Delete_Event_Questions } from "../../gql";

export interface ListItemeventQuestion {
  eventQuestion: EventQuestions;
  onDeleteQuestion: (id: string) => void;
}

export const EventQuestionListItem: FC<ListItemeventQuestion> = ({
  eventQuestion,
  onDeleteQuestion,
}) => {
  // const dispatch = useAppDispatch();
  const [deleteEventQuestions] = useMutation(Delete_Event_Questions);

  const handleRemoveQuestionFromEvent = async (itemId: string) => {
    // console.log("remove question from event", itemId);
    if (
      confirm(
        "Are you sure you want to delete this question? This will remove it from the event, but not from any notebooks or from the question database."
      )
    ) {
      await deleteEventQuestions({
        variables: {
          id: itemId,
        },
      });
      onDeleteQuestion(itemId);
    }
  };

  return (
    <>
      <div className="flex w-full items-center space-x-2 border-b border-gray-300 bg-white py-1">
        <div className="w-1/10 flex items-center gap-1 text-center">
          <div>{eventQuestion.sortOrder}</div>
        </div>
        <div className="flex w-1/2 items-center gap-1">
          <div>{eventQuestion?.question?.question}</div>
        </div>

        <div className="flex w-2/5 justify-between">
          {/* <div className="cursor-pointer">
            <Edit onClick={openEventEditor} className="h-4 w-4" />
          </div> */}
          <div className="cursor-pointer">
            {/* <Copy className="h-4 w-4" /> */}
          </div>
          <div className="cursor-pointer">
            <Trash2
              onClick={() => handleRemoveQuestionFromEvent(eventQuestion.id)}
              className="h-4 w-4"
            />
          </div>
          {/* <div className="cursor-pointer">
              <FileSymlink className="h-4 w-4" />
            </div> */}
        </div>
      </div>
    </>
  );
};
