import React, { Suspense } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogContent,
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  Input,
  DialogClose,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "../RadixWrapper";
import { z } from "zod";
import {
  DefaultValues,
  FieldValues,
  Path,
  UseFormReturn,
  useForm,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { QCFormFieldType, QCInputSelectFieldType } from "src/types/QuickCreate";
import { useSuspenseQuery } from "@apollo/client";

type QuickCreateEntityType<TFormSchema> = {
  creating: boolean;
  defaultValues: DefaultValues<TFormSchema>;
  entityName: string;
  formFields: QCFormFieldType[];
  isOpen: boolean;
  onOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (values: TFormSchema) => Promise<boolean>;
  schema: z.Schema<TFormSchema>;
};

type SelectFieldType<TFormValues extends FieldValues> = {
  form: UseFormReturn<TFormValues>;
} & QCInputSelectFieldType;

function SelectField<TFormValues extends FieldValues>(
  props: SelectFieldType<TFormValues>
) {
  const { data } = useSuspenseQuery(props.query, {
    variables: props.queryVariables,
    fetchPolicy: "cache-and-network",
  });

  const items = props.queryParser(data);

  return (
    <FormField
      key={props.name}
      control={props.form.control}
      name={props.name as Path<TFormValues>}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{props.label}</FormLabel>
          <Select onValueChange={field.onChange} defaultValue={field.value}>
            <FormControl>
              <SelectTrigger>
                <SelectValue placeholder={props.placeholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {items.map((item) => (
                <SelectItem key={item.value} value={item.value}>
                  {item.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function QuickCreateEntity<TFormSchema extends FieldValues>({
  creating,
  defaultValues,
  entityName,
  formFields,
  isOpen,
  onOpenChange,
  onSubmit,
  schema,
}: QuickCreateEntityType<TFormSchema>) {
  const onFormSubmit = async (data: TFormSchema) => {
    const success = await onSubmit(data);

    if (success) {
      onOpenChange(false);
    }

    form.reset({ ...defaultValues });
  };

  const form = useForm<TFormSchema>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Quickly create {entityName.toLowerCase()}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onFormSubmit)}
            className="space-y-8"
          >
            {formFields.map((f) => {
              if (f.type === "text") {
                return (
                  <FormField
                    key={f.name}
                    control={form.control}
                    name={f.name as Path<TFormSchema>}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{f.label}</FormLabel>
                        <FormControl>
                          <Input
                            placeholder={f.placeholder}
                            {...field}
                            autoFocus
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                );
              }

              if (f.type === "select") {
                return (
                  <Suspense fallback={f.suspenseFallback} key={f.name}>
                    <SelectField form={form} {...f} />
                  </Suspense>
                );
              }
            })}
            <div className="flex justify-between">
              <Button type="submit" disabled={creating}>
                {creating ? "Creating..." : "Create"}
              </Button>
              <DialogClose asChild>
                <Button
                  type="button"
                  variant="secondary"
                  className="cursor-pointer"
                  title="Cancel"
                >
                  Cancel
                </Button>
              </DialogClose>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
