import { useMutation, useQuery } from "@apollo/client";
import { PaginationState } from "@tanstack/react-table";
import { FilePlus2, Search } from "lucide-react";
import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Input,
  Label,
  Switch,
} from "src/components/RadixWrapper";
import { useToast } from "src/components/RadixWrapper/UseToast";
import {
  Create_Note_Mutation,
  Get_NoteBooks,
  Get_Notebooks_Aggregate,
  Update_NoteBook_Mutation,
  order_by,
} from "src/gql";
import { useAuth } from "src/hooks/useAuth";
import { NotebookInterface } from "src/types/Notebook";
import NotebookListToTableAdapter from "./Adapter";

const ITEMS_PER_PAGE = 10;

type CreateNoteMutationResponseType = {
  insert_notebooks_one: NotebookInterface;
};

type GetNotebooksQueryResponseType = {
  notebooks: NotebookInterface[];
};

type GetNotebooksAggregateQueryResponseType = {
  notebooks_aggregate: {
    aggregate: {
      count: number;
    };
  };
};

type FilterType = {
  onlyFavorites?: boolean;
  hideArchived?: boolean;
};

type ListNotebooksProps = {
  closeDialog: () => void;
};

export function ListNotebooks({ closeDialog }: ListNotebooksProps) {
  const { user, updateUser } = useAuth();
  const [notebooks, setNotebooks] = useState<NotebookInterface[]>([]);
  const [creatingNotebook, setCreatingNotebook] = useState(false);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: ITEMS_PER_PAGE,
  });
  const [searchTimeOutId, setSearchTimeOutId] = useState<NodeJS.Timeout | null>(
    null
  );
  const [filters, setFilters] = useState({
    onlyFavorites: user?.settings.notebookManager?.onlyFavorites || false,
    hideArchived: user?.settings.notebookManager?.hideArchived || false,
    keyword: "",
  });
  const [newNotebookName, setNewNotebookName] = useState("");
  const [newNotebookDialogOpen, setNewNotebookDialogOpen] = useState(false);
  const [createNoteMutation] =
    useMutation<CreateNoteMutationResponseType>(Create_Note_Mutation);
  const [updateNotebookMutation] = useMutation(Update_NoteBook_Mutation);
  const { toast } = useToast();

  const { data, refetch, loading } = useQuery<GetNotebooksQueryResponseType>(
    Get_NoteBooks,
    {
      variables: {
        where: {
          owner_id: { _eq: user?.id },
          isStarred: { _eq: filters.onlyFavorites || undefined },
          isArchived: { _neq: filters.hideArchived || undefined },
          name: {
            _ilike: `%${filters.keyword}%`,
          },
        },
        limit: pageSize,
        offset: pageIndex * pageSize,
        order_by: {
          updated_at: order_by.desc,
        },
      },
      fetchPolicy: "network-only",
    }
  );

  const { data: aggData, refetch: refetchAgg } =
    useQuery<GetNotebooksAggregateQueryResponseType>(Get_Notebooks_Aggregate, {
      variables: {
        where: {
          owner_id: { _eq: user?.id },
          isStarred: { _eq: true },
        },
        fetchPolicy: "network-only",
      },
    });

  const createNotebook = async () => {
    if (creatingNotebook || newNotebookName.length === 0) {
      return;
    }

    setCreatingNotebook(true);

    const noteMutation = {
      variables: {
        object: {
          name: newNotebookName,
          owner_id: user?.id,
          org_id: user?.orgId,
        },
      },
    };
    await createNoteMutation(noteMutation);

    toast({
      description: "New notebook created successfully",
    });

    setCreatingNotebook(false);
    setNewNotebookName("");
    setNewNotebookDialogOpen(false);

    refetch();
  };

  const handleSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    if (searchTimeOutId) {
      clearTimeout(searchTimeOutId);
    }

    const timeOutId = setTimeout(() => {
      setFilters((prev) => ({ ...prev, keyword: event.target.value }));
    }, 500);

    setSearchTimeOutId(timeOutId);
  };

  const handleFilters = (f: FilterType) => {
    setFilters((prev) => ({ ...prev, ...f }));
  };

  const toggleStarring = async (params: Record<string, unknown>) => {
    const { notebookId } = params as Record<string, string>;

    if (!data) {
      return;
    }

    const starredNotebook = notebooks.find((n) => n.id === notebookId);

    if (!starredNotebook) {
      return;
    }

    if (starredNotebook.isStarred) {
      await updateNotebookMutation({
        variables: {
          pk_columns: { id: notebookId },
          _set: { isStarred: false },
        },
      });
    } else {
      // Check if limit is reached
      if (process.env.REACT_APP_FAVORITE_LIMIT) {
        if (
          aggData?.notebooks_aggregate.aggregate.count &&
          aggData?.notebooks_aggregate.aggregate.count >=
            parseInt(process.env.REACT_APP_FAVORITE_LIMIT, 10)
        ) {
          toast({
            variant: "destructive",
            description:
              "You have reached the maximum number of notebooks that can be favorited.",
          });

          return;
        }
      }
      await updateNotebookMutation({
        variables: {
          pk_columns: { id: notebookId },
          _set: { isStarred: true },
        },
      });
    }

    refetchAgg();
  };

  // Reset pagination each time filter changes
  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: ITEMS_PER_PAGE,
    });
  }, [filters]);

  // Store latest notebooks in local state to modify them
  // (such as favorite)
  useEffect(() => {
    if (data) {
      setNotebooks(data.notebooks);
    }
  }, [data]);

  // If the filters change, save the update in db
  // and in our app
  useEffect(() => {
    (async () => {
      const notebookManagerSettings = user?.settings.notebookManager;

      if (
        filters.hideArchived !== notebookManagerSettings?.hideArchived ||
        filters.onlyFavorites !== notebookManagerSettings?.onlyFavorites
      ) {
        console.log({ filters, notebookManagerSettings });
        // All checks passed. We indeed have settings that have changed. Update both db and our app
        const settings = {
          ...user?.settings,
          version: 2,
          notebookManager: {
            hideArchived: filters.hideArchived,
            onlyFavorites: filters.onlyFavorites,
          },
        };

        await updateUser({ settings });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <>
      <DialogHeader>
        <DialogTitle className="mb-2">Notebooks</DialogTitle>
      </DialogHeader>
      <section className="flex justify-between gap-4">
        <div className="flex gap-4">
          <Dialog
            open={newNotebookDialogOpen}
            onOpenChange={(open: boolean) => setNewNotebookDialogOpen(open)}
          >
            <DialogTrigger asChild>
              <Button variant="outline" disabled={creatingNotebook}>
                <FilePlus2 className="mr-2 h-4 w-4" />
                New Notebook
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>New Notebook</DialogTitle>
                <DialogDescription>
                  Enter a name for your new notebook here.
                </DialogDescription>
              </DialogHeader>
              <div className="py-4">
                <div className="flex items-center gap-4">
                  <Label htmlFor="name">Notebook Name</Label>
                  <Input
                    id="name"
                    className="col-span-3"
                    placeholder="Christmas Qs"
                    value={newNotebookName}
                    onChange={(e) => setNewNotebookName(e.target.value)}
                    autoFocus
                  />
                </div>
              </div>
              <DialogFooter>
                <Button
                  type="button"
                  onClick={createNotebook}
                  disabled={creatingNotebook || newNotebookName.length === 0}
                >
                  {creatingNotebook ? "Creating..." : "Create Notebook"}
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
          <div className="flex items-center space-x-2">
            <Switch
              id="hide-archived"
              checked={filters.hideArchived}
              onCheckedChange={(v: boolean) =>
                handleFilters({ hideArchived: v })
              }
            />
            <Label htmlFor="hide-archived">Hide archived</Label>
          </div>
          <div className="flex items-center space-x-2">
            <Switch
              id="only-favorites"
              checked={filters.onlyFavorites}
              onCheckedChange={(v: boolean) =>
                handleFilters({ onlyFavorites: v })
              }
            />
            <Label htmlFor="only-favorites">Only favorites</Label>
          </div>
        </div>
        <div className="relative w-64">
          <Search className="absolute bottom-0 left-3 top-0 my-auto h-4 w-4 text-gray-500" />
          <Input
            className="pl-12 pr-4"
            placeholder="Search for notebooks"
            onChange={handleSearch}
          />
        </div>
      </section>
      <section className="overflow-hidden">
        <NotebookListToTableAdapter
          notes={notebooks}
          pageIndex={pageIndex}
          pageSize={pageSize}
          onPaginationChange={setPagination}
          onUpdateRow={toggleStarring}
          loading={loading}
          closeDialog={closeDialog}
        />
      </section>
    </>
  );
}
