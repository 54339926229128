import { gql } from "@apollo/client";
// import { string_comparison_exp } from "./queries";

export enum RateType {
  HOST = "HOST",
  SCORING = "SCORING",
}

export const GET_EVENT_HOSTS = gql`
  query GetEventHosts($where: eventHosts_bool_exp, $limit: Int, $offset: Int) {
    eventHosts(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { type: asc }
    ) {
      id
      type
      notes
      pay
      event_id
      user_id
      host_id
      host {
        id
        nickname
        googleAccountEmail
        rates
      }
    }
    # hosts_aggregate(where: $where) {
    #   aggregate {
    #     count
    #   }
    # }
  }
`;

export const ADD_EVENT_HOST = gql`
  mutation AddEventHost($object: eventHosts_insert_input!) {
    insert_eventHosts_one(object: $object) {
      id
    }
  }
`;

export const UPDATE_EVENT_HOST = gql`
  mutation UpdateEventHost(
    $pk_columns: eventHosts_pk_columns_input!
    $_set: eventHosts_set_input!
  ) {
    update_eventHosts_by_pk(pk_columns: $pk_columns, _set: $_set) {
      id
    }
  }
`;

export const REMOVE_EVENT_HOST = gql`
  mutation RemoveEventHost($id: uuid!) {
    delete_eventHosts_by_pk(id: $id) {
      id
    }
  }
`;
