import React from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import PubNub from "pubnub";
import { useAuth } from "src/hooks/useAuth";

export const PubSubContext = createContext<{
  pubSubInstance: PubNub | null;
  pubSubUuid: string;
}>({
  pubSubInstance: null,
  pubSubUuid: uuidv4(),
});

export const PubSubProvider = React.memo(function PubSubProvider({
  children,
}: {
  children: React.ReactNode | null;
}) {
  const { user } = useAuth();
  const [pubSubInstance, setPubSubInstance] = useState<PubNub | null>(null);
  const [pubSubUuid, setPubSubUuid] = useState<string>("");

  const clientUUID = user?.id;

  useEffect(() => {
    if (!pubSubInstance && clientUUID) {
      console.log("setting up pubnub instance", clientUUID);
      const pubNubInstance = new PubNub({
        publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
        subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY || "",
        userId: clientUUID,
      });

      setPubSubInstance(pubNubInstance);

      setPubSubUuid(clientUUID);

      return () => {
        pubNubInstance.unsubscribeAll();
      };
    } else {
      return;
    }
  }, [pubSubInstance, clientUUID]);

  return (
    <PubSubContext.Provider value={{ pubSubInstance, pubSubUuid }}>
      {children}
    </PubSubContext.Provider>
  );
});

export const usePubSubInstance = () => {
  return useContext(PubSubContext);
};
