import { ShieldQuestion } from "lucide-react";
import React from "react";
import AccountDetails from "src/components/Accounts/Details";
import RightSidebar, {
  RightSidebarNavItem,
} from "src/components/Common/RightSidebar";

const SidebarItems: RightSidebarNavItem[] = [
  {
    id: "DUMMY",
    icon: <ShieldQuestion />,
    tooltip: "Dummy action",
  },
];

export const AccountDetailsPage = () => {
  return (
    <>
      <AccountDetails />
      <RightSidebar items={SidebarItems} />
    </>
  );
};

export default AccountDetailsPage;
