import React, { Suspense } from "react";
import { Link, useParams } from "react-router-dom";
import { Button } from "../RadixWrapper";
import { useSuspenseQuery } from "@apollo/client";
import { GET_HOST_BY_ID } from "src/gql";
import { Hosts } from "src/generated/graphql";
import clsx from "clsx";
import { HostInfoSkeleton } from "./Skeleton";

type GetHostByIdResponseType = {
  hosts_by_pk: Hosts;
};

type DefinitionItemType = {
  type: string;
  data: string;
  isEven?: boolean;
};

function DefinitionItem({ type, data, isEven }: DefinitionItemType) {
  return (
    <div
      className={clsx({
        "grid grid-cols-3 gap-4 px-3 py-6": true,
        "bg-gray-50": isEven,
      })}
    >
      <dt className="text-sm font-medium leading-6 text-gray-900">{type}</dt>
      <dd className="col-span-2 mt-0 text-sm leading-6 text-gray-700">
        {data}
      </dd>
    </div>
  );
}

function HostInfo() {
  const { id } = useParams();

  const { data } = useSuspenseQuery<GetHostByIdResponseType>(GET_HOST_BY_ID, {
    variables: {
      id,
    },
  });

  const host = data.hosts_by_pk;

  return (
    <section>
      <dl>
        <DefinitionItem type="Name" data={host.nickname ?? "N/A"} />
        {/* <DefinitionItem type="Region" data={host.region.name} isEven /> */}
      </dl>
    </section>
  );
}

export default function HostDetails() {
  return (
    <div className="container">
      <div className="my-4 flex w-full items-center justify-between">
        <h1 className="text-xl font-bold">Host Details</h1>
        <Button className="cursor-pointer">
          <Link to="/hosts">View all hosts</Link>
        </Button>
      </div>

      <Suspense fallback={<HostInfoSkeleton />}>
        <HostInfo />
      </Suspense>
    </div>
  );
}
