/**
 * A component that shows two modes for an element
 * Read only mode => You can only view the data
 * Write mode => You can also edit the data
 */

import React, { useEffect, useRef, useState } from "react";
import { CellContext } from "@tanstack/react-table";
import {
  Button,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/RadixWrapper";
import { Link } from "react-router-dom";
import { AlertTriangle } from "lucide-react";

type AddlEditableCellType = {
  linkTo?: string;
  className?: string;
  options?: { id: string; name: string; [key: string]: unknown }[];
  isBeingEdited?: boolean;
};

// https://medium.com/@oherterich/creating-a-textarea-with-dynamic-height-using-react-and-typescript-5ed2d78d9848
// Updates the height of a <textarea> when the value changes.
const useAutosizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
  value: string
) => {
  useEffect(() => {
    if (textAreaRef) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.style.height = "0px";
      const scrollHeight = textAreaRef.scrollHeight;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.style.height = scrollHeight + "px";
    }
  }, [textAreaRef, value]);
};

export function EditableCell<T>({
  getValue,
  table,
  row,
  column,
  linkTo,
  className = "",
  options,
  isBeingEdited = false,
}: CellContext<T, string> & AddlEditableCellType) {
  const initialValue = getValue();
  const columnMeta = column.columnDef.meta;
  const tableMeta = table.options.meta;
  const [value, setValue] = useState(initialValue);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useAutosizeTextArea(textareaRef.current, value);

  // If the value changes externally,
  // we can still make sure we are displaying the latest value
  useEffect(() => setValue(initialValue), [initialValue]);

  // If the input value changes, we need to do the following:
  // - Pass the updated value back to parent for any other sibling
  //    components to work with
  // - Update the input's state (controlled component)
  const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;

    tableMeta?.trackEdit(row.index, column.id, newValue);
    setValue(newValue);
  };

  const onSelectChange = (v: string) => {
    tableMeta?.trackEdit(row.index, column.id, v);
    setValue(v);
  };

  const getOptionLabel = () => {
    if (options) {
      const o = options.find((c) => c.id === initialValue);

      return o?.name;
    }
  };

  // If this component is in edit mode, we show the input field
  // Otherwise, we show it in a <span /> tag
  if (tableMeta?.editedRows[row.id]) {
    if (columnMeta?.type === "select") {
      return (
        <Select value={value} onValueChange={onSelectChange}>
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select a category" />
          </SelectTrigger>
          <SelectContent>
            {options?.map((c) => (
              <SelectItem key={c.id} value={c.id}>
                {c.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      );
    }
    return (
      <textarea
        className={`max-h-36 w-full border-0 bg-transparent bg-none shadow-none outline-none ${className}`}
        value={value}
        onChange={onInputChange}
        ref={textareaRef}
      />
    );
  }

  if (linkTo) {
    return (
      <Button variant="link">
        <Link to={linkTo}>{value}</Link>
      </Button>
    );
  }

  if (columnMeta?.type === "select") {
    return <span>{getOptionLabel()}</span>;
  }
  return (
    <div className="flex flex-col justify-center gap-2">
      {isBeingEdited && (
        <span className="inline-flex items-center gap-x-1.5 self-start rounded-md border border-rose-500 bg-rose-50 px-2 py-1 text-xs text-rose-600">
          <AlertTriangle className="h-4 w-4 text-rose-600" />
          editing . . .
        </span>
      )}
      <span>{value}</span>
    </div>
  );
}
