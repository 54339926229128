import { useMutation, useSuspenseQuery } from "@apollo/client";
import { Reorder } from "framer-motion";
import {
  CalendarDays,
  Check,
  FileArchive,
  GripVertical,
  Pencil,
  Star,
  Trash2,
  X,
} from "lucide-react";
import randomcolor from "randomcolor";
import React, { useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Dialog,
  DialogTrigger,
  Input,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "src/components/RadixWrapper";
import { useToast } from "src/components/RadixWrapper/UseToast";
import {
  Delete_NoteBook_Question_Mutation,
  Get_Notebooks_Aggregate,
  Insert_NoteBook_Question_Mutation,
  Update_Many_NoteBook_Questions_Mutation,
  Update_NoteBook_Mutation,
} from "src/gql";
import {
  Get_NoteBooks_By_Id,
  Subscribe_Notebook_By_Pk,
} from "src/gql/notebooks";
import { useAuth } from "src/hooks/useAuth";
import {
  NoteBookQuestionInterface,
  NotebookInterface,
} from "src/types/Notebook";
import { QuestionsInterface } from "src/types/Questions";
import { EventManager } from "../Events";
import {
  NOTEBOOK_ACTIONS,
  notebookReducer,
  type NotebookStateType,
} from "./reducer";
import clsx from "clsx";

type RadixBadgePropsType = React.ComponentProps<typeof Badge>;

const initialState: NotebookStateType = {
  notebook: null,
  isEditingNotebookName: false,
};

type GetNotebookResponseQueryResponse = {
  notebooks_by_pk: NotebookInterface;
};

type GetNotebooksAggregateQueryResponseType = {
  notebooks_aggregate: {
    aggregate: {
      count: number;
    };
  };
};

type EditNotebookType = {
  notebookId?: string;
};

function getCategoryColor(categoryName: string) {
  const color = randomcolor({ seed: categoryName });

  return color as RadixBadgePropsType["color"];
}

export function EditNotebook({ notebookId }: EditNotebookType) {
  const { id: urlParamsId } = useParams();
  const id = notebookId || urlParamsId;

  const [state, dispatch] = useReducer(notebookReducer, initialState);
  const [newNameForNote, setNewNameForNote] = useState("");
  const [updateNotebookTimeout, setUpdateNotebookTimeout] =
    useState<NodeJS.Timeout>();
  const [IsEMOpen, setIsEMOpen] = useState(false);

  const { user } = useAuth();

  const { data, subscribeToMore } =
    useSuspenseQuery<GetNotebookResponseQueryResponse>(Get_NoteBooks_By_Id, {
      variables: {
        id,
      },
      fetchPolicy: "cache-and-network",
    });

  subscribeToMore({
    document: Subscribe_Notebook_By_Pk,
    variables: {
      id,
    },
    updateQuery: (prev, { subscriptionData }) => {
      if (!subscriptionData.data) {
        return prev;
      }

      const updatedNotebook = subscriptionData.data.notebooks_by_pk;

      return Object.assign({}, prev, {
        notebooks_by_pk: {
          ...updatedNotebook,
        },
      });
    },
  });

  const { data: aggData } =
    useSuspenseQuery<GetNotebooksAggregateQueryResponseType>(
      Get_Notebooks_Aggregate,
      {
        variables: {
          where: {
            owner_id: { _eq: user?.id },
            isStarred: { _eq: true },
          },
          fetchPolicy: "network-only",
        },
      }
    );

  const [updateNotebookMutation] = useMutation(Update_NoteBook_Mutation);
  const [insertNotebookQuestion] = useMutation(
    Insert_NoteBook_Question_Mutation
  );
  const [updateNoteBookQuestion] = useMutation(
    Update_Many_NoteBook_Questions_Mutation
  );
  const [deleteNoteQuestionMutation] = useMutation(
    Delete_NoteBook_Question_Mutation
  );

  const { toast } = useToast();

  useEffect(() => {
    if (data.notebooks_by_pk.id) {
      dispatch({
        type: NOTEBOOK_ACTIONS.NOTEBOOK_LOADED,
        notebook: data.notebooks_by_pk,
      });
    }
  }, [data]);

  const closeWindow = () => {
    window.close();
  };

  const saveNewNoteName = async () => {
    if (newNameForNote && newNameForNote.length) {
      // Optimistic update
      dispatch({
        type: NOTEBOOK_ACTIONS.NOTEBOOK_NAME_EDITED,
        newName: newNameForNote,
      });

      await updateNotebookMutation({
        variables: {
          pk_columns: { id: state.notebook?.id },
          _set: { name: newNameForNote },
        },
      });
    }
  };

  const handleQReorder = (questions: NoteBookQuestionInterface[]) => {
    dispatch({
      type: NOTEBOOK_ACTIONS.NOTEBOOK_QUESTIONS_REORDERED,
      questions,
    });

    const updatePayload = questions.map((item, index) => {
      return {
        where: {
          question_id: { _eq: item.notebookQuestions_question.id },
        },
        _set: { sortOrder: index },
      };
    });

    if (updateNotebookTimeout) {
      clearTimeout(updateNotebookTimeout);
    }

    const timeOutId = setTimeout(async () => {
      updateNoteBookQuestion({
        variables: {
          updates: updatePayload,
        },
      });
    }, 200);

    setUpdateNotebookTimeout(timeOutId);
  };

  const handleQDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (!state.notebook || state.notebook.isArchived) {
      return;
    }

    const newItem: QuestionsInterface = JSON.parse(
      event.dataTransfer.getData("text/plain")
    );

    const exists = state.notebook.notebooks_notebookQuestions.some(
      (q) => q.notebookQuestions_question.id === newItem.id
    );

    if (exists) {
      return;
    }

    const insertedItem = await insertNotebookQuestion({
      variables: {
        object: {
          question_id: newItem.id,
          notebook_id: state.notebook.id,
          sortOrder: state.notebook.notebooks_notebookQuestions.length + 1,
        },
      },
    });

    dispatch({
      type: NOTEBOOK_ACTIONS.NEW_QUESTION_DROPPED,
      question: insertedItem.data.insert_notebookQuestions_one,
    });
  };

  const archiveNotebook = async () => {
    if (!state.notebook) {
      return;
    }

    if (state.notebook.isArchived) {
      alert("This notebook is already archived!");

      return;
    }

    if (window.confirm("Are you sure you want to archive this notebook?")) {
      // Optimistic update
      dispatch({
        type: NOTEBOOK_ACTIONS.NOTEBOOK_ARCHIVED,
      });

      await updateNotebookMutation({
        variables: {
          pk_columns: { id: state.notebook.id },
          _set: { isArchived: true },
        },
      });

      alert(
        "This notebook has been successfully archived. This window will now close"
      );

      closeWindow();
    }
  };

  const handleQDelete = async (question: NoteBookQuestionInterface) => {
    await deleteNoteQuestionMutation({
      variables: {
        id: question.id,
      },
    });

    dispatch({
      type: NOTEBOOK_ACTIONS.NOTEBOOK_QUESTION_DELETED,
      question,
    });
  };

  const toggleStarring = async () => {
    if (!state.notebook) {
      return;
    }

    let isStarred;

    if (state.notebook.isStarred) {
      await updateNotebookMutation({
        variables: {
          pk_columns: { id: state.notebook.id },
          _set: { isStarred: false },
        },
      });
      isStarred = false;
    } else {
      // Check if limit is reached
      if (process.env.REACT_APP_FAVORITE_LIMIT) {
        if (
          aggData?.notebooks_aggregate.aggregate.count &&
          aggData?.notebooks_aggregate.aggregate.count >=
            parseInt(process.env.REACT_APP_FAVORITE_LIMIT, 10)
        ) {
          toast({
            variant: "destructive",
            description:
              "You have reached the maximum number of notebooks that can be favorited.",
          });

          return;
        }
      }
      await updateNotebookMutation({
        variables: {
          pk_columns: { id: state.notebook.id },
          _set: { isStarred: true },
        },
      });
      isStarred = true;
    }

    dispatch({
      type: NOTEBOOK_ACTIONS.NOTEBOOK_FAVORITE_TOGGLE,
      isStarred,
    });

    toast({
      title: "Success",
      description: isStarred
        ? "This notebook has been favorited successfully"
        : "This notebook has been removed from your favorites",
    });
  };

  if (!state.notebook) {
    return null;
  }

  return (
    <>
      <section>
        <div className="mb-4 flex w-full items-center justify-start gap-4">
          <h1 className="text-2xl font-bold">Notebooks</h1>

          {!state.isEditingNotebookName && (
            <>
              <span className="text-gray-400">{state.notebook.name}</span>
              <Button
                onClick={() =>
                  dispatch({
                    type: NOTEBOOK_ACTIONS.EDIT_NOTEBOOK_NAME,
                  })
                }
                variant="ghost"
                size="icon"
              >
                <Pencil size="14" />
              </Button>
            </>
          )}

          {state.isEditingNotebookName && (
            <>
              <Input
                defaultValue={state.notebook.name}
                onChange={(event) => setNewNameForNote(event.target.value)}
                className="w-48"
              />
              <Button onClick={saveNewNoteName} variant="ghost" size="icon">
                <Check />
              </Button>
              <Button
                onClick={() =>
                  dispatch({
                    type: NOTEBOOK_ACTIONS.NOTEBOOK_NAME_EDIT_CANCELLED,
                  })
                }
                variant="ghost"
                size="icon"
              >
                <X />
              </Button>
            </>
          )}

          <div className="ml-auto flex items-center gap-4">
            <span className="text-xs text-gray-500">
              # of questions:{" "}
              {state.notebook.notebooks_notebookQuestions.length}
            </span>
            <Dialog open={IsEMOpen} onOpenChange={setIsEMOpen}>
              <DialogTrigger asChild>
                <Button variant="default">
                  <CalendarDays className="mr-2 h-4 w-4" /> Add to event
                </Button>
              </DialogTrigger>
              <EventManager
                questions={state.notebook.notebooks_notebookQuestions}
                key={`state.currentEditedNote.id-${String(IsEMOpen)}`}
                onCompletion={() => setIsEMOpen(false)}
              />
            </Dialog>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="secondary"
                    size="icon"
                    onClick={toggleStarring}
                  >
                    {state.notebook.isStarred && (
                      <Star fill="yellow" strokeWidth={1} className="h-4 w-4" />
                    )}
                    {!state.notebook.isStarred && (
                      <Star strokeWidth={1} className="h-4 w-4" />
                    )}
                  </Button>
                </TooltipTrigger>
                <TooltipContent className="text-center">
                  Toggle Favorite
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <Button variant="outline" onClick={archiveNotebook}>
              <FileArchive className="mr-2 h-4 w-4" /> Archive
            </Button>
            {!notebookId && (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button variant="outline" size="icon" onClick={closeWindow}>
                      <X className="h-4 w-4" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent className="text-center">
                    Close this <br /> window
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </div>
        </div>
      </section>

      {(!state.notebook.notebooks_notebookQuestions ||
        state.notebook.notebooks_notebookQuestions.length === 0) &&
        !state.notebook.isArchived && (
          <section
            className="flex h-48 w-full items-center justify-center rounded-md border-2 border-dashed border-violet-6"
            onDrop={handleQDrop}
            onDragOver={(e) => e.preventDefault()}
          >
            <p className="text-gray-400">Drag and drop questions here</p>
          </section>
        )}

      {state.notebook.notebooks_notebookQuestions &&
        state.notebook.notebooks_notebookQuestions.length > 0 && (
          <Reorder.Group
            axis="y"
            values={state.notebook.notebooks_notebookQuestions}
            onReorder={handleQReorder}
            onDrop={handleQDrop}
            onDragOver={(e) => e.preventDefault()}
            className={clsx({ "border-b-4 border-dashed pb-48": !notebookId })}
          >
            {state.notebook.notebooks_notebookQuestions.map((question) => {
              return (
                <Reorder.Item
                  key={question.id}
                  value={question}
                  drag={!state.notebook?.isArchived}
                >
                  <div className="grid grid-cols-12 items-center gap-4 border-b p-2">
                    <div className="flex justify-center">
                      <GripVertical className="h-4 w-4 cursor-move" />
                    </div>
                    <Badge
                      variant="custom"
                      className="justify-center text-center"
                      style={{
                        backgroundColor: `${getCategoryColor(
                          question.notebookQuestions_question.category?.name
                        )}`,
                      }}
                    >
                      {question.notebookQuestions_question.category?.name}
                    </Badge>
                    <p className="col-span-9 text-sm">
                      {question.notebookQuestions_question.question}
                    </p>
                    <div className="flex justify-center">
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleQDelete(question)}
                        disabled={state.notebook?.isArchived}
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </div>
                  </div>
                </Reorder.Item>
              );
            })}
          </Reorder.Group>
        )}
    </>
  );
}
