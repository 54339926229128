import { useMutation } from "@apollo/client";
import { PaginationState } from "@tanstack/react-table";
import clsx from "clsx";
import { Filter, LibraryBig, Plus, Search, Settings } from "lucide-react";
import React, { Suspense, useEffect, useMemo, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Outlet, useLocation } from "react-router-dom";
import { CategoryFilter } from "src/components/Categories/CategorySelection";
import RightSidebar, {
  RightSidebarNavItem,
} from "src/components/Common/RightSidebar";
import { FNContainer } from "src/components/FavoriteNotebooks";
import { CreateEditQuestion, QuestionList } from "src/components/Questions";
import { Filter as FilterQuestions } from "src/components/Questions/ComplexFilter";
import { StoredEntityType } from "src/components/Questions/ComplexFilter/CreateEdit/types";
import { prepareComplextFilterQuery } from "src/components/Questions/ComplexFilter/helper";
import { ComplexFilter } from "src/components/Questions/Filter/Complex";
import { StatusFilter } from "src/components/Questions/Filter/Status";
import { TypeFilter } from "src/components/Questions/Filter/Type";
import { ListNotebooks } from "src/components/Questions/Notebooks/List";
import { FilterSelectSkeleton } from "src/components/Questions/Skeleton";
import {
  Button,
  Dialog,
  DialogContent,
  Input,
} from "src/components/RadixWrapper";
import { useToast } from "src/components/RadixWrapper/UseToast";
import {
  GetQuestionsQuery,
  useGetLatestQuestionsSubscription,
  useGetQuestionsQuery,
} from "src/generated/graphql";
import { Update_Question_Mutation } from "src/gql";
import {
  editingCanceled,
  setQuestions,
  useAppDispatch,
  usePubSubInstance,
} from "src/store";
import { ComplexFilterInterface } from "src/types/Filter";
import { QuestionsInterface } from "src/types/Questions";
import { useLocalStorage } from "usehooks-ts";

const ITEMS_PER_PAGE = 30;

enum SIDEBAR_IDS {
  FILTER = "filter",
  NOTEBOOK = "notebook",
  COLUMN_VISIBILITY = "column_visibility",
}

type FilterType = {
  keyword: string;
  category_id: string | undefined;
  type_id: string | undefined;
  status: string | undefined;
  complexFilter: ComplexFilterInterface | undefined;
};

const SidebarItems: RightSidebarNavItem[] = [
  {
    id: SIDEBAR_IDS.FILTER,
    icon: <Filter />,
    tooltip: "Filter Questions (ctrl+f)",
  },
  {
    id: SIDEBAR_IDS.NOTEBOOK,
    icon: <LibraryBig />,
    tooltip: "View notebooks (ctrl+b)",
  },
  {
    id: SIDEBAR_IDS.COLUMN_VISIBILITY,
    icon: <Settings />,
    tooltip: "Toggle column visibility",
  },
];

const initialFilters: FilterType = {
  keyword: "",
  category_id: undefined,
  type_id: undefined,
  status: undefined,
  complexFilter: undefined,
};

const formatQuestionForDisplay = (
  dbQuestion: GetQuestionsQuery["questions"][number]
) => {
  return {
    id: dbQuestion.id,
    question: dbQuestion.question,
    isTimeSensitive: dbQuestion.isTimeSensitive,
    updated_at: new Date(dbQuestion.updated_at ?? new Date()).toISOString(),
    answer: dbQuestion.answer ?? "",
    org_id: dbQuestion.org_id ?? "",
    status: dbQuestion.status ?? "",
    created_at: new Date(dbQuestion.created_at ?? new Date()).toISOString(),
    category: {
      name: dbQuestion.category?.name ?? "",
      id: dbQuestion.category?.id ?? "",
    },
    category_id: dbQuestion.category_id ?? "",
    questionType: {
      name: dbQuestion.questionType?.name ?? "",
      id: dbQuestion.questionType?.id ?? "",
    },
    questionTags: dbQuestion.questionTags ?? [],
    type_id: dbQuestion.type_id ?? "",
  };
};

export const Questions = () => {
  const [lsValue, setLsValue] = useLocalStorage(
    "whattrivia-q-filters",
    initialFilters
  );
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: ITEMS_PER_PAGE,
  });
  const [questionsList, setQuestionsList] = useState<QuestionsInterface[]>([]);
  const [showQDialog, setShowQDialog] = useState(false);
  const [searchTimeOutId, setSearchTimeOutId] = useState<NodeJS.Timeout | null>(
    null
  );
  const [filters, setFilters] = useState<FilterType>(lsValue);
  const [isFilterOpen, setisFilterOpen] = useState(false);
  const [isNMOpen, setIsNMOpen] = useState(false); // NM => Notebook Manager
  const [isCVOpen, setIsCVOpen] = useState(false); // CV => Column Visibility
  const [searchText, setSearchText] = useState(lsValue.keyword);

  const location = useLocation();

  const { toast } = useToast();

  const {
    data: QuestionsData,
    loading: loadingQuestions,
    fetchMore,
  } = useGetQuestionsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: ITEMS_PER_PAGE,
      offset: 0,
      where: {
        _and: [
          {
            _or: [
              {
                question: {
                  _ilike: `%${filters.keyword}%`,
                },
              },
              {
                answer: { _ilike: `%${filters.keyword}%` },
              },
            ],
          },
          {
            category_id: {
              _eq: filters.category_id,
            },
          },
          {
            type_id: {
              _eq: filters.type_id,
            },
          },
          {
            status: {
              _eq: filters.status,
            },
          },
          {
            _or: [
              ...prepareComplextFilterQuery(
                filters.complexFilter
                  ? (JSON.parse(
                      filters.complexFilter.filter as string
                    ) as StoredEntityType)
                  : ({} as StoredEntityType)
              ),
            ],
          },
        ],
      },
    },
  });

  const latestDateTime = useMemo(() => new Date(), []);

  useGetLatestQuestionsSubscription({
    variables: {
      where: {
        updated_at: {
          _gt: latestDateTime,
        },
      },
    },
    fetchPolicy: "no-cache",
    onData({ data: { data: response } }) {
      if ((response?.questions ?? []).length > 0) {
        const updatedQs = response?.questions || [];

        for (let i = 0; i < updatedQs.length; i++) {
          const questionId = updatedQs[i].id;
          dispatch(editingCanceled({ id: questionId }));

          setQuestionsList((old) => {
            return old.map((row) => {
              if (row.id === questionId) {
                return formatQuestionForDisplay(updatedQs[i]);
              }

              return row;
            });
          });
        }
      }
    },
  });

  const [updateQuestion] = useMutation(Update_Question_Mutation);

  const dispatch = useAppDispatch();

  const { pubSubInstance } = usePubSubInstance();

  // const listener = useMemo(
  //   () => ({
  //     status: (statusEvent: Pubnub.StatusEvent) => {
  //       if (statusEvent.category === "PNConnectedCategory") {
  //         console.log("Connected");
  //       }
  //     },
  //     message: async (event: Pubnub.MessageEvent) => {
  //       if (event.message.type === "editing_form") {
  //         dispatch(
  //           triggerEdit({
  //             isEditing: true,
  //             question: event.message.question,
  //           })
  //         );
  //       }

  //       if (event.message.type === "editing_canceled") {
  //         dispatch(editingCanceled({ id: event?.message?.question?.id }));
  //       }

  //       // if (event.message.type === "question_updated") {
  //       //   dispatch(editingCanceled({ id: event?.message?.question?.id }));

  //       //   setQuestionsList((old) => {
  //       //     return old.map((row) => {
  //       //       if (row.id === event.message.question.id) {
  //       //         return event.message.question as QuestionsInterface;
  //       //       }

  //       //       return row;
  //       //     });
  //       //   });
  //       // }
  //     },
  //   }),
  //   [dispatch]
  // );

  // useEffect(() => {
  //   pubSubInstance?.addListener(listener);

  //   pubSubInstance?.subscribe({
  //     withPresence: true,
  //     channels: ["text_channel"],
  //   });

  //   return () => {
  //     pubSubInstance?.removeListener(listener);
  //     pubSubInstance?.unsubscribeAll();
  //   };
  // }, [pubSubInstance, listener]);

  useEffect(() => {
    const term = location.search.replace("?", "");

    if (term.length > 0) {
      setFilters((prev) => ({ ...prev, keyword: term }));
    }
  }, [location.search]);

  useEffect(() => {
    dispatch(
      setQuestions({
        questions: QuestionsData?.questions,
      })
    );
  }, [QuestionsData, dispatch]);

  useEffect(() => {
    fetchMore({
      variables: {
        limit: pageSize,
        offset: pageIndex * pageSize,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult || !fetchMoreResult.questions) return prev;
        return Object.assign(
          {},
          {
            questions: [...fetchMoreResult.questions],
            questions_aggregate: prev.questions_aggregate,
          }
        );
      },
    });
  }, [fetchMore, pageIndex, pageSize]);

  useEffect(() => {
    if (searchTimeOutId) {
      clearTimeout(searchTimeOutId);
    }

    const timeOutId = setTimeout(() => {
      setFilters((prev) => ({ ...prev, keyword: searchText }));
    }, 500);

    setSearchTimeOutId(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    setLsValue(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  // Keyboard shortcuts
  useHotkeys("mod+f", () => setisFilterOpen(true), { preventDefault: true }, [
    isFilterOpen,
  ]);
  useHotkeys("mod+b", () => setIsNMOpen(true), { preventDefault: true }, [
    isNMOpen,
  ]);

  useHotkeys("mod+m", () => setShowQDialog(true), { preventDefault: true }, [
    showQDialog,
  ]);

  useEffect(() => {
    if (QuestionsData?.questions) {
      // ! This re-formatting is necessary because TanStack Table deals only with primitive
      // ! data types. PostgreSQL uses "timestamptz" for date, and typescript associated this
      // ! with "Date" type in javascript. Date type is NOT a primitive type. Hence, this re-formatting
      // ! Note - This means that the QuestionsInterface type that we have defined will use string for
      // ! date fields, not Date. This is applicable to others too - like the EventsInterface
      const items: QuestionsInterface[] = QuestionsData?.questions.map(
        formatQuestionForDisplay
      );

      setQuestionsList(items);
    }
  }, [QuestionsData]);

  const toggleAddQuestionModal = () => {
    setShowQDialog((prev) => !prev);
  };

  const onDeleteRow = async () => {};
  const onUpdateRow = async (params: Record<string, unknown>) => {
    const { answer, category, question, questionId } = params as Record<
      string,
      string
    >;

    toast({
      title: "Updating the question with the following values:",
      description: (
        <pre className="mt-2 w-[340px] whitespace-pre-wrap rounded-md bg-slate-950 p-4">
          <code className="text-white">
            {JSON.stringify(
              {
                id: questionId,
                category_id: category,
                question,
                answer,
              },
              null,
              2
            )}
          </code>
        </pre>
      ),
    });

    try {
      const { data } = await updateQuestion({
        variables: {
          pk_columns: { id: questionId },
          _set: {
            answer,
            question,
            category_id: category,
          },
        },
      });

      toast({
        title: "Success",
        description: "Question updated successfully",
      });

      pubSubInstance?.publish({
        channel: "text_channel",
        message: {
          question: data.update_questions_by_pk,
          type: "question_updated",
        },
      });
    } catch (err) {
      console.log(err);

      toast({
        variant: "destructive",
        title: "FAILED",
        description: `Could not update the question. Try again.`,
      });
    }
  };

  const onFilterChange = (
    id: string,
    value: string | undefined | ComplexFilterInterface
  ) => {
    if (typeof value === "string") {
      if (value?.length === 0) {
        value = undefined;
      }
    }

    setFilters((prev) => ({ ...prev, [id]: value }));
  };

  const onSidebarItemClick = (id: string) => {
    if (id === SIDEBAR_IDS.FILTER) {
      setisFilterOpen(true);
    } else if (id === SIDEBAR_IDS.NOTEBOOK) {
      setIsNMOpen(true);
    } else if (id === SIDEBAR_IDS.COLUMN_VISIBILITY) {
      setIsCVOpen(true);
    }
  };

  const hideClearAllFilters = () => {
    if (
      filters.keyword === "" &&
      filters.category_id === undefined &&
      filters.type_id === undefined &&
      filters.complexFilter === undefined
    ) {
      return true;
    }

    return false;
  };

  const clearAllFilters = () => {
    setFilters(initialFilters);
    setSearchText("");
  };

  return (
    <div className="relative w-full pr-20">
      <Dialog open={isFilterOpen} onOpenChange={setisFilterOpen}>
        <DialogContent className=" min-w-max">
          <FilterQuestions />
        </DialogContent>
      </Dialog>

      <div className="flex w-full flex-col p-4">
        <section className="mb-8">
          <div className="flex w-full items-center justify-between">
            <h1 className="text-xl font-bold">Questions</h1>
            <Button
              className="cursor-pointer"
              title="Create a new Question (ctrl+m)"
              onClick={toggleAddQuestionModal}
            >
              <Plus className="mr-2 h-4 w-4" /> Add Question
            </Button>
          </div>

          <section className="mt-4 flex justify-end gap-2 rounded-t-lg bg-mauve-2 p-2">
            <Button
              variant="link"
              onClick={clearAllFilters}
              className={clsx({ hidden: hideClearAllFilters() })}
            >
              Clear All Filters
            </Button>

            <Suspense fallback={<FilterSelectSkeleton />}>
              <StatusFilter
                value={filters.status}
                onValueSelect={onFilterChange}
              />
            </Suspense>
            <Suspense fallback={<FilterSelectSkeleton />}>
              <TypeFilter
                value={filters.type_id}
                onValueSelect={onFilterChange}
              />
            </Suspense>

            <Suspense fallback={<FilterSelectSkeleton />}>
              <CategoryFilter
                value={filters.category_id}
                onValueSelect={onFilterChange}
              />
            </Suspense>

            <Suspense fallback={<FilterSelectSkeleton />}>
              <ComplexFilter
                value={filters.complexFilter}
                onValueSelect={onFilterChange}
              />
            </Suspense>

            <div className="relative w-64">
              <Search className="absolute bottom-0 left-3 top-0 my-auto h-4 w-4 text-gray-500" />
              <Input
                className="bg-white pl-12 pr-4"
                placeholder="Search for questions"
                onChange={(evt) => setSearchText(evt.target.value)}
                value={searchText}
              />
            </div>
          </section>
          <QuestionList
            questions={questionsList}
            pageIndex={pageIndex}
            pageSize={pageSize}
            totalCount={
              QuestionsData?.questions_aggregate.aggregate?.count || 0
            }
            onPaginationChange={setPagination}
            loadingData={loadingQuestions}
            onDeleteRow={onDeleteRow}
            onUpdateRow={onUpdateRow}
            isCVOpen={isCVOpen}
            onCVOpenChange={setIsCVOpen}
          />
        </section>

        <FNContainer />

        <CreateEditQuestion
          showQuestion={showQDialog}
          toggleShowQuestion={toggleAddQuestionModal}
        />
      </div>

      <Dialog open={isNMOpen} onOpenChange={setIsNMOpen}>
        <DialogContent className="max-w-4xl">
          <ListNotebooks closeDialog={() => setIsNMOpen(false)} />
        </DialogContent>
      </Dialog>

      {/* <div>{JSON.stringify(filters)}</div> */}

      <Outlet />

      <RightSidebar items={SidebarItems} onItemClick={onSidebarItemClick} />
    </div>
  );
};
