import RightSidebar, {
  RightSidebarNavItem,
} from "src/components/Common/RightSidebar";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { CreateEditEvent } from "src/components/Events";
import { LocateFixed, UserPlus } from "lucide-react";
import NewAccountQuickCreate from "src/components/Events/components/NewAccountQC";
import NewLocationQuickCreate from "src/components/Events/components/NewLocationQC";

enum SIDEBAR_IDS {
  CREATEACCOUNT = "create_account",
  CREATELOCATION = "create_location",
}

const SidebarItems: RightSidebarNavItem[] = [
  {
    id: SIDEBAR_IDS.CREATEACCOUNT,
    icon: <UserPlus />,
    tooltip: "Quickly create an account",
  },
  {
    id: SIDEBAR_IDS.CREATELOCATION,
    icon: <LocateFixed />,
    tooltip: "Quickly create a location",
  },
];

export const EventForm = () => {
  const { id: eventId } = useParams();

  const [isNAOpen, setIsNAOpen] = useState(false);
  const [isNLOpen, setIsNLOpen] = useState(false);

  const onSidebarItemClick = (id: string) => {
    if (id === SIDEBAR_IDS.CREATEACCOUNT) {
      setIsNAOpen(true);
    } else if (id === SIDEBAR_IDS.CREATELOCATION) {
      setIsNLOpen(true);
    }
  };

  const onCreateAccount = () => {
    setIsNAOpen(true);
  };

  const onCreateLocation = () => {
    setIsNLOpen(true);
  };

  return (
    <div className="relative flex w-full justify-center pr-20">
      <div className="flex w-auto flex-col p-4">
        <section className="mb-8">
          <div className="flex w-full items-center justify-between">
            <h1 className="text-xl font-bold">
              {eventId ? "Edit event" : "Add New Event"}
            </h1>
          </div>
        </section>

        <CreateEditEvent
          eventId={eventId}
          key={eventId ?? new Date().toISOString()}
          onCreateAccount={onCreateAccount}
          onCreateLocation={onCreateLocation}
        />
      </div>

      <NewAccountQuickCreate isOpen={isNAOpen} onOpenChange={setIsNAOpen} />
      <NewLocationQuickCreate isOpen={isNLOpen} onOpenChange={setIsNLOpen} />

      <RightSidebar items={SidebarItems} onItemClick={onSidebarItemClick} />
    </div>
  );
};
