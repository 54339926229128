import React, { Suspense, useEffect, useState } from "react";
import { Categories } from "src/generated/graphql";
import {
  Button,
  Checkbox,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../RadixWrapper";
import { CategoryFilter } from "./CategorySelection";
import { CategorySelectionSkeleton } from "./Skeleton";
import { useMutation } from "@apollo/client";
import { Merge_Category_Mutation } from "src/gql";
import { useToast } from "../RadixWrapper/UseToast";

type MergeCategoriesType = {
  category: Categories | null;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onDeleteCategory: () => void;
};

type MergeDialogSummaryType = {
  fromCategory: { id: string; name: string };
  toCategory?: { id?: string; name?: string };
  deleteAfterMerge: boolean;
};

const intialFilterState = {
  category_id: undefined,
  deleteAfterMerge: false,
  selectedCategoryLabel: "",
};

function MergeDialogSummary({
  fromCategory,
  toCategory,
  deleteAfterMerge,
}: MergeDialogSummaryType) {
  if (!toCategory?.id) {
    return null;
  }

  return (
    <>
      <hr className="bg-black" />

      <h3 className="text-lg font-bold">Summary</h3>

      <div className="grid grid-cols-2 gap-4 px-0">
        <dt className="text-sm font-medium leading-6 text-gray-900">
          From Category
        </dt>
        <dd className="mt-0 text-sm leading-6 text-gray-700">
          <code
            className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold"
            title={`id: ${fromCategory.id}`}
          >
            {fromCategory.name}
          </code>
        </dd>
      </div>

      <div className="grid grid-cols-2 gap-4 px-0">
        <dt className="text-sm font-medium leading-6 text-gray-900">
          To Category
        </dt>
        <dd className="mt-0 text-sm leading-6 text-gray-700">
          <code
            className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold"
            title={`id: ${toCategory.id}`}
          >
            {toCategory.name}
          </code>
        </dd>
      </div>

      <div className="grid grid-cols-2 gap-4 px-0">
        <dt className="text-sm font-medium leading-6 text-gray-900">
          Delete{" "}
          <code
            className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold"
            title={`id: ${fromCategory.id}`}
          >
            {fromCategory.name}
          </code>
          ?
        </dt>
        <dd className="mt-0 text-sm leading-6 text-gray-700">
          {deleteAfterMerge ? "Yes" : "No"}
        </dd>
      </div>
    </>
  );
}

export default function MergeCategoriesDialog({
  category,
  open,
  onOpenChange,
  onDeleteCategory,
}: MergeCategoriesType) {
  const [filter, setFilter] = useState<{
    category_id: string | undefined;
    deleteAfterMerge: boolean;
    selectedCategoryLabel?: string;
  }>(intialFilterState);

  const [MergeCategoryMutation] = useMutation(Merge_Category_Mutation);

  const { toast } = useToast();

  const handleCategorySelection = (
    id: string,
    value: string | undefined,
    selectedCategoryLabel?: string
  ) => {
    if (typeof value === "string") {
      if (value?.length === 0) {
        value = undefined;
      }
    }

    setFilter((prev) => ({ ...prev, [id]: value, selectedCategoryLabel }));
  };

  const handleDeletionCheck = (checked: boolean) => {
    setFilter((prev) => ({ ...prev, deleteAfterMerge: checked }));
  };

  const handleMerge = async () => {
    if (!filter.category_id) {
      return;
    }

    toast({
      description: `Merging ${category?.name} with ${
        filter.selectedCategoryLabel
      } ${filter.deleteAfterMerge ? "with deletion" : "without deletion"}`,
    });

    await MergeCategoryMutation({
      variables: {
        fromCategory: category?.id,
        toCategory: filter.category_id,
        isDeleteFromCategory: filter.deleteAfterMerge,
      },
    });

    toast({
      description: "Categories merged",
    });

    if (filter.deleteAfterMerge) {
      onDeleteCategory();
    }

    onOpenChange(false);
  };

  useEffect(() => {
    if (!open) {
      setFilter(intialFilterState);
    }
  }, [open]);

  if (!category) {
    return null;
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Merge w/ Category</DialogTitle>
        </DialogHeader>
        <p>
          Select a category to merge{" "}
          {
            <code
              className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold"
              title={`id: ${category.id}`}
            >
              {category.name}
            </code>
          }{" "}
          into. This will update all associated questions to the new category.
        </p>

        <div>
          <Suspense fallback={<CategorySelectionSkeleton />}>
            <CategoryFilter
              value={filter.category_id}
              onValueSelect={handleCategorySelection}
              filterCategoryId={category.id}
            />
          </Suspense>
        </div>

        <div className="flex items-center space-x-2">
          <Checkbox
            checked={filter.deleteAfterMerge}
            onCheckedChange={handleDeletionCheck}
            id="delete-after-merge"
          />
          <label
            htmlFor="delete-after-merge"
            className="text-sm leading-none opacity-70"
          >
            After merge, delete the category{" "}
            <code
              className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold"
              title={category.id}
            >
              {category.name}
            </code>
            ?
          </label>
        </div>

        <MergeDialogSummary
          fromCategory={{ id: category.id, name: category.name }}
          toCategory={{
            id: filter.category_id,
            name: filter.selectedCategoryLabel,
          }}
          deleteAfterMerge={filter.deleteAfterMerge}
        />

        <DialogFooter>
          <Button onClick={handleMerge} disabled={!filter.category_id}>
            Merge
          </Button>

          <DialogClose asChild>
            <Button variant="outline">Cancel</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
