import React, { FunctionComponent } from "react"; // importing FunctionComponent
import { Outlet } from "react-router-dom";
import { useAuth } from "src/hooks/useAuth";

type StandaloneLayoutProps = {
  // title: string;
  // paragraph: string;
};

export const StandaloneLayout: FunctionComponent<
  StandaloneLayoutProps
> = () => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className="grid min-h-screen">
      <main>
        <Outlet />
      </main>
    </div>
  );
};
