import { type ResourcesConfig } from "aws-amplify";

export const HASURA = {
  URL: process.env.REACT_APP_HASURA_URL || "url",
  WSS: process.env.REACT_APP_HASURA_URL_WS || "ws",
  // TOKEN: process.env.REACT_APP_HASURA_TOKEN,
};

export const AMPLIFY_AUTH: ResourcesConfig["Auth"] = {
  Cognito: {
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_IDc as string,
    userPoolClientId: process.env
      .REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID as string,
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID as string,
  },
};

export const GENERAL = {
  awsRegion: process.env.REACT_APP_AWS_REGION as string,
};
