import React from "react";
import clsx from "clsx";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "src/components/RadixWrapper";
import { STEPTYPES } from "./types";

type StepsType = {
  activeStep: STEPTYPES;
  onClick: (arg0: STEPTYPES) => void;
};

type StepItemType = {
  label: string;
  isActive: boolean;
  onClick: () => void;
};

function StepItem({ label, isActive, onClick }: StepItemType) {
  return (
    <BreadcrumbItem onClick={onClick} className="group cursor-pointer">
      <BreadcrumbPage
        className={clsx("text-gray-300 group-hover:text-black", {
          "text-black": isActive,
        })}
      >
        {label}
      </BreadcrumbPage>
    </BreadcrumbItem>
  );
}

export function Steps({ activeStep, onClick }: StepsType) {
  return (
    <Breadcrumb className="mb-8">
      <BreadcrumbList>
        <StepItem
          label="1. Accounts"
          isActive={activeStep >= 0}
          onClick={() => onClick(STEPTYPES.ACCOUNTS)}
        />

        <BreadcrumbSeparator />

        <StepItem
          label="2. Locations"
          isActive={activeStep >= 1}
          onClick={() => onClick(STEPTYPES.LOCATIONS)}
        />

        <BreadcrumbSeparator />

        <StepItem
          label="3. Events"
          isActive={activeStep >= 2}
          onClick={() => onClick(STEPTYPES.EVENTS)}
        />

        <BreadcrumbSeparator />

        <StepItem
          label="4. Complete"
          isActive={activeStep >= 3}
          onClick={() => onClick(STEPTYPES.COMPLETE)}
        />
      </BreadcrumbList>
    </Breadcrumb>
  );
}
