import React from "react";
import { FlexibleQueryMaker } from "./FlexibleQueryMaker";
import { SearchEntity } from "./SearchEntity";
import { EntityStateItemType, QueryItem, STEPTYPES } from "./types";
import { GET_ACCOUNTS, GET_EVENTS, GET_LOCATIONS } from "src/gql";
import { PeriodSelector } from "./PeriodSelector";

type QueryEntityType<T> = {
  type: STEPTYPES;
  entity: EntityStateItemType<T>;
  updateEntity: (item: EntityStateItemType<T>) => void;
};

const EntityParams = {
  [STEPTYPES.ACCOUNTS]: {
    getQuery: GET_ACCOUNTS,
    entity: "accounts" as const,
  },
  [STEPTYPES.LOCATIONS]: {
    getQuery: GET_LOCATIONS,
    entity: "locations" as const,
  },
  [STEPTYPES.EVENTS]: {
    getQuery: GET_EVENTS,
    entity: "events" as const,
  },
  [STEPTYPES.COMPLETE]: null,
};

export function QueryEntity<T extends QueryItem>({
  type,
  entity,
  updateEntity,
}: QueryEntityType<T>) {
  const handleEntityChanges = (
    value: T[] | string,
    key: keyof EntityStateItemType<T>
  ) => {
    console.log({ value, key });
    updateEntity({
      ...entity,
      [key]: value,
    });
  };

  if (type === STEPTYPES.COMPLETE) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col gap-4">
        <FlexibleQueryMaker
          label="Not Used At"
          entity={entity.notIn}
          updateEntity={(items: T[]) => handleEntityChanges(items, "notIn")}
          isEvent={type === STEPTYPES.EVENTS}
        />

        <PeriodSelector
          value={entity.notSince}
          onValueChange={(value: string) =>
            handleEntityChanges(value, "notSince")
          }
          isVisible={type !== STEPTYPES.EVENTS}
          prefix="since"
        />
      </div>

      <SearchEntity
        query={EntityParams[type].getQuery}
        entity={EntityParams[type].entity}
      />

      <div className="flex flex-col gap-4">
        <FlexibleQueryMaker
          label="Was Used At"
          entity={entity.in}
          updateEntity={(items: T[]) => handleEntityChanges(items, "in")}
          isEvent={type === STEPTYPES.EVENTS}
        />

        <PeriodSelector
          value={entity.since}
          onValueChange={(value: string) => {
            console.log("since");
            handleEntityChanges(value, "since");
          }}
          isVisible={type !== STEPTYPES.EVENTS}
          prefix="notsince"
        />
      </div>
    </>
  );
}
