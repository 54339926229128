import React, { useState } from "react";
import { DocumentNode, useQuery } from "@apollo/client";
import { Search } from "lucide-react";
import dateFormat from "dateformat";
import { Input } from "src/components/RadixWrapper";
import { QueryItem } from "./types";

type GetEntityQueryType = {
  [key: string]: QueryItem[];
};

function SearchResultItem<T extends QueryItem>({
  item,
  isEvent = false,
}: {
  item: T;
  isEvent?: boolean;
}) {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (event: React.MouseEvent<EventTarget>) => {
    setPosition({ x: event.screenX, y: event.screenY });
  };

  const handleDragStart = (event: React.DragEvent<HTMLLIElement>, item: T) => {
    event.dataTransfer.clearData();
    event.dataTransfer.setData("text/plain", JSON.stringify(item));
  };

  return (
    <li
      className="border-b p-1 hover:cursor-move hover:bg-gray-100"
      style={{ top: `${position.y}px`, left: `${position.x}px` }}
      onDrag={handleDrag}
      onDragStart={(event) => handleDragStart(event, item)}
      draggable={true}
    >
      <p>{item.name}</p>
      <p className="text-sm text-muted-foreground">
        {isEvent && item.eventDateTime
          ? dateFormat(item.eventDateTime, "mm/dd/yyyy")
          : null}
      </p>
    </li>
  );
}

export function SearchEntity(props: {
  query: DocumentNode;
  entity: "accounts" | "locations" | "events";
}) {
  const { query, entity } = props;
  const [term, setTerm] = useState("");

  // TODO - Handle error in search
  const { data, loading } = useQuery<GetEntityQueryType>(query, {
    variables: {
      limit: 5,
      where: {
        name: {
          _ilike: `%${term}%`,
        },
      },
    },
    skip: !term.length,
  });

  return (
    <div className="min-h-72 w-96 border-x border-gray-200 px-4">
      <div className="relative w-full">
        <Search className="absolute bottom-0 left-3 top-0 my-auto h-4 w-4 text-gray-500" />
        <Input
          value={term}
          onChange={(e) => setTerm(e.target.value)}
          className="mb-4 pl-8 pr-4"
          placeholder={`Search for ${entity}`}
        />
      </div>
      {term.length !== 0 && loading && (
        <p className="text-sm text-gray-500">Searching...</p>
      )}
      {term.length !== 0 && !loading && (
        <>
          <p className="mb-4 text-sm text-gray-500">Results</p>
          <ul>
            {data &&
              (data?.[entity] as QueryItem[]).map((a) => (
                <SearchResultItem
                  key={a.id}
                  item={a}
                  isEvent={entity === "events"}
                />
              ))}
            {data && (data?.[entity] as QueryItem[]).length === 0 && (
              <p className="mt-24 text-center text-xs text-gray-500">
                No search results
              </p>
            )}
          </ul>
        </>
      )}
    </div>
  );
}
