import { gql } from "@apollo/client";

export const Get_Unique_Roles_Query = gql`
  query GetUniqueRolesQuery {
    roles(distinct_on: name) {
      name
    }
  }
`;

export const Get_User_Roles_Query = gql`
  query GetUserRolesQuery($id: String!) {
    users_by_pk(id: $id) {
      id
      users_roles {
        id
        name
      }
    }
  }
`;

export const Search_Users = gql`
  query SearchUsers {
    users {
      id
      first_name
      last_name
      email
    }
  }
`;

export const Remove_Role_For_User_Mutation = gql`
  mutation RemoveRoleForUserMutation($id: uuid!) {
    delete_roles_by_pk(id: $id) {
      id
      name
    }
  }
`;

export const Add_Role_For_User_Mutation = gql`
  mutation InsertRoleForUserMutation($userId: String!, $name: String!) {
    insert_roles_one(object: { user_id: $userId, name: $name }) {
      id
      name
    }
  }
`;
