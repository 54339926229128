import React from "react";
import {
  Skeleton,
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "../RadixWrapper";

function HostsTableRowSkeleton() {
  return (
    <TableRow>
      <TableCell>
        <Skeleton className="h-9 w-full" />
      </TableCell>
      <TableCell>
        <Skeleton className="h-9 w-24" />
      </TableCell>
    </TableRow>
  );
}

export function HostsTableSkeleton() {
  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-full">Name</TableHead>
            <TableHead className="w-full">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {Array(10)
            .fill(0)
            .map((item, index) => (
              <HostsTableRowSkeleton key={index} />
            ))}
        </TableBody>
      </Table>
    </div>
  );
}

export function HostInfoSkeleton() {
  return (
    <section>
      <dl>
        <div className="grid grid-cols-3 gap-4 px-3 py-6">
          <dt className="text-sm font-medium leading-6 text-gray-900">Name</dt>
          <dd className="col-span-2 mt-0 text-sm leading-6 text-gray-700">
            <Skeleton className="h-6 w-96" />
          </dd>
        </div>
        <div className="grid grid-cols-3 gap-4 bg-gray-50 px-3 py-6">
          <dt className="text-sm font-medium leading-6 text-gray-900">
            Region
          </dt>
          <dd className="col-span-2 mt-0 text-sm leading-6 text-gray-700">
            <Skeleton className="h-6 w-96" />
          </dd>
        </div>
      </dl>
    </section>
  );
}
