import gql from "graphql-tag";

export const Get_NoteBooks_By_Id = gql`
  query GetNotebooksById($id: uuid!) {
    notebooks_by_pk(id: $id) {
      id
      owner_id
      name
      isArchived
      notebooks_notebookQuestions(order_by: { sortOrder: asc }) {
        id
        question_id
        sortOrder
        notebookQuestions_question {
          id
          question
          answer
          category {
            id
            name
          }
          created_at
          updated_at
          category_id
        }
      }
      isStarred
    }
  }
`;

export const Subscribe_Notebooks = gql`
  subscription SubscribeNotebooks(
    $where: notebooks_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [notebooks_order_by!]
  ) {
    notebooks(
      where: $where
      limit: $limit
      order_by: $order_by
      offset: $offset
    ) {
      id
      owner_id
      name
      isArchived
      notebooks_notebookQuestions {
        id
        question_id
        sortOrder
        notebookQuestions_question {
          id
          question
          answer
          category {
            id
            name
          }
          created_at
          updated_at
          category_id
        }
      }
      updated_at
      isStarred
    }
  }
`;

export const Subscribe_Notebook_By_Pk = gql`
  subscription SubscribeNotebookByPk($id: uuid!) {
    notebooks_by_pk(id: $id) {
      id
      owner_id
      name
      isArchived
      notebooks_notebookQuestions(order_by: { sortOrder: asc }) {
        id
        question_id
        sortOrder
        notebookQuestions_question {
          id
          question
          answer
          category {
            id
            name
          }
          created_at
          updated_at
          category_id
        }
      }
      isStarred
    }
  }
`;
