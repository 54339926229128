import { createSlice } from "@reduxjs/toolkit";
import { AggregateInfoInterface } from "src/types/AggregateInfo";
import { EventInterface } from "src/types/Event";

export interface EventsStateInterface {
  isEditing: boolean;
  editingEvents: EventInterface[];
  events?: EventInterface[];
  events_info: AggregateInfoInterface;
}

const initialState: EventsStateInterface = {
  isEditing: false,
  editingEvents: [],
  events: [],
  events_info: { aggregate: { count: 0 } },
};

const eventSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    triggerEditEvent(state, action) {
      state.isEditing = action?.payload?.isEditing;
      state.editingEvents.push(action?.payload?.event);
    },
    editingEventCanceled(state, action) {
      state.editingEvents = state.editingEvents.filter(
        (event) => event.id !== action.payload.id
      );
    },
    setEvents(state, action) {
      state.events = action?.payload?.events;
      state.events_info = action?.payload?.events_info;
    },
    resetEventState(state) {
      state.editingEvents = [];
      state.events = [];
      state.isEditing = false;
    },
  },
});

export const {
  triggerEditEvent,
  editingEventCanceled,
  setEvents,
  resetEventState,
} = eventSlice.actions;
export const eventReducer = eventSlice.reducer;
