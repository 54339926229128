import { useMutation } from "@apollo/client";
import clsx from "clsx";
import { Star, PenSquare } from "lucide-react";
import React, { Suspense, useEffect, useState } from "react";
import {
  GetNotebooksQuery,
  Order_By,
  useSubscribeNotebooksSubscription,
} from "src/generated/graphql";
import {
  Insert_NoteBook_Question_Mutation,
  Update_NoteBook_Mutation,
} from "src/gql";
import { useAuth } from "src/hooks/useAuth";
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../RadixWrapper";
import { EditNotebook } from "../Questions/Notebooks/Edit";
import { QuestionsInterface } from "src/types/Questions";
import { useToast } from "../RadixWrapper/UseToast";

type FNWindowProps = {
  notebook: GetNotebooksQuery["notebooks"][number];
};

function FNWindow({ notebook }: FNWindowProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [updateNotebookMutation] = useMutation(Update_NoteBook_Mutation);
  const [questions, setQuestions] = useState<
    typeof notebook.notebooks_notebookQuestions
  >([]);
  const { toast } = useToast();

  useEffect(() => {
    const qs = JSON.parse(
      JSON.stringify(notebook.notebooks_notebookQuestions)
    ) as typeof notebook.notebooks_notebookQuestions;
    setQuestions(qs.sort((a, b) => a.sortOrder - b.sortOrder));
  }, [notebook]);

  const [insertNotebookQuestion] = useMutation(
    Insert_NoteBook_Question_Mutation
  );

  const unFavorite = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (
      window.confirm(
        `Are you sure you want to un-favorite the notebook with title "${notebook.name}"`
      )
    )
      await updateNotebookMutation({
        variables: {
          pk_columns: { id: notebook.id },
          _set: { isStarred: false },
        },
      });
  };

  const handleQDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    const newItem: QuestionsInterface = JSON.parse(
      event.dataTransfer.getData("text/plain")
    );

    const exists = questions.some(
      (q) => q.notebookQuestions_question.id === newItem.id
    );

    if (exists) {
      return;
    }

    toast({
      title: "Adding question to notebook",
      description: `Adding the dropped question to the notebook "${notebook.name}"`,
    });

    const insertedItem = await insertNotebookQuestion({
      variables: {
        object: {
          question_id: newItem.id,
          notebook_id: notebook.id,
          sortOrder: questions.length + 1,
        },
      },
    });

    setQuestions((prev) => [
      ...prev,
      insertedItem.data.insert_notebookQuestions_one,
    ]);

    toast({
      title: "Success",
      description: "New question added successfully",
    });
  };

  return (
    <div
      className={clsx(
        "pointer-events-auto z-40 w-80 rounded-t-lg border bg-white shadow-lg transition-all duration-300 ease-in-out",
        { "h-72": isOpen }
      )}
      onDragOver={(e) => e.preventDefault()}
      onDrop={handleQDrop}
    >
      <div
        className="flex cursor-pointer items-center justify-between gap-4 rounded-t-sm bg-blue-500 px-3 py-1 text-white"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div className="w-9/12">
          <h4 className="truncate text-sm font-semibold" title={notebook.name}>
            {notebook.name}
          </h4>
          <div className="text-xs">{questions.length} questions</div>
        </div>
        <div className="flex w-1/12 justify-end">
          <Dialog>
            <DialogTrigger asChild>
              <Button
                size="icon"
                variant="ghost"
                onClick={(e) => e.stopPropagation()}
                className="hover:bg-inherit hover:text-inherit"
              >
                <PenSquare className="h-4 w-4" />
              </Button>
            </DialogTrigger>
            <DialogContent className="max-w-7xl">
              <DialogHeader>
                <DialogTitle>
                  {/* No Title - Just a gap, for the close dialog button display to have ample space around it */}
                  &nbsp;
                </DialogTitle>
              </DialogHeader>
              <Suspense fallback={<span>Loading...</span>}>
                <section className="max-h-[50vh] overflow-y-auto p-4">
                  <EditNotebook notebookId={notebook.id} />
                </section>
              </Suspense>
            </DialogContent>
          </Dialog>
        </div>
        <div className="flex w-1/12 justify-end" onClick={unFavorite}>
          <Star className="h-4 w-4" fill="#fff" />
        </div>
      </div>
      {isOpen && (
        <div className="h-64 overflow-y-auto p-2 pb-16">
          {questions.length > 0 ? (
            <ol className="list-inside list-decimal space-y-2 text-xs">
              {questions.map((q) => (
                <li
                  key={q.id}
                  className="truncate"
                  title={q.notebookQuestions_question.question}
                >
                  {q.notebookQuestions_question.question}
                </li>
              ))}
            </ol>
          ) : (
            <p className="text-gray-500">No questions added yet</p>
          )}
        </div>
      )}
    </div>
  );
}

// FN => Favorite Notebooks
export function FNContainer() {
  const { user } = useAuth();

  const [favNotebooks, setFavNotebooks] = useState<
    GetNotebooksQuery["notebooks"]
  >([]);

  useSubscribeNotebooksSubscription({
    variables: {
      where: {
        owner_id: { _eq: user?.id },
        isStarred: {
          _eq: true,
        },
      },
      order_by: [
        {
          updated_at: Order_By.Desc,
        },
      ],
    },
    fetchPolicy: "no-cache",
    onData({ data: { data: response } }) {
      if (response?.notebooks) {
        setFavNotebooks(response.notebooks);
      }
    },
  });

  return (
    <div className="pointer-events-none fixed bottom-0 right-20">
      <div className="flex items-end gap-2">
        {favNotebooks.map((fn) => (
          <FNWindow notebook={fn} key={fn.id} />
        ))}
      </div>
    </div>
  );
}
