import React, { useEffect, useState } from "react";
import { Steps } from "./Steps";
import { QueryEntity } from "./QueryEntity";
import { AccountInterface } from "src/types/Account";
import { useGetFilterQuery } from "src/generated/graphql";
import { EntityStateItemType, STEPTYPES, TIMELINE } from "./types";
import {
  Button,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "src/components/RadixWrapper";
import { FILTER_MODES } from "../";
import { Summary } from "./Summary";
import { Create_Filter_Mutation, Update_Filter_Mutation } from "src/gql";
import { useMutation } from "@apollo/client";
import { useToast } from "src/components/RadixWrapper/UseToast";
import { useAuth } from "src/hooks/useAuth";
import { LocationInterface } from "src/types/Location";
import { EventInterface } from "src/types/Event";

export type EntityStateType = {
  [STEPTYPES.ACCOUNTS]: EntityStateItemType<AccountInterface>;
  [STEPTYPES.LOCATIONS]: EntityStateItemType<LocationInterface>;
  [STEPTYPES.EVENTS]: EntityStateItemType<EventInterface>;
  [STEPTYPES.COMPLETE]: { name: string };
};

type CreateEditFilterType = {
  id?: string;
  onChangeMode: (arg0: FILTER_MODES) => void;
};

type ParsedFilterType = {
  accounts: EntityStateItemType<AccountInterface>;
  locations: EntityStateItemType<LocationInterface>;
  events: EntityStateItemType<EventInterface>;
};

export function CreateEditFilter({ id, onChangeMode }: CreateEditFilterType) {
  const [activeStep, setActiveStep] = useState(STEPTYPES.ACCOUNTS);
  const [entityStates, setEntityStates] = useState<EntityStateType>({
    [STEPTYPES.ACCOUNTS]: {
      in: [] as AccountInterface[],
      notIn: [] as AccountInterface[],
      since: TIMELINE.ONEYEAR,
      notSince: TIMELINE.ONEYEAR,
    },
    [STEPTYPES.LOCATIONS]: {
      in: [] as LocationInterface[],
      notIn: [] as LocationInterface[],
      since: TIMELINE.ONEYEAR,
      notSince: TIMELINE.ONEYEAR,
    },
    [STEPTYPES.EVENTS]: {
      in: [] as EventInterface[],
      notIn: [] as EventInterface[],
    },
    [STEPTYPES.COMPLETE]: { name: "" },
  });
  const [saving, setSaving] = useState(false);
  const { user } = useAuth();
  const { toast } = useToast();
  const { data, loading: loadingExistingFilter } = useGetFilterQuery({
    variables: {
      id: id || "",
    },
    skip: !id,
    fetchPolicy: "network-only",
  });
  const [createFilterMutation] = useMutation(Create_Filter_Mutation);
  const [updateFilterMutation] = useMutation(Update_Filter_Mutation);

  const updateEntityItems = (
    item: EntityStateItemType<unknown> | { name: string }
  ) => {
    setEntityStates((prev) => ({
      ...prev,
      [activeStep]: item,
    }));
  };

  const goBack = () => {
    if (activeStep === STEPTYPES.ACCOUNTS) {
      onChangeMode(FILTER_MODES.LIST);
    } else {
      setActiveStep((prev) => prev - 1);
    }
  };

  const goNext = () => {
    if (activeStep !== STEPTYPES.COMPLETE) {
      setActiveStep((prev) => prev + 1);
    } else {
      createEditFilter();
    }
  };

  const createEditFilter = async () => {
    if (saving) {
      return;
    }

    if (entityStates[STEPTYPES.COMPLETE].name.length === 0) {
      toast({
        variant: "destructive",
        description: "Specify a name for your filter",
      });

      return;
    }

    setSaving(true);

    toast({
      description: "Saving filter...",
    });

    try {
      if (id) {
        await updateFilterMutation({
          variables: {
            pk_columns: {
              id,
            },
            _set: {
              filter: JSON.stringify({
                accounts: entityStates[STEPTYPES.ACCOUNTS],
                locations: entityStates[STEPTYPES.LOCATIONS],
                events: entityStates[STEPTYPES.EVENTS],
              }),
              gql: "",
              name: entityStates[STEPTYPES.COMPLETE].name,
            },
          },
        });
      } else {
        await createFilterMutation({
          variables: {
            object: {
              filter: JSON.stringify({
                accounts: entityStates[STEPTYPES.ACCOUNTS],
                locations: entityStates[STEPTYPES.LOCATIONS],
                events: entityStates[STEPTYPES.EVENTS],
              }),
              gql: "",
              name: entityStates[STEPTYPES.COMPLETE].name,
              owner_id: user?.id,
              org_id: user?.orgId,
            },
          },
        });
      }

      toast({
        description: "Filter saved successfully",
      });

      onChangeMode(FILTER_MODES.LIST);
    } catch (err) {
      console.log(err);

      toast({
        variant: "destructive",
        description: "An error occurred when saving the filter. Try again.",
      });

      setSaving(false);
    }
  };

  const getNextText = () => {
    if (id && saving) {
      return "Updating...";
    } else if (saving) {
      return "Creating...";
    } else if (id) {
      return "Update";
    }

    return "Create";
  };

  useEffect(() => {
    if (id && data) {
      const parsedFilter = JSON.parse(
        data?.filters_by_pk?.filter as string
      ) as ParsedFilterType;

      setEntityStates({
        [STEPTYPES.ACCOUNTS]: parsedFilter.accounts,
        [STEPTYPES.LOCATIONS]: parsedFilter.locations,
        [STEPTYPES.EVENTS]: parsedFilter.events,
        [STEPTYPES.COMPLETE]: { name: data?.filters_by_pk?.name },
      } as EntityStateType);
    }
  }, [id, data]);

  if (id && loadingExistingFilter) {
    return (
      <>
        <DialogHeader>
          <DialogTitle>Edit filter</DialogTitle>
        </DialogHeader>

        <section className="mb-4 min-h-72 max-w-screen-xl">
          <Steps activeStep={activeStep} onClick={setActiveStep} />

          <section className="text-center">
            <span>Loading...</span>
          </section>
        </section>

        <DialogFooter className="justify-between">
          <Button variant="ghost" onClick={goBack} disabled={saving}>
            {activeStep === STEPTYPES.ACCOUNTS && "Cancel"}
            {activeStep !== STEPTYPES.ACCOUNTS && "Back"}
          </Button>

          <Button variant="outline" onClick={goNext} disabled={saving}>
            {activeStep === STEPTYPES.COMPLETE &&
              (saving ? "Creating..." : "Create")}
            {activeStep !== STEPTYPES.COMPLETE && "Next"}
          </Button>
        </DialogFooter>
      </>
    );
  }

  return (
    <>
      <DialogHeader>
        <DialogTitle>{id ? "Edit filter" : "Create a new filter"}</DialogTitle>
      </DialogHeader>

      <section className="mb-4 min-h-72 max-w-screen-xl">
        <Steps activeStep={activeStep} onClick={setActiveStep} />

        {!loadingExistingFilter && (
          <section className="flex space-x-4">
            {activeStep !== STEPTYPES.COMPLETE && (
              <QueryEntity
                type={activeStep}
                entity={entityStates[activeStep]}
                updateEntity={updateEntityItems}
                key={activeStep}
              />
            )}
            {activeStep === STEPTYPES.COMPLETE && (
              <Summary
                finalState={entityStates}
                onUpdateName={updateEntityItems}
                isReadOnly={saving}
              />
            )}
          </section>
        )}

        {id && loadingExistingFilter && (
          <section className="text-center">
            <span>Loading...</span>
          </section>
        )}
      </section>

      <DialogFooter className="justify-between">
        <Button variant="ghost" onClick={goBack} disabled={saving}>
          {activeStep === STEPTYPES.ACCOUNTS && "Cancel"}
          {activeStep !== STEPTYPES.ACCOUNTS && "Back"}
        </Button>

        <Button variant="outline" onClick={goNext} disabled={saving}>
          {activeStep === STEPTYPES.COMPLETE && getNextText()}
          {activeStep !== STEPTYPES.COMPLETE && "Next"}
        </Button>
      </DialogFooter>
    </>
  );
}
