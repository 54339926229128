import React from "react";
import { ThreeDots } from "react-loader-spinner";

export const LoadingAnimation = () => {
  return (
    <div className="pl-4">
      <ThreeDots
        height="40"
        width="40"
        radius="4"
        color="gray"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        visible={true}
      />
    </div>
  );
};
