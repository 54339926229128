import { ShieldQuestion } from "lucide-react";
import React from "react";
import RightSidebar, {
  RightSidebarNavItem,
} from "src/components/Common/RightSidebar";
import CategoryDetails from "src/components/Categories/Details";

const SidebarItems: RightSidebarNavItem[] = [
  {
    id: "DUMMY",
    icon: <ShieldQuestion />,
    tooltip: "Dummy action",
  },
];

export const CategoryDetailsPage = () => {
  return (
    <>
      <CategoryDetails />
      <RightSidebar items={SidebarItems} />
    </>
  );
};

export default CategoryDetailsPage;
