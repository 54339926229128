import React, { useMemo } from "react";
import Select from "react-select";
import { SearchHostsQuery, useSearchHostsQuery } from "src/generated/graphql";

type HostSelectionType = {
  onValueSelect: (host: SearchHostsQuery["hosts"][number]) => void;
};

export function HostSelection({ onValueSelect }: HostSelectionType) {
  // const { data: hostsData } = useSuspenseQuery<GetHostsQuery>(Get_Hosts, {
  //   variables: {
  //     where: {
  //       id: {
  //         _neq: filterHostId,
  //       },
  //     },
  //   },
  // });

  const { data: hostsData } = useSearchHostsQuery();

  const hosts = useMemo(() => {
    if (!hostsData) return [];
    const h: SearchHostsQuery["hosts"] = JSON.parse(
      JSON.stringify(hostsData?.hosts)
    );
    const sortedHosts = h.sort((a, b) =>
      (a.nickname || "").localeCompare(b.nickname || "", "en", {
        sensitivity: "base",
      })
    );
    return sortedHosts.map((sh) => ({
      label: sh.nickname,
      value: sh.id,
    }));
  }, [hostsData]);

  const onChange = (option: { value: string } | null) => {
    if (option) {
      const host = hostsData?.hosts.find((h) => h.id === option.value);

      if (host) {
        onValueSelect(host);
      }
    }
  };

  return (
    <Select
      className="w-full"
      name="hosts"
      onChange={onChange}
      options={hosts}
      placeholder="Search hosts"
      value={null}
      isSearchable
      isClearable
    />
  );
}
