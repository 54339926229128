import gql from "graphql-tag";

export const EventStatusEnum = {
  DRAFT: "DRAFT",
  READY: "READY",
  PENDING_APPROVAL: "PENDING_APPROVAL",
  COMPLETE: "COMPLETE",
  CANCELLED: "CANCELLED",
};

export const EventStatusDropdownValues = [
  { label: "Draft", value: EventStatusEnum.DRAFT },
  { label: "Ready to Go", value: EventStatusEnum.READY },
  { label: "Pending Approval", value: EventStatusEnum.PENDING_APPROVAL },
  { label: "Complete", value: EventStatusEnum.COMPLETE },
  { label: "Cancelled", value: EventStatusEnum.CANCELLED },
];
export const GET_EVENTS = gql`
  query GetEvents($where: events_bool_exp, $limit: Int, $offset: Int) {
    events(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { eventDateTime: asc }
    ) {
      id
      account_id
      eventDateTime
      eventQuestions_aggregate {
        aggregate {
          count
        }
      }
      location_id
      location {
        id
        city
        name
        state
      }
      name
      org_id
      status
      cost
      status
      region_id
      region {
        id
        name
      }
    }
    events_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_EVENT_BY_ID = gql`
  query GetEventById($id: uuid!) {
    events_by_pk(id: $id) {
      account {
        name
        id
      }
      eventDateTime
      eventQuestions(order_by: { sortOrder: asc }) {
        sortOrder
        question {
          category {
            name
            id
          }
          id
          question
          answer
        }
      }
      id
      name
      location {
        id
        name
      }
      status
      region_id
      cost
      region {
        id
        name
      }
    }
  }
`;

export const GET_EVENT_QUESTIONS = gql`
  query GetEventQuestions($where: eventQuestions_bool_exp) {
    eventQuestions(where: $where) {
      created_at
      event_id
      id
      notes
      org_id
      question_id
      updated_at
      sortOrder
      event {
        id
        name
        eventDateTime
        cost
        status
      }
      question {
        id
        category {
          name
        }
        question
      }
    }
  }
`;
