import { AccountInterface } from "src/types/Account";
import { EventInterface } from "src/types/Event";
import { LocationInterface } from "src/types/Location";

export enum STEPTYPES {
  ACCOUNTS = 0,
  COMPLETE = 3,
  EVENTS = 2,
  LOCATIONS = 1,
}

export enum TIMELINE {
  ONEYEAR = "oneyear",
  THREEYEARS = "threeyears",
  EVER = "ever",
}

export type EntityStateItemType<T> = {
  in: T[];
  notIn: T[];
  since?: TIMELINE;
  notSince?: TIMELINE;
};

export type QueryItem = {
  id?: string;
  name: string;
  eventDateTime?: string;
};

export type StoredEntityType = {
  accounts: EntityStateItemType<AccountInterface>;
  locations: EntityStateItemType<LocationInterface>;
  events: EntityStateItemType<EventInterface>;
};
