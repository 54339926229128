import { useSuspenseQuery } from "@apollo/client";
import React, { useMemo } from "react";
import Select from "react-select";
import { SearchUsersQuery } from "src/generated/graphql";
import { Search_Users } from "src/gql/application-users";
import { useAuth } from "src/hooks/useAuth";

type HostUserSelectionType = {
  value: string | undefined;
  onValueSelect: (id: string) => void;
};

export function HostUserSelection({
  value,
  onValueSelect,
}: HostUserSelectionType) {
  const { user } = useAuth();
  const { data: usersData } = useSuspenseQuery<SearchUsersQuery>(Search_Users, {
    variables: {
      where: {
        org_id: user?.orgId,
      },
    },
    fetchPolicy: "cache-first",
  });

  const users = useMemo(() => {
    const u: typeof usersData.users = JSON.parse(
      JSON.stringify(usersData.users)
    );
    return u.map((user) => ({ label: user.email, value: user.id }));
  }, [usersData]);

  const selectedValue = useMemo(() => {
    const u: typeof usersData.users = JSON.parse(
      JSON.stringify(usersData.users)
    );

    const selectedUser = u.find((user) => user.id === value);

    if (!selectedUser) {
      return null;
    }

    return { label: selectedUser.email, value: selectedUser.id };
  }, [usersData, value]);

  const onChange = (option: { label: string; value: string } | null) => {
    if (option) {
      onValueSelect(option.value);
    } else {
      onValueSelect("");
    }
  };

  return (
    <Select
      className="w-48"
      name="users"
      onChange={onChange}
      options={users}
      placeholder="Select a user"
      value={selectedValue}
      isSearchable
    />
  );
}
