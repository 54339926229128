import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { GenericPageColumns } from "src/types/Settings";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// Checks if the items in setting1 are having same value as the items in setting 2
// (Order does not matter - items are identified by their name field)
export function areSettingsEqual(
  setting1: GenericPageColumns[],
  setting2: GenericPageColumns[]
) {
  if (setting1.length !== setting2.length) {
    return false;
  }

  const setting1Map = new Map(setting1.map((s) => [s.name, s.isVisible]));

  for (const item of setting2) {
    if (
      !setting1Map.has(item.name) ||
      setting1Map.get(item.name) !== item.isVisible
    ) {
      return false;
    }
  }

  return true;
}
