/**
 * ! DO NOT USE Sheets component here. I faced the following issues:
 * ! - If I add Select component in the form, the sheet would open, then close and then open again
 * ! - I could not disable event propagation if I clicked on any of the action column buttons (Note that
 * !   the entire row in Category table also has a click handler on it - both would trigger). This is how
 * !   Portals behave apparently, but event.stopPropogation() did not help
 */
import { useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import clsx from "clsx";
import { XCircle } from "lucide-react";
import React from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from "src/components/RadixWrapper";
import { Categories } from "src/generated/graphql";
import { Create_Category_Mutation, Update_Category_Mutation } from "src/gql";
import * as z from "zod";
import { useToast } from "../RadixWrapper/UseToast";
import { useAuth } from "src/hooks/useAuth";

const formSchema = z.object({
  name: z.string().min(1),
});

type CreateCategoryFormType = {
  showCategory: boolean;
  toggleShowCategory: () => void;
  category?: Categories;
};

export function CategoryForm({
  showCategory,
  toggleShowCategory,
  category,
}: CreateCategoryFormType) {
  const { user } = useAuth();
  const { toast } = useToast();
  const [createCategoryMutation] = useMutation(Create_Category_Mutation);
  const [updateCategory] = useMutation(Update_Category_Mutation);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: category
      ? {
          name: category.name,
        }
      : {
          name: "",
        },
  });
  // const { pubSubInstance } = usePubSubInstance();

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    console.log("Blah");
    toggleShowCategory();

    toast({
      title: category
        ? "Updating the category with the following values:"
        : "Creating a category with the following values:",
      description: (
        <pre className="mt-2 w-[340px] whitespace-pre-wrap rounded-md bg-slate-950 p-4">
          <code className="text-white">{JSON.stringify(values, null, 2)}</code>
        </pre>
      ),
    });

    try {
      if (category) {
        // const { data } =
        await updateCategory({
          variables: {
            pk_columns: { id: category.id },
            _set: {
              name: values.name,
            },
          },
        });

        // pubSubInstance?.publish({
        //   channel: "text_channel",
        //   message: {
        //     category: data.update_categories_by_pk,
        //     type: "category_updated",
        //   },
        // });
      } else {
        await createCategoryMutation({
          variables: {
            object: {
              name: values.name,
              org_id: user?.orgId,
            },
          },
        });
      }

      toast({
        title: "SUCCESS",
        description: category
          ? "Category updated successfully"
          : "New category created successfully",
      });

      form.reset();
    } catch (err) {
      if (category) {
        console.log("An error occurred during category update");
        console.log(err);

        toast({
          variant: "destructive",
          title: "FAILED",
          description: `Could not update the category. Try again.`,
        });
      } else {
        console.log("An error occurred during category creation");
        console.log(err);

        toast({
          variant: "destructive",
          title: "FAILED",
          description: `Could not create the category. Try again.`,
        });
      }
    }
  };

  return (
    <div
      className={clsx({
        "fixed inset-0 z-10 h-full w-full": true,
        invisible: !showCategory,
      })}
    >
      <div
        onClick={toggleShowCategory}
        className={clsx({
          "absolute inset-0 h-full w-full bg-gray-900": true,
          "transition-all duration-500 ease-out": true,
          "opacity-0": !showCategory,
          "opacity-50": showCategory,
        })}
      ></div>
      <div
        className={clsx({
          "absolute right-0 h-full w-96 bg-white": true,
          "transition-all duration-300 ease-out": true,
          "translate-x-full": !showCategory,
        })}
      >
        <div className="flex flex-col space-y-2 px-6 pt-6">
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-semibold text-foreground">
              {category ? "Edit Category" : "Create Category"}
            </h2>
            <Button
              variant="ghost"
              size="icon"
              className="rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 disabled:pointer-events-none"
              onClick={toggleShowCategory}
            >
              <XCircle className="h-4 w-4" />
            </Button>
          </div>
          <p className="text-sm text-muted-foreground">
            Here you can{" "}
            {category ? "edit an existing category" : "create a new category"}
          </p>
        </div>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-8 p-6"
          >
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Enter the category name</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="eg. Phil's Discount Furniture"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button type="submit">
              {category ? "Save" : "Create Category"}
            </Button>
          </form>
        </Form>
      </div>
    </div>
  );
}
