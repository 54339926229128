import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  date: { input: any; output: any; }
  jsonb: { input: unknown; output: unknown; }
  numeric: { input: number | string; output: number | string; }
  timestamptz: { input: Date; output: Date; }
  uuid: { input: string; output: string; }
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type CategoryResponse = {
  __typename?: 'CategoryResponse';
  primaryCategory?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type MergeCategoriesOutput = {
  __typename?: 'MergeCategoriesOutput';
  affectedQuestionCount: Scalars['String']['output'];
};

export type SignedUrlResponse = {
  __typename?: 'SignedUrlResponse';
  signedUrl: Scalars['String']['output'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "accounts" */
export type Accounts = {
  __typename?: 'accounts';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['uuid']['output'];
  legacyId?: Maybe<Scalars['numeric']['output']>;
  name: Scalars['String']['output'];
  org_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  region: Regions;
  region_id: Scalars['uuid']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "accounts" */
export type Accounts_Aggregate = {
  __typename?: 'accounts_aggregate';
  aggregate?: Maybe<Accounts_Aggregate_Fields>;
  nodes: Array<Accounts>;
};

/** aggregate fields of "accounts" */
export type Accounts_Aggregate_Fields = {
  __typename?: 'accounts_aggregate_fields';
  avg?: Maybe<Accounts_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Accounts_Max_Fields>;
  min?: Maybe<Accounts_Min_Fields>;
  stddev?: Maybe<Accounts_Stddev_Fields>;
  stddev_pop?: Maybe<Accounts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Accounts_Stddev_Samp_Fields>;
  sum?: Maybe<Accounts_Sum_Fields>;
  var_pop?: Maybe<Accounts_Var_Pop_Fields>;
  var_samp?: Maybe<Accounts_Var_Samp_Fields>;
  variance?: Maybe<Accounts_Variance_Fields>;
};


/** aggregate fields of "accounts" */
export type Accounts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Accounts_Avg_Fields = {
  __typename?: 'accounts_avg_fields';
  legacyId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "accounts". All fields are combined with a logical 'AND'. */
export type Accounts_Bool_Exp = {
  _and?: InputMaybe<Array<Accounts_Bool_Exp>>;
  _not?: InputMaybe<Accounts_Bool_Exp>;
  _or?: InputMaybe<Array<Accounts_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  legacyId?: InputMaybe<Numeric_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  region?: InputMaybe<Regions_Bool_Exp>;
  region_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "accounts" */
export enum Accounts_Constraint {
  /** unique or primary key constraint on columns "name" */
  AccountsNameKey = 'accounts_name_key',
  /** unique or primary key constraint on columns "id" */
  AccountsPkey = 'accounts_pkey'
}

/** input type for incrementing numeric columns in table "accounts" */
export type Accounts_Inc_Input = {
  legacyId?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "accounts" */
export type Accounts_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  legacyId?: InputMaybe<Scalars['numeric']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  region?: InputMaybe<Regions_Obj_Rel_Insert_Input>;
  region_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Accounts_Max_Fields = {
  __typename?: 'accounts_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  legacyId?: Maybe<Scalars['numeric']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  region_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Accounts_Min_Fields = {
  __typename?: 'accounts_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  legacyId?: Maybe<Scalars['numeric']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  region_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "accounts" */
export type Accounts_Mutation_Response = {
  __typename?: 'accounts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Accounts>;
};

/** input type for inserting object relation for remote table "accounts" */
export type Accounts_Obj_Rel_Insert_Input = {
  data: Accounts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Accounts_On_Conflict>;
};

/** on_conflict condition type for table "accounts" */
export type Accounts_On_Conflict = {
  constraint: Accounts_Constraint;
  update_columns?: Array<Accounts_Update_Column>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};

/** Ordering options when selecting data from "accounts". */
export type Accounts_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  legacyId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  region?: InputMaybe<Regions_Order_By>;
  region_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: accounts */
export type Accounts_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "accounts" */
export enum Accounts_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LegacyId = 'legacyId',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "accounts" */
export type Accounts_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  legacyId?: InputMaybe<Scalars['numeric']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  region_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Accounts_Stddev_Fields = {
  __typename?: 'accounts_stddev_fields';
  legacyId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Accounts_Stddev_Pop_Fields = {
  __typename?: 'accounts_stddev_pop_fields';
  legacyId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Accounts_Stddev_Samp_Fields = {
  __typename?: 'accounts_stddev_samp_fields';
  legacyId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "accounts" */
export type Accounts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Accounts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Accounts_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  legacyId?: InputMaybe<Scalars['numeric']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  region_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Accounts_Sum_Fields = {
  __typename?: 'accounts_sum_fields';
  legacyId?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "accounts" */
export enum Accounts_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LegacyId = 'legacyId',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Accounts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Accounts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Accounts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Accounts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Accounts_Var_Pop_Fields = {
  __typename?: 'accounts_var_pop_fields';
  legacyId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Accounts_Var_Samp_Fields = {
  __typename?: 'accounts_var_samp_fields';
  legacyId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Accounts_Variance_Fields = {
  __typename?: 'accounts_variance_fields';
  legacyId?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "billingAccounts" */
export type BillingAccounts = {
  __typename?: 'billingAccounts';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  invoiceCCEmail?: Maybe<Scalars['String']['output']>;
  invoiceToEmail?: Maybe<Scalars['String']['output']>;
  legacyId?: Maybe<Scalars['numeric']['output']>;
  name: Scalars['String']['output'];
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  stripeInvoiceFrequency: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "billingAccounts" */
export type BillingAccounts_Aggregate = {
  __typename?: 'billingAccounts_aggregate';
  aggregate?: Maybe<BillingAccounts_Aggregate_Fields>;
  nodes: Array<BillingAccounts>;
};

/** aggregate fields of "billingAccounts" */
export type BillingAccounts_Aggregate_Fields = {
  __typename?: 'billingAccounts_aggregate_fields';
  avg?: Maybe<BillingAccounts_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<BillingAccounts_Max_Fields>;
  min?: Maybe<BillingAccounts_Min_Fields>;
  stddev?: Maybe<BillingAccounts_Stddev_Fields>;
  stddev_pop?: Maybe<BillingAccounts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<BillingAccounts_Stddev_Samp_Fields>;
  sum?: Maybe<BillingAccounts_Sum_Fields>;
  var_pop?: Maybe<BillingAccounts_Var_Pop_Fields>;
  var_samp?: Maybe<BillingAccounts_Var_Samp_Fields>;
  variance?: Maybe<BillingAccounts_Variance_Fields>;
};


/** aggregate fields of "billingAccounts" */
export type BillingAccounts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<BillingAccounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type BillingAccounts_Avg_Fields = {
  __typename?: 'billingAccounts_avg_fields';
  legacyId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "billingAccounts". All fields are combined with a logical 'AND'. */
export type BillingAccounts_Bool_Exp = {
  _and?: InputMaybe<Array<BillingAccounts_Bool_Exp>>;
  _not?: InputMaybe<BillingAccounts_Bool_Exp>;
  _or?: InputMaybe<Array<BillingAccounts_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoiceCCEmail?: InputMaybe<String_Comparison_Exp>;
  invoiceToEmail?: InputMaybe<String_Comparison_Exp>;
  legacyId?: InputMaybe<Numeric_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  stripeCustomerId?: InputMaybe<String_Comparison_Exp>;
  stripeInvoiceFrequency?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "billingAccounts" */
export enum BillingAccounts_Constraint {
  /** unique or primary key constraint on columns "id" */
  BillingAccountsPkey = 'billingAccounts_pkey'
}

/** input type for incrementing numeric columns in table "billingAccounts" */
export type BillingAccounts_Inc_Input = {
  legacyId?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "billingAccounts" */
export type BillingAccounts_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  invoiceCCEmail?: InputMaybe<Scalars['String']['input']>;
  invoiceToEmail?: InputMaybe<Scalars['String']['input']>;
  legacyId?: InputMaybe<Scalars['numeric']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  stripeInvoiceFrequency?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type BillingAccounts_Max_Fields = {
  __typename?: 'billingAccounts_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  invoiceCCEmail?: Maybe<Scalars['String']['output']>;
  invoiceToEmail?: Maybe<Scalars['String']['output']>;
  legacyId?: Maybe<Scalars['numeric']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  stripeInvoiceFrequency?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type BillingAccounts_Min_Fields = {
  __typename?: 'billingAccounts_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  invoiceCCEmail?: Maybe<Scalars['String']['output']>;
  invoiceToEmail?: Maybe<Scalars['String']['output']>;
  legacyId?: Maybe<Scalars['numeric']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  stripeInvoiceFrequency?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "billingAccounts" */
export type BillingAccounts_Mutation_Response = {
  __typename?: 'billingAccounts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<BillingAccounts>;
};

/** on_conflict condition type for table "billingAccounts" */
export type BillingAccounts_On_Conflict = {
  constraint: BillingAccounts_Constraint;
  update_columns?: Array<BillingAccounts_Update_Column>;
  where?: InputMaybe<BillingAccounts_Bool_Exp>;
};

/** Ordering options when selecting data from "billingAccounts". */
export type BillingAccounts_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceCCEmail?: InputMaybe<Order_By>;
  invoiceToEmail?: InputMaybe<Order_By>;
  legacyId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  stripeCustomerId?: InputMaybe<Order_By>;
  stripeInvoiceFrequency?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: billingAccounts */
export type BillingAccounts_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "billingAccounts" */
export enum BillingAccounts_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceCcEmail = 'invoiceCCEmail',
  /** column name */
  InvoiceToEmail = 'invoiceToEmail',
  /** column name */
  LegacyId = 'legacyId',
  /** column name */
  Name = 'name',
  /** column name */
  StripeCustomerId = 'stripeCustomerId',
  /** column name */
  StripeInvoiceFrequency = 'stripeInvoiceFrequency',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "billingAccounts" */
export type BillingAccounts_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  invoiceCCEmail?: InputMaybe<Scalars['String']['input']>;
  invoiceToEmail?: InputMaybe<Scalars['String']['input']>;
  legacyId?: InputMaybe<Scalars['numeric']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  stripeInvoiceFrequency?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type BillingAccounts_Stddev_Fields = {
  __typename?: 'billingAccounts_stddev_fields';
  legacyId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type BillingAccounts_Stddev_Pop_Fields = {
  __typename?: 'billingAccounts_stddev_pop_fields';
  legacyId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type BillingAccounts_Stddev_Samp_Fields = {
  __typename?: 'billingAccounts_stddev_samp_fields';
  legacyId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "billingAccounts" */
export type BillingAccounts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: BillingAccounts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type BillingAccounts_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  invoiceCCEmail?: InputMaybe<Scalars['String']['input']>;
  invoiceToEmail?: InputMaybe<Scalars['String']['input']>;
  legacyId?: InputMaybe<Scalars['numeric']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  stripeInvoiceFrequency?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type BillingAccounts_Sum_Fields = {
  __typename?: 'billingAccounts_sum_fields';
  legacyId?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "billingAccounts" */
export enum BillingAccounts_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceCcEmail = 'invoiceCCEmail',
  /** column name */
  InvoiceToEmail = 'invoiceToEmail',
  /** column name */
  LegacyId = 'legacyId',
  /** column name */
  Name = 'name',
  /** column name */
  StripeCustomerId = 'stripeCustomerId',
  /** column name */
  StripeInvoiceFrequency = 'stripeInvoiceFrequency',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type BillingAccounts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<BillingAccounts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BillingAccounts_Set_Input>;
  /** filter the rows which have to be updated */
  where: BillingAccounts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type BillingAccounts_Var_Pop_Fields = {
  __typename?: 'billingAccounts_var_pop_fields';
  legacyId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type BillingAccounts_Var_Samp_Fields = {
  __typename?: 'billingAccounts_var_samp_fields';
  legacyId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type BillingAccounts_Variance_Fields = {
  __typename?: 'billingAccounts_variance_fields';
  legacyId?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "bonusTypes" */
export type BonusTypes = {
  __typename?: 'bonusTypes';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  org_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "bonusTypes" */
export type BonusTypes_Aggregate = {
  __typename?: 'bonusTypes_aggregate';
  aggregate?: Maybe<BonusTypes_Aggregate_Fields>;
  nodes: Array<BonusTypes>;
};

/** aggregate fields of "bonusTypes" */
export type BonusTypes_Aggregate_Fields = {
  __typename?: 'bonusTypes_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<BonusTypes_Max_Fields>;
  min?: Maybe<BonusTypes_Min_Fields>;
};


/** aggregate fields of "bonusTypes" */
export type BonusTypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<BonusTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "bonusTypes". All fields are combined with a logical 'AND'. */
export type BonusTypes_Bool_Exp = {
  _and?: InputMaybe<Array<BonusTypes_Bool_Exp>>;
  _not?: InputMaybe<BonusTypes_Bool_Exp>;
  _or?: InputMaybe<Array<BonusTypes_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "bonusTypes" */
export enum BonusTypes_Constraint {
  /** unique or primary key constraint on columns "id" */
  BonusTypesPkey = 'bonusTypes_pkey'
}

/** input type for inserting data into table "bonusTypes" */
export type BonusTypes_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type BonusTypes_Max_Fields = {
  __typename?: 'bonusTypes_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type BonusTypes_Min_Fields = {
  __typename?: 'bonusTypes_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "bonusTypes" */
export type BonusTypes_Mutation_Response = {
  __typename?: 'bonusTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<BonusTypes>;
};

/** on_conflict condition type for table "bonusTypes" */
export type BonusTypes_On_Conflict = {
  constraint: BonusTypes_Constraint;
  update_columns?: Array<BonusTypes_Update_Column>;
  where?: InputMaybe<BonusTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "bonusTypes". */
export type BonusTypes_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bonusTypes */
export type BonusTypes_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "bonusTypes" */
export enum BonusTypes_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "bonusTypes" */
export type BonusTypes_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "bonusTypes" */
export type BonusTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: BonusTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type BonusTypes_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "bonusTypes" */
export enum BonusTypes_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type BonusTypes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BonusTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: BonusTypes_Bool_Exp;
};

/** columns and relationships of "bonuses" */
export type Bonuses = {
  __typename?: 'bonuses';
  body?: Maybe<Scalars['String']['output']>;
  bodyAnswers?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  category?: Maybe<Categories>;
  category_id?: Maybe<Scalars['uuid']['output']>;
  created_at: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  eventQuestions: Array<EventQuestions>;
  /** An aggregate relationship */
  eventQuestions_aggregate: EventQuestions_Aggregate;
  fileAnswersKey?: Maybe<Scalars['String']['output']>;
  fileKey?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  org_id: Scalars['uuid']['output'];
  previewKey?: Maybe<Scalars['String']['output']>;
  questionCount: Scalars['numeric']['output'];
  scoringSuggestion?: Maybe<Scalars['String']['output']>;
  serial?: Maybe<Scalars['String']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "bonuses" */
export type BonusesEventQuestionsArgs = {
  distinct_on?: InputMaybe<Array<EventQuestions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventQuestions_Order_By>>;
  where?: InputMaybe<EventQuestions_Bool_Exp>;
};


/** columns and relationships of "bonuses" */
export type BonusesEventQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EventQuestions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventQuestions_Order_By>>;
  where?: InputMaybe<EventQuestions_Bool_Exp>;
};

/** aggregated selection of "bonuses" */
export type Bonuses_Aggregate = {
  __typename?: 'bonuses_aggregate';
  aggregate?: Maybe<Bonuses_Aggregate_Fields>;
  nodes: Array<Bonuses>;
};

/** aggregate fields of "bonuses" */
export type Bonuses_Aggregate_Fields = {
  __typename?: 'bonuses_aggregate_fields';
  avg?: Maybe<Bonuses_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Bonuses_Max_Fields>;
  min?: Maybe<Bonuses_Min_Fields>;
  stddev?: Maybe<Bonuses_Stddev_Fields>;
  stddev_pop?: Maybe<Bonuses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bonuses_Stddev_Samp_Fields>;
  sum?: Maybe<Bonuses_Sum_Fields>;
  var_pop?: Maybe<Bonuses_Var_Pop_Fields>;
  var_samp?: Maybe<Bonuses_Var_Samp_Fields>;
  variance?: Maybe<Bonuses_Variance_Fields>;
};


/** aggregate fields of "bonuses" */
export type Bonuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bonuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Bonuses_Avg_Fields = {
  __typename?: 'bonuses_avg_fields';
  questionCount?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "bonuses". All fields are combined with a logical 'AND'. */
export type Bonuses_Bool_Exp = {
  _and?: InputMaybe<Array<Bonuses_Bool_Exp>>;
  _not?: InputMaybe<Bonuses_Bool_Exp>;
  _or?: InputMaybe<Array<Bonuses_Bool_Exp>>;
  body?: InputMaybe<String_Comparison_Exp>;
  bodyAnswers?: InputMaybe<String_Comparison_Exp>;
  category?: InputMaybe<Categories_Bool_Exp>;
  category_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  eventQuestions?: InputMaybe<EventQuestions_Bool_Exp>;
  eventQuestions_aggregate?: InputMaybe<EventQuestions_Aggregate_Bool_Exp>;
  fileAnswersKey?: InputMaybe<String_Comparison_Exp>;
  fileKey?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  instructions?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  previewKey?: InputMaybe<String_Comparison_Exp>;
  questionCount?: InputMaybe<Numeric_Comparison_Exp>;
  scoringSuggestion?: InputMaybe<String_Comparison_Exp>;
  serial?: InputMaybe<String_Comparison_Exp>;
  thumbnailUrl?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "bonuses" */
export enum Bonuses_Constraint {
  /** unique or primary key constraint on columns "id" */
  BonusPkey = 'bonus_pkey'
}

/** input type for incrementing numeric columns in table "bonuses" */
export type Bonuses_Inc_Input = {
  questionCount?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "bonuses" */
export type Bonuses_Insert_Input = {
  body?: InputMaybe<Scalars['String']['input']>;
  bodyAnswers?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Categories_Obj_Rel_Insert_Input>;
  category_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  eventQuestions?: InputMaybe<EventQuestions_Arr_Rel_Insert_Input>;
  fileAnswersKey?: InputMaybe<Scalars['String']['input']>;
  fileKey?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  previewKey?: InputMaybe<Scalars['String']['input']>;
  questionCount?: InputMaybe<Scalars['numeric']['input']>;
  scoringSuggestion?: InputMaybe<Scalars['String']['input']>;
  serial?: InputMaybe<Scalars['String']['input']>;
  thumbnailUrl?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Bonuses_Max_Fields = {
  __typename?: 'bonuses_max_fields';
  body?: Maybe<Scalars['String']['output']>;
  bodyAnswers?: Maybe<Scalars['String']['output']>;
  category_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fileAnswersKey?: Maybe<Scalars['String']['output']>;
  fileKey?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  instructions?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  previewKey?: Maybe<Scalars['String']['output']>;
  questionCount?: Maybe<Scalars['numeric']['output']>;
  scoringSuggestion?: Maybe<Scalars['String']['output']>;
  serial?: Maybe<Scalars['String']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Bonuses_Min_Fields = {
  __typename?: 'bonuses_min_fields';
  body?: Maybe<Scalars['String']['output']>;
  bodyAnswers?: Maybe<Scalars['String']['output']>;
  category_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fileAnswersKey?: Maybe<Scalars['String']['output']>;
  fileKey?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  instructions?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  previewKey?: Maybe<Scalars['String']['output']>;
  questionCount?: Maybe<Scalars['numeric']['output']>;
  scoringSuggestion?: Maybe<Scalars['String']['output']>;
  serial?: Maybe<Scalars['String']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "bonuses" */
export type Bonuses_Mutation_Response = {
  __typename?: 'bonuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Bonuses>;
};

/** input type for inserting object relation for remote table "bonuses" */
export type Bonuses_Obj_Rel_Insert_Input = {
  data: Bonuses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Bonuses_On_Conflict>;
};

/** on_conflict condition type for table "bonuses" */
export type Bonuses_On_Conflict = {
  constraint: Bonuses_Constraint;
  update_columns?: Array<Bonuses_Update_Column>;
  where?: InputMaybe<Bonuses_Bool_Exp>;
};

/** Ordering options when selecting data from "bonuses". */
export type Bonuses_Order_By = {
  body?: InputMaybe<Order_By>;
  bodyAnswers?: InputMaybe<Order_By>;
  category?: InputMaybe<Categories_Order_By>;
  category_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  eventQuestions_aggregate?: InputMaybe<EventQuestions_Aggregate_Order_By>;
  fileAnswersKey?: InputMaybe<Order_By>;
  fileKey?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instructions?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  previewKey?: InputMaybe<Order_By>;
  questionCount?: InputMaybe<Order_By>;
  scoringSuggestion?: InputMaybe<Order_By>;
  serial?: InputMaybe<Order_By>;
  thumbnailUrl?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bonuses */
export type Bonuses_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "bonuses" */
export enum Bonuses_Select_Column {
  /** column name */
  Body = 'body',
  /** column name */
  BodyAnswers = 'bodyAnswers',
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  FileAnswersKey = 'fileAnswersKey',
  /** column name */
  FileKey = 'fileKey',
  /** column name */
  Id = 'id',
  /** column name */
  Instructions = 'instructions',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  PreviewKey = 'previewKey',
  /** column name */
  QuestionCount = 'questionCount',
  /** column name */
  ScoringSuggestion = 'scoringSuggestion',
  /** column name */
  Serial = 'serial',
  /** column name */
  ThumbnailUrl = 'thumbnailUrl',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "bonuses" */
export type Bonuses_Set_Input = {
  body?: InputMaybe<Scalars['String']['input']>;
  bodyAnswers?: InputMaybe<Scalars['String']['input']>;
  category_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fileAnswersKey?: InputMaybe<Scalars['String']['input']>;
  fileKey?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  previewKey?: InputMaybe<Scalars['String']['input']>;
  questionCount?: InputMaybe<Scalars['numeric']['input']>;
  scoringSuggestion?: InputMaybe<Scalars['String']['input']>;
  serial?: InputMaybe<Scalars['String']['input']>;
  thumbnailUrl?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Bonuses_Stddev_Fields = {
  __typename?: 'bonuses_stddev_fields';
  questionCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Bonuses_Stddev_Pop_Fields = {
  __typename?: 'bonuses_stddev_pop_fields';
  questionCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Bonuses_Stddev_Samp_Fields = {
  __typename?: 'bonuses_stddev_samp_fields';
  questionCount?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "bonuses" */
export type Bonuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bonuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bonuses_Stream_Cursor_Value_Input = {
  body?: InputMaybe<Scalars['String']['input']>;
  bodyAnswers?: InputMaybe<Scalars['String']['input']>;
  category_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fileAnswersKey?: InputMaybe<Scalars['String']['input']>;
  fileKey?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  previewKey?: InputMaybe<Scalars['String']['input']>;
  questionCount?: InputMaybe<Scalars['numeric']['input']>;
  scoringSuggestion?: InputMaybe<Scalars['String']['input']>;
  serial?: InputMaybe<Scalars['String']['input']>;
  thumbnailUrl?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Bonuses_Sum_Fields = {
  __typename?: 'bonuses_sum_fields';
  questionCount?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "bonuses" */
export enum Bonuses_Update_Column {
  /** column name */
  Body = 'body',
  /** column name */
  BodyAnswers = 'bodyAnswers',
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  FileAnswersKey = 'fileAnswersKey',
  /** column name */
  FileKey = 'fileKey',
  /** column name */
  Id = 'id',
  /** column name */
  Instructions = 'instructions',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  PreviewKey = 'previewKey',
  /** column name */
  QuestionCount = 'questionCount',
  /** column name */
  ScoringSuggestion = 'scoringSuggestion',
  /** column name */
  Serial = 'serial',
  /** column name */
  ThumbnailUrl = 'thumbnailUrl',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Bonuses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Bonuses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bonuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bonuses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Bonuses_Var_Pop_Fields = {
  __typename?: 'bonuses_var_pop_fields';
  questionCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Bonuses_Var_Samp_Fields = {
  __typename?: 'bonuses_var_samp_fields';
  questionCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Bonuses_Variance_Fields = {
  __typename?: 'bonuses_variance_fields';
  questionCount?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "categories" */
export type Categories = {
  __typename?: 'categories';
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  org_id: Scalars['uuid']['output'];
  /** An array relationship */
  questions: Array<Questions>;
  /** An aggregate relationship */
  questions_aggregate: Questions_Aggregate;
};


/** columns and relationships of "categories" */
export type CategoriesQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type CategoriesQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};

/** aggregated selection of "categories" */
export type Categories_Aggregate = {
  __typename?: 'categories_aggregate';
  aggregate?: Maybe<Categories_Aggregate_Fields>;
  nodes: Array<Categories>;
};

/** aggregate fields of "categories" */
export type Categories_Aggregate_Fields = {
  __typename?: 'categories_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Categories_Max_Fields>;
  min?: Maybe<Categories_Min_Fields>;
};


/** aggregate fields of "categories" */
export type Categories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "categories". All fields are combined with a logical 'AND'. */
export type Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Categories_Bool_Exp>>;
  _not?: InputMaybe<Categories_Bool_Exp>;
  _or?: InputMaybe<Array<Categories_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  questions?: InputMaybe<Questions_Bool_Exp>;
  questions_aggregate?: InputMaybe<Questions_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "categories" */
export enum Categories_Constraint {
  /** unique or primary key constraint on columns "name", "org_id" */
  CategoriesNameOrgIdKey = 'categories_name_org_id_key',
  /** unique or primary key constraint on columns "id" */
  CategoriesPkey = 'categories_pkey'
}

/** input type for inserting data into table "categories" */
export type Categories_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  questions?: InputMaybe<Questions_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Categories_Max_Fields = {
  __typename?: 'categories_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Categories_Min_Fields = {
  __typename?: 'categories_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "categories" */
export type Categories_Mutation_Response = {
  __typename?: 'categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Categories>;
};

/** input type for inserting object relation for remote table "categories" */
export type Categories_Obj_Rel_Insert_Input = {
  data: Categories_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Categories_On_Conflict>;
};

/** on_conflict condition type for table "categories" */
export type Categories_On_Conflict = {
  constraint: Categories_Constraint;
  update_columns?: Array<Categories_Update_Column>;
  where?: InputMaybe<Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "categories". */
export type Categories_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  questions_aggregate?: InputMaybe<Questions_Aggregate_Order_By>;
};

/** primary key columns input for table: categories */
export type Categories_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "categories" */
export enum Categories_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id'
}

/** input type for updating data in table "categories" */
export type Categories_Set_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "categories" */
export type Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Categories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Categories_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "categories" */
export enum Categories_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id'
}

export type Categories_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Categories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Categories_Bool_Exp;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _gte?: InputMaybe<Scalars['date']['input']>;
  _in?: InputMaybe<Array<Scalars['date']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
  _neq?: InputMaybe<Scalars['date']['input']>;
  _nin?: InputMaybe<Array<Scalars['date']['input']>>;
};

/** columns and relationships of "eventHosts" */
export type EventHosts = {
  __typename?: 'eventHosts';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  event: Events;
  event_id: Scalars['uuid']['output'];
  /** An object relationship */
  host: Hosts;
  host_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  org_id: Scalars['uuid']['output'];
  pay?: Maybe<Scalars['numeric']['output']>;
  type: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['String']['output'];
};

/** aggregated selection of "eventHosts" */
export type EventHosts_Aggregate = {
  __typename?: 'eventHosts_aggregate';
  aggregate?: Maybe<EventHosts_Aggregate_Fields>;
  nodes: Array<EventHosts>;
};

export type EventHosts_Aggregate_Bool_Exp = {
  count?: InputMaybe<EventHosts_Aggregate_Bool_Exp_Count>;
};

export type EventHosts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<EventHosts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EventHosts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "eventHosts" */
export type EventHosts_Aggregate_Fields = {
  __typename?: 'eventHosts_aggregate_fields';
  avg?: Maybe<EventHosts_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<EventHosts_Max_Fields>;
  min?: Maybe<EventHosts_Min_Fields>;
  stddev?: Maybe<EventHosts_Stddev_Fields>;
  stddev_pop?: Maybe<EventHosts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<EventHosts_Stddev_Samp_Fields>;
  sum?: Maybe<EventHosts_Sum_Fields>;
  var_pop?: Maybe<EventHosts_Var_Pop_Fields>;
  var_samp?: Maybe<EventHosts_Var_Samp_Fields>;
  variance?: Maybe<EventHosts_Variance_Fields>;
};


/** aggregate fields of "eventHosts" */
export type EventHosts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EventHosts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "eventHosts" */
export type EventHosts_Aggregate_Order_By = {
  avg?: InputMaybe<EventHosts_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<EventHosts_Max_Order_By>;
  min?: InputMaybe<EventHosts_Min_Order_By>;
  stddev?: InputMaybe<EventHosts_Stddev_Order_By>;
  stddev_pop?: InputMaybe<EventHosts_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<EventHosts_Stddev_Samp_Order_By>;
  sum?: InputMaybe<EventHosts_Sum_Order_By>;
  var_pop?: InputMaybe<EventHosts_Var_Pop_Order_By>;
  var_samp?: InputMaybe<EventHosts_Var_Samp_Order_By>;
  variance?: InputMaybe<EventHosts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "eventHosts" */
export type EventHosts_Arr_Rel_Insert_Input = {
  data: Array<EventHosts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventHosts_On_Conflict>;
};

/** aggregate avg on columns */
export type EventHosts_Avg_Fields = {
  __typename?: 'eventHosts_avg_fields';
  pay?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "eventHosts" */
export type EventHosts_Avg_Order_By = {
  pay?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "eventHosts". All fields are combined with a logical 'AND'. */
export type EventHosts_Bool_Exp = {
  _and?: InputMaybe<Array<EventHosts_Bool_Exp>>;
  _not?: InputMaybe<EventHosts_Bool_Exp>;
  _or?: InputMaybe<Array<EventHosts_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  event?: InputMaybe<Events_Bool_Exp>;
  event_id?: InputMaybe<Uuid_Comparison_Exp>;
  host?: InputMaybe<Hosts_Bool_Exp>;
  host_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  pay?: InputMaybe<Numeric_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "eventHosts" */
export enum EventHosts_Constraint {
  /** unique or primary key constraint on columns "id" */
  EventHostsPkey = 'eventHosts_pkey'
}

/** input type for incrementing numeric columns in table "eventHosts" */
export type EventHosts_Inc_Input = {
  pay?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "eventHosts" */
export type EventHosts_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  event?: InputMaybe<Events_Obj_Rel_Insert_Input>;
  event_id?: InputMaybe<Scalars['uuid']['input']>;
  host?: InputMaybe<Hosts_Obj_Rel_Insert_Input>;
  host_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  pay?: InputMaybe<Scalars['numeric']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type EventHosts_Max_Fields = {
  __typename?: 'eventHosts_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  event_id?: Maybe<Scalars['uuid']['output']>;
  host_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  pay?: Maybe<Scalars['numeric']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "eventHosts" */
export type EventHosts_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  host_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  pay?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type EventHosts_Min_Fields = {
  __typename?: 'eventHosts_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  event_id?: Maybe<Scalars['uuid']['output']>;
  host_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  pay?: Maybe<Scalars['numeric']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "eventHosts" */
export type EventHosts_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  host_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  pay?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "eventHosts" */
export type EventHosts_Mutation_Response = {
  __typename?: 'eventHosts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<EventHosts>;
};

/** on_conflict condition type for table "eventHosts" */
export type EventHosts_On_Conflict = {
  constraint: EventHosts_Constraint;
  update_columns?: Array<EventHosts_Update_Column>;
  where?: InputMaybe<EventHosts_Bool_Exp>;
};

/** Ordering options when selecting data from "eventHosts". */
export type EventHosts_Order_By = {
  created_at?: InputMaybe<Order_By>;
  event?: InputMaybe<Events_Order_By>;
  event_id?: InputMaybe<Order_By>;
  host?: InputMaybe<Hosts_Order_By>;
  host_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  pay?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: eventHosts */
export type EventHosts_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "eventHosts" */
export enum EventHosts_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventId = 'event_id',
  /** column name */
  HostId = 'host_id',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Pay = 'pay',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "eventHosts" */
export type EventHosts_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  event_id?: InputMaybe<Scalars['uuid']['input']>;
  host_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  pay?: InputMaybe<Scalars['numeric']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type EventHosts_Stddev_Fields = {
  __typename?: 'eventHosts_stddev_fields';
  pay?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "eventHosts" */
export type EventHosts_Stddev_Order_By = {
  pay?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type EventHosts_Stddev_Pop_Fields = {
  __typename?: 'eventHosts_stddev_pop_fields';
  pay?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "eventHosts" */
export type EventHosts_Stddev_Pop_Order_By = {
  pay?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type EventHosts_Stddev_Samp_Fields = {
  __typename?: 'eventHosts_stddev_samp_fields';
  pay?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "eventHosts" */
export type EventHosts_Stddev_Samp_Order_By = {
  pay?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "eventHosts" */
export type EventHosts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EventHosts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EventHosts_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  event_id?: InputMaybe<Scalars['uuid']['input']>;
  host_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  pay?: InputMaybe<Scalars['numeric']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type EventHosts_Sum_Fields = {
  __typename?: 'eventHosts_sum_fields';
  pay?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "eventHosts" */
export type EventHosts_Sum_Order_By = {
  pay?: InputMaybe<Order_By>;
};

/** update columns of table "eventHosts" */
export enum EventHosts_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventId = 'event_id',
  /** column name */
  HostId = 'host_id',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Pay = 'pay',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type EventHosts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventHosts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventHosts_Set_Input>;
  /** filter the rows which have to be updated */
  where: EventHosts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type EventHosts_Var_Pop_Fields = {
  __typename?: 'eventHosts_var_pop_fields';
  pay?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "eventHosts" */
export type EventHosts_Var_Pop_Order_By = {
  pay?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type EventHosts_Var_Samp_Fields = {
  __typename?: 'eventHosts_var_samp_fields';
  pay?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "eventHosts" */
export type EventHosts_Var_Samp_Order_By = {
  pay?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type EventHosts_Variance_Fields = {
  __typename?: 'eventHosts_variance_fields';
  pay?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "eventHosts" */
export type EventHosts_Variance_Order_By = {
  pay?: InputMaybe<Order_By>;
};

/** Questions Associated with an Event */
export type EventQuestions = {
  __typename?: 'eventQuestions';
  /** An object relationship */
  bonus?: Maybe<Bonuses>;
  bonus_id?: Maybe<Scalars['uuid']['output']>;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  event: Events;
  event_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  notes: Scalars['String']['output'];
  org_id: Scalars['uuid']['output'];
  /** An object relationship */
  question?: Maybe<Questions>;
  question_id?: Maybe<Scalars['uuid']['output']>;
  sortOrder: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "eventQuestions" */
export type EventQuestions_Aggregate = {
  __typename?: 'eventQuestions_aggregate';
  aggregate?: Maybe<EventQuestions_Aggregate_Fields>;
  nodes: Array<EventQuestions>;
};

export type EventQuestions_Aggregate_Bool_Exp = {
  count?: InputMaybe<EventQuestions_Aggregate_Bool_Exp_Count>;
};

export type EventQuestions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<EventQuestions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EventQuestions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "eventQuestions" */
export type EventQuestions_Aggregate_Fields = {
  __typename?: 'eventQuestions_aggregate_fields';
  avg?: Maybe<EventQuestions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<EventQuestions_Max_Fields>;
  min?: Maybe<EventQuestions_Min_Fields>;
  stddev?: Maybe<EventQuestions_Stddev_Fields>;
  stddev_pop?: Maybe<EventQuestions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<EventQuestions_Stddev_Samp_Fields>;
  sum?: Maybe<EventQuestions_Sum_Fields>;
  var_pop?: Maybe<EventQuestions_Var_Pop_Fields>;
  var_samp?: Maybe<EventQuestions_Var_Samp_Fields>;
  variance?: Maybe<EventQuestions_Variance_Fields>;
};


/** aggregate fields of "eventQuestions" */
export type EventQuestions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EventQuestions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "eventQuestions" */
export type EventQuestions_Aggregate_Order_By = {
  avg?: InputMaybe<EventQuestions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<EventQuestions_Max_Order_By>;
  min?: InputMaybe<EventQuestions_Min_Order_By>;
  stddev?: InputMaybe<EventQuestions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<EventQuestions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<EventQuestions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<EventQuestions_Sum_Order_By>;
  var_pop?: InputMaybe<EventQuestions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<EventQuestions_Var_Samp_Order_By>;
  variance?: InputMaybe<EventQuestions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "eventQuestions" */
export type EventQuestions_Arr_Rel_Insert_Input = {
  data: Array<EventQuestions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventQuestions_On_Conflict>;
};

/** aggregate avg on columns */
export type EventQuestions_Avg_Fields = {
  __typename?: 'eventQuestions_avg_fields';
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "eventQuestions" */
export type EventQuestions_Avg_Order_By = {
  sortOrder?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "eventQuestions". All fields are combined with a logical 'AND'. */
export type EventQuestions_Bool_Exp = {
  _and?: InputMaybe<Array<EventQuestions_Bool_Exp>>;
  _not?: InputMaybe<EventQuestions_Bool_Exp>;
  _or?: InputMaybe<Array<EventQuestions_Bool_Exp>>;
  bonus?: InputMaybe<Bonuses_Bool_Exp>;
  bonus_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  event?: InputMaybe<Events_Bool_Exp>;
  event_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  question?: InputMaybe<Questions_Bool_Exp>;
  question_id?: InputMaybe<Uuid_Comparison_Exp>;
  sortOrder?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "eventQuestions" */
export enum EventQuestions_Constraint {
  /** unique or primary key constraint on columns "id" */
  EventQuestionsPkey = 'eventQuestions_pkey'
}

/** input type for incrementing numeric columns in table "eventQuestions" */
export type EventQuestions_Inc_Input = {
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "eventQuestions" */
export type EventQuestions_Insert_Input = {
  bonus?: InputMaybe<Bonuses_Obj_Rel_Insert_Input>;
  bonus_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  event?: InputMaybe<Events_Obj_Rel_Insert_Input>;
  event_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  question?: InputMaybe<Questions_Obj_Rel_Insert_Input>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type EventQuestions_Max_Fields = {
  __typename?: 'eventQuestions_max_fields';
  bonus_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  event_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  question_id?: Maybe<Scalars['uuid']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "eventQuestions" */
export type EventQuestions_Max_Order_By = {
  bonus_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  sortOrder?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type EventQuestions_Min_Fields = {
  __typename?: 'eventQuestions_min_fields';
  bonus_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  event_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  question_id?: Maybe<Scalars['uuid']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "eventQuestions" */
export type EventQuestions_Min_Order_By = {
  bonus_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  sortOrder?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "eventQuestions" */
export type EventQuestions_Mutation_Response = {
  __typename?: 'eventQuestions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<EventQuestions>;
};

/** on_conflict condition type for table "eventQuestions" */
export type EventQuestions_On_Conflict = {
  constraint: EventQuestions_Constraint;
  update_columns?: Array<EventQuestions_Update_Column>;
  where?: InputMaybe<EventQuestions_Bool_Exp>;
};

/** Ordering options when selecting data from "eventQuestions". */
export type EventQuestions_Order_By = {
  bonus?: InputMaybe<Bonuses_Order_By>;
  bonus_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  event?: InputMaybe<Events_Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  question?: InputMaybe<Questions_Order_By>;
  question_id?: InputMaybe<Order_By>;
  sortOrder?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: eventQuestions */
export type EventQuestions_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "eventQuestions" */
export enum EventQuestions_Select_Column {
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  SortOrder = 'sortOrder',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "eventQuestions" */
export type EventQuestions_Set_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  event_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type EventQuestions_Stddev_Fields = {
  __typename?: 'eventQuestions_stddev_fields';
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "eventQuestions" */
export type EventQuestions_Stddev_Order_By = {
  sortOrder?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type EventQuestions_Stddev_Pop_Fields = {
  __typename?: 'eventQuestions_stddev_pop_fields';
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "eventQuestions" */
export type EventQuestions_Stddev_Pop_Order_By = {
  sortOrder?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type EventQuestions_Stddev_Samp_Fields = {
  __typename?: 'eventQuestions_stddev_samp_fields';
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "eventQuestions" */
export type EventQuestions_Stddev_Samp_Order_By = {
  sortOrder?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "eventQuestions" */
export type EventQuestions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EventQuestions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EventQuestions_Stream_Cursor_Value_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  event_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type EventQuestions_Sum_Fields = {
  __typename?: 'eventQuestions_sum_fields';
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "eventQuestions" */
export type EventQuestions_Sum_Order_By = {
  sortOrder?: InputMaybe<Order_By>;
};

/** update columns of table "eventQuestions" */
export enum EventQuestions_Update_Column {
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  SortOrder = 'sortOrder',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventQuestions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventQuestions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventQuestions_Set_Input>;
  /** filter the rows which have to be updated */
  where: EventQuestions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type EventQuestions_Var_Pop_Fields = {
  __typename?: 'eventQuestions_var_pop_fields';
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "eventQuestions" */
export type EventQuestions_Var_Pop_Order_By = {
  sortOrder?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type EventQuestions_Var_Samp_Fields = {
  __typename?: 'eventQuestions_var_samp_fields';
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "eventQuestions" */
export type EventQuestions_Var_Samp_Order_By = {
  sortOrder?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type EventQuestions_Variance_Fields = {
  __typename?: 'eventQuestions_variance_fields';
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "eventQuestions" */
export type EventQuestions_Variance_Order_By = {
  sortOrder?: InputMaybe<Order_By>;
};

/** Hosts for Templates */
export type EventTemplateHosts = {
  __typename?: 'eventTemplateHosts';
  event_id: Scalars['uuid']['output'];
  host_id: Scalars['uuid']['output'];
  pay?: Maybe<Scalars['numeric']['output']>;
  type: Scalars['String']['output'];
  user_id: Scalars['String']['output'];
};

/** aggregated selection of "eventTemplateHosts" */
export type EventTemplateHosts_Aggregate = {
  __typename?: 'eventTemplateHosts_aggregate';
  aggregate?: Maybe<EventTemplateHosts_Aggregate_Fields>;
  nodes: Array<EventTemplateHosts>;
};

/** aggregate fields of "eventTemplateHosts" */
export type EventTemplateHosts_Aggregate_Fields = {
  __typename?: 'eventTemplateHosts_aggregate_fields';
  avg?: Maybe<EventTemplateHosts_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<EventTemplateHosts_Max_Fields>;
  min?: Maybe<EventTemplateHosts_Min_Fields>;
  stddev?: Maybe<EventTemplateHosts_Stddev_Fields>;
  stddev_pop?: Maybe<EventTemplateHosts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<EventTemplateHosts_Stddev_Samp_Fields>;
  sum?: Maybe<EventTemplateHosts_Sum_Fields>;
  var_pop?: Maybe<EventTemplateHosts_Var_Pop_Fields>;
  var_samp?: Maybe<EventTemplateHosts_Var_Samp_Fields>;
  variance?: Maybe<EventTemplateHosts_Variance_Fields>;
};


/** aggregate fields of "eventTemplateHosts" */
export type EventTemplateHosts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EventTemplateHosts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type EventTemplateHosts_Avg_Fields = {
  __typename?: 'eventTemplateHosts_avg_fields';
  pay?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "eventTemplateHosts". All fields are combined with a logical 'AND'. */
export type EventTemplateHosts_Bool_Exp = {
  _and?: InputMaybe<Array<EventTemplateHosts_Bool_Exp>>;
  _not?: InputMaybe<EventTemplateHosts_Bool_Exp>;
  _or?: InputMaybe<Array<EventTemplateHosts_Bool_Exp>>;
  event_id?: InputMaybe<Uuid_Comparison_Exp>;
  host_id?: InputMaybe<Uuid_Comparison_Exp>;
  pay?: InputMaybe<Numeric_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "eventTemplateHosts" */
export enum EventTemplateHosts_Constraint {
  /** unique or primary key constraint on columns "host_id", "event_id" */
  EventTemplateHostsPkey = 'eventTemplateHosts_pkey'
}

/** input type for incrementing numeric columns in table "eventTemplateHosts" */
export type EventTemplateHosts_Inc_Input = {
  pay?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "eventTemplateHosts" */
export type EventTemplateHosts_Insert_Input = {
  event_id?: InputMaybe<Scalars['uuid']['input']>;
  host_id?: InputMaybe<Scalars['uuid']['input']>;
  pay?: InputMaybe<Scalars['numeric']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type EventTemplateHosts_Max_Fields = {
  __typename?: 'eventTemplateHosts_max_fields';
  event_id?: Maybe<Scalars['uuid']['output']>;
  host_id?: Maybe<Scalars['uuid']['output']>;
  pay?: Maybe<Scalars['numeric']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type EventTemplateHosts_Min_Fields = {
  __typename?: 'eventTemplateHosts_min_fields';
  event_id?: Maybe<Scalars['uuid']['output']>;
  host_id?: Maybe<Scalars['uuid']['output']>;
  pay?: Maybe<Scalars['numeric']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "eventTemplateHosts" */
export type EventTemplateHosts_Mutation_Response = {
  __typename?: 'eventTemplateHosts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<EventTemplateHosts>;
};

/** on_conflict condition type for table "eventTemplateHosts" */
export type EventTemplateHosts_On_Conflict = {
  constraint: EventTemplateHosts_Constraint;
  update_columns?: Array<EventTemplateHosts_Update_Column>;
  where?: InputMaybe<EventTemplateHosts_Bool_Exp>;
};

/** Ordering options when selecting data from "eventTemplateHosts". */
export type EventTemplateHosts_Order_By = {
  event_id?: InputMaybe<Order_By>;
  host_id?: InputMaybe<Order_By>;
  pay?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: eventTemplateHosts */
export type EventTemplateHosts_Pk_Columns_Input = {
  event_id: Scalars['uuid']['input'];
  host_id: Scalars['uuid']['input'];
};

/** select columns of table "eventTemplateHosts" */
export enum EventTemplateHosts_Select_Column {
  /** column name */
  EventId = 'event_id',
  /** column name */
  HostId = 'host_id',
  /** column name */
  Pay = 'pay',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "eventTemplateHosts" */
export type EventTemplateHosts_Set_Input = {
  event_id?: InputMaybe<Scalars['uuid']['input']>;
  host_id?: InputMaybe<Scalars['uuid']['input']>;
  pay?: InputMaybe<Scalars['numeric']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type EventTemplateHosts_Stddev_Fields = {
  __typename?: 'eventTemplateHosts_stddev_fields';
  pay?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type EventTemplateHosts_Stddev_Pop_Fields = {
  __typename?: 'eventTemplateHosts_stddev_pop_fields';
  pay?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type EventTemplateHosts_Stddev_Samp_Fields = {
  __typename?: 'eventTemplateHosts_stddev_samp_fields';
  pay?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "eventTemplateHosts" */
export type EventTemplateHosts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EventTemplateHosts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EventTemplateHosts_Stream_Cursor_Value_Input = {
  event_id?: InputMaybe<Scalars['uuid']['input']>;
  host_id?: InputMaybe<Scalars['uuid']['input']>;
  pay?: InputMaybe<Scalars['numeric']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type EventTemplateHosts_Sum_Fields = {
  __typename?: 'eventTemplateHosts_sum_fields';
  pay?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "eventTemplateHosts" */
export enum EventTemplateHosts_Update_Column {
  /** column name */
  EventId = 'event_id',
  /** column name */
  HostId = 'host_id',
  /** column name */
  Pay = 'pay',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

export type EventTemplateHosts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventTemplateHosts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventTemplateHosts_Set_Input>;
  /** filter the rows which have to be updated */
  where: EventTemplateHosts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type EventTemplateHosts_Var_Pop_Fields = {
  __typename?: 'eventTemplateHosts_var_pop_fields';
  pay?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type EventTemplateHosts_Var_Samp_Fields = {
  __typename?: 'eventTemplateHosts_var_samp_fields';
  pay?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type EventTemplateHosts_Variance_Fields = {
  __typename?: 'eventTemplateHosts_variance_fields';
  pay?: Maybe<Scalars['Float']['output']>;
};

/** Create events automatically */
export type EventTemplates = {
  __typename?: 'eventTemplates';
  account_id: Scalars['uuid']['output'];
  billingaccount_id?: Maybe<Scalars['uuid']['output']>;
  cost: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  dayOfWeek: Scalars['String']['output'];
  firstEventDate: Scalars['date']['output'];
  googleSheetPrefix?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  isActive: Scalars['Boolean']['output'];
  isAutoGenerate: Scalars['Boolean']['output'];
  isAutoSendInvoice: Scalars['Boolean']['output'];
  isCreateInvoice: Scalars['Boolean']['output'];
  location_id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  org_id: Scalars['uuid']['output'];
  region_id: Scalars['uuid']['output'];
  time: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "eventTemplates" */
export type EventTemplates_Aggregate = {
  __typename?: 'eventTemplates_aggregate';
  aggregate?: Maybe<EventTemplates_Aggregate_Fields>;
  nodes: Array<EventTemplates>;
};

/** aggregate fields of "eventTemplates" */
export type EventTemplates_Aggregate_Fields = {
  __typename?: 'eventTemplates_aggregate_fields';
  avg?: Maybe<EventTemplates_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<EventTemplates_Max_Fields>;
  min?: Maybe<EventTemplates_Min_Fields>;
  stddev?: Maybe<EventTemplates_Stddev_Fields>;
  stddev_pop?: Maybe<EventTemplates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<EventTemplates_Stddev_Samp_Fields>;
  sum?: Maybe<EventTemplates_Sum_Fields>;
  var_pop?: Maybe<EventTemplates_Var_Pop_Fields>;
  var_samp?: Maybe<EventTemplates_Var_Samp_Fields>;
  variance?: Maybe<EventTemplates_Variance_Fields>;
};


/** aggregate fields of "eventTemplates" */
export type EventTemplates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EventTemplates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type EventTemplates_Avg_Fields = {
  __typename?: 'eventTemplates_avg_fields';
  cost?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "eventTemplates". All fields are combined with a logical 'AND'. */
export type EventTemplates_Bool_Exp = {
  _and?: InputMaybe<Array<EventTemplates_Bool_Exp>>;
  _not?: InputMaybe<EventTemplates_Bool_Exp>;
  _or?: InputMaybe<Array<EventTemplates_Bool_Exp>>;
  account_id?: InputMaybe<Uuid_Comparison_Exp>;
  billingaccount_id?: InputMaybe<Uuid_Comparison_Exp>;
  cost?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dayOfWeek?: InputMaybe<String_Comparison_Exp>;
  firstEventDate?: InputMaybe<Date_Comparison_Exp>;
  googleSheetPrefix?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isActive?: InputMaybe<Boolean_Comparison_Exp>;
  isAutoGenerate?: InputMaybe<Boolean_Comparison_Exp>;
  isAutoSendInvoice?: InputMaybe<Boolean_Comparison_Exp>;
  isCreateInvoice?: InputMaybe<Boolean_Comparison_Exp>;
  location_id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  region_id?: InputMaybe<Uuid_Comparison_Exp>;
  time?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "eventTemplates" */
export enum EventTemplates_Constraint {
  /** unique or primary key constraint on columns "id" */
  EventTemplatesPkey = 'eventTemplates_pkey'
}

/** input type for incrementing numeric columns in table "eventTemplates" */
export type EventTemplates_Inc_Input = {
  cost?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "eventTemplates" */
export type EventTemplates_Insert_Input = {
  account_id?: InputMaybe<Scalars['uuid']['input']>;
  billingaccount_id?: InputMaybe<Scalars['uuid']['input']>;
  cost?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  dayOfWeek?: InputMaybe<Scalars['String']['input']>;
  firstEventDate?: InputMaybe<Scalars['date']['input']>;
  googleSheetPrefix?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isAutoGenerate?: InputMaybe<Scalars['Boolean']['input']>;
  isAutoSendInvoice?: InputMaybe<Scalars['Boolean']['input']>;
  isCreateInvoice?: InputMaybe<Scalars['Boolean']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  region_id?: InputMaybe<Scalars['uuid']['input']>;
  time?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type EventTemplates_Max_Fields = {
  __typename?: 'eventTemplates_max_fields';
  account_id?: Maybe<Scalars['uuid']['output']>;
  billingaccount_id?: Maybe<Scalars['uuid']['output']>;
  cost?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  dayOfWeek?: Maybe<Scalars['String']['output']>;
  firstEventDate?: Maybe<Scalars['date']['output']>;
  googleSheetPrefix?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  region_id?: Maybe<Scalars['uuid']['output']>;
  time?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type EventTemplates_Min_Fields = {
  __typename?: 'eventTemplates_min_fields';
  account_id?: Maybe<Scalars['uuid']['output']>;
  billingaccount_id?: Maybe<Scalars['uuid']['output']>;
  cost?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  dayOfWeek?: Maybe<Scalars['String']['output']>;
  firstEventDate?: Maybe<Scalars['date']['output']>;
  googleSheetPrefix?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  region_id?: Maybe<Scalars['uuid']['output']>;
  time?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "eventTemplates" */
export type EventTemplates_Mutation_Response = {
  __typename?: 'eventTemplates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<EventTemplates>;
};

/** on_conflict condition type for table "eventTemplates" */
export type EventTemplates_On_Conflict = {
  constraint: EventTemplates_Constraint;
  update_columns?: Array<EventTemplates_Update_Column>;
  where?: InputMaybe<EventTemplates_Bool_Exp>;
};

/** Ordering options when selecting data from "eventTemplates". */
export type EventTemplates_Order_By = {
  account_id?: InputMaybe<Order_By>;
  billingaccount_id?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dayOfWeek?: InputMaybe<Order_By>;
  firstEventDate?: InputMaybe<Order_By>;
  googleSheetPrefix?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isActive?: InputMaybe<Order_By>;
  isAutoGenerate?: InputMaybe<Order_By>;
  isAutoSendInvoice?: InputMaybe<Order_By>;
  isCreateInvoice?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: eventTemplates */
export type EventTemplates_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "eventTemplates" */
export enum EventTemplates_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  BillingaccountId = 'billingaccount_id',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DayOfWeek = 'dayOfWeek',
  /** column name */
  FirstEventDate = 'firstEventDate',
  /** column name */
  GoogleSheetPrefix = 'googleSheetPrefix',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsAutoGenerate = 'isAutoGenerate',
  /** column name */
  IsAutoSendInvoice = 'isAutoSendInvoice',
  /** column name */
  IsCreateInvoice = 'isCreateInvoice',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  Time = 'time',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "eventTemplates" */
export type EventTemplates_Set_Input = {
  account_id?: InputMaybe<Scalars['uuid']['input']>;
  billingaccount_id?: InputMaybe<Scalars['uuid']['input']>;
  cost?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  dayOfWeek?: InputMaybe<Scalars['String']['input']>;
  firstEventDate?: InputMaybe<Scalars['date']['input']>;
  googleSheetPrefix?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isAutoGenerate?: InputMaybe<Scalars['Boolean']['input']>;
  isAutoSendInvoice?: InputMaybe<Scalars['Boolean']['input']>;
  isCreateInvoice?: InputMaybe<Scalars['Boolean']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  region_id?: InputMaybe<Scalars['uuid']['input']>;
  time?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type EventTemplates_Stddev_Fields = {
  __typename?: 'eventTemplates_stddev_fields';
  cost?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type EventTemplates_Stddev_Pop_Fields = {
  __typename?: 'eventTemplates_stddev_pop_fields';
  cost?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type EventTemplates_Stddev_Samp_Fields = {
  __typename?: 'eventTemplates_stddev_samp_fields';
  cost?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "eventTemplates" */
export type EventTemplates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EventTemplates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EventTemplates_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['uuid']['input']>;
  billingaccount_id?: InputMaybe<Scalars['uuid']['input']>;
  cost?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  dayOfWeek?: InputMaybe<Scalars['String']['input']>;
  firstEventDate?: InputMaybe<Scalars['date']['input']>;
  googleSheetPrefix?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isAutoGenerate?: InputMaybe<Scalars['Boolean']['input']>;
  isAutoSendInvoice?: InputMaybe<Scalars['Boolean']['input']>;
  isCreateInvoice?: InputMaybe<Scalars['Boolean']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  region_id?: InputMaybe<Scalars['uuid']['input']>;
  time?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type EventTemplates_Sum_Fields = {
  __typename?: 'eventTemplates_sum_fields';
  cost?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "eventTemplates" */
export enum EventTemplates_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  BillingaccountId = 'billingaccount_id',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DayOfWeek = 'dayOfWeek',
  /** column name */
  FirstEventDate = 'firstEventDate',
  /** column name */
  GoogleSheetPrefix = 'googleSheetPrefix',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsAutoGenerate = 'isAutoGenerate',
  /** column name */
  IsAutoSendInvoice = 'isAutoSendInvoice',
  /** column name */
  IsCreateInvoice = 'isCreateInvoice',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  Time = 'time',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventTemplates_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventTemplates_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventTemplates_Set_Input>;
  /** filter the rows which have to be updated */
  where: EventTemplates_Bool_Exp;
};

/** aggregate var_pop on columns */
export type EventTemplates_Var_Pop_Fields = {
  __typename?: 'eventTemplates_var_pop_fields';
  cost?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type EventTemplates_Var_Samp_Fields = {
  __typename?: 'eventTemplates_var_samp_fields';
  cost?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type EventTemplates_Variance_Fields = {
  __typename?: 'eventTemplates_variance_fields';
  cost?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "events" */
export type Events = {
  __typename?: 'events';
  /** An object relationship */
  account?: Maybe<Accounts>;
  account_id?: Maybe<Scalars['uuid']['output']>;
  cost: Scalars['numeric']['output'];
  eventDateTime: Scalars['timestamptz']['output'];
  /** An array relationship */
  eventQuestions: Array<EventQuestions>;
  /** An aggregate relationship */
  eventQuestions_aggregate: EventQuestions_Aggregate;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  location?: Maybe<Locations>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name: Scalars['String']['output'];
  org_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  region?: Maybe<Regions>;
  region_id?: Maybe<Scalars['uuid']['output']>;
  status: Scalars['String']['output'];
};


/** columns and relationships of "events" */
export type EventsEventQuestionsArgs = {
  distinct_on?: InputMaybe<Array<EventQuestions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventQuestions_Order_By>>;
  where?: InputMaybe<EventQuestions_Bool_Exp>;
};


/** columns and relationships of "events" */
export type EventsEventQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EventQuestions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventQuestions_Order_By>>;
  where?: InputMaybe<EventQuestions_Bool_Exp>;
};

/** aggregated selection of "events" */
export type Events_Aggregate = {
  __typename?: 'events_aggregate';
  aggregate?: Maybe<Events_Aggregate_Fields>;
  nodes: Array<Events>;
};

export type Events_Aggregate_Bool_Exp = {
  count?: InputMaybe<Events_Aggregate_Bool_Exp_Count>;
};

export type Events_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Events_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "events" */
export type Events_Aggregate_Fields = {
  __typename?: 'events_aggregate_fields';
  avg?: Maybe<Events_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Events_Max_Fields>;
  min?: Maybe<Events_Min_Fields>;
  stddev?: Maybe<Events_Stddev_Fields>;
  stddev_pop?: Maybe<Events_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Events_Stddev_Samp_Fields>;
  sum?: Maybe<Events_Sum_Fields>;
  var_pop?: Maybe<Events_Var_Pop_Fields>;
  var_samp?: Maybe<Events_Var_Samp_Fields>;
  variance?: Maybe<Events_Variance_Fields>;
};


/** aggregate fields of "events" */
export type Events_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "events" */
export type Events_Aggregate_Order_By = {
  avg?: InputMaybe<Events_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Events_Max_Order_By>;
  min?: InputMaybe<Events_Min_Order_By>;
  stddev?: InputMaybe<Events_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Events_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Events_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Events_Sum_Order_By>;
  var_pop?: InputMaybe<Events_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Events_Var_Samp_Order_By>;
  variance?: InputMaybe<Events_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "events" */
export type Events_Arr_Rel_Insert_Input = {
  data: Array<Events_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Events_On_Conflict>;
};

/** aggregate avg on columns */
export type Events_Avg_Fields = {
  __typename?: 'events_avg_fields';
  cost?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "events" */
export type Events_Avg_Order_By = {
  cost?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "events". All fields are combined with a logical 'AND'. */
export type Events_Bool_Exp = {
  _and?: InputMaybe<Array<Events_Bool_Exp>>;
  _not?: InputMaybe<Events_Bool_Exp>;
  _or?: InputMaybe<Array<Events_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  account_id?: InputMaybe<Uuid_Comparison_Exp>;
  cost?: InputMaybe<Numeric_Comparison_Exp>;
  eventDateTime?: InputMaybe<Timestamptz_Comparison_Exp>;
  eventQuestions?: InputMaybe<EventQuestions_Bool_Exp>;
  eventQuestions_aggregate?: InputMaybe<EventQuestions_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  location?: InputMaybe<Locations_Bool_Exp>;
  location_id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  region?: InputMaybe<Regions_Bool_Exp>;
  region_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "events" */
export enum Events_Constraint {
  /** unique or primary key constraint on columns "id" */
  EventsPkey = 'events_pkey'
}

/** input type for incrementing numeric columns in table "events" */
export type Events_Inc_Input = {
  cost?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "events" */
export type Events_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['uuid']['input']>;
  cost?: InputMaybe<Scalars['numeric']['input']>;
  eventDateTime?: InputMaybe<Scalars['timestamptz']['input']>;
  eventQuestions?: InputMaybe<EventQuestions_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location?: InputMaybe<Locations_Obj_Rel_Insert_Input>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  region?: InputMaybe<Regions_Obj_Rel_Insert_Input>;
  region_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Events_Max_Fields = {
  __typename?: 'events_max_fields';
  account_id?: Maybe<Scalars['uuid']['output']>;
  cost?: Maybe<Scalars['numeric']['output']>;
  eventDateTime?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  region_id?: Maybe<Scalars['uuid']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "events" */
export type Events_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  eventDateTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Events_Min_Fields = {
  __typename?: 'events_min_fields';
  account_id?: Maybe<Scalars['uuid']['output']>;
  cost?: Maybe<Scalars['numeric']['output']>;
  eventDateTime?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  region_id?: Maybe<Scalars['uuid']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "events" */
export type Events_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  eventDateTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "events" */
export type Events_Mutation_Response = {
  __typename?: 'events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Events>;
};

/** input type for inserting object relation for remote table "events" */
export type Events_Obj_Rel_Insert_Input = {
  data: Events_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Events_On_Conflict>;
};

/** on_conflict condition type for table "events" */
export type Events_On_Conflict = {
  constraint: Events_Constraint;
  update_columns?: Array<Events_Update_Column>;
  where?: InputMaybe<Events_Bool_Exp>;
};

/** Ordering options when selecting data from "events". */
export type Events_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  account_id?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  eventDateTime?: InputMaybe<Order_By>;
  eventQuestions_aggregate?: InputMaybe<EventQuestions_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Locations_Order_By>;
  location_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  region?: InputMaybe<Regions_Order_By>;
  region_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: events */
export type Events_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "events" */
export enum Events_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Cost = 'cost',
  /** column name */
  EventDateTime = 'eventDateTime',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "events" */
export type Events_Set_Input = {
  account_id?: InputMaybe<Scalars['uuid']['input']>;
  cost?: InputMaybe<Scalars['numeric']['input']>;
  eventDateTime?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  region_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Events_Stddev_Fields = {
  __typename?: 'events_stddev_fields';
  cost?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "events" */
export type Events_Stddev_Order_By = {
  cost?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Events_Stddev_Pop_Fields = {
  __typename?: 'events_stddev_pop_fields';
  cost?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "events" */
export type Events_Stddev_Pop_Order_By = {
  cost?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Events_Stddev_Samp_Fields = {
  __typename?: 'events_stddev_samp_fields';
  cost?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "events" */
export type Events_Stddev_Samp_Order_By = {
  cost?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "events" */
export type Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Events_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['uuid']['input']>;
  cost?: InputMaybe<Scalars['numeric']['input']>;
  eventDateTime?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  region_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Events_Sum_Fields = {
  __typename?: 'events_sum_fields';
  cost?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "events" */
export type Events_Sum_Order_By = {
  cost?: InputMaybe<Order_By>;
};

/** update columns of table "events" */
export enum Events_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Cost = 'cost',
  /** column name */
  EventDateTime = 'eventDateTime',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  Status = 'status'
}

export type Events_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Events_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Events_Set_Input>;
  /** filter the rows which have to be updated */
  where: Events_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Events_Var_Pop_Fields = {
  __typename?: 'events_var_pop_fields';
  cost?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "events" */
export type Events_Var_Pop_Order_By = {
  cost?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Events_Var_Samp_Fields = {
  __typename?: 'events_var_samp_fields';
  cost?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "events" */
export type Events_Var_Samp_Order_By = {
  cost?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Events_Variance_Fields = {
  __typename?: 'events_variance_fields';
  cost?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "events" */
export type Events_Variance_Order_By = {
  cost?: InputMaybe<Order_By>;
};

/** Question Search Filters */
export type Filters = {
  __typename?: 'filters';
  created_at: Scalars['timestamptz']['output'];
  filter: Scalars['jsonb']['output'];
  gql: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  org_id: Scalars['uuid']['output'];
  owner_id: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
};


/** Question Search Filters */
export type FiltersFilterArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "filters" */
export type Filters_Aggregate = {
  __typename?: 'filters_aggregate';
  aggregate?: Maybe<Filters_Aggregate_Fields>;
  nodes: Array<Filters>;
};

/** aggregate fields of "filters" */
export type Filters_Aggregate_Fields = {
  __typename?: 'filters_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Filters_Max_Fields>;
  min?: Maybe<Filters_Min_Fields>;
};


/** aggregate fields of "filters" */
export type Filters_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Filters_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Filters_Append_Input = {
  filter?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "filters". All fields are combined with a logical 'AND'. */
export type Filters_Bool_Exp = {
  _and?: InputMaybe<Array<Filters_Bool_Exp>>;
  _not?: InputMaybe<Filters_Bool_Exp>;
  _or?: InputMaybe<Array<Filters_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  filter?: InputMaybe<Jsonb_Comparison_Exp>;
  gql?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  owner_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "filters" */
export enum Filters_Constraint {
  /** unique or primary key constraint on columns "id" */
  FiltersPkey = 'filters_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Filters_Delete_At_Path_Input = {
  filter?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Filters_Delete_Elem_Input = {
  filter?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Filters_Delete_Key_Input = {
  filter?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "filters" */
export type Filters_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  filter?: InputMaybe<Scalars['jsonb']['input']>;
  gql?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  owner_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Filters_Max_Fields = {
  __typename?: 'filters_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  gql?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  owner_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Filters_Min_Fields = {
  __typename?: 'filters_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  gql?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  owner_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "filters" */
export type Filters_Mutation_Response = {
  __typename?: 'filters_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Filters>;
};

/** on_conflict condition type for table "filters" */
export type Filters_On_Conflict = {
  constraint: Filters_Constraint;
  update_columns?: Array<Filters_Update_Column>;
  where?: InputMaybe<Filters_Bool_Exp>;
};

/** Ordering options when selecting data from "filters". */
export type Filters_Order_By = {
  created_at?: InputMaybe<Order_By>;
  filter?: InputMaybe<Order_By>;
  gql?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: filters */
export type Filters_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Filters_Prepend_Input = {
  filter?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "filters" */
export enum Filters_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Filter = 'filter',
  /** column name */
  Gql = 'gql',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "filters" */
export type Filters_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  filter?: InputMaybe<Scalars['jsonb']['input']>;
  gql?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  owner_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "filters" */
export type Filters_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Filters_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Filters_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  filter?: InputMaybe<Scalars['jsonb']['input']>;
  gql?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  owner_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "filters" */
export enum Filters_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Filter = 'filter',
  /** column name */
  Gql = 'gql',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Filters_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Filters_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Filters_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Filters_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Filters_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Filters_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Filters_Set_Input>;
  /** filter the rows which have to be updated */
  where: Filters_Bool_Exp;
};

/** Users who Host Events */
export type Hosts = {
  __typename?: 'hosts';
  created_at: Scalars['timestamptz']['output'];
  dateEnded?: Maybe<Scalars['date']['output']>;
  dateStarted?: Maybe<Scalars['date']['output']>;
  /** An array relationship */
  eventHosts: Array<EventHosts>;
  /** An aggregate relationship */
  eventHosts_aggregate: EventHosts_Aggregate;
  googleAccountEmail?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  isActive: Scalars['Boolean']['output'];
  nickname?: Maybe<Scalars['String']['output']>;
  org_id: Scalars['uuid']['output'];
  rates: Scalars['jsonb']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['String']['output'];
};


/** Users who Host Events */
export type HostsEventHostsArgs = {
  distinct_on?: InputMaybe<Array<EventHosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventHosts_Order_By>>;
  where?: InputMaybe<EventHosts_Bool_Exp>;
};


/** Users who Host Events */
export type HostsEventHosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EventHosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventHosts_Order_By>>;
  where?: InputMaybe<EventHosts_Bool_Exp>;
};


/** Users who Host Events */
export type HostsRatesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "hosts" */
export type Hosts_Aggregate = {
  __typename?: 'hosts_aggregate';
  aggregate?: Maybe<Hosts_Aggregate_Fields>;
  nodes: Array<Hosts>;
};

/** aggregate fields of "hosts" */
export type Hosts_Aggregate_Fields = {
  __typename?: 'hosts_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Hosts_Max_Fields>;
  min?: Maybe<Hosts_Min_Fields>;
};


/** aggregate fields of "hosts" */
export type Hosts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hosts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Hosts_Append_Input = {
  rates?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "hosts". All fields are combined with a logical 'AND'. */
export type Hosts_Bool_Exp = {
  _and?: InputMaybe<Array<Hosts_Bool_Exp>>;
  _not?: InputMaybe<Hosts_Bool_Exp>;
  _or?: InputMaybe<Array<Hosts_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dateEnded?: InputMaybe<Date_Comparison_Exp>;
  dateStarted?: InputMaybe<Date_Comparison_Exp>;
  eventHosts?: InputMaybe<EventHosts_Bool_Exp>;
  eventHosts_aggregate?: InputMaybe<EventHosts_Aggregate_Bool_Exp>;
  googleAccountEmail?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isActive?: InputMaybe<Boolean_Comparison_Exp>;
  nickname?: InputMaybe<String_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  rates?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "hosts" */
export enum Hosts_Constraint {
  /** unique or primary key constraint on columns "id" */
  HostsPkey = 'hosts_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Hosts_Delete_At_Path_Input = {
  rates?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Hosts_Delete_Elem_Input = {
  rates?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Hosts_Delete_Key_Input = {
  rates?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "hosts" */
export type Hosts_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  dateEnded?: InputMaybe<Scalars['date']['input']>;
  dateStarted?: InputMaybe<Scalars['date']['input']>;
  eventHosts?: InputMaybe<EventHosts_Arr_Rel_Insert_Input>;
  googleAccountEmail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  rates?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Hosts_Max_Fields = {
  __typename?: 'hosts_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  dateEnded?: Maybe<Scalars['date']['output']>;
  dateStarted?: Maybe<Scalars['date']['output']>;
  googleAccountEmail?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Hosts_Min_Fields = {
  __typename?: 'hosts_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  dateEnded?: Maybe<Scalars['date']['output']>;
  dateStarted?: Maybe<Scalars['date']['output']>;
  googleAccountEmail?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "hosts" */
export type Hosts_Mutation_Response = {
  __typename?: 'hosts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Hosts>;
};

/** input type for inserting object relation for remote table "hosts" */
export type Hosts_Obj_Rel_Insert_Input = {
  data: Hosts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Hosts_On_Conflict>;
};

/** on_conflict condition type for table "hosts" */
export type Hosts_On_Conflict = {
  constraint: Hosts_Constraint;
  update_columns?: Array<Hosts_Update_Column>;
  where?: InputMaybe<Hosts_Bool_Exp>;
};

/** Ordering options when selecting data from "hosts". */
export type Hosts_Order_By = {
  created_at?: InputMaybe<Order_By>;
  dateEnded?: InputMaybe<Order_By>;
  dateStarted?: InputMaybe<Order_By>;
  eventHosts_aggregate?: InputMaybe<EventHosts_Aggregate_Order_By>;
  googleAccountEmail?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isActive?: InputMaybe<Order_By>;
  nickname?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  rates?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: hosts */
export type Hosts_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Hosts_Prepend_Input = {
  rates?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "hosts" */
export enum Hosts_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateEnded = 'dateEnded',
  /** column name */
  DateStarted = 'dateStarted',
  /** column name */
  GoogleAccountEmail = 'googleAccountEmail',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Rates = 'rates',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "hosts" */
export type Hosts_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  dateEnded?: InputMaybe<Scalars['date']['input']>;
  dateStarted?: InputMaybe<Scalars['date']['input']>;
  googleAccountEmail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  rates?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "hosts" */
export type Hosts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Hosts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hosts_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  dateEnded?: InputMaybe<Scalars['date']['input']>;
  dateStarted?: InputMaybe<Scalars['date']['input']>;
  googleAccountEmail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  rates?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "hosts" */
export enum Hosts_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateEnded = 'dateEnded',
  /** column name */
  DateStarted = 'dateStarted',
  /** column name */
  GoogleAccountEmail = 'googleAccountEmail',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Rates = 'rates',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Hosts_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Hosts_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Hosts_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Hosts_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Hosts_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Hosts_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hosts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Hosts_Bool_Exp;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** Trivia Venue Locations */
export type Locations = {
  __typename?: 'locations';
  address?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  events: Array<Events>;
  /** An aggregate relationship */
  events_aggregate: Events_Aggregate;
  id: Scalars['uuid']['output'];
  isActive: Scalars['Boolean']['output'];
  legacyId?: Maybe<Scalars['numeric']['output']>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  region: Regions;
  region_id: Scalars['uuid']['output'];
  state: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  zip: Scalars['String']['output'];
};


/** Trivia Venue Locations */
export type LocationsEventsArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


/** Trivia Venue Locations */
export type LocationsEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};

/** aggregated selection of "locations" */
export type Locations_Aggregate = {
  __typename?: 'locations_aggregate';
  aggregate?: Maybe<Locations_Aggregate_Fields>;
  nodes: Array<Locations>;
};

/** aggregate fields of "locations" */
export type Locations_Aggregate_Fields = {
  __typename?: 'locations_aggregate_fields';
  avg?: Maybe<Locations_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Locations_Max_Fields>;
  min?: Maybe<Locations_Min_Fields>;
  stddev?: Maybe<Locations_Stddev_Fields>;
  stddev_pop?: Maybe<Locations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Locations_Stddev_Samp_Fields>;
  sum?: Maybe<Locations_Sum_Fields>;
  var_pop?: Maybe<Locations_Var_Pop_Fields>;
  var_samp?: Maybe<Locations_Var_Samp_Fields>;
  variance?: Maybe<Locations_Variance_Fields>;
};


/** aggregate fields of "locations" */
export type Locations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Locations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Locations_Avg_Fields = {
  __typename?: 'locations_avg_fields';
  legacyId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "locations". All fields are combined with a logical 'AND'. */
export type Locations_Bool_Exp = {
  _and?: InputMaybe<Array<Locations_Bool_Exp>>;
  _not?: InputMaybe<Locations_Bool_Exp>;
  _or?: InputMaybe<Array<Locations_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  events?: InputMaybe<Events_Bool_Exp>;
  events_aggregate?: InputMaybe<Events_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isActive?: InputMaybe<Boolean_Comparison_Exp>;
  legacyId?: InputMaybe<Numeric_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  region?: InputMaybe<Regions_Bool_Exp>;
  region_id?: InputMaybe<Uuid_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  zip?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "locations" */
export enum Locations_Constraint {
  /** unique or primary key constraint on columns "id" */
  LocationsPkey = 'locations_pkey'
}

/** input type for incrementing numeric columns in table "locations" */
export type Locations_Inc_Input = {
  legacyId?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "locations" */
export type Locations_Insert_Input = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  events?: InputMaybe<Events_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId?: InputMaybe<Scalars['numeric']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  region?: InputMaybe<Regions_Obj_Rel_Insert_Input>;
  region_id?: InputMaybe<Scalars['uuid']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Locations_Max_Fields = {
  __typename?: 'locations_max_fields';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  legacyId?: Maybe<Scalars['numeric']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  region_id?: Maybe<Scalars['uuid']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Locations_Min_Fields = {
  __typename?: 'locations_min_fields';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  legacyId?: Maybe<Scalars['numeric']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  region_id?: Maybe<Scalars['uuid']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "locations" */
export type Locations_Mutation_Response = {
  __typename?: 'locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Locations>;
};

/** input type for inserting object relation for remote table "locations" */
export type Locations_Obj_Rel_Insert_Input = {
  data: Locations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Locations_On_Conflict>;
};

/** on_conflict condition type for table "locations" */
export type Locations_On_Conflict = {
  constraint: Locations_Constraint;
  update_columns?: Array<Locations_Update_Column>;
  where?: InputMaybe<Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "locations". */
export type Locations_Order_By = {
  address?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  events_aggregate?: InputMaybe<Events_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  isActive?: InputMaybe<Order_By>;
  legacyId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  region?: InputMaybe<Regions_Order_By>;
  region_id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  zip?: InputMaybe<Order_By>;
};

/** primary key columns input for table: locations */
export type Locations_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "locations" */
export enum Locations_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  LegacyId = 'legacyId',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Zip = 'zip'
}

/** input type for updating data in table "locations" */
export type Locations_Set_Input = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId?: InputMaybe<Scalars['numeric']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  region_id?: InputMaybe<Scalars['uuid']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Locations_Stddev_Fields = {
  __typename?: 'locations_stddev_fields';
  legacyId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Locations_Stddev_Pop_Fields = {
  __typename?: 'locations_stddev_pop_fields';
  legacyId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Locations_Stddev_Samp_Fields = {
  __typename?: 'locations_stddev_samp_fields';
  legacyId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "locations" */
export type Locations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Locations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Locations_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId?: InputMaybe<Scalars['numeric']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  region_id?: InputMaybe<Scalars['uuid']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Locations_Sum_Fields = {
  __typename?: 'locations_sum_fields';
  legacyId?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "locations" */
export enum Locations_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  LegacyId = 'legacyId',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Zip = 'zip'
}

export type Locations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Locations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Locations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Locations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Locations_Var_Pop_Fields = {
  __typename?: 'locations_var_pop_fields';
  legacyId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Locations_Var_Samp_Fields = {
  __typename?: 'locations_var_samp_fields';
  legacyId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Locations_Variance_Fields = {
  __typename?: 'locations_variance_fields';
  legacyId?: Maybe<Scalars['Float']['output']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "accounts" */
  delete_accounts?: Maybe<Accounts_Mutation_Response>;
  /** delete single row from the table: "accounts" */
  delete_accounts_by_pk?: Maybe<Accounts>;
  /** delete data from the table: "billingAccounts" */
  delete_billingAccounts?: Maybe<BillingAccounts_Mutation_Response>;
  /** delete single row from the table: "billingAccounts" */
  delete_billingAccounts_by_pk?: Maybe<BillingAccounts>;
  /** delete data from the table: "bonusTypes" */
  delete_bonusTypes?: Maybe<BonusTypes_Mutation_Response>;
  /** delete single row from the table: "bonusTypes" */
  delete_bonusTypes_by_pk?: Maybe<BonusTypes>;
  /** delete data from the table: "bonuses" */
  delete_bonuses?: Maybe<Bonuses_Mutation_Response>;
  /** delete single row from the table: "bonuses" */
  delete_bonuses_by_pk?: Maybe<Bonuses>;
  /** delete data from the table: "categories" */
  delete_categories?: Maybe<Categories_Mutation_Response>;
  /** delete single row from the table: "categories" */
  delete_categories_by_pk?: Maybe<Categories>;
  /** delete data from the table: "eventHosts" */
  delete_eventHosts?: Maybe<EventHosts_Mutation_Response>;
  /** delete single row from the table: "eventHosts" */
  delete_eventHosts_by_pk?: Maybe<EventHosts>;
  /** delete data from the table: "eventQuestions" */
  delete_eventQuestions?: Maybe<EventQuestions_Mutation_Response>;
  /** delete single row from the table: "eventQuestions" */
  delete_eventQuestions_by_pk?: Maybe<EventQuestions>;
  /** delete data from the table: "eventTemplateHosts" */
  delete_eventTemplateHosts?: Maybe<EventTemplateHosts_Mutation_Response>;
  /** delete single row from the table: "eventTemplateHosts" */
  delete_eventTemplateHosts_by_pk?: Maybe<EventTemplateHosts>;
  /** delete data from the table: "eventTemplates" */
  delete_eventTemplates?: Maybe<EventTemplates_Mutation_Response>;
  /** delete single row from the table: "eventTemplates" */
  delete_eventTemplates_by_pk?: Maybe<EventTemplates>;
  /** delete data from the table: "events" */
  delete_events?: Maybe<Events_Mutation_Response>;
  /** delete single row from the table: "events" */
  delete_events_by_pk?: Maybe<Events>;
  /** delete data from the table: "filters" */
  delete_filters?: Maybe<Filters_Mutation_Response>;
  /** delete single row from the table: "filters" */
  delete_filters_by_pk?: Maybe<Filters>;
  /** delete data from the table: "hosts" */
  delete_hosts?: Maybe<Hosts_Mutation_Response>;
  /** delete single row from the table: "hosts" */
  delete_hosts_by_pk?: Maybe<Hosts>;
  /** delete data from the table: "locations" */
  delete_locations?: Maybe<Locations_Mutation_Response>;
  /** delete single row from the table: "locations" */
  delete_locations_by_pk?: Maybe<Locations>;
  /** delete data from the table: "notebookQuestions" */
  delete_notebookQuestions?: Maybe<NotebookQuestions_Mutation_Response>;
  /** delete single row from the table: "notebookQuestions" */
  delete_notebookQuestions_by_pk?: Maybe<NotebookQuestions>;
  /** delete data from the table: "notebooks" */
  delete_notebooks?: Maybe<Notebooks_Mutation_Response>;
  /** delete single row from the table: "notebooks" */
  delete_notebooks_by_pk?: Maybe<Notebooks>;
  /** delete data from the table: "organizations" */
  delete_organizations?: Maybe<Organizations_Mutation_Response>;
  /** delete single row from the table: "organizations" */
  delete_organizations_by_pk?: Maybe<Organizations>;
  /** delete data from the table: "questionTags" */
  delete_questionTags?: Maybe<QuestionTags_Mutation_Response>;
  /** delete single row from the table: "questionTags" */
  delete_questionTags_by_pk?: Maybe<QuestionTags>;
  /** delete data from the table: "questionTypes" */
  delete_questionTypes?: Maybe<QuestionTypes_Mutation_Response>;
  /** delete single row from the table: "questionTypes" */
  delete_questionTypes_by_pk?: Maybe<QuestionTypes>;
  /** delete data from the table: "questionVersions" */
  delete_questionVersions?: Maybe<QuestionVersions_Mutation_Response>;
  /** delete single row from the table: "questionVersions" */
  delete_questionVersions_by_pk?: Maybe<QuestionVersions>;
  /** delete data from the table: "questions" */
  delete_questions?: Maybe<Questions_Mutation_Response>;
  /** delete single row from the table: "questions" */
  delete_questions_by_pk?: Maybe<Questions>;
  /** delete data from the table: "regions" */
  delete_regions?: Maybe<Regions_Mutation_Response>;
  /** delete single row from the table: "regions" */
  delete_regions_by_pk?: Maybe<Regions>;
  /** delete data from the table: "roles" */
  delete_roles?: Maybe<Roles_Mutation_Response>;
  /** delete single row from the table: "roles" */
  delete_roles_by_pk?: Maybe<Roles>;
  /** delete data from the table: "tags" */
  delete_tags?: Maybe<Tags_Mutation_Response>;
  /** delete single row from the table: "tags" */
  delete_tags_by_pk?: Maybe<Tags>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** Get Secure Upload URL */
  getSignedUploadUrl?: Maybe<SignedUrlResponse>;
  /** insert data into the table: "accounts" */
  insert_accounts?: Maybe<Accounts_Mutation_Response>;
  /** insert a single row into the table: "accounts" */
  insert_accounts_one?: Maybe<Accounts>;
  /** insert data into the table: "billingAccounts" */
  insert_billingAccounts?: Maybe<BillingAccounts_Mutation_Response>;
  /** insert a single row into the table: "billingAccounts" */
  insert_billingAccounts_one?: Maybe<BillingAccounts>;
  /** insert data into the table: "bonusTypes" */
  insert_bonusTypes?: Maybe<BonusTypes_Mutation_Response>;
  /** insert a single row into the table: "bonusTypes" */
  insert_bonusTypes_one?: Maybe<BonusTypes>;
  /** insert data into the table: "bonuses" */
  insert_bonuses?: Maybe<Bonuses_Mutation_Response>;
  /** insert a single row into the table: "bonuses" */
  insert_bonuses_one?: Maybe<Bonuses>;
  /** insert data into the table: "categories" */
  insert_categories?: Maybe<Categories_Mutation_Response>;
  /** insert a single row into the table: "categories" */
  insert_categories_one?: Maybe<Categories>;
  /** insert data into the table: "eventHosts" */
  insert_eventHosts?: Maybe<EventHosts_Mutation_Response>;
  /** insert a single row into the table: "eventHosts" */
  insert_eventHosts_one?: Maybe<EventHosts>;
  /** insert data into the table: "eventQuestions" */
  insert_eventQuestions?: Maybe<EventQuestions_Mutation_Response>;
  /** insert a single row into the table: "eventQuestions" */
  insert_eventQuestions_one?: Maybe<EventQuestions>;
  /** insert data into the table: "eventTemplateHosts" */
  insert_eventTemplateHosts?: Maybe<EventTemplateHosts_Mutation_Response>;
  /** insert a single row into the table: "eventTemplateHosts" */
  insert_eventTemplateHosts_one?: Maybe<EventTemplateHosts>;
  /** insert data into the table: "eventTemplates" */
  insert_eventTemplates?: Maybe<EventTemplates_Mutation_Response>;
  /** insert a single row into the table: "eventTemplates" */
  insert_eventTemplates_one?: Maybe<EventTemplates>;
  /** insert data into the table: "events" */
  insert_events?: Maybe<Events_Mutation_Response>;
  /** insert a single row into the table: "events" */
  insert_events_one?: Maybe<Events>;
  /** insert data into the table: "filters" */
  insert_filters?: Maybe<Filters_Mutation_Response>;
  /** insert a single row into the table: "filters" */
  insert_filters_one?: Maybe<Filters>;
  /** insert data into the table: "hosts" */
  insert_hosts?: Maybe<Hosts_Mutation_Response>;
  /** insert a single row into the table: "hosts" */
  insert_hosts_one?: Maybe<Hosts>;
  /** insert data into the table: "locations" */
  insert_locations?: Maybe<Locations_Mutation_Response>;
  /** insert a single row into the table: "locations" */
  insert_locations_one?: Maybe<Locations>;
  /** insert data into the table: "notebookQuestions" */
  insert_notebookQuestions?: Maybe<NotebookQuestions_Mutation_Response>;
  /** insert a single row into the table: "notebookQuestions" */
  insert_notebookQuestions_one?: Maybe<NotebookQuestions>;
  /** insert data into the table: "notebooks" */
  insert_notebooks?: Maybe<Notebooks_Mutation_Response>;
  /** insert a single row into the table: "notebooks" */
  insert_notebooks_one?: Maybe<Notebooks>;
  /** insert data into the table: "organizations" */
  insert_organizations?: Maybe<Organizations_Mutation_Response>;
  /** insert a single row into the table: "organizations" */
  insert_organizations_one?: Maybe<Organizations>;
  /** insert data into the table: "questionTags" */
  insert_questionTags?: Maybe<QuestionTags_Mutation_Response>;
  /** insert a single row into the table: "questionTags" */
  insert_questionTags_one?: Maybe<QuestionTags>;
  /** insert data into the table: "questionTypes" */
  insert_questionTypes?: Maybe<QuestionTypes_Mutation_Response>;
  /** insert a single row into the table: "questionTypes" */
  insert_questionTypes_one?: Maybe<QuestionTypes>;
  /** insert data into the table: "questionVersions" */
  insert_questionVersions?: Maybe<QuestionVersions_Mutation_Response>;
  /** insert a single row into the table: "questionVersions" */
  insert_questionVersions_one?: Maybe<QuestionVersions>;
  /** insert data into the table: "questions" */
  insert_questions?: Maybe<Questions_Mutation_Response>;
  /** insert a single row into the table: "questions" */
  insert_questions_one?: Maybe<Questions>;
  /** insert data into the table: "regions" */
  insert_regions?: Maybe<Regions_Mutation_Response>;
  /** insert a single row into the table: "regions" */
  insert_regions_one?: Maybe<Regions>;
  /** insert data into the table: "roles" */
  insert_roles?: Maybe<Roles_Mutation_Response>;
  /** insert a single row into the table: "roles" */
  insert_roles_one?: Maybe<Roles>;
  /** insert data into the table: "tags" */
  insert_tags?: Maybe<Tags_Mutation_Response>;
  /** insert a single row into the table: "tags" */
  insert_tags_one?: Maybe<Tags>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** Move Questions to a New Category */
  mergeCategory?: Maybe<MergeCategoriesOutput>;
  /** update data of the table: "accounts" */
  update_accounts?: Maybe<Accounts_Mutation_Response>;
  /** update single row of the table: "accounts" */
  update_accounts_by_pk?: Maybe<Accounts>;
  /** update multiples rows of table: "accounts" */
  update_accounts_many?: Maybe<Array<Maybe<Accounts_Mutation_Response>>>;
  /** update data of the table: "billingAccounts" */
  update_billingAccounts?: Maybe<BillingAccounts_Mutation_Response>;
  /** update single row of the table: "billingAccounts" */
  update_billingAccounts_by_pk?: Maybe<BillingAccounts>;
  /** update multiples rows of table: "billingAccounts" */
  update_billingAccounts_many?: Maybe<Array<Maybe<BillingAccounts_Mutation_Response>>>;
  /** update data of the table: "bonusTypes" */
  update_bonusTypes?: Maybe<BonusTypes_Mutation_Response>;
  /** update single row of the table: "bonusTypes" */
  update_bonusTypes_by_pk?: Maybe<BonusTypes>;
  /** update multiples rows of table: "bonusTypes" */
  update_bonusTypes_many?: Maybe<Array<Maybe<BonusTypes_Mutation_Response>>>;
  /** update data of the table: "bonuses" */
  update_bonuses?: Maybe<Bonuses_Mutation_Response>;
  /** update single row of the table: "bonuses" */
  update_bonuses_by_pk?: Maybe<Bonuses>;
  /** update multiples rows of table: "bonuses" */
  update_bonuses_many?: Maybe<Array<Maybe<Bonuses_Mutation_Response>>>;
  /** update data of the table: "categories" */
  update_categories?: Maybe<Categories_Mutation_Response>;
  /** update single row of the table: "categories" */
  update_categories_by_pk?: Maybe<Categories>;
  /** update multiples rows of table: "categories" */
  update_categories_many?: Maybe<Array<Maybe<Categories_Mutation_Response>>>;
  /** update data of the table: "eventHosts" */
  update_eventHosts?: Maybe<EventHosts_Mutation_Response>;
  /** update single row of the table: "eventHosts" */
  update_eventHosts_by_pk?: Maybe<EventHosts>;
  /** update multiples rows of table: "eventHosts" */
  update_eventHosts_many?: Maybe<Array<Maybe<EventHosts_Mutation_Response>>>;
  /** update data of the table: "eventQuestions" */
  update_eventQuestions?: Maybe<EventQuestions_Mutation_Response>;
  /** update single row of the table: "eventQuestions" */
  update_eventQuestions_by_pk?: Maybe<EventQuestions>;
  /** update multiples rows of table: "eventQuestions" */
  update_eventQuestions_many?: Maybe<Array<Maybe<EventQuestions_Mutation_Response>>>;
  /** update data of the table: "eventTemplateHosts" */
  update_eventTemplateHosts?: Maybe<EventTemplateHosts_Mutation_Response>;
  /** update single row of the table: "eventTemplateHosts" */
  update_eventTemplateHosts_by_pk?: Maybe<EventTemplateHosts>;
  /** update multiples rows of table: "eventTemplateHosts" */
  update_eventTemplateHosts_many?: Maybe<Array<Maybe<EventTemplateHosts_Mutation_Response>>>;
  /** update data of the table: "eventTemplates" */
  update_eventTemplates?: Maybe<EventTemplates_Mutation_Response>;
  /** update single row of the table: "eventTemplates" */
  update_eventTemplates_by_pk?: Maybe<EventTemplates>;
  /** update multiples rows of table: "eventTemplates" */
  update_eventTemplates_many?: Maybe<Array<Maybe<EventTemplates_Mutation_Response>>>;
  /** update data of the table: "events" */
  update_events?: Maybe<Events_Mutation_Response>;
  /** update single row of the table: "events" */
  update_events_by_pk?: Maybe<Events>;
  /** update multiples rows of table: "events" */
  update_events_many?: Maybe<Array<Maybe<Events_Mutation_Response>>>;
  /** update data of the table: "filters" */
  update_filters?: Maybe<Filters_Mutation_Response>;
  /** update single row of the table: "filters" */
  update_filters_by_pk?: Maybe<Filters>;
  /** update multiples rows of table: "filters" */
  update_filters_many?: Maybe<Array<Maybe<Filters_Mutation_Response>>>;
  /** update data of the table: "hosts" */
  update_hosts?: Maybe<Hosts_Mutation_Response>;
  /** update single row of the table: "hosts" */
  update_hosts_by_pk?: Maybe<Hosts>;
  /** update multiples rows of table: "hosts" */
  update_hosts_many?: Maybe<Array<Maybe<Hosts_Mutation_Response>>>;
  /** update data of the table: "locations" */
  update_locations?: Maybe<Locations_Mutation_Response>;
  /** update single row of the table: "locations" */
  update_locations_by_pk?: Maybe<Locations>;
  /** update multiples rows of table: "locations" */
  update_locations_many?: Maybe<Array<Maybe<Locations_Mutation_Response>>>;
  /** update data of the table: "notebookQuestions" */
  update_notebookQuestions?: Maybe<NotebookQuestions_Mutation_Response>;
  /** update single row of the table: "notebookQuestions" */
  update_notebookQuestions_by_pk?: Maybe<NotebookQuestions>;
  /** update multiples rows of table: "notebookQuestions" */
  update_notebookQuestions_many?: Maybe<Array<Maybe<NotebookQuestions_Mutation_Response>>>;
  /** update data of the table: "notebooks" */
  update_notebooks?: Maybe<Notebooks_Mutation_Response>;
  /** update single row of the table: "notebooks" */
  update_notebooks_by_pk?: Maybe<Notebooks>;
  /** update multiples rows of table: "notebooks" */
  update_notebooks_many?: Maybe<Array<Maybe<Notebooks_Mutation_Response>>>;
  /** update data of the table: "organizations" */
  update_organizations?: Maybe<Organizations_Mutation_Response>;
  /** update single row of the table: "organizations" */
  update_organizations_by_pk?: Maybe<Organizations>;
  /** update multiples rows of table: "organizations" */
  update_organizations_many?: Maybe<Array<Maybe<Organizations_Mutation_Response>>>;
  /** update data of the table: "questionTags" */
  update_questionTags?: Maybe<QuestionTags_Mutation_Response>;
  /** update single row of the table: "questionTags" */
  update_questionTags_by_pk?: Maybe<QuestionTags>;
  /** update multiples rows of table: "questionTags" */
  update_questionTags_many?: Maybe<Array<Maybe<QuestionTags_Mutation_Response>>>;
  /** update data of the table: "questionTypes" */
  update_questionTypes?: Maybe<QuestionTypes_Mutation_Response>;
  /** update single row of the table: "questionTypes" */
  update_questionTypes_by_pk?: Maybe<QuestionTypes>;
  /** update multiples rows of table: "questionTypes" */
  update_questionTypes_many?: Maybe<Array<Maybe<QuestionTypes_Mutation_Response>>>;
  /** update data of the table: "questionVersions" */
  update_questionVersions?: Maybe<QuestionVersions_Mutation_Response>;
  /** update single row of the table: "questionVersions" */
  update_questionVersions_by_pk?: Maybe<QuestionVersions>;
  /** update multiples rows of table: "questionVersions" */
  update_questionVersions_many?: Maybe<Array<Maybe<QuestionVersions_Mutation_Response>>>;
  /** update data of the table: "questions" */
  update_questions?: Maybe<Questions_Mutation_Response>;
  /** update single row of the table: "questions" */
  update_questions_by_pk?: Maybe<Questions>;
  /** update multiples rows of table: "questions" */
  update_questions_many?: Maybe<Array<Maybe<Questions_Mutation_Response>>>;
  /** update data of the table: "regions" */
  update_regions?: Maybe<Regions_Mutation_Response>;
  /** update single row of the table: "regions" */
  update_regions_by_pk?: Maybe<Regions>;
  /** update multiples rows of table: "regions" */
  update_regions_many?: Maybe<Array<Maybe<Regions_Mutation_Response>>>;
  /** update data of the table: "roles" */
  update_roles?: Maybe<Roles_Mutation_Response>;
  /** update single row of the table: "roles" */
  update_roles_by_pk?: Maybe<Roles>;
  /** update multiples rows of table: "roles" */
  update_roles_many?: Maybe<Array<Maybe<Roles_Mutation_Response>>>;
  /** update data of the table: "tags" */
  update_tags?: Maybe<Tags_Mutation_Response>;
  /** update single row of the table: "tags" */
  update_tags_by_pk?: Maybe<Tags>;
  /** update multiples rows of table: "tags" */
  update_tags_many?: Maybe<Array<Maybe<Tags_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete_AccountsArgs = {
  where: Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Accounts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_BillingAccountsArgs = {
  where: BillingAccounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_BillingAccounts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_BonusTypesArgs = {
  where: BonusTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_BonusTypes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_BonusesArgs = {
  where: Bonuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bonuses_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_CategoriesArgs = {
  where: Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Categories_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_EventHostsArgs = {
  where: EventHosts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EventHosts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_EventQuestionsArgs = {
  where: EventQuestions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EventQuestions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_EventTemplateHostsArgs = {
  where: EventTemplateHosts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EventTemplateHosts_By_PkArgs = {
  event_id: Scalars['uuid']['input'];
  host_id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_EventTemplatesArgs = {
  where: EventTemplates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EventTemplates_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_EventsArgs = {
  where: Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Events_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_FiltersArgs = {
  where: Filters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Filters_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_HostsArgs = {
  where: Hosts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hosts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_LocationsArgs = {
  where: Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Locations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_NotebookQuestionsArgs = {
  where: NotebookQuestions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_NotebookQuestions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_NotebooksArgs = {
  where: Notebooks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notebooks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_OrganizationsArgs = {
  where: Organizations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Organizations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_QuestionTagsArgs = {
  where: QuestionTags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_QuestionTags_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_QuestionTypesArgs = {
  where: QuestionTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_QuestionTypes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_QuestionVersionsArgs = {
  where: QuestionVersions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_QuestionVersions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_QuestionsArgs = {
  where: Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Questions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_RegionsArgs = {
  where: Regions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Regions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_RolesArgs = {
  where: Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Roles_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_TagsArgs = {
  where: Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tags_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootGetSignedUploadUrlArgs = {
  fileName: Scalars['String']['input'];
  fileType: Scalars['String']['input'];
  isSecure: Scalars['Boolean']['input'];
};


/** mutation root */
export type Mutation_RootInsert_AccountsArgs = {
  objects: Array<Accounts_Insert_Input>;
  on_conflict?: InputMaybe<Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Accounts_OneArgs = {
  object: Accounts_Insert_Input;
  on_conflict?: InputMaybe<Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BillingAccountsArgs = {
  objects: Array<BillingAccounts_Insert_Input>;
  on_conflict?: InputMaybe<BillingAccounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BillingAccounts_OneArgs = {
  object: BillingAccounts_Insert_Input;
  on_conflict?: InputMaybe<BillingAccounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BonusTypesArgs = {
  objects: Array<BonusTypes_Insert_Input>;
  on_conflict?: InputMaybe<BonusTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BonusTypes_OneArgs = {
  object: BonusTypes_Insert_Input;
  on_conflict?: InputMaybe<BonusTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BonusesArgs = {
  objects: Array<Bonuses_Insert_Input>;
  on_conflict?: InputMaybe<Bonuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonuses_OneArgs = {
  object: Bonuses_Insert_Input;
  on_conflict?: InputMaybe<Bonuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CategoriesArgs = {
  objects: Array<Categories_Insert_Input>;
  on_conflict?: InputMaybe<Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Categories_OneArgs = {
  object: Categories_Insert_Input;
  on_conflict?: InputMaybe<Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EventHostsArgs = {
  objects: Array<EventHosts_Insert_Input>;
  on_conflict?: InputMaybe<EventHosts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EventHosts_OneArgs = {
  object: EventHosts_Insert_Input;
  on_conflict?: InputMaybe<EventHosts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EventQuestionsArgs = {
  objects: Array<EventQuestions_Insert_Input>;
  on_conflict?: InputMaybe<EventQuestions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EventQuestions_OneArgs = {
  object: EventQuestions_Insert_Input;
  on_conflict?: InputMaybe<EventQuestions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EventTemplateHostsArgs = {
  objects: Array<EventTemplateHosts_Insert_Input>;
  on_conflict?: InputMaybe<EventTemplateHosts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EventTemplateHosts_OneArgs = {
  object: EventTemplateHosts_Insert_Input;
  on_conflict?: InputMaybe<EventTemplateHosts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EventTemplatesArgs = {
  objects: Array<EventTemplates_Insert_Input>;
  on_conflict?: InputMaybe<EventTemplates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EventTemplates_OneArgs = {
  object: EventTemplates_Insert_Input;
  on_conflict?: InputMaybe<EventTemplates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EventsArgs = {
  objects: Array<Events_Insert_Input>;
  on_conflict?: InputMaybe<Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Events_OneArgs = {
  object: Events_Insert_Input;
  on_conflict?: InputMaybe<Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FiltersArgs = {
  objects: Array<Filters_Insert_Input>;
  on_conflict?: InputMaybe<Filters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Filters_OneArgs = {
  object: Filters_Insert_Input;
  on_conflict?: InputMaybe<Filters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_HostsArgs = {
  objects: Array<Hosts_Insert_Input>;
  on_conflict?: InputMaybe<Hosts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Hosts_OneArgs = {
  object: Hosts_Insert_Input;
  on_conflict?: InputMaybe<Hosts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LocationsArgs = {
  objects: Array<Locations_Insert_Input>;
  on_conflict?: InputMaybe<Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Locations_OneArgs = {
  object: Locations_Insert_Input;
  on_conflict?: InputMaybe<Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NotebookQuestionsArgs = {
  objects: Array<NotebookQuestions_Insert_Input>;
  on_conflict?: InputMaybe<NotebookQuestions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NotebookQuestions_OneArgs = {
  object: NotebookQuestions_Insert_Input;
  on_conflict?: InputMaybe<NotebookQuestions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NotebooksArgs = {
  objects: Array<Notebooks_Insert_Input>;
  on_conflict?: InputMaybe<Notebooks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notebooks_OneArgs = {
  object: Notebooks_Insert_Input;
  on_conflict?: InputMaybe<Notebooks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrganizationsArgs = {
  objects: Array<Organizations_Insert_Input>;
  on_conflict?: InputMaybe<Organizations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organizations_OneArgs = {
  object: Organizations_Insert_Input;
  on_conflict?: InputMaybe<Organizations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuestionTagsArgs = {
  objects: Array<QuestionTags_Insert_Input>;
  on_conflict?: InputMaybe<QuestionTags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuestionTags_OneArgs = {
  object: QuestionTags_Insert_Input;
  on_conflict?: InputMaybe<QuestionTags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuestionTypesArgs = {
  objects: Array<QuestionTypes_Insert_Input>;
  on_conflict?: InputMaybe<QuestionTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuestionTypes_OneArgs = {
  object: QuestionTypes_Insert_Input;
  on_conflict?: InputMaybe<QuestionTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuestionVersionsArgs = {
  objects: Array<QuestionVersions_Insert_Input>;
  on_conflict?: InputMaybe<QuestionVersions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuestionVersions_OneArgs = {
  object: QuestionVersions_Insert_Input;
  on_conflict?: InputMaybe<QuestionVersions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuestionsArgs = {
  objects: Array<Questions_Insert_Input>;
  on_conflict?: InputMaybe<Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questions_OneArgs = {
  object: Questions_Insert_Input;
  on_conflict?: InputMaybe<Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RegionsArgs = {
  objects: Array<Regions_Insert_Input>;
  on_conflict?: InputMaybe<Regions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Regions_OneArgs = {
  object: Regions_Insert_Input;
  on_conflict?: InputMaybe<Regions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RolesArgs = {
  objects: Array<Roles_Insert_Input>;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Roles_OneArgs = {
  object: Roles_Insert_Input;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TagsArgs = {
  objects: Array<Tags_Insert_Input>;
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tags_OneArgs = {
  object: Tags_Insert_Input;
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootMergeCategoryArgs = {
  fromCategory: Scalars['uuid']['input'];
  isDeleteFromCategory?: InputMaybe<Scalars['Boolean']['input']>;
  toCategory: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootUpdate_AccountsArgs = {
  _inc?: InputMaybe<Accounts_Inc_Input>;
  _set?: InputMaybe<Accounts_Set_Input>;
  where: Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Accounts_By_PkArgs = {
  _inc?: InputMaybe<Accounts_Inc_Input>;
  _set?: InputMaybe<Accounts_Set_Input>;
  pk_columns: Accounts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Accounts_ManyArgs = {
  updates: Array<Accounts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_BillingAccountsArgs = {
  _inc?: InputMaybe<BillingAccounts_Inc_Input>;
  _set?: InputMaybe<BillingAccounts_Set_Input>;
  where: BillingAccounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_BillingAccounts_By_PkArgs = {
  _inc?: InputMaybe<BillingAccounts_Inc_Input>;
  _set?: InputMaybe<BillingAccounts_Set_Input>;
  pk_columns: BillingAccounts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BillingAccounts_ManyArgs = {
  updates: Array<BillingAccounts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_BonusTypesArgs = {
  _set?: InputMaybe<BonusTypes_Set_Input>;
  where: BonusTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_BonusTypes_By_PkArgs = {
  _set?: InputMaybe<BonusTypes_Set_Input>;
  pk_columns: BonusTypes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BonusTypes_ManyArgs = {
  updates: Array<BonusTypes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_BonusesArgs = {
  _inc?: InputMaybe<Bonuses_Inc_Input>;
  _set?: InputMaybe<Bonuses_Set_Input>;
  where: Bonuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bonuses_By_PkArgs = {
  _inc?: InputMaybe<Bonuses_Inc_Input>;
  _set?: InputMaybe<Bonuses_Set_Input>;
  pk_columns: Bonuses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bonuses_ManyArgs = {
  updates: Array<Bonuses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CategoriesArgs = {
  _set?: InputMaybe<Categories_Set_Input>;
  where: Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Categories_By_PkArgs = {
  _set?: InputMaybe<Categories_Set_Input>;
  pk_columns: Categories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Categories_ManyArgs = {
  updates: Array<Categories_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EventHostsArgs = {
  _inc?: InputMaybe<EventHosts_Inc_Input>;
  _set?: InputMaybe<EventHosts_Set_Input>;
  where: EventHosts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EventHosts_By_PkArgs = {
  _inc?: InputMaybe<EventHosts_Inc_Input>;
  _set?: InputMaybe<EventHosts_Set_Input>;
  pk_columns: EventHosts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EventHosts_ManyArgs = {
  updates: Array<EventHosts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EventQuestionsArgs = {
  _inc?: InputMaybe<EventQuestions_Inc_Input>;
  _set?: InputMaybe<EventQuestions_Set_Input>;
  where: EventQuestions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EventQuestions_By_PkArgs = {
  _inc?: InputMaybe<EventQuestions_Inc_Input>;
  _set?: InputMaybe<EventQuestions_Set_Input>;
  pk_columns: EventQuestions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EventQuestions_ManyArgs = {
  updates: Array<EventQuestions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EventTemplateHostsArgs = {
  _inc?: InputMaybe<EventTemplateHosts_Inc_Input>;
  _set?: InputMaybe<EventTemplateHosts_Set_Input>;
  where: EventTemplateHosts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EventTemplateHosts_By_PkArgs = {
  _inc?: InputMaybe<EventTemplateHosts_Inc_Input>;
  _set?: InputMaybe<EventTemplateHosts_Set_Input>;
  pk_columns: EventTemplateHosts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EventTemplateHosts_ManyArgs = {
  updates: Array<EventTemplateHosts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EventTemplatesArgs = {
  _inc?: InputMaybe<EventTemplates_Inc_Input>;
  _set?: InputMaybe<EventTemplates_Set_Input>;
  where: EventTemplates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EventTemplates_By_PkArgs = {
  _inc?: InputMaybe<EventTemplates_Inc_Input>;
  _set?: InputMaybe<EventTemplates_Set_Input>;
  pk_columns: EventTemplates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EventTemplates_ManyArgs = {
  updates: Array<EventTemplates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EventsArgs = {
  _inc?: InputMaybe<Events_Inc_Input>;
  _set?: InputMaybe<Events_Set_Input>;
  where: Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Events_By_PkArgs = {
  _inc?: InputMaybe<Events_Inc_Input>;
  _set?: InputMaybe<Events_Set_Input>;
  pk_columns: Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Events_ManyArgs = {
  updates: Array<Events_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FiltersArgs = {
  _append?: InputMaybe<Filters_Append_Input>;
  _delete_at_path?: InputMaybe<Filters_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Filters_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Filters_Delete_Key_Input>;
  _prepend?: InputMaybe<Filters_Prepend_Input>;
  _set?: InputMaybe<Filters_Set_Input>;
  where: Filters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Filters_By_PkArgs = {
  _append?: InputMaybe<Filters_Append_Input>;
  _delete_at_path?: InputMaybe<Filters_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Filters_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Filters_Delete_Key_Input>;
  _prepend?: InputMaybe<Filters_Prepend_Input>;
  _set?: InputMaybe<Filters_Set_Input>;
  pk_columns: Filters_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Filters_ManyArgs = {
  updates: Array<Filters_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_HostsArgs = {
  _append?: InputMaybe<Hosts_Append_Input>;
  _delete_at_path?: InputMaybe<Hosts_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hosts_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hosts_Delete_Key_Input>;
  _prepend?: InputMaybe<Hosts_Prepend_Input>;
  _set?: InputMaybe<Hosts_Set_Input>;
  where: Hosts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hosts_By_PkArgs = {
  _append?: InputMaybe<Hosts_Append_Input>;
  _delete_at_path?: InputMaybe<Hosts_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hosts_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hosts_Delete_Key_Input>;
  _prepend?: InputMaybe<Hosts_Prepend_Input>;
  _set?: InputMaybe<Hosts_Set_Input>;
  pk_columns: Hosts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Hosts_ManyArgs = {
  updates: Array<Hosts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LocationsArgs = {
  _inc?: InputMaybe<Locations_Inc_Input>;
  _set?: InputMaybe<Locations_Set_Input>;
  where: Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Locations_By_PkArgs = {
  _inc?: InputMaybe<Locations_Inc_Input>;
  _set?: InputMaybe<Locations_Set_Input>;
  pk_columns: Locations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Locations_ManyArgs = {
  updates: Array<Locations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_NotebookQuestionsArgs = {
  _inc?: InputMaybe<NotebookQuestions_Inc_Input>;
  _set?: InputMaybe<NotebookQuestions_Set_Input>;
  where: NotebookQuestions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_NotebookQuestions_By_PkArgs = {
  _inc?: InputMaybe<NotebookQuestions_Inc_Input>;
  _set?: InputMaybe<NotebookQuestions_Set_Input>;
  pk_columns: NotebookQuestions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_NotebookQuestions_ManyArgs = {
  updates: Array<NotebookQuestions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_NotebooksArgs = {
  _set?: InputMaybe<Notebooks_Set_Input>;
  where: Notebooks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notebooks_By_PkArgs = {
  _set?: InputMaybe<Notebooks_Set_Input>;
  pk_columns: Notebooks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notebooks_ManyArgs = {
  updates: Array<Notebooks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OrganizationsArgs = {
  _set?: InputMaybe<Organizations_Set_Input>;
  where: Organizations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Organizations_By_PkArgs = {
  _set?: InputMaybe<Organizations_Set_Input>;
  pk_columns: Organizations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Organizations_ManyArgs = {
  updates: Array<Organizations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionTagsArgs = {
  _set?: InputMaybe<QuestionTags_Set_Input>;
  where: QuestionTags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionTags_By_PkArgs = {
  _set?: InputMaybe<QuestionTags_Set_Input>;
  pk_columns: QuestionTags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionTags_ManyArgs = {
  updates: Array<QuestionTags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionTypesArgs = {
  _set?: InputMaybe<QuestionTypes_Set_Input>;
  where: QuestionTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionTypes_By_PkArgs = {
  _set?: InputMaybe<QuestionTypes_Set_Input>;
  pk_columns: QuestionTypes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionTypes_ManyArgs = {
  updates: Array<QuestionTypes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionVersionsArgs = {
  _set?: InputMaybe<QuestionVersions_Set_Input>;
  where: QuestionVersions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionVersions_By_PkArgs = {
  _set?: InputMaybe<QuestionVersions_Set_Input>;
  pk_columns: QuestionVersions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionVersions_ManyArgs = {
  updates: Array<QuestionVersions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionsArgs = {
  _set?: InputMaybe<Questions_Set_Input>;
  where: Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Questions_By_PkArgs = {
  _set?: InputMaybe<Questions_Set_Input>;
  pk_columns: Questions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Questions_ManyArgs = {
  updates: Array<Questions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RegionsArgs = {
  _inc?: InputMaybe<Regions_Inc_Input>;
  _set?: InputMaybe<Regions_Set_Input>;
  where: Regions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Regions_By_PkArgs = {
  _inc?: InputMaybe<Regions_Inc_Input>;
  _set?: InputMaybe<Regions_Set_Input>;
  pk_columns: Regions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Regions_ManyArgs = {
  updates: Array<Regions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RolesArgs = {
  _set?: InputMaybe<Roles_Set_Input>;
  where: Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_By_PkArgs = {
  _set?: InputMaybe<Roles_Set_Input>;
  pk_columns: Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_ManyArgs = {
  updates: Array<Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TagsArgs = {
  _set?: InputMaybe<Tags_Set_Input>;
  where: Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tags_By_PkArgs = {
  _set?: InputMaybe<Tags_Set_Input>;
  pk_columns: Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tags_ManyArgs = {
  updates: Array<Tags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};

/** columns and relationships of "notebookQuestions" */
export type NotebookQuestions = {
  __typename?: 'notebookQuestions';
  id: Scalars['uuid']['output'];
  /** An object relationship */
  notebookQuestions_notebook: Notebooks;
  /** An object relationship */
  notebookQuestions_question: Questions;
  notebook_id: Scalars['uuid']['output'];
  org_id?: Maybe<Scalars['uuid']['output']>;
  question_id: Scalars['uuid']['output'];
  sortOrder: Scalars['Int']['output'];
};

/** aggregated selection of "notebookQuestions" */
export type NotebookQuestions_Aggregate = {
  __typename?: 'notebookQuestions_aggregate';
  aggregate?: Maybe<NotebookQuestions_Aggregate_Fields>;
  nodes: Array<NotebookQuestions>;
};

export type NotebookQuestions_Aggregate_Bool_Exp = {
  count?: InputMaybe<NotebookQuestions_Aggregate_Bool_Exp_Count>;
};

export type NotebookQuestions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<NotebookQuestions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<NotebookQuestions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "notebookQuestions" */
export type NotebookQuestions_Aggregate_Fields = {
  __typename?: 'notebookQuestions_aggregate_fields';
  avg?: Maybe<NotebookQuestions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<NotebookQuestions_Max_Fields>;
  min?: Maybe<NotebookQuestions_Min_Fields>;
  stddev?: Maybe<NotebookQuestions_Stddev_Fields>;
  stddev_pop?: Maybe<NotebookQuestions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<NotebookQuestions_Stddev_Samp_Fields>;
  sum?: Maybe<NotebookQuestions_Sum_Fields>;
  var_pop?: Maybe<NotebookQuestions_Var_Pop_Fields>;
  var_samp?: Maybe<NotebookQuestions_Var_Samp_Fields>;
  variance?: Maybe<NotebookQuestions_Variance_Fields>;
};


/** aggregate fields of "notebookQuestions" */
export type NotebookQuestions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<NotebookQuestions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "notebookQuestions" */
export type NotebookQuestions_Aggregate_Order_By = {
  avg?: InputMaybe<NotebookQuestions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<NotebookQuestions_Max_Order_By>;
  min?: InputMaybe<NotebookQuestions_Min_Order_By>;
  stddev?: InputMaybe<NotebookQuestions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<NotebookQuestions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<NotebookQuestions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<NotebookQuestions_Sum_Order_By>;
  var_pop?: InputMaybe<NotebookQuestions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<NotebookQuestions_Var_Samp_Order_By>;
  variance?: InputMaybe<NotebookQuestions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "notebookQuestions" */
export type NotebookQuestions_Arr_Rel_Insert_Input = {
  data: Array<NotebookQuestions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<NotebookQuestions_On_Conflict>;
};

/** aggregate avg on columns */
export type NotebookQuestions_Avg_Fields = {
  __typename?: 'notebookQuestions_avg_fields';
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "notebookQuestions" */
export type NotebookQuestions_Avg_Order_By = {
  sortOrder?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "notebookQuestions". All fields are combined with a logical 'AND'. */
export type NotebookQuestions_Bool_Exp = {
  _and?: InputMaybe<Array<NotebookQuestions_Bool_Exp>>;
  _not?: InputMaybe<NotebookQuestions_Bool_Exp>;
  _or?: InputMaybe<Array<NotebookQuestions_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notebookQuestions_notebook?: InputMaybe<Notebooks_Bool_Exp>;
  notebookQuestions_question?: InputMaybe<Questions_Bool_Exp>;
  notebook_id?: InputMaybe<Uuid_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  question_id?: InputMaybe<Uuid_Comparison_Exp>;
  sortOrder?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "notebookQuestions" */
export enum NotebookQuestions_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotebookQuestionsPkey = 'notebookQuestions_pkey'
}

/** input type for incrementing numeric columns in table "notebookQuestions" */
export type NotebookQuestions_Inc_Input = {
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "notebookQuestions" */
export type NotebookQuestions_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  notebookQuestions_notebook?: InputMaybe<Notebooks_Obj_Rel_Insert_Input>;
  notebookQuestions_question?: InputMaybe<Questions_Obj_Rel_Insert_Input>;
  notebook_id?: InputMaybe<Scalars['uuid']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type NotebookQuestions_Max_Fields = {
  __typename?: 'notebookQuestions_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  notebook_id?: Maybe<Scalars['uuid']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  question_id?: Maybe<Scalars['uuid']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "notebookQuestions" */
export type NotebookQuestions_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  notebook_id?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  sortOrder?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type NotebookQuestions_Min_Fields = {
  __typename?: 'notebookQuestions_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  notebook_id?: Maybe<Scalars['uuid']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  question_id?: Maybe<Scalars['uuid']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "notebookQuestions" */
export type NotebookQuestions_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  notebook_id?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  sortOrder?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "notebookQuestions" */
export type NotebookQuestions_Mutation_Response = {
  __typename?: 'notebookQuestions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<NotebookQuestions>;
};

/** on_conflict condition type for table "notebookQuestions" */
export type NotebookQuestions_On_Conflict = {
  constraint: NotebookQuestions_Constraint;
  update_columns?: Array<NotebookQuestions_Update_Column>;
  where?: InputMaybe<NotebookQuestions_Bool_Exp>;
};

/** Ordering options when selecting data from "notebookQuestions". */
export type NotebookQuestions_Order_By = {
  id?: InputMaybe<Order_By>;
  notebookQuestions_notebook?: InputMaybe<Notebooks_Order_By>;
  notebookQuestions_question?: InputMaybe<Questions_Order_By>;
  notebook_id?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  sortOrder?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notebookQuestions */
export type NotebookQuestions_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "notebookQuestions" */
export enum NotebookQuestions_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  NotebookId = 'notebook_id',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  SortOrder = 'sortOrder'
}

/** input type for updating data in table "notebookQuestions" */
export type NotebookQuestions_Set_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  notebook_id?: InputMaybe<Scalars['uuid']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type NotebookQuestions_Stddev_Fields = {
  __typename?: 'notebookQuestions_stddev_fields';
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "notebookQuestions" */
export type NotebookQuestions_Stddev_Order_By = {
  sortOrder?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type NotebookQuestions_Stddev_Pop_Fields = {
  __typename?: 'notebookQuestions_stddev_pop_fields';
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "notebookQuestions" */
export type NotebookQuestions_Stddev_Pop_Order_By = {
  sortOrder?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type NotebookQuestions_Stddev_Samp_Fields = {
  __typename?: 'notebookQuestions_stddev_samp_fields';
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "notebookQuestions" */
export type NotebookQuestions_Stddev_Samp_Order_By = {
  sortOrder?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "notebookQuestions" */
export type NotebookQuestions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: NotebookQuestions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type NotebookQuestions_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  notebook_id?: InputMaybe<Scalars['uuid']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type NotebookQuestions_Sum_Fields = {
  __typename?: 'notebookQuestions_sum_fields';
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "notebookQuestions" */
export type NotebookQuestions_Sum_Order_By = {
  sortOrder?: InputMaybe<Order_By>;
};

/** update columns of table "notebookQuestions" */
export enum NotebookQuestions_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  NotebookId = 'notebook_id',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  SortOrder = 'sortOrder'
}

export type NotebookQuestions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<NotebookQuestions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<NotebookQuestions_Set_Input>;
  /** filter the rows which have to be updated */
  where: NotebookQuestions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type NotebookQuestions_Var_Pop_Fields = {
  __typename?: 'notebookQuestions_var_pop_fields';
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "notebookQuestions" */
export type NotebookQuestions_Var_Pop_Order_By = {
  sortOrder?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type NotebookQuestions_Var_Samp_Fields = {
  __typename?: 'notebookQuestions_var_samp_fields';
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "notebookQuestions" */
export type NotebookQuestions_Var_Samp_Order_By = {
  sortOrder?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type NotebookQuestions_Variance_Fields = {
  __typename?: 'notebookQuestions_variance_fields';
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "notebookQuestions" */
export type NotebookQuestions_Variance_Order_By = {
  sortOrder?: InputMaybe<Order_By>;
};

/** columns and relationships of "notebooks" */
export type Notebooks = {
  __typename?: 'notebooks';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  isArchived: Scalars['Boolean']['output'];
  isStarred: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  notebooks_notebookQuestions: Array<NotebookQuestions>;
  /** An aggregate relationship */
  notebooks_notebookQuestions_aggregate: NotebookQuestions_Aggregate;
  /** An object relationship */
  notebooks_owner?: Maybe<Users>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  owner_id?: Maybe<Scalars['uuid']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "notebooks" */
export type NotebooksNotebooks_NotebookQuestionsArgs = {
  distinct_on?: InputMaybe<Array<NotebookQuestions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NotebookQuestions_Order_By>>;
  where?: InputMaybe<NotebookQuestions_Bool_Exp>;
};


/** columns and relationships of "notebooks" */
export type NotebooksNotebooks_NotebookQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<NotebookQuestions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NotebookQuestions_Order_By>>;
  where?: InputMaybe<NotebookQuestions_Bool_Exp>;
};

/** aggregated selection of "notebooks" */
export type Notebooks_Aggregate = {
  __typename?: 'notebooks_aggregate';
  aggregate?: Maybe<Notebooks_Aggregate_Fields>;
  nodes: Array<Notebooks>;
};

/** aggregate fields of "notebooks" */
export type Notebooks_Aggregate_Fields = {
  __typename?: 'notebooks_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Notebooks_Max_Fields>;
  min?: Maybe<Notebooks_Min_Fields>;
};


/** aggregate fields of "notebooks" */
export type Notebooks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notebooks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "notebooks". All fields are combined with a logical 'AND'. */
export type Notebooks_Bool_Exp = {
  _and?: InputMaybe<Array<Notebooks_Bool_Exp>>;
  _not?: InputMaybe<Notebooks_Bool_Exp>;
  _or?: InputMaybe<Array<Notebooks_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isArchived?: InputMaybe<Boolean_Comparison_Exp>;
  isStarred?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  notebooks_notebookQuestions?: InputMaybe<NotebookQuestions_Bool_Exp>;
  notebooks_notebookQuestions_aggregate?: InputMaybe<NotebookQuestions_Aggregate_Bool_Exp>;
  notebooks_owner?: InputMaybe<Users_Bool_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  owner_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "notebooks" */
export enum Notebooks_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotebooksPkey = 'notebooks_pkey'
}

/** input type for inserting data into table "notebooks" */
export type Notebooks_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isStarred?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notebooks_notebookQuestions?: InputMaybe<NotebookQuestions_Arr_Rel_Insert_Input>;
  notebooks_owner?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Notebooks_Max_Fields = {
  __typename?: 'notebooks_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  owner_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Notebooks_Min_Fields = {
  __typename?: 'notebooks_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  owner_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "notebooks" */
export type Notebooks_Mutation_Response = {
  __typename?: 'notebooks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Notebooks>;
};

/** input type for inserting object relation for remote table "notebooks" */
export type Notebooks_Obj_Rel_Insert_Input = {
  data: Notebooks_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Notebooks_On_Conflict>;
};

/** on_conflict condition type for table "notebooks" */
export type Notebooks_On_Conflict = {
  constraint: Notebooks_Constraint;
  update_columns?: Array<Notebooks_Update_Column>;
  where?: InputMaybe<Notebooks_Bool_Exp>;
};

/** Ordering options when selecting data from "notebooks". */
export type Notebooks_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isArchived?: InputMaybe<Order_By>;
  isStarred?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notebooks_notebookQuestions_aggregate?: InputMaybe<NotebookQuestions_Aggregate_Order_By>;
  notebooks_owner?: InputMaybe<Users_Order_By>;
  org_id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notebooks */
export type Notebooks_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "notebooks" */
export enum Notebooks_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  IsStarred = 'isStarred',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "notebooks" */
export type Notebooks_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isStarred?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "notebooks" */
export type Notebooks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notebooks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notebooks_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isStarred?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "notebooks" */
export enum Notebooks_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  IsStarred = 'isStarred',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Notebooks_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notebooks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notebooks_Bool_Exp;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** Multi-Tenant Orgs to Silo Data Per Org */
export type Organizations = {
  __typename?: 'organizations';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
};


/** Multi-Tenant Orgs to Silo Data Per Org */
export type OrganizationsUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** Multi-Tenant Orgs to Silo Data Per Org */
export type OrganizationsUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** aggregated selection of "organizations" */
export type Organizations_Aggregate = {
  __typename?: 'organizations_aggregate';
  aggregate?: Maybe<Organizations_Aggregate_Fields>;
  nodes: Array<Organizations>;
};

/** aggregate fields of "organizations" */
export type Organizations_Aggregate_Fields = {
  __typename?: 'organizations_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Organizations_Max_Fields>;
  min?: Maybe<Organizations_Min_Fields>;
};


/** aggregate fields of "organizations" */
export type Organizations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organizations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "organizations". All fields are combined with a logical 'AND'. */
export type Organizations_Bool_Exp = {
  _and?: InputMaybe<Array<Organizations_Bool_Exp>>;
  _not?: InputMaybe<Organizations_Bool_Exp>;
  _or?: InputMaybe<Array<Organizations_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  users?: InputMaybe<Users_Bool_Exp>;
  users_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "organizations" */
export enum Organizations_Constraint {
  /** unique or primary key constraint on columns "name" */
  OrganizationsNameKey = 'organizations_name_key',
  /** unique or primary key constraint on columns "id" */
  OrganizationsPkey = 'organizations_pkey'
}

/** input type for inserting data into table "organizations" */
export type Organizations_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  users?: InputMaybe<Users_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Organizations_Max_Fields = {
  __typename?: 'organizations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Organizations_Min_Fields = {
  __typename?: 'organizations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "organizations" */
export type Organizations_Mutation_Response = {
  __typename?: 'organizations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Organizations>;
};

/** input type for inserting object relation for remote table "organizations" */
export type Organizations_Obj_Rel_Insert_Input = {
  data: Organizations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organizations_On_Conflict>;
};

/** on_conflict condition type for table "organizations" */
export type Organizations_On_Conflict = {
  constraint: Organizations_Constraint;
  update_columns?: Array<Organizations_Update_Column>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};

/** Ordering options when selecting data from "organizations". */
export type Organizations_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  users_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
};

/** primary key columns input for table: organizations */
export type Organizations_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "organizations" */
export enum Organizations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "organizations" */
export type Organizations_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "organizations" */
export type Organizations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organizations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organizations_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "organizations" */
export enum Organizations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Organizations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organizations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organizations_Bool_Exp;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "accounts" */
  accounts: Array<Accounts>;
  /** fetch aggregated fields from the table: "accounts" */
  accounts_aggregate: Accounts_Aggregate;
  /** fetch data from the table: "accounts" using primary key columns */
  accounts_by_pk?: Maybe<Accounts>;
  /** fetch data from the table: "billingAccounts" */
  billingAccounts: Array<BillingAccounts>;
  /** fetch aggregated fields from the table: "billingAccounts" */
  billingAccounts_aggregate: BillingAccounts_Aggregate;
  /** fetch data from the table: "billingAccounts" using primary key columns */
  billingAccounts_by_pk?: Maybe<BillingAccounts>;
  /** fetch data from the table: "bonusTypes" */
  bonusTypes: Array<BonusTypes>;
  /** fetch aggregated fields from the table: "bonusTypes" */
  bonusTypes_aggregate: BonusTypes_Aggregate;
  /** fetch data from the table: "bonusTypes" using primary key columns */
  bonusTypes_by_pk?: Maybe<BonusTypes>;
  /** fetch data from the table: "bonuses" */
  bonuses: Array<Bonuses>;
  /** fetch aggregated fields from the table: "bonuses" */
  bonuses_aggregate: Bonuses_Aggregate;
  /** fetch data from the table: "bonuses" using primary key columns */
  bonuses_by_pk?: Maybe<Bonuses>;
  /** fetch data from the table: "categories" */
  categories: Array<Categories>;
  /** fetch aggregated fields from the table: "categories" */
  categories_aggregate: Categories_Aggregate;
  /** fetch data from the table: "categories" using primary key columns */
  categories_by_pk?: Maybe<Categories>;
  /** An array relationship */
  eventHosts: Array<EventHosts>;
  /** An aggregate relationship */
  eventHosts_aggregate: EventHosts_Aggregate;
  /** fetch data from the table: "eventHosts" using primary key columns */
  eventHosts_by_pk?: Maybe<EventHosts>;
  /** An array relationship */
  eventQuestions: Array<EventQuestions>;
  /** An aggregate relationship */
  eventQuestions_aggregate: EventQuestions_Aggregate;
  /** fetch data from the table: "eventQuestions" using primary key columns */
  eventQuestions_by_pk?: Maybe<EventQuestions>;
  /** fetch data from the table: "eventTemplateHosts" */
  eventTemplateHosts: Array<EventTemplateHosts>;
  /** fetch aggregated fields from the table: "eventTemplateHosts" */
  eventTemplateHosts_aggregate: EventTemplateHosts_Aggregate;
  /** fetch data from the table: "eventTemplateHosts" using primary key columns */
  eventTemplateHosts_by_pk?: Maybe<EventTemplateHosts>;
  /** fetch data from the table: "eventTemplates" */
  eventTemplates: Array<EventTemplates>;
  /** fetch aggregated fields from the table: "eventTemplates" */
  eventTemplates_aggregate: EventTemplates_Aggregate;
  /** fetch data from the table: "eventTemplates" using primary key columns */
  eventTemplates_by_pk?: Maybe<EventTemplates>;
  /** An array relationship */
  events: Array<Events>;
  /** An aggregate relationship */
  events_aggregate: Events_Aggregate;
  /** fetch data from the table: "events" using primary key columns */
  events_by_pk?: Maybe<Events>;
  /** fetch data from the table: "filters" */
  filters: Array<Filters>;
  /** fetch aggregated fields from the table: "filters" */
  filters_aggregate: Filters_Aggregate;
  /** fetch data from the table: "filters" using primary key columns */
  filters_by_pk?: Maybe<Filters>;
  /** fetch data from the table: "hosts" */
  hosts: Array<Hosts>;
  /** fetch aggregated fields from the table: "hosts" */
  hosts_aggregate: Hosts_Aggregate;
  /** fetch data from the table: "hosts" using primary key columns */
  hosts_by_pk?: Maybe<Hosts>;
  /** fetch data from the table: "locations" */
  locations: Array<Locations>;
  /** fetch aggregated fields from the table: "locations" */
  locations_aggregate: Locations_Aggregate;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<Locations>;
  /** fetch data from the table: "notebookQuestions" */
  notebookQuestions: Array<NotebookQuestions>;
  /** fetch aggregated fields from the table: "notebookQuestions" */
  notebookQuestions_aggregate: NotebookQuestions_Aggregate;
  /** fetch data from the table: "notebookQuestions" using primary key columns */
  notebookQuestions_by_pk?: Maybe<NotebookQuestions>;
  /** fetch data from the table: "notebooks" */
  notebooks: Array<Notebooks>;
  /** fetch aggregated fields from the table: "notebooks" */
  notebooks_aggregate: Notebooks_Aggregate;
  /** fetch data from the table: "notebooks" using primary key columns */
  notebooks_by_pk?: Maybe<Notebooks>;
  /** fetch data from the table: "organizations" */
  organizations: Array<Organizations>;
  /** fetch aggregated fields from the table: "organizations" */
  organizations_aggregate: Organizations_Aggregate;
  /** fetch data from the table: "organizations" using primary key columns */
  organizations_by_pk?: Maybe<Organizations>;
  /** An array relationship */
  questionTags: Array<QuestionTags>;
  /** An aggregate relationship */
  questionTags_aggregate: QuestionTags_Aggregate;
  /** fetch data from the table: "questionTags" using primary key columns */
  questionTags_by_pk?: Maybe<QuestionTags>;
  /** fetch data from the table: "questionTypes" */
  questionTypes: Array<QuestionTypes>;
  /** fetch aggregated fields from the table: "questionTypes" */
  questionTypes_aggregate: QuestionTypes_Aggregate;
  /** fetch data from the table: "questionTypes" using primary key columns */
  questionTypes_by_pk?: Maybe<QuestionTypes>;
  /** An array relationship */
  questionVersions: Array<QuestionVersions>;
  /** An aggregate relationship */
  questionVersions_aggregate: QuestionVersions_Aggregate;
  /** fetch data from the table: "questionVersions" using primary key columns */
  questionVersions_by_pk?: Maybe<QuestionVersions>;
  /** An array relationship */
  questions: Array<Questions>;
  /** An aggregate relationship */
  questions_aggregate: Questions_Aggregate;
  /** fetch data from the table: "questions" using primary key columns */
  questions_by_pk?: Maybe<Questions>;
  /** fetch data from the table: "regions" */
  regions: Array<Regions>;
  /** fetch aggregated fields from the table: "regions" */
  regions_aggregate: Regions_Aggregate;
  /** fetch data from the table: "regions" using primary key columns */
  regions_by_pk?: Maybe<Regions>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** Suggest Question Tags */
  suggestQuestionTags?: Maybe<CategoryResponse>;
  /** fetch data from the table: "tags" */
  tags: Array<Tags>;
  /** fetch aggregated fields from the table: "tags" */
  tags_aggregate: Tags_Aggregate;
  /** fetch data from the table: "tags" using primary key columns */
  tags_by_pk?: Maybe<Tags>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


export type Query_RootAccountsArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


export type Query_RootAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


export type Query_RootAccounts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootBillingAccountsArgs = {
  distinct_on?: InputMaybe<Array<BillingAccounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BillingAccounts_Order_By>>;
  where?: InputMaybe<BillingAccounts_Bool_Exp>;
};


export type Query_RootBillingAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BillingAccounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BillingAccounts_Order_By>>;
  where?: InputMaybe<BillingAccounts_Bool_Exp>;
};


export type Query_RootBillingAccounts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootBonusTypesArgs = {
  distinct_on?: InputMaybe<Array<BonusTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BonusTypes_Order_By>>;
  where?: InputMaybe<BonusTypes_Bool_Exp>;
};


export type Query_RootBonusTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BonusTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BonusTypes_Order_By>>;
  where?: InputMaybe<BonusTypes_Bool_Exp>;
};


export type Query_RootBonusTypes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootBonusesArgs = {
  distinct_on?: InputMaybe<Array<Bonuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Bonuses_Order_By>>;
  where?: InputMaybe<Bonuses_Bool_Exp>;
};


export type Query_RootBonuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Bonuses_Order_By>>;
  where?: InputMaybe<Bonuses_Bool_Exp>;
};


export type Query_RootBonuses_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Order_By>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};


export type Query_RootCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Order_By>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};


export type Query_RootCategories_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootEventHostsArgs = {
  distinct_on?: InputMaybe<Array<EventHosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventHosts_Order_By>>;
  where?: InputMaybe<EventHosts_Bool_Exp>;
};


export type Query_RootEventHosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EventHosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventHosts_Order_By>>;
  where?: InputMaybe<EventHosts_Bool_Exp>;
};


export type Query_RootEventHosts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootEventQuestionsArgs = {
  distinct_on?: InputMaybe<Array<EventQuestions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventQuestions_Order_By>>;
  where?: InputMaybe<EventQuestions_Bool_Exp>;
};


export type Query_RootEventQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EventQuestions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventQuestions_Order_By>>;
  where?: InputMaybe<EventQuestions_Bool_Exp>;
};


export type Query_RootEventQuestions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootEventTemplateHostsArgs = {
  distinct_on?: InputMaybe<Array<EventTemplateHosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventTemplateHosts_Order_By>>;
  where?: InputMaybe<EventTemplateHosts_Bool_Exp>;
};


export type Query_RootEventTemplateHosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EventTemplateHosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventTemplateHosts_Order_By>>;
  where?: InputMaybe<EventTemplateHosts_Bool_Exp>;
};


export type Query_RootEventTemplateHosts_By_PkArgs = {
  event_id: Scalars['uuid']['input'];
  host_id: Scalars['uuid']['input'];
};


export type Query_RootEventTemplatesArgs = {
  distinct_on?: InputMaybe<Array<EventTemplates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventTemplates_Order_By>>;
  where?: InputMaybe<EventTemplates_Bool_Exp>;
};


export type Query_RootEventTemplates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EventTemplates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventTemplates_Order_By>>;
  where?: InputMaybe<EventTemplates_Bool_Exp>;
};


export type Query_RootEventTemplates_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootEventsArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


export type Query_RootEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


export type Query_RootEvents_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootFiltersArgs = {
  distinct_on?: InputMaybe<Array<Filters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Filters_Order_By>>;
  where?: InputMaybe<Filters_Bool_Exp>;
};


export type Query_RootFilters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Filters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Filters_Order_By>>;
  where?: InputMaybe<Filters_Bool_Exp>;
};


export type Query_RootFilters_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootHostsArgs = {
  distinct_on?: InputMaybe<Array<Hosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Hosts_Order_By>>;
  where?: InputMaybe<Hosts_Bool_Exp>;
};


export type Query_RootHosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Hosts_Order_By>>;
  where?: InputMaybe<Hosts_Bool_Exp>;
};


export type Query_RootHosts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootLocationsArgs = {
  distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Locations_Order_By>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};


export type Query_RootLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Locations_Order_By>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};


export type Query_RootLocations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootNotebookQuestionsArgs = {
  distinct_on?: InputMaybe<Array<NotebookQuestions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NotebookQuestions_Order_By>>;
  where?: InputMaybe<NotebookQuestions_Bool_Exp>;
};


export type Query_RootNotebookQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<NotebookQuestions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NotebookQuestions_Order_By>>;
  where?: InputMaybe<NotebookQuestions_Bool_Exp>;
};


export type Query_RootNotebookQuestions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootNotebooksArgs = {
  distinct_on?: InputMaybe<Array<Notebooks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notebooks_Order_By>>;
  where?: InputMaybe<Notebooks_Bool_Exp>;
};


export type Query_RootNotebooks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notebooks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notebooks_Order_By>>;
  where?: InputMaybe<Notebooks_Bool_Exp>;
};


export type Query_RootNotebooks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


export type Query_RootOrganizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


export type Query_RootOrganizations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootQuestionTagsArgs = {
  distinct_on?: InputMaybe<Array<QuestionTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionTags_Order_By>>;
  where?: InputMaybe<QuestionTags_Bool_Exp>;
};


export type Query_RootQuestionTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionTags_Order_By>>;
  where?: InputMaybe<QuestionTags_Bool_Exp>;
};


export type Query_RootQuestionTags_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootQuestionTypesArgs = {
  distinct_on?: InputMaybe<Array<QuestionTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionTypes_Order_By>>;
  where?: InputMaybe<QuestionTypes_Bool_Exp>;
};


export type Query_RootQuestionTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionTypes_Order_By>>;
  where?: InputMaybe<QuestionTypes_Bool_Exp>;
};


export type Query_RootQuestionTypes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootQuestionVersionsArgs = {
  distinct_on?: InputMaybe<Array<QuestionVersions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionVersions_Order_By>>;
  where?: InputMaybe<QuestionVersions_Bool_Exp>;
};


export type Query_RootQuestionVersions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionVersions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionVersions_Order_By>>;
  where?: InputMaybe<QuestionVersions_Bool_Exp>;
};


export type Query_RootQuestionVersions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


export type Query_RootQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


export type Query_RootQuestions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootRegionsArgs = {
  distinct_on?: InputMaybe<Array<Regions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Regions_Order_By>>;
  where?: InputMaybe<Regions_Bool_Exp>;
};


export type Query_RootRegions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Regions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Regions_Order_By>>;
  where?: InputMaybe<Regions_Bool_Exp>;
};


export type Query_RootRegions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Query_RootRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Query_RootRoles_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootSuggestQuestionTagsArgs = {
  answer?: InputMaybe<Scalars['String']['input']>;
  question: Scalars['String']['input'];
};


export type Query_RootTagsArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Query_RootTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Query_RootTags_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['String']['input'];
};

/** Association of tags to questions */
export type QuestionTags = {
  __typename?: 'questionTags';
  id: Scalars['uuid']['output'];
  org_id: Scalars['uuid']['output'];
  /** An object relationship */
  question: Questions;
  question_id: Scalars['uuid']['output'];
  /** An object relationship */
  tag: Tags;
  tag_id: Scalars['uuid']['output'];
};

/** aggregated selection of "questionTags" */
export type QuestionTags_Aggregate = {
  __typename?: 'questionTags_aggregate';
  aggregate?: Maybe<QuestionTags_Aggregate_Fields>;
  nodes: Array<QuestionTags>;
};

export type QuestionTags_Aggregate_Bool_Exp = {
  count?: InputMaybe<QuestionTags_Aggregate_Bool_Exp_Count>;
};

export type QuestionTags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<QuestionTags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<QuestionTags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "questionTags" */
export type QuestionTags_Aggregate_Fields = {
  __typename?: 'questionTags_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<QuestionTags_Max_Fields>;
  min?: Maybe<QuestionTags_Min_Fields>;
};


/** aggregate fields of "questionTags" */
export type QuestionTags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<QuestionTags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "questionTags" */
export type QuestionTags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<QuestionTags_Max_Order_By>;
  min?: InputMaybe<QuestionTags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "questionTags" */
export type QuestionTags_Arr_Rel_Insert_Input = {
  data: Array<QuestionTags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<QuestionTags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "questionTags". All fields are combined with a logical 'AND'. */
export type QuestionTags_Bool_Exp = {
  _and?: InputMaybe<Array<QuestionTags_Bool_Exp>>;
  _not?: InputMaybe<QuestionTags_Bool_Exp>;
  _or?: InputMaybe<Array<QuestionTags_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  question?: InputMaybe<Questions_Bool_Exp>;
  question_id?: InputMaybe<Uuid_Comparison_Exp>;
  tag?: InputMaybe<Tags_Bool_Exp>;
  tag_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "questionTags" */
export enum QuestionTags_Constraint {
  /** unique or primary key constraint on columns "id" */
  QuestionTagsPkey = 'questionTags_pkey'
}

/** input type for inserting data into table "questionTags" */
export type QuestionTags_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  question?: InputMaybe<Questions_Obj_Rel_Insert_Input>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  tag?: InputMaybe<Tags_Obj_Rel_Insert_Input>;
  tag_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type QuestionTags_Max_Fields = {
  __typename?: 'questionTags_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  question_id?: Maybe<Scalars['uuid']['output']>;
  tag_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "questionTags" */
export type QuestionTags_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type QuestionTags_Min_Fields = {
  __typename?: 'questionTags_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  question_id?: Maybe<Scalars['uuid']['output']>;
  tag_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "questionTags" */
export type QuestionTags_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "questionTags" */
export type QuestionTags_Mutation_Response = {
  __typename?: 'questionTags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<QuestionTags>;
};

/** on_conflict condition type for table "questionTags" */
export type QuestionTags_On_Conflict = {
  constraint: QuestionTags_Constraint;
  update_columns?: Array<QuestionTags_Update_Column>;
  where?: InputMaybe<QuestionTags_Bool_Exp>;
};

/** Ordering options when selecting data from "questionTags". */
export type QuestionTags_Order_By = {
  id?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  question?: InputMaybe<Questions_Order_By>;
  question_id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Tags_Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: questionTags */
export type QuestionTags_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "questionTags" */
export enum QuestionTags_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  TagId = 'tag_id'
}

/** input type for updating data in table "questionTags" */
export type QuestionTags_Set_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  tag_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "questionTags" */
export type QuestionTags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: QuestionTags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type QuestionTags_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  tag_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "questionTags" */
export enum QuestionTags_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  TagId = 'tag_id'
}

export type QuestionTags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<QuestionTags_Set_Input>;
  /** filter the rows which have to be updated */
  where: QuestionTags_Bool_Exp;
};

/** columns and relationships of "questionTypes" */
export type QuestionTypes = {
  __typename?: 'questionTypes';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  org_id?: Maybe<Scalars['uuid']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "questionTypes" */
export type QuestionTypes_Aggregate = {
  __typename?: 'questionTypes_aggregate';
  aggregate?: Maybe<QuestionTypes_Aggregate_Fields>;
  nodes: Array<QuestionTypes>;
};

/** aggregate fields of "questionTypes" */
export type QuestionTypes_Aggregate_Fields = {
  __typename?: 'questionTypes_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<QuestionTypes_Max_Fields>;
  min?: Maybe<QuestionTypes_Min_Fields>;
};


/** aggregate fields of "questionTypes" */
export type QuestionTypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<QuestionTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "questionTypes". All fields are combined with a logical 'AND'. */
export type QuestionTypes_Bool_Exp = {
  _and?: InputMaybe<Array<QuestionTypes_Bool_Exp>>;
  _not?: InputMaybe<QuestionTypes_Bool_Exp>;
  _or?: InputMaybe<Array<QuestionTypes_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "questionTypes" */
export enum QuestionTypes_Constraint {
  /** unique or primary key constraint on columns "name" */
  QuestionTypesNameKey = 'questionTypes_name_key',
  /** unique or primary key constraint on columns "id" */
  QuestionTypesPkey = 'questionTypes_pkey'
}

/** input type for inserting data into table "questionTypes" */
export type QuestionTypes_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type QuestionTypes_Max_Fields = {
  __typename?: 'questionTypes_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type QuestionTypes_Min_Fields = {
  __typename?: 'questionTypes_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "questionTypes" */
export type QuestionTypes_Mutation_Response = {
  __typename?: 'questionTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<QuestionTypes>;
};

/** input type for inserting object relation for remote table "questionTypes" */
export type QuestionTypes_Obj_Rel_Insert_Input = {
  data: QuestionTypes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<QuestionTypes_On_Conflict>;
};

/** on_conflict condition type for table "questionTypes" */
export type QuestionTypes_On_Conflict = {
  constraint: QuestionTypes_Constraint;
  update_columns?: Array<QuestionTypes_Update_Column>;
  where?: InputMaybe<QuestionTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "questionTypes". */
export type QuestionTypes_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: questionTypes */
export type QuestionTypes_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "questionTypes" */
export enum QuestionTypes_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "questionTypes" */
export type QuestionTypes_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "questionTypes" */
export type QuestionTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: QuestionTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type QuestionTypes_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "questionTypes" */
export enum QuestionTypes_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type QuestionTypes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<QuestionTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: QuestionTypes_Bool_Exp;
};

/** columns and relationships of "questionVersions" */
export type QuestionVersions = {
  __typename?: 'questionVersions';
  answer?: Maybe<Scalars['String']['output']>;
  category_id?: Maybe<Scalars['uuid']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  isTimeSensitive: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  org_id: Scalars['uuid']['output'];
  question: Scalars['String']['output'];
  /** An object relationship */
  questionByQuestionId: Questions;
  question_id: Scalars['uuid']['output'];
  status?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "questionVersions" */
export type QuestionVersions_Aggregate = {
  __typename?: 'questionVersions_aggregate';
  aggregate?: Maybe<QuestionVersions_Aggregate_Fields>;
  nodes: Array<QuestionVersions>;
};

export type QuestionVersions_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<QuestionVersions_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<QuestionVersions_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<QuestionVersions_Aggregate_Bool_Exp_Count>;
};

export type QuestionVersions_Aggregate_Bool_Exp_Bool_And = {
  arguments: QuestionVersions_Select_Column_QuestionVersions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<QuestionVersions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type QuestionVersions_Aggregate_Bool_Exp_Bool_Or = {
  arguments: QuestionVersions_Select_Column_QuestionVersions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<QuestionVersions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type QuestionVersions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<QuestionVersions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<QuestionVersions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "questionVersions" */
export type QuestionVersions_Aggregate_Fields = {
  __typename?: 'questionVersions_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<QuestionVersions_Max_Fields>;
  min?: Maybe<QuestionVersions_Min_Fields>;
};


/** aggregate fields of "questionVersions" */
export type QuestionVersions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<QuestionVersions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "questionVersions" */
export type QuestionVersions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<QuestionVersions_Max_Order_By>;
  min?: InputMaybe<QuestionVersions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "questionVersions" */
export type QuestionVersions_Arr_Rel_Insert_Input = {
  data: Array<QuestionVersions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<QuestionVersions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "questionVersions". All fields are combined with a logical 'AND'. */
export type QuestionVersions_Bool_Exp = {
  _and?: InputMaybe<Array<QuestionVersions_Bool_Exp>>;
  _not?: InputMaybe<QuestionVersions_Bool_Exp>;
  _or?: InputMaybe<Array<QuestionVersions_Bool_Exp>>;
  answer?: InputMaybe<String_Comparison_Exp>;
  category_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isTimeSensitive?: InputMaybe<Boolean_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  question?: InputMaybe<String_Comparison_Exp>;
  questionByQuestionId?: InputMaybe<Questions_Bool_Exp>;
  question_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "questionVersions" */
export enum QuestionVersions_Constraint {
  /** unique or primary key constraint on columns "id" */
  QuestionVersionsPkey = 'questionVersions_pkey'
}

/** input type for inserting data into table "questionVersions" */
export type QuestionVersions_Insert_Input = {
  answer?: InputMaybe<Scalars['String']['input']>;
  category_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isTimeSensitive?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  questionByQuestionId?: InputMaybe<Questions_Obj_Rel_Insert_Input>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type QuestionVersions_Max_Fields = {
  __typename?: 'questionVersions_max_fields';
  answer?: Maybe<Scalars['String']['output']>;
  category_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  question_id?: Maybe<Scalars['uuid']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "questionVersions" */
export type QuestionVersions_Max_Order_By = {
  answer?: InputMaybe<Order_By>;
  category_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type QuestionVersions_Min_Fields = {
  __typename?: 'questionVersions_min_fields';
  answer?: Maybe<Scalars['String']['output']>;
  category_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  question_id?: Maybe<Scalars['uuid']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "questionVersions" */
export type QuestionVersions_Min_Order_By = {
  answer?: InputMaybe<Order_By>;
  category_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "questionVersions" */
export type QuestionVersions_Mutation_Response = {
  __typename?: 'questionVersions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<QuestionVersions>;
};

/** on_conflict condition type for table "questionVersions" */
export type QuestionVersions_On_Conflict = {
  constraint: QuestionVersions_Constraint;
  update_columns?: Array<QuestionVersions_Update_Column>;
  where?: InputMaybe<QuestionVersions_Bool_Exp>;
};

/** Ordering options when selecting data from "questionVersions". */
export type QuestionVersions_Order_By = {
  answer?: InputMaybe<Order_By>;
  category_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isTimeSensitive?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  questionByQuestionId?: InputMaybe<Questions_Order_By>;
  question_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: questionVersions */
export type QuestionVersions_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "questionVersions" */
export enum QuestionVersions_Select_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsTimeSensitive = 'isTimeSensitive',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Question = 'question',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "questionVersions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "questionVersions" */
export enum QuestionVersions_Select_Column_QuestionVersions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsTimeSensitive = 'isTimeSensitive'
}

/** select "questionVersions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "questionVersions" */
export enum QuestionVersions_Select_Column_QuestionVersions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsTimeSensitive = 'isTimeSensitive'
}

/** input type for updating data in table "questionVersions" */
export type QuestionVersions_Set_Input = {
  answer?: InputMaybe<Scalars['String']['input']>;
  category_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isTimeSensitive?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "questionVersions" */
export type QuestionVersions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: QuestionVersions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type QuestionVersions_Stream_Cursor_Value_Input = {
  answer?: InputMaybe<Scalars['String']['input']>;
  category_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isTimeSensitive?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "questionVersions" */
export enum QuestionVersions_Update_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsTimeSensitive = 'isTimeSensitive',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Question = 'question',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type QuestionVersions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<QuestionVersions_Set_Input>;
  /** filter the rows which have to be updated */
  where: QuestionVersions_Bool_Exp;
};

/** columns and relationships of "questions" */
export type Questions = {
  __typename?: 'questions';
  answer?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  category?: Maybe<Categories>;
  category_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  creator_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  eventQuestions: Array<EventQuestions>;
  /** An aggregate relationship */
  eventQuestions_aggregate: EventQuestions_Aggregate;
  id: Scalars['uuid']['output'];
  isTimeSensitive: Scalars['Boolean']['output'];
  org_id?: Maybe<Scalars['uuid']['output']>;
  question: Scalars['String']['output'];
  /** An array relationship */
  questionTags: Array<QuestionTags>;
  /** An aggregate relationship */
  questionTags_aggregate: QuestionTags_Aggregate;
  /** An object relationship */
  questionType: QuestionTypes;
  /** An array relationship */
  questionVersions: Array<QuestionVersions>;
  /** An aggregate relationship */
  questionVersions_aggregate: QuestionVersions_Aggregate;
  status: Scalars['String']['output'];
  type_id: Scalars['uuid']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "questions" */
export type QuestionsEventQuestionsArgs = {
  distinct_on?: InputMaybe<Array<EventQuestions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventQuestions_Order_By>>;
  where?: InputMaybe<EventQuestions_Bool_Exp>;
};


/** columns and relationships of "questions" */
export type QuestionsEventQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EventQuestions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventQuestions_Order_By>>;
  where?: InputMaybe<EventQuestions_Bool_Exp>;
};


/** columns and relationships of "questions" */
export type QuestionsQuestionTagsArgs = {
  distinct_on?: InputMaybe<Array<QuestionTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionTags_Order_By>>;
  where?: InputMaybe<QuestionTags_Bool_Exp>;
};


/** columns and relationships of "questions" */
export type QuestionsQuestionTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionTags_Order_By>>;
  where?: InputMaybe<QuestionTags_Bool_Exp>;
};


/** columns and relationships of "questions" */
export type QuestionsQuestionVersionsArgs = {
  distinct_on?: InputMaybe<Array<QuestionVersions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionVersions_Order_By>>;
  where?: InputMaybe<QuestionVersions_Bool_Exp>;
};


/** columns and relationships of "questions" */
export type QuestionsQuestionVersions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionVersions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionVersions_Order_By>>;
  where?: InputMaybe<QuestionVersions_Bool_Exp>;
};

/** aggregated selection of "questions" */
export type Questions_Aggregate = {
  __typename?: 'questions_aggregate';
  aggregate?: Maybe<Questions_Aggregate_Fields>;
  nodes: Array<Questions>;
};

export type Questions_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Questions_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Questions_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Questions_Aggregate_Bool_Exp_Count>;
};

export type Questions_Aggregate_Bool_Exp_Bool_And = {
  arguments: Questions_Select_Column_Questions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Questions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Questions_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Questions_Select_Column_Questions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Questions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Questions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Questions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "questions" */
export type Questions_Aggregate_Fields = {
  __typename?: 'questions_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Questions_Max_Fields>;
  min?: Maybe<Questions_Min_Fields>;
};


/** aggregate fields of "questions" */
export type Questions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "questions" */
export type Questions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Questions_Max_Order_By>;
  min?: InputMaybe<Questions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "questions" */
export type Questions_Arr_Rel_Insert_Input = {
  data: Array<Questions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Questions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "questions". All fields are combined with a logical 'AND'. */
export type Questions_Bool_Exp = {
  _and?: InputMaybe<Array<Questions_Bool_Exp>>;
  _not?: InputMaybe<Questions_Bool_Exp>;
  _or?: InputMaybe<Array<Questions_Bool_Exp>>;
  answer?: InputMaybe<String_Comparison_Exp>;
  category?: InputMaybe<Categories_Bool_Exp>;
  category_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  creator_id?: InputMaybe<Uuid_Comparison_Exp>;
  eventQuestions?: InputMaybe<EventQuestions_Bool_Exp>;
  eventQuestions_aggregate?: InputMaybe<EventQuestions_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isTimeSensitive?: InputMaybe<Boolean_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  question?: InputMaybe<String_Comparison_Exp>;
  questionTags?: InputMaybe<QuestionTags_Bool_Exp>;
  questionTags_aggregate?: InputMaybe<QuestionTags_Aggregate_Bool_Exp>;
  questionType?: InputMaybe<QuestionTypes_Bool_Exp>;
  questionVersions?: InputMaybe<QuestionVersions_Bool_Exp>;
  questionVersions_aggregate?: InputMaybe<QuestionVersions_Aggregate_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "questions" */
export enum Questions_Constraint {
  /** unique or primary key constraint on columns "id" */
  QuestionsPkey = 'questions_pkey'
}

/** input type for inserting data into table "questions" */
export type Questions_Insert_Input = {
  answer?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Categories_Obj_Rel_Insert_Input>;
  category_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  creator_id?: InputMaybe<Scalars['uuid']['input']>;
  eventQuestions?: InputMaybe<EventQuestions_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isTimeSensitive?: InputMaybe<Scalars['Boolean']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  questionTags?: InputMaybe<QuestionTags_Arr_Rel_Insert_Input>;
  questionType?: InputMaybe<QuestionTypes_Obj_Rel_Insert_Input>;
  questionVersions?: InputMaybe<QuestionVersions_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']['input']>;
  type_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Questions_Max_Fields = {
  __typename?: 'questions_max_fields';
  answer?: Maybe<Scalars['String']['output']>;
  category_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  creator_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "questions" */
export type Questions_Max_Order_By = {
  answer?: InputMaybe<Order_By>;
  category_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Questions_Min_Fields = {
  __typename?: 'questions_min_fields';
  answer?: Maybe<Scalars['String']['output']>;
  category_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  creator_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "questions" */
export type Questions_Min_Order_By = {
  answer?: InputMaybe<Order_By>;
  category_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "questions" */
export type Questions_Mutation_Response = {
  __typename?: 'questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Questions>;
};

/** input type for inserting object relation for remote table "questions" */
export type Questions_Obj_Rel_Insert_Input = {
  data: Questions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Questions_On_Conflict>;
};

/** on_conflict condition type for table "questions" */
export type Questions_On_Conflict = {
  constraint: Questions_Constraint;
  update_columns?: Array<Questions_Update_Column>;
  where?: InputMaybe<Questions_Bool_Exp>;
};

/** Ordering options when selecting data from "questions". */
export type Questions_Order_By = {
  answer?: InputMaybe<Order_By>;
  category?: InputMaybe<Categories_Order_By>;
  category_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  eventQuestions_aggregate?: InputMaybe<EventQuestions_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  isTimeSensitive?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  questionTags_aggregate?: InputMaybe<QuestionTags_Aggregate_Order_By>;
  questionType?: InputMaybe<QuestionTypes_Order_By>;
  questionVersions_aggregate?: InputMaybe<QuestionVersions_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: questions */
export type Questions_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "questions" */
export enum Questions_Select_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsTimeSensitive = 'isTimeSensitive',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Question = 'question',
  /** column name */
  Status = 'status',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "questions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "questions" */
export enum Questions_Select_Column_Questions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsTimeSensitive = 'isTimeSensitive'
}

/** select "questions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "questions" */
export enum Questions_Select_Column_Questions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsTimeSensitive = 'isTimeSensitive'
}

/** input type for updating data in table "questions" */
export type Questions_Set_Input = {
  answer?: InputMaybe<Scalars['String']['input']>;
  category_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  creator_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isTimeSensitive?: InputMaybe<Scalars['Boolean']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  type_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "questions" */
export type Questions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Questions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Questions_Stream_Cursor_Value_Input = {
  answer?: InputMaybe<Scalars['String']['input']>;
  category_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  creator_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isTimeSensitive?: InputMaybe<Scalars['Boolean']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  type_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "questions" */
export enum Questions_Update_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsTimeSensitive = 'isTimeSensitive',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Question = 'question',
  /** column name */
  Status = 'status',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Questions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Questions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Questions_Bool_Exp;
};

/** columns and relationships of "regions" */
export type Regions = {
  __typename?: 'regions';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  legacyId?: Maybe<Scalars['numeric']['output']>;
  name: Scalars['String']['output'];
  org_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "regions" */
export type Regions_Aggregate = {
  __typename?: 'regions_aggregate';
  aggregate?: Maybe<Regions_Aggregate_Fields>;
  nodes: Array<Regions>;
};

/** aggregate fields of "regions" */
export type Regions_Aggregate_Fields = {
  __typename?: 'regions_aggregate_fields';
  avg?: Maybe<Regions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Regions_Max_Fields>;
  min?: Maybe<Regions_Min_Fields>;
  stddev?: Maybe<Regions_Stddev_Fields>;
  stddev_pop?: Maybe<Regions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Regions_Stddev_Samp_Fields>;
  sum?: Maybe<Regions_Sum_Fields>;
  var_pop?: Maybe<Regions_Var_Pop_Fields>;
  var_samp?: Maybe<Regions_Var_Samp_Fields>;
  variance?: Maybe<Regions_Variance_Fields>;
};


/** aggregate fields of "regions" */
export type Regions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Regions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Regions_Avg_Fields = {
  __typename?: 'regions_avg_fields';
  legacyId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "regions". All fields are combined with a logical 'AND'. */
export type Regions_Bool_Exp = {
  _and?: InputMaybe<Array<Regions_Bool_Exp>>;
  _not?: InputMaybe<Regions_Bool_Exp>;
  _or?: InputMaybe<Array<Regions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  legacyId?: InputMaybe<Numeric_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "regions" */
export enum Regions_Constraint {
  /** unique or primary key constraint on columns "id" */
  RegionsPkey = 'regions_pkey'
}

/** input type for incrementing numeric columns in table "regions" */
export type Regions_Inc_Input = {
  legacyId?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "regions" */
export type Regions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  legacyId?: InputMaybe<Scalars['numeric']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Regions_Max_Fields = {
  __typename?: 'regions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  legacyId?: Maybe<Scalars['numeric']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Regions_Min_Fields = {
  __typename?: 'regions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  legacyId?: Maybe<Scalars['numeric']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "regions" */
export type Regions_Mutation_Response = {
  __typename?: 'regions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Regions>;
};

/** input type for inserting object relation for remote table "regions" */
export type Regions_Obj_Rel_Insert_Input = {
  data: Regions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Regions_On_Conflict>;
};

/** on_conflict condition type for table "regions" */
export type Regions_On_Conflict = {
  constraint: Regions_Constraint;
  update_columns?: Array<Regions_Update_Column>;
  where?: InputMaybe<Regions_Bool_Exp>;
};

/** Ordering options when selecting data from "regions". */
export type Regions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  legacyId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: regions */
export type Regions_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "regions" */
export enum Regions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LegacyId = 'legacyId',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "regions" */
export type Regions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  legacyId?: InputMaybe<Scalars['numeric']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Regions_Stddev_Fields = {
  __typename?: 'regions_stddev_fields';
  legacyId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Regions_Stddev_Pop_Fields = {
  __typename?: 'regions_stddev_pop_fields';
  legacyId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Regions_Stddev_Samp_Fields = {
  __typename?: 'regions_stddev_samp_fields';
  legacyId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "regions" */
export type Regions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Regions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Regions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  legacyId?: InputMaybe<Scalars['numeric']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Regions_Sum_Fields = {
  __typename?: 'regions_sum_fields';
  legacyId?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "regions" */
export enum Regions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LegacyId = 'legacyId',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Regions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Regions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Regions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Regions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Regions_Var_Pop_Fields = {
  __typename?: 'regions_var_pop_fields';
  legacyId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Regions_Var_Samp_Fields = {
  __typename?: 'regions_var_samp_fields';
  legacyId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Regions_Variance_Fields = {
  __typename?: 'regions_variance_fields';
  legacyId?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "roles" */
export type Roles = {
  __typename?: 'roles';
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  user_id: Scalars['String']['output'];
};

/** aggregated selection of "roles" */
export type Roles_Aggregate = {
  __typename?: 'roles_aggregate';
  aggregate?: Maybe<Roles_Aggregate_Fields>;
  nodes: Array<Roles>;
};

export type Roles_Aggregate_Bool_Exp = {
  count?: InputMaybe<Roles_Aggregate_Bool_Exp_Count>;
};

export type Roles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Roles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "roles" */
export type Roles_Aggregate_Fields = {
  __typename?: 'roles_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Roles_Max_Fields>;
  min?: Maybe<Roles_Min_Fields>;
};


/** aggregate fields of "roles" */
export type Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "roles" */
export type Roles_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Roles_Max_Order_By>;
  min?: InputMaybe<Roles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "roles" */
export type Roles_Arr_Rel_Insert_Input = {
  data: Array<Roles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "roles". All fields are combined with a logical 'AND'. */
export type Roles_Bool_Exp = {
  _and?: InputMaybe<Array<Roles_Bool_Exp>>;
  _not?: InputMaybe<Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Roles_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "roles" */
export enum Roles_Constraint {
  /** unique or primary key constraint on columns "id" */
  RolesPkey = 'roles_pkey'
}

/** input type for inserting data into table "roles" */
export type Roles_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Roles_Max_Fields = {
  __typename?: 'roles_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "roles" */
export type Roles_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Roles_Min_Fields = {
  __typename?: 'roles_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "roles" */
export type Roles_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "roles" */
export type Roles_Mutation_Response = {
  __typename?: 'roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Roles>;
};

/** on_conflict condition type for table "roles" */
export type Roles_On_Conflict = {
  constraint: Roles_Constraint;
  update_columns?: Array<Roles_Update_Column>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "roles". */
export type Roles_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: roles */
export type Roles_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "roles" */
export enum Roles_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "roles" */
export type Roles_Set_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "roles" */
export type Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Roles_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "roles" */
export enum Roles_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UserId = 'user_id'
}

export type Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Roles_Bool_Exp;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "accounts" */
  accounts: Array<Accounts>;
  /** fetch aggregated fields from the table: "accounts" */
  accounts_aggregate: Accounts_Aggregate;
  /** fetch data from the table: "accounts" using primary key columns */
  accounts_by_pk?: Maybe<Accounts>;
  /** fetch data from the table in a streaming manner: "accounts" */
  accounts_stream: Array<Accounts>;
  /** fetch data from the table: "billingAccounts" */
  billingAccounts: Array<BillingAccounts>;
  /** fetch aggregated fields from the table: "billingAccounts" */
  billingAccounts_aggregate: BillingAccounts_Aggregate;
  /** fetch data from the table: "billingAccounts" using primary key columns */
  billingAccounts_by_pk?: Maybe<BillingAccounts>;
  /** fetch data from the table in a streaming manner: "billingAccounts" */
  billingAccounts_stream: Array<BillingAccounts>;
  /** fetch data from the table: "bonusTypes" */
  bonusTypes: Array<BonusTypes>;
  /** fetch aggregated fields from the table: "bonusTypes" */
  bonusTypes_aggregate: BonusTypes_Aggregate;
  /** fetch data from the table: "bonusTypes" using primary key columns */
  bonusTypes_by_pk?: Maybe<BonusTypes>;
  /** fetch data from the table in a streaming manner: "bonusTypes" */
  bonusTypes_stream: Array<BonusTypes>;
  /** fetch data from the table: "bonuses" */
  bonuses: Array<Bonuses>;
  /** fetch aggregated fields from the table: "bonuses" */
  bonuses_aggregate: Bonuses_Aggregate;
  /** fetch data from the table: "bonuses" using primary key columns */
  bonuses_by_pk?: Maybe<Bonuses>;
  /** fetch data from the table in a streaming manner: "bonuses" */
  bonuses_stream: Array<Bonuses>;
  /** fetch data from the table: "categories" */
  categories: Array<Categories>;
  /** fetch aggregated fields from the table: "categories" */
  categories_aggregate: Categories_Aggregate;
  /** fetch data from the table: "categories" using primary key columns */
  categories_by_pk?: Maybe<Categories>;
  /** fetch data from the table in a streaming manner: "categories" */
  categories_stream: Array<Categories>;
  /** An array relationship */
  eventHosts: Array<EventHosts>;
  /** An aggregate relationship */
  eventHosts_aggregate: EventHosts_Aggregate;
  /** fetch data from the table: "eventHosts" using primary key columns */
  eventHosts_by_pk?: Maybe<EventHosts>;
  /** fetch data from the table in a streaming manner: "eventHosts" */
  eventHosts_stream: Array<EventHosts>;
  /** An array relationship */
  eventQuestions: Array<EventQuestions>;
  /** An aggregate relationship */
  eventQuestions_aggregate: EventQuestions_Aggregate;
  /** fetch data from the table: "eventQuestions" using primary key columns */
  eventQuestions_by_pk?: Maybe<EventQuestions>;
  /** fetch data from the table in a streaming manner: "eventQuestions" */
  eventQuestions_stream: Array<EventQuestions>;
  /** fetch data from the table: "eventTemplateHosts" */
  eventTemplateHosts: Array<EventTemplateHosts>;
  /** fetch aggregated fields from the table: "eventTemplateHosts" */
  eventTemplateHosts_aggregate: EventTemplateHosts_Aggregate;
  /** fetch data from the table: "eventTemplateHosts" using primary key columns */
  eventTemplateHosts_by_pk?: Maybe<EventTemplateHosts>;
  /** fetch data from the table in a streaming manner: "eventTemplateHosts" */
  eventTemplateHosts_stream: Array<EventTemplateHosts>;
  /** fetch data from the table: "eventTemplates" */
  eventTemplates: Array<EventTemplates>;
  /** fetch aggregated fields from the table: "eventTemplates" */
  eventTemplates_aggregate: EventTemplates_Aggregate;
  /** fetch data from the table: "eventTemplates" using primary key columns */
  eventTemplates_by_pk?: Maybe<EventTemplates>;
  /** fetch data from the table in a streaming manner: "eventTemplates" */
  eventTemplates_stream: Array<EventTemplates>;
  /** An array relationship */
  events: Array<Events>;
  /** An aggregate relationship */
  events_aggregate: Events_Aggregate;
  /** fetch data from the table: "events" using primary key columns */
  events_by_pk?: Maybe<Events>;
  /** fetch data from the table in a streaming manner: "events" */
  events_stream: Array<Events>;
  /** fetch data from the table: "filters" */
  filters: Array<Filters>;
  /** fetch aggregated fields from the table: "filters" */
  filters_aggregate: Filters_Aggregate;
  /** fetch data from the table: "filters" using primary key columns */
  filters_by_pk?: Maybe<Filters>;
  /** fetch data from the table in a streaming manner: "filters" */
  filters_stream: Array<Filters>;
  /** fetch data from the table: "hosts" */
  hosts: Array<Hosts>;
  /** fetch aggregated fields from the table: "hosts" */
  hosts_aggregate: Hosts_Aggregate;
  /** fetch data from the table: "hosts" using primary key columns */
  hosts_by_pk?: Maybe<Hosts>;
  /** fetch data from the table in a streaming manner: "hosts" */
  hosts_stream: Array<Hosts>;
  /** fetch data from the table: "locations" */
  locations: Array<Locations>;
  /** fetch aggregated fields from the table: "locations" */
  locations_aggregate: Locations_Aggregate;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<Locations>;
  /** fetch data from the table in a streaming manner: "locations" */
  locations_stream: Array<Locations>;
  /** fetch data from the table: "notebookQuestions" */
  notebookQuestions: Array<NotebookQuestions>;
  /** fetch aggregated fields from the table: "notebookQuestions" */
  notebookQuestions_aggregate: NotebookQuestions_Aggregate;
  /** fetch data from the table: "notebookQuestions" using primary key columns */
  notebookQuestions_by_pk?: Maybe<NotebookQuestions>;
  /** fetch data from the table in a streaming manner: "notebookQuestions" */
  notebookQuestions_stream: Array<NotebookQuestions>;
  /** fetch data from the table: "notebooks" */
  notebooks: Array<Notebooks>;
  /** fetch aggregated fields from the table: "notebooks" */
  notebooks_aggregate: Notebooks_Aggregate;
  /** fetch data from the table: "notebooks" using primary key columns */
  notebooks_by_pk?: Maybe<Notebooks>;
  /** fetch data from the table in a streaming manner: "notebooks" */
  notebooks_stream: Array<Notebooks>;
  /** fetch data from the table: "organizations" */
  organizations: Array<Organizations>;
  /** fetch aggregated fields from the table: "organizations" */
  organizations_aggregate: Organizations_Aggregate;
  /** fetch data from the table: "organizations" using primary key columns */
  organizations_by_pk?: Maybe<Organizations>;
  /** fetch data from the table in a streaming manner: "organizations" */
  organizations_stream: Array<Organizations>;
  /** An array relationship */
  questionTags: Array<QuestionTags>;
  /** An aggregate relationship */
  questionTags_aggregate: QuestionTags_Aggregate;
  /** fetch data from the table: "questionTags" using primary key columns */
  questionTags_by_pk?: Maybe<QuestionTags>;
  /** fetch data from the table in a streaming manner: "questionTags" */
  questionTags_stream: Array<QuestionTags>;
  /** fetch data from the table: "questionTypes" */
  questionTypes: Array<QuestionTypes>;
  /** fetch aggregated fields from the table: "questionTypes" */
  questionTypes_aggregate: QuestionTypes_Aggregate;
  /** fetch data from the table: "questionTypes" using primary key columns */
  questionTypes_by_pk?: Maybe<QuestionTypes>;
  /** fetch data from the table in a streaming manner: "questionTypes" */
  questionTypes_stream: Array<QuestionTypes>;
  /** An array relationship */
  questionVersions: Array<QuestionVersions>;
  /** An aggregate relationship */
  questionVersions_aggregate: QuestionVersions_Aggregate;
  /** fetch data from the table: "questionVersions" using primary key columns */
  questionVersions_by_pk?: Maybe<QuestionVersions>;
  /** fetch data from the table in a streaming manner: "questionVersions" */
  questionVersions_stream: Array<QuestionVersions>;
  /** An array relationship */
  questions: Array<Questions>;
  /** An aggregate relationship */
  questions_aggregate: Questions_Aggregate;
  /** fetch data from the table: "questions" using primary key columns */
  questions_by_pk?: Maybe<Questions>;
  /** fetch data from the table in a streaming manner: "questions" */
  questions_stream: Array<Questions>;
  /** fetch data from the table: "regions" */
  regions: Array<Regions>;
  /** fetch aggregated fields from the table: "regions" */
  regions_aggregate: Regions_Aggregate;
  /** fetch data from the table: "regions" using primary key columns */
  regions_by_pk?: Maybe<Regions>;
  /** fetch data from the table in a streaming manner: "regions" */
  regions_stream: Array<Regions>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** fetch data from the table in a streaming manner: "roles" */
  roles_stream: Array<Roles>;
  /** fetch data from the table: "tags" */
  tags: Array<Tags>;
  /** fetch aggregated fields from the table: "tags" */
  tags_aggregate: Tags_Aggregate;
  /** fetch data from the table: "tags" using primary key columns */
  tags_by_pk?: Maybe<Tags>;
  /** fetch data from the table in a streaming manner: "tags" */
  tags_stream: Array<Tags>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
};


export type Subscription_RootAccountsArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


export type Subscription_RootAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


export type Subscription_RootAccounts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAccounts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Accounts_Stream_Cursor_Input>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


export type Subscription_RootBillingAccountsArgs = {
  distinct_on?: InputMaybe<Array<BillingAccounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BillingAccounts_Order_By>>;
  where?: InputMaybe<BillingAccounts_Bool_Exp>;
};


export type Subscription_RootBillingAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BillingAccounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BillingAccounts_Order_By>>;
  where?: InputMaybe<BillingAccounts_Bool_Exp>;
};


export type Subscription_RootBillingAccounts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootBillingAccounts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BillingAccounts_Stream_Cursor_Input>>;
  where?: InputMaybe<BillingAccounts_Bool_Exp>;
};


export type Subscription_RootBonusTypesArgs = {
  distinct_on?: InputMaybe<Array<BonusTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BonusTypes_Order_By>>;
  where?: InputMaybe<BonusTypes_Bool_Exp>;
};


export type Subscription_RootBonusTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BonusTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BonusTypes_Order_By>>;
  where?: InputMaybe<BonusTypes_Bool_Exp>;
};


export type Subscription_RootBonusTypes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootBonusTypes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BonusTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<BonusTypes_Bool_Exp>;
};


export type Subscription_RootBonusesArgs = {
  distinct_on?: InputMaybe<Array<Bonuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Bonuses_Order_By>>;
  where?: InputMaybe<Bonuses_Bool_Exp>;
};


export type Subscription_RootBonuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Bonuses_Order_By>>;
  where?: InputMaybe<Bonuses_Bool_Exp>;
};


export type Subscription_RootBonuses_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootBonuses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Bonuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Bonuses_Bool_Exp>;
};


export type Subscription_RootCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Order_By>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};


export type Subscription_RootCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Order_By>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};


export type Subscription_RootCategories_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootCategories_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Categories_Stream_Cursor_Input>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};


export type Subscription_RootEventHostsArgs = {
  distinct_on?: InputMaybe<Array<EventHosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventHosts_Order_By>>;
  where?: InputMaybe<EventHosts_Bool_Exp>;
};


export type Subscription_RootEventHosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EventHosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventHosts_Order_By>>;
  where?: InputMaybe<EventHosts_Bool_Exp>;
};


export type Subscription_RootEventHosts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootEventHosts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<EventHosts_Stream_Cursor_Input>>;
  where?: InputMaybe<EventHosts_Bool_Exp>;
};


export type Subscription_RootEventQuestionsArgs = {
  distinct_on?: InputMaybe<Array<EventQuestions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventQuestions_Order_By>>;
  where?: InputMaybe<EventQuestions_Bool_Exp>;
};


export type Subscription_RootEventQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EventQuestions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventQuestions_Order_By>>;
  where?: InputMaybe<EventQuestions_Bool_Exp>;
};


export type Subscription_RootEventQuestions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootEventQuestions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<EventQuestions_Stream_Cursor_Input>>;
  where?: InputMaybe<EventQuestions_Bool_Exp>;
};


export type Subscription_RootEventTemplateHostsArgs = {
  distinct_on?: InputMaybe<Array<EventTemplateHosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventTemplateHosts_Order_By>>;
  where?: InputMaybe<EventTemplateHosts_Bool_Exp>;
};


export type Subscription_RootEventTemplateHosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EventTemplateHosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventTemplateHosts_Order_By>>;
  where?: InputMaybe<EventTemplateHosts_Bool_Exp>;
};


export type Subscription_RootEventTemplateHosts_By_PkArgs = {
  event_id: Scalars['uuid']['input'];
  host_id: Scalars['uuid']['input'];
};


export type Subscription_RootEventTemplateHosts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<EventTemplateHosts_Stream_Cursor_Input>>;
  where?: InputMaybe<EventTemplateHosts_Bool_Exp>;
};


export type Subscription_RootEventTemplatesArgs = {
  distinct_on?: InputMaybe<Array<EventTemplates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventTemplates_Order_By>>;
  where?: InputMaybe<EventTemplates_Bool_Exp>;
};


export type Subscription_RootEventTemplates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EventTemplates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventTemplates_Order_By>>;
  where?: InputMaybe<EventTemplates_Bool_Exp>;
};


export type Subscription_RootEventTemplates_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootEventTemplates_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<EventTemplates_Stream_Cursor_Input>>;
  where?: InputMaybe<EventTemplates_Bool_Exp>;
};


export type Subscription_RootEventsArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


export type Subscription_RootEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


export type Subscription_RootEvents_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootEvents_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Events_Stream_Cursor_Input>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


export type Subscription_RootFiltersArgs = {
  distinct_on?: InputMaybe<Array<Filters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Filters_Order_By>>;
  where?: InputMaybe<Filters_Bool_Exp>;
};


export type Subscription_RootFilters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Filters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Filters_Order_By>>;
  where?: InputMaybe<Filters_Bool_Exp>;
};


export type Subscription_RootFilters_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootFilters_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Filters_Stream_Cursor_Input>>;
  where?: InputMaybe<Filters_Bool_Exp>;
};


export type Subscription_RootHostsArgs = {
  distinct_on?: InputMaybe<Array<Hosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Hosts_Order_By>>;
  where?: InputMaybe<Hosts_Bool_Exp>;
};


export type Subscription_RootHosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Hosts_Order_By>>;
  where?: InputMaybe<Hosts_Bool_Exp>;
};


export type Subscription_RootHosts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootHosts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Hosts_Stream_Cursor_Input>>;
  where?: InputMaybe<Hosts_Bool_Exp>;
};


export type Subscription_RootLocationsArgs = {
  distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Locations_Order_By>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};


export type Subscription_RootLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Locations_Order_By>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};


export type Subscription_RootLocations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootLocations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Locations_Stream_Cursor_Input>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};


export type Subscription_RootNotebookQuestionsArgs = {
  distinct_on?: InputMaybe<Array<NotebookQuestions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NotebookQuestions_Order_By>>;
  where?: InputMaybe<NotebookQuestions_Bool_Exp>;
};


export type Subscription_RootNotebookQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<NotebookQuestions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NotebookQuestions_Order_By>>;
  where?: InputMaybe<NotebookQuestions_Bool_Exp>;
};


export type Subscription_RootNotebookQuestions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootNotebookQuestions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<NotebookQuestions_Stream_Cursor_Input>>;
  where?: InputMaybe<NotebookQuestions_Bool_Exp>;
};


export type Subscription_RootNotebooksArgs = {
  distinct_on?: InputMaybe<Array<Notebooks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notebooks_Order_By>>;
  where?: InputMaybe<Notebooks_Bool_Exp>;
};


export type Subscription_RootNotebooks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notebooks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notebooks_Order_By>>;
  where?: InputMaybe<Notebooks_Bool_Exp>;
};


export type Subscription_RootNotebooks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootNotebooks_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Notebooks_Stream_Cursor_Input>>;
  where?: InputMaybe<Notebooks_Bool_Exp>;
};


export type Subscription_RootOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


export type Subscription_RootOrganizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


export type Subscription_RootOrganizations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootOrganizations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Organizations_Stream_Cursor_Input>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


export type Subscription_RootQuestionTagsArgs = {
  distinct_on?: InputMaybe<Array<QuestionTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionTags_Order_By>>;
  where?: InputMaybe<QuestionTags_Bool_Exp>;
};


export type Subscription_RootQuestionTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionTags_Order_By>>;
  where?: InputMaybe<QuestionTags_Bool_Exp>;
};


export type Subscription_RootQuestionTags_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootQuestionTags_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<QuestionTags_Stream_Cursor_Input>>;
  where?: InputMaybe<QuestionTags_Bool_Exp>;
};


export type Subscription_RootQuestionTypesArgs = {
  distinct_on?: InputMaybe<Array<QuestionTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionTypes_Order_By>>;
  where?: InputMaybe<QuestionTypes_Bool_Exp>;
};


export type Subscription_RootQuestionTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionTypes_Order_By>>;
  where?: InputMaybe<QuestionTypes_Bool_Exp>;
};


export type Subscription_RootQuestionTypes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootQuestionTypes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<QuestionTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<QuestionTypes_Bool_Exp>;
};


export type Subscription_RootQuestionVersionsArgs = {
  distinct_on?: InputMaybe<Array<QuestionVersions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionVersions_Order_By>>;
  where?: InputMaybe<QuestionVersions_Bool_Exp>;
};


export type Subscription_RootQuestionVersions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionVersions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionVersions_Order_By>>;
  where?: InputMaybe<QuestionVersions_Bool_Exp>;
};


export type Subscription_RootQuestionVersions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootQuestionVersions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<QuestionVersions_Stream_Cursor_Input>>;
  where?: InputMaybe<QuestionVersions_Bool_Exp>;
};


export type Subscription_RootQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


export type Subscription_RootQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


export type Subscription_RootQuestions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootQuestions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Questions_Stream_Cursor_Input>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


export type Subscription_RootRegionsArgs = {
  distinct_on?: InputMaybe<Array<Regions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Regions_Order_By>>;
  where?: InputMaybe<Regions_Bool_Exp>;
};


export type Subscription_RootRegions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Regions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Regions_Order_By>>;
  where?: InputMaybe<Regions_Bool_Exp>;
};


export type Subscription_RootRegions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootRegions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Regions_Stream_Cursor_Input>>;
  where?: InputMaybe<Regions_Bool_Exp>;
};


export type Subscription_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootRoles_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootRoles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootTagsArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Subscription_RootTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Subscription_RootTags_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootTags_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Tags for Questions */
export type Tags = {
  __typename?: 'tags';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "tags" */
export type Tags_Aggregate = {
  __typename?: 'tags_aggregate';
  aggregate?: Maybe<Tags_Aggregate_Fields>;
  nodes: Array<Tags>;
};

/** aggregate fields of "tags" */
export type Tags_Aggregate_Fields = {
  __typename?: 'tags_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tags_Max_Fields>;
  min?: Maybe<Tags_Min_Fields>;
};


/** aggregate fields of "tags" */
export type Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "tags". All fields are combined with a logical 'AND'. */
export type Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Tags_Bool_Exp>>;
  _not?: InputMaybe<Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Tags_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tags" */
export enum Tags_Constraint {
  /** unique or primary key constraint on columns "name" */
  TagsNameKey = 'tags_name_key',
  /** unique or primary key constraint on columns "id" */
  TagsPkey = 'tags_pkey'
}

/** input type for inserting data into table "tags" */
export type Tags_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Tags_Max_Fields = {
  __typename?: 'tags_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Tags_Min_Fields = {
  __typename?: 'tags_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "tags" */
export type Tags_Mutation_Response = {
  __typename?: 'tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tags>;
};

/** input type for inserting object relation for remote table "tags" */
export type Tags_Obj_Rel_Insert_Input = {
  data: Tags_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};

/** on_conflict condition type for table "tags" */
export type Tags_On_Conflict = {
  constraint: Tags_Constraint;
  update_columns?: Array<Tags_Update_Column>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "tags". */
export type Tags_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tags */
export type Tags_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "tags" */
export enum Tags_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "tags" */
export type Tags_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "tags" */
export type Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "tags" */
export enum Tags_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Tags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tags_Bool_Exp;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email: Scalars['String']['output'];
  first_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  organization?: Maybe<Organizations>;
  settings?: Maybe<Scalars['jsonb']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  users_roles: Array<Roles>;
  /** An aggregate relationship */
  users_roles_aggregate: Roles_Aggregate;
};


/** columns and relationships of "users" */
export type UsersSettingsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "users" */
export type UsersUsers_RolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUsers_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

export type Users_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Users_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Users_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Users_Aggregate_Bool_Exp_Count>;
};

export type Users_Aggregate_Bool_Exp_Bool_And = {
  arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Max_Order_By>;
  min?: InputMaybe<Users_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Append_Input = {
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  isActive?: InputMaybe<Boolean_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  settings?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  users_roles?: InputMaybe<Roles_Bool_Exp>;
  users_roles_aggregate?: InputMaybe<Roles_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Delete_At_Path_Input = {
  settings?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Delete_Elem_Input = {
  settings?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Delete_Key_Input = {
  settings?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  users_roles?: InputMaybe<Roles_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isActive?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  settings?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  users_roles_aggregate?: InputMaybe<Roles_Aggregate_Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Prepend_Input = {
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  LastName = 'last_name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Settings = 'settings',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "users_aggregate_bool_exp_bool_and_arguments_columns" columns of table "users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'isActive'
}

/** select "users_aggregate_bool_exp_bool_or_arguments_columns" columns of table "users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'isActive'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  LastName = 'last_name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Settings = 'settings',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Users_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Users_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Users_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

export type GetUniqueRolesQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUniqueRolesQueryQuery = { __typename?: 'query_root', roles: Array<{ __typename?: 'roles', name: string }> };

export type GetUserRolesQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetUserRolesQueryQuery = { __typename?: 'query_root', users_by_pk?: { __typename?: 'users', id: string, users_roles: Array<{ __typename?: 'roles', id: string, name: string }> } | null };

export type SearchUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchUsersQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', id: string, first_name?: string | null, last_name?: string | null, email: string }> };

export type RemoveRoleForUserMutationMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type RemoveRoleForUserMutationMutation = { __typename?: 'mutation_root', delete_roles_by_pk?: { __typename?: 'roles', id: string, name: string } | null };

export type InsertRoleForUserMutationMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  name: Scalars['String']['input'];
}>;


export type InsertRoleForUserMutationMutation = { __typename?: 'mutation_root', insert_roles_one?: { __typename?: 'roles', id: string, name: string } | null };

export type SearchCategoriesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Categories_Bool_Exp>;
}>;


export type SearchCategoriesQuery = { __typename?: 'query_root', categories: Array<{ __typename?: 'categories', id: string, name: string, questions_aggregate: { __typename?: 'questions_aggregate', aggregate?: { __typename?: 'questions_aggregate_fields', count: number } | null } }>, categories_aggregate: { __typename?: 'categories_aggregate', aggregate?: { __typename?: 'categories_aggregate_fields', count: number } | null } };

export type GetCategoryByIdQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type GetCategoryByIdQuery = { __typename?: 'query_root', categories_by_pk?: { __typename?: 'categories', id: string, name: string } | null };

export type Insert_CategoryMutationVariables = Exact<{
  object: Categories_Insert_Input;
}>;


export type Insert_CategoryMutation = { __typename?: 'mutation_root', insert_categories_one?: { __typename?: 'categories', id: string, name: string } | null };

export type Update_Categories_By_PkMutationVariables = Exact<{
  pk_columns: Categories_Pk_Columns_Input;
  _set: Categories_Set_Input;
}>;


export type Update_Categories_By_PkMutation = { __typename?: 'mutation_root', update_categories_by_pk?: { __typename?: 'categories', name: string, id: string } | null };

export type Delete_Categories_By_PkMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type Delete_Categories_By_PkMutation = { __typename?: 'mutation_root', delete_categories_by_pk?: { __typename?: 'categories', id: string, name: string } | null };

export type Merge_Category_MutationMutationVariables = Exact<{
  fromCategory: Scalars['uuid']['input'];
  toCategory: Scalars['uuid']['input'];
  isDeleteFromCategory?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type Merge_Category_MutationMutation = { __typename?: 'mutation_root', mergeCategory?: { __typename?: 'MergeCategoriesOutput', affectedQuestionCount: string } | null };

export type GetEventHostsQueryVariables = Exact<{
  where?: InputMaybe<EventHosts_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetEventHostsQuery = { __typename?: 'query_root', eventHosts: Array<{ __typename?: 'eventHosts', id: string, type: string, notes?: string | null, pay?: number | string | null, event_id: string, user_id: string, host_id: string, host: { __typename?: 'hosts', id: string, nickname?: string | null, googleAccountEmail?: string | null, rates: unknown } }> };

export type AddEventHostMutationVariables = Exact<{
  object: EventHosts_Insert_Input;
}>;


export type AddEventHostMutation = { __typename?: 'mutation_root', insert_eventHosts_one?: { __typename?: 'eventHosts', id: string } | null };

export type UpdateEventHostMutationVariables = Exact<{
  pk_columns: EventHosts_Pk_Columns_Input;
  _set: EventHosts_Set_Input;
}>;


export type UpdateEventHostMutation = { __typename?: 'mutation_root', update_eventHosts_by_pk?: { __typename?: 'eventHosts', id: string } | null };

export type RemoveEventHostMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type RemoveEventHostMutation = { __typename?: 'mutation_root', delete_eventHosts_by_pk?: { __typename?: 'eventHosts', id: string } | null };

export type GetEventsQueryVariables = Exact<{
  where?: InputMaybe<Events_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetEventsQuery = { __typename?: 'query_root', events: Array<{ __typename?: 'events', id: string, account_id?: string | null, eventDateTime: Date, location_id?: string | null, name: string, org_id?: string | null, status: string, cost: number | string, region_id?: string | null, eventQuestions_aggregate: { __typename?: 'eventQuestions_aggregate', aggregate?: { __typename?: 'eventQuestions_aggregate_fields', count: number } | null }, location?: { __typename?: 'locations', id: string, city: string, name: string, state: string } | null, region?: { __typename?: 'regions', id: string, name: string } | null }>, events_aggregate: { __typename?: 'events_aggregate', aggregate?: { __typename?: 'events_aggregate_fields', count: number } | null } };

export type GetEventByIdQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type GetEventByIdQuery = { __typename?: 'query_root', events_by_pk?: { __typename?: 'events', eventDateTime: Date, id: string, name: string, status: string, region_id?: string | null, cost: number | string, account?: { __typename?: 'accounts', name: string, id: string } | null, eventQuestions: Array<{ __typename?: 'eventQuestions', sortOrder: number, question?: { __typename?: 'questions', id: string, question: string, answer?: string | null, category?: { __typename?: 'categories', name: string, id: string } | null } | null }>, location?: { __typename?: 'locations', id: string, name: string } | null, region?: { __typename?: 'regions', id: string, name: string } | null } | null };

export type GetEventQuestionsQueryVariables = Exact<{
  where?: InputMaybe<EventQuestions_Bool_Exp>;
}>;


export type GetEventQuestionsQuery = { __typename?: 'query_root', eventQuestions: Array<{ __typename?: 'eventQuestions', created_at: Date, event_id: string, id: string, notes: string, org_id: string, question_id?: string | null, updated_at: Date, sortOrder: number, event: { __typename?: 'events', id: string, name: string, eventDateTime: Date, cost: number | string, status: string }, question?: { __typename?: 'questions', id: string, question: string, category?: { __typename?: 'categories', name: string } | null } | null }> };

export type ListFiltersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ListFiltersQuery = { __typename?: 'query_root', filters: Array<{ __typename?: 'filters', created_at: Date, filter: unknown, gql: string, id: string, name: string, org_id: string, owner_id: string, updated_at: Date }> };

export type GetFilterQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type GetFilterQuery = { __typename?: 'query_root', filters_by_pk?: { __typename?: 'filters', created_at: Date, filter: unknown, gql: string, id: string, name: string, org_id: string, owner_id: string, updated_at: Date } | null };

export type CreateFilterMutationMutationVariables = Exact<{
  object: Filters_Insert_Input;
}>;


export type CreateFilterMutationMutation = { __typename?: 'mutation_root', insert_filters_one?: { __typename?: 'filters', id: string } | null };

export type UpdateFilterMutationMutationVariables = Exact<{
  pk_columns: Filters_Pk_Columns_Input;
  _set: Filters_Set_Input;
}>;


export type UpdateFilterMutationMutation = { __typename?: 'mutation_root', update_filters_by_pk?: { __typename?: 'filters', created_at: Date, filter: unknown, gql: string, id: string, name: string, org_id: string, owner_id: string, updated_at: Date } | null };

export type RemoveFilterMutationMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type RemoveFilterMutationMutation = { __typename?: 'mutation_root', delete_filters_by_pk?: { __typename?: 'filters', id: string } | null };

export type SearchHostsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Hosts_Bool_Exp>;
}>;


export type SearchHostsQuery = { __typename?: 'query_root', hosts: Array<{ __typename?: 'hosts', id: string, nickname?: string | null, googleAccountEmail?: string | null, rates: unknown, isActive: boolean, dateStarted?: any | null, dateEnded?: any | null, user_id: string, eventHosts_aggregate: { __typename?: 'eventHosts_aggregate', aggregate?: { __typename?: 'eventHosts_aggregate_fields', count: number } | null } }>, hosts_aggregate: { __typename?: 'hosts_aggregate', aggregate?: { __typename?: 'hosts_aggregate_fields', count: number } | null } };

export type GetHostByIdQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type GetHostByIdQuery = { __typename?: 'query_root', hosts_by_pk?: { __typename?: 'hosts', id: string, nickname?: string | null, googleAccountEmail?: string | null, user_id: string, rates: unknown, eventHosts_aggregate: { __typename?: 'eventHosts_aggregate', aggregate?: { __typename?: 'eventHosts_aggregate_fields', count: number } | null } } | null };

export type Insert_HostMutationVariables = Exact<{
  object: Hosts_Insert_Input;
}>;


export type Insert_HostMutation = { __typename?: 'mutation_root', insert_hosts_one?: { __typename?: 'hosts', id: string, nickname?: string | null, googleAccountEmail?: string | null, user_id: string, rates: unknown } | null };

export type Update_Hosts_By_PkMutationVariables = Exact<{
  pk_columns: Hosts_Pk_Columns_Input;
  _set: Hosts_Set_Input;
}>;


export type Update_Hosts_By_PkMutation = { __typename?: 'mutation_root', update_hosts_by_pk?: { __typename?: 'hosts', id: string, nickname?: string | null, googleAccountEmail?: string | null, user_id: string, rates: unknown } | null };

export type Delete_Hosts_By_PkMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type Delete_Hosts_By_PkMutation = { __typename?: 'mutation_root', delete_hosts_by_pk?: { __typename?: 'hosts', id: string, nickname?: string | null, googleAccountEmail?: string | null, rates: unknown } | null };

export type GetLocationsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Locations_Bool_Exp>;
}>;


export type GetLocationsQuery = { __typename?: 'query_root', locations: Array<{ __typename?: 'locations', id: string, name: string, address?: string | null, city: string, state: string, zip: string, region_id: string, region: { __typename?: 'regions', id: string, name: string } }>, locations_aggregate: { __typename?: 'locations_aggregate', aggregate?: { __typename?: 'locations_aggregate_fields', count: number } | null } };

export type GetLocationByIdQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type GetLocationByIdQuery = { __typename?: 'query_root', locations_by_pk?: { __typename?: 'locations', id: string, name: string, region_id: string, region: { __typename?: 'regions', id: string, name: string } } | null };

export type Insert_QuestionMutationVariables = Exact<{
  object: Questions_Insert_Input;
}>;


export type Insert_QuestionMutation = { __typename?: 'mutation_root', insert_questions_one?: { __typename?: 'questions', id: string, question: string, answer?: string | null } | null };

export type Insert_LocationMutationVariables = Exact<{
  object: Locations_Insert_Input;
}>;


export type Insert_LocationMutation = { __typename?: 'mutation_root', insert_locations_one?: { __typename?: 'locations', id: string, name: string } | null };

export type Update_Questions_By_PkMutationVariables = Exact<{
  pk_columns: Questions_Pk_Columns_Input;
  _set: Questions_Set_Input;
}>;


export type Update_Questions_By_PkMutation = { __typename?: 'mutation_root', update_questions_by_pk?: { __typename?: 'questions', question: string, answer?: string | null, org_id?: string | null, category_id?: string | null, created_at?: Date | null, id: string, isTimeSensitive: boolean, updated_at?: Date | null, type_id: string, status: string, category?: { __typename?: 'categories', name: string, id: string } | null, questionType: { __typename?: 'questionTypes', name: string, id: string }, questionTags: Array<{ __typename?: 'questionTags', id: string, tag: { __typename?: 'tags', id: string, name: string } }> } | null };

export type Insert_Notebooks_OneMutationVariables = Exact<{
  object: Notebooks_Insert_Input;
}>;


export type Insert_Notebooks_OneMutation = { __typename?: 'mutation_root', insert_notebooks_one?: { __typename?: 'notebooks', id: string, owner_id?: string | null, name: string, created_at: Date, updated_at: Date, notebooks_notebookQuestions: Array<{ __typename?: 'notebookQuestions', id: string, question_id: string, notebookQuestions_question: { __typename?: 'questions', id: string, question: string, answer?: string | null, created_at?: Date | null, updated_at?: Date | null, category_id?: string | null, category?: { __typename?: 'categories', id: string, name: string } | null } }> } | null };

export type Update_Notebooks_By_PkMutationVariables = Exact<{
  pk_columns: Notebooks_Pk_Columns_Input;
  _set: Notebooks_Set_Input;
}>;


export type Update_Notebooks_By_PkMutation = { __typename?: 'mutation_root', update_notebooks_by_pk?: { __typename?: 'notebooks', id: string, owner_id?: string | null, name: string, isArchived: boolean, created_at: Date, updated_at: Date, isStarred: boolean, notebooks_notebookQuestions: Array<{ __typename?: 'notebookQuestions', id: string, question_id: string, notebookQuestions_question: { __typename?: 'questions', id: string, question: string, answer?: string | null, created_at?: Date | null, updated_at?: Date | null, category_id?: string | null, category?: { __typename?: 'categories', id: string, name: string } | null } }> } | null };

export type Update_NotebookQuestions_ManyMutationVariables = Exact<{
  updates: Array<NotebookQuestions_Updates> | NotebookQuestions_Updates;
}>;


export type Update_NotebookQuestions_ManyMutation = { __typename?: 'mutation_root', update_notebookQuestions_many?: Array<{ __typename?: 'notebookQuestions_mutation_response', affected_rows: number } | null> | null };

export type Insert_NotebookQuestions_OneMutationVariables = Exact<{
  object: NotebookQuestions_Insert_Input;
}>;


export type Insert_NotebookQuestions_OneMutation = { __typename?: 'mutation_root', insert_notebookQuestions_one?: { __typename?: 'notebookQuestions', id: string, question_id: string, sortOrder: number, notebookQuestions_question: { __typename?: 'questions', answer?: string | null, question: string, id: string, created_at?: Date | null, updated_at?: Date | null, category?: { __typename?: 'categories', name: string, id: string } | null } } | null };

export type Delete_NotebookQuestions_By_PkMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type Delete_NotebookQuestions_By_PkMutation = { __typename?: 'mutation_root', delete_notebookQuestions_by_pk?: { __typename?: 'notebookQuestions', id: string } | null };

export type Update_Locations_By_PkMutationVariables = Exact<{
  pk_columns: Locations_Pk_Columns_Input;
  _set: Locations_Set_Input;
}>;


export type Update_Locations_By_PkMutation = { __typename?: 'mutation_root', update_locations_by_pk?: { __typename?: 'locations', name: string, address?: string | null, city: string, state: string, zip: string, id: string } | null };

export type Delete_Locations_By_PkMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type Delete_Locations_By_PkMutation = { __typename?: 'mutation_root', delete_locations_by_pk?: { __typename?: 'locations', id: string, name: string } | null };

export type Insert_Events_OneMutationVariables = Exact<{
  object: Events_Insert_Input;
}>;


export type Insert_Events_OneMutation = { __typename?: 'mutation_root', insert_events_one?: { __typename?: 'events', id: string, name: string, org_id?: string | null, eventDateTime: Date, location_id?: string | null, account?: { __typename?: 'accounts', id: string, name: string, region_id: string } | null, location?: { __typename?: 'locations', city: string, org_id?: string | null, state: string } | null } | null };

export type Update_Events_By_PkMutationVariables = Exact<{
  pk_columns: Events_Pk_Columns_Input;
  _set: Events_Set_Input;
}>;


export type Update_Events_By_PkMutation = { __typename?: 'mutation_root', update_events_by_pk?: { __typename?: 'events', id: string, account_id?: string | null, eventDateTime: Date, location_id?: string | null, name: string, org_id?: string | null, status: string, eventQuestions_aggregate: { __typename?: 'eventQuestions_aggregate', aggregate?: { __typename?: 'eventQuestions_aggregate_fields', count: number } | null }, location?: { __typename?: 'locations', id: string, city: string, name: string, state: string } | null } | null };

export type Delete_Events_By_PkMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type Delete_Events_By_PkMutation = { __typename?: 'mutation_root', delete_events_by_pk?: { __typename?: 'events', id: string, name: string, org_id?: string | null, eventDateTime: Date } | null };

export type Insert_AccountMutationVariables = Exact<{
  object: Accounts_Insert_Input;
}>;


export type Insert_AccountMutation = { __typename?: 'mutation_root', insert_accounts_one?: { __typename?: 'accounts', id: string, name: string } | null };

export type Update_Accounts_By_PkMutationVariables = Exact<{
  pk_columns: Accounts_Pk_Columns_Input;
  _set: Accounts_Set_Input;
}>;


export type Update_Accounts_By_PkMutation = { __typename?: 'mutation_root', update_accounts_by_pk?: { __typename?: 'accounts', name: string, id: string } | null };

export type Delete_Accounts_By_PkMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type Delete_Accounts_By_PkMutation = { __typename?: 'mutation_root', delete_accounts_by_pk?: { __typename?: 'accounts', id: string, name: string } | null };

export type Delete_EventQuestions_By_PkMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type Delete_EventQuestions_By_PkMutation = { __typename?: 'mutation_root', delete_eventQuestions_by_pk?: { __typename?: 'eventQuestions', id: string } | null };

export type DeleteEventQuestionsByQIdMutationVariables = Exact<{
  where: EventQuestions_Bool_Exp;
}>;


export type DeleteEventQuestionsByQIdMutation = { __typename?: 'mutation_root', delete_eventQuestions?: { __typename?: 'eventQuestions_mutation_response', affected_rows: number } | null };

export type MyMutationMutationVariables = Exact<{
  objects: Array<EventQuestions_Insert_Input> | EventQuestions_Insert_Input;
}>;


export type MyMutationMutation = { __typename?: 'mutation_root', insert_eventQuestions?: { __typename?: 'eventQuestions_mutation_response', affected_rows: number } | null };

export type UpdateUserMutationVariables = Exact<{
  pk_columns: Users_Pk_Columns_Input;
  _set?: InputMaybe<Users_Set_Input>;
}>;


export type UpdateUserMutation = { __typename?: 'mutation_root', update_users_by_pk?: { __typename?: 'users', id: string, email: string, settings?: unknown | null } | null };

export type GetNotebooksByIdQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type GetNotebooksByIdQuery = { __typename?: 'query_root', notebooks_by_pk?: { __typename?: 'notebooks', id: string, owner_id?: string | null, name: string, isArchived: boolean, isStarred: boolean, notebooks_notebookQuestions: Array<{ __typename?: 'notebookQuestions', id: string, question_id: string, sortOrder: number, notebookQuestions_question: { __typename?: 'questions', id: string, question: string, answer?: string | null, created_at?: Date | null, updated_at?: Date | null, category_id?: string | null, category?: { __typename?: 'categories', id: string, name: string } | null } }> } | null };

export type SubscribeNotebooksSubscriptionVariables = Exact<{
  where?: InputMaybe<Notebooks_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notebooks_Order_By> | Notebooks_Order_By>;
}>;


export type SubscribeNotebooksSubscription = { __typename?: 'subscription_root', notebooks: Array<{ __typename?: 'notebooks', id: string, owner_id?: string | null, name: string, isArchived: boolean, updated_at: Date, isStarred: boolean, notebooks_notebookQuestions: Array<{ __typename?: 'notebookQuestions', id: string, question_id: string, sortOrder: number, notebookQuestions_question: { __typename?: 'questions', id: string, question: string, answer?: string | null, created_at?: Date | null, updated_at?: Date | null, category_id?: string | null, category?: { __typename?: 'categories', id: string, name: string } | null } }> }> };

export type SubscribeNotebookByPkSubscriptionVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type SubscribeNotebookByPkSubscription = { __typename?: 'subscription_root', notebooks_by_pk?: { __typename?: 'notebooks', id: string, owner_id?: string | null, name: string, isArchived: boolean, isStarred: boolean, notebooks_notebookQuestions: Array<{ __typename?: 'notebookQuestions', id: string, question_id: string, sortOrder: number, notebookQuestions_question: { __typename?: 'questions', id: string, question: string, answer?: string | null, created_at?: Date | null, updated_at?: Date | null, category_id?: string | null, category?: { __typename?: 'categories', id: string, name: string } | null } }> } | null };

export type GetUserQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetUserQuery = { __typename?: 'query_root', users_by_pk?: { __typename?: 'users', email: string, id: string, settings?: unknown | null } | null };

export type GetAccountLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccountLocationsQuery = { __typename?: 'query_root', locations: Array<{ __typename?: 'locations', id: string, name: string, address?: string | null, city: string, state: string, zip: string }> };

export type GetAccountLocationByIdQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type GetAccountLocationByIdQuery = { __typename?: 'query_root', locations_by_pk?: { __typename?: 'locations', address?: string | null, city: string, name: string, state: string, updated_at: Date, zip: string, id: string } | null };

export type GetAccountsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Accounts_Bool_Exp>;
}>;


export type GetAccountsQuery = { __typename?: 'query_root', accounts: Array<{ __typename?: 'accounts', id: string, name: string, region_id: string, region: { __typename?: 'regions', created_at: Date, id: string, name: string, org_id: string, updated_at: Date } }>, accounts_aggregate: { __typename?: 'accounts_aggregate', aggregate?: { __typename?: 'accounts_aggregate_fields', count: number } | null } };

export type GetRegionsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Regions_Bool_Exp>;
}>;


export type GetRegionsQuery = { __typename?: 'query_root', regions: Array<{ __typename?: 'regions', id: string, name: string }>, regions_aggregate: { __typename?: 'regions_aggregate', aggregate?: { __typename?: 'regions_aggregate_fields', count: number } | null } };

export type GetAccountQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type GetAccountQuery = { __typename?: 'query_root', accounts_by_pk?: { __typename?: 'accounts', id: string, name: string, region_id: string, region: { __typename?: 'regions', created_at: Date, id: string, name: string, org_id: string, updated_at: Date } } | null };

export type GetQuestionsQueryVariables = Exact<{
  where?: InputMaybe<Questions_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetQuestionsQuery = { __typename?: 'query_root', questions: Array<{ __typename?: 'questions', id: string, question: string, isTimeSensitive: boolean, updated_at?: Date | null, answer?: string | null, org_id?: string | null, created_at?: Date | null, category_id?: string | null, type_id: string, status: string, category?: { __typename?: 'categories', name: string, id: string } | null, questionType: { __typename?: 'questionTypes', name: string, id: string }, questionTags: Array<{ __typename?: 'questionTags', id: string, tag: { __typename?: 'tags', id: string, name: string } }>, eventQuestions_aggregate: { __typename?: 'eventQuestions_aggregate', aggregate?: { __typename?: 'eventQuestions_aggregate_fields', count: number } | null } }>, questions_aggregate: { __typename?: 'questions_aggregate', aggregate?: { __typename?: 'questions_aggregate_fields', count: number } | null } };

export type GetCategoriesQueryVariables = Exact<{
  where?: InputMaybe<Categories_Bool_Exp>;
}>;


export type GetCategoriesQuery = { __typename?: 'query_root', categories: Array<{ __typename?: 'categories', id: string, name: string }> };

export type GetQuestionTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetQuestionTypesQuery = { __typename?: 'query_root', questionTypes: Array<{ __typename?: 'questionTypes', id: string, name: string }> };

export type GetNotebooksQueryVariables = Exact<{
  where?: InputMaybe<Notebooks_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notebooks_Order_By> | Notebooks_Order_By>;
}>;


export type GetNotebooksQuery = { __typename?: 'query_root', notebooks: Array<{ __typename?: 'notebooks', id: string, owner_id?: string | null, name: string, isArchived: boolean, updated_at: Date, isStarred: boolean, notebooks_notebookQuestions: Array<{ __typename?: 'notebookQuestions', id: string, question_id: string, sortOrder: number, notebookQuestions_question: { __typename?: 'questions', id: string, question: string, answer?: string | null, created_at?: Date | null, updated_at?: Date | null, category_id?: string | null, category?: { __typename?: 'categories', id: string, name: string } | null } }> }> };

export type GetNotebooksAggregateQueryVariables = Exact<{
  where?: InputMaybe<Notebooks_Bool_Exp>;
}>;


export type GetNotebooksAggregateQuery = { __typename?: 'query_root', notebooks_aggregate: { __typename?: 'notebooks_aggregate', aggregate?: { __typename?: 'notebooks_aggregate_fields', count: number } | null } };

export type InsertQuestionTagsMutationMutationVariables = Exact<{
  objects: Array<QuestionTags_Insert_Input> | QuestionTags_Insert_Input;
}>;


export type InsertQuestionTagsMutationMutation = { __typename?: 'mutation_root', insert_questionTags?: { __typename?: 'questionTags_mutation_response', affected_rows: number } | null };

export type DeleteQuestionTagsMutationMutationVariables = Exact<{
  where: QuestionTags_Bool_Exp;
}>;


export type DeleteQuestionTagsMutationMutation = { __typename?: 'mutation_root', delete_questionTags?: { __typename?: 'questionTags_mutation_response', affected_rows: number } | null };

export type GetLatestQuestionsSubscriptionVariables = Exact<{
  where?: InputMaybe<Questions_Bool_Exp>;
}>;


export type GetLatestQuestionsSubscription = { __typename?: 'subscription_root', questions: Array<{ __typename?: 'questions', id: string, question: string, isTimeSensitive: boolean, updated_at?: Date | null, answer?: string | null, org_id?: string | null, created_at?: Date | null, category_id?: string | null, type_id: string, status: string, category?: { __typename?: 'categories', name: string, id: string } | null, questionType: { __typename?: 'questionTypes', name: string, id: string }, questionTags: Array<{ __typename?: 'questionTags', id: string, tag: { __typename?: 'tags', id: string, name: string } }>, eventQuestions_aggregate: { __typename?: 'eventQuestions_aggregate', aggregate?: { __typename?: 'eventQuestions_aggregate_fields', count: number } | null } }> };

export type SearchTagsQueryVariables = Exact<{
  where?: InputMaybe<Tags_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SearchTagsQuery = { __typename?: 'query_root', tags: Array<{ __typename?: 'tags', id: string, name: string }> };

export type SuggestTagsQueryVariables = Exact<{
  question: Scalars['String']['input'];
}>;


export type SuggestTagsQuery = { __typename?: 'query_root', suggestQuestionTags?: { __typename?: 'CategoryResponse', tags?: Array<string | null> | null } | null };

export type InsertTagsMutationMutationVariables = Exact<{
  objects: Array<Tags_Insert_Input> | Tags_Insert_Input;
}>;


export type InsertTagsMutationMutation = { __typename?: 'mutation_root', insert_tags?: { __typename?: 'tags_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'tags', id: string, name: string }> } | null };


export const GetUniqueRolesQueryDocument = gql`
    query GetUniqueRolesQuery {
  roles(distinct_on: name) {
    name
  }
}
    `;

/**
 * __useGetUniqueRolesQueryQuery__
 *
 * To run a query within a React component, call `useGetUniqueRolesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUniqueRolesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUniqueRolesQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUniqueRolesQueryQuery(baseOptions?: Apollo.QueryHookOptions<GetUniqueRolesQueryQuery, GetUniqueRolesQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUniqueRolesQueryQuery, GetUniqueRolesQueryQueryVariables>(GetUniqueRolesQueryDocument, options);
      }
export function useGetUniqueRolesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUniqueRolesQueryQuery, GetUniqueRolesQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUniqueRolesQueryQuery, GetUniqueRolesQueryQueryVariables>(GetUniqueRolesQueryDocument, options);
        }
export type GetUniqueRolesQueryQueryHookResult = ReturnType<typeof useGetUniqueRolesQueryQuery>;
export type GetUniqueRolesQueryLazyQueryHookResult = ReturnType<typeof useGetUniqueRolesQueryLazyQuery>;
export type GetUniqueRolesQueryQueryResult = Apollo.QueryResult<GetUniqueRolesQueryQuery, GetUniqueRolesQueryQueryVariables>;
export const GetUserRolesQueryDocument = gql`
    query GetUserRolesQuery($id: String!) {
  users_by_pk(id: $id) {
    id
    users_roles {
      id
      name
    }
  }
}
    `;

/**
 * __useGetUserRolesQueryQuery__
 *
 * To run a query within a React component, call `useGetUserRolesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRolesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRolesQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserRolesQueryQuery(baseOptions: Apollo.QueryHookOptions<GetUserRolesQueryQuery, GetUserRolesQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserRolesQueryQuery, GetUserRolesQueryQueryVariables>(GetUserRolesQueryDocument, options);
      }
export function useGetUserRolesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserRolesQueryQuery, GetUserRolesQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserRolesQueryQuery, GetUserRolesQueryQueryVariables>(GetUserRolesQueryDocument, options);
        }
export type GetUserRolesQueryQueryHookResult = ReturnType<typeof useGetUserRolesQueryQuery>;
export type GetUserRolesQueryLazyQueryHookResult = ReturnType<typeof useGetUserRolesQueryLazyQuery>;
export type GetUserRolesQueryQueryResult = Apollo.QueryResult<GetUserRolesQueryQuery, GetUserRolesQueryQueryVariables>;
export const SearchUsersDocument = gql`
    query SearchUsers {
  users {
    id
    first_name
    last_name
    email
  }
}
    `;

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchUsersQuery(baseOptions?: Apollo.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
      }
export function useSearchUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
        }
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersQueryResult = Apollo.QueryResult<SearchUsersQuery, SearchUsersQueryVariables>;
export const RemoveRoleForUserMutationDocument = gql`
    mutation RemoveRoleForUserMutation($id: uuid!) {
  delete_roles_by_pk(id: $id) {
    id
    name
  }
}
    `;
export type RemoveRoleForUserMutationMutationFn = Apollo.MutationFunction<RemoveRoleForUserMutationMutation, RemoveRoleForUserMutationMutationVariables>;

/**
 * __useRemoveRoleForUserMutationMutation__
 *
 * To run a mutation, you first call `useRemoveRoleForUserMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRoleForUserMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRoleForUserMutationMutation, { data, loading, error }] = useRemoveRoleForUserMutationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveRoleForUserMutationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRoleForUserMutationMutation, RemoveRoleForUserMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveRoleForUserMutationMutation, RemoveRoleForUserMutationMutationVariables>(RemoveRoleForUserMutationDocument, options);
      }
export type RemoveRoleForUserMutationMutationHookResult = ReturnType<typeof useRemoveRoleForUserMutationMutation>;
export type RemoveRoleForUserMutationMutationResult = Apollo.MutationResult<RemoveRoleForUserMutationMutation>;
export type RemoveRoleForUserMutationMutationOptions = Apollo.BaseMutationOptions<RemoveRoleForUserMutationMutation, RemoveRoleForUserMutationMutationVariables>;
export const InsertRoleForUserMutationDocument = gql`
    mutation InsertRoleForUserMutation($userId: String!, $name: String!) {
  insert_roles_one(object: {user_id: $userId, name: $name}) {
    id
    name
  }
}
    `;
export type InsertRoleForUserMutationMutationFn = Apollo.MutationFunction<InsertRoleForUserMutationMutation, InsertRoleForUserMutationMutationVariables>;

/**
 * __useInsertRoleForUserMutationMutation__
 *
 * To run a mutation, you first call `useInsertRoleForUserMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertRoleForUserMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertRoleForUserMutationMutation, { data, loading, error }] = useInsertRoleForUserMutationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useInsertRoleForUserMutationMutation(baseOptions?: Apollo.MutationHookOptions<InsertRoleForUserMutationMutation, InsertRoleForUserMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertRoleForUserMutationMutation, InsertRoleForUserMutationMutationVariables>(InsertRoleForUserMutationDocument, options);
      }
export type InsertRoleForUserMutationMutationHookResult = ReturnType<typeof useInsertRoleForUserMutationMutation>;
export type InsertRoleForUserMutationMutationResult = Apollo.MutationResult<InsertRoleForUserMutationMutation>;
export type InsertRoleForUserMutationMutationOptions = Apollo.BaseMutationOptions<InsertRoleForUserMutationMutation, InsertRoleForUserMutationMutationVariables>;
export const SearchCategoriesDocument = gql`
    query SearchCategories($limit: Int, $offset: Int, $where: categories_bool_exp) {
  categories(limit: $limit, offset: $offset, where: $where, order_by: {name: asc}) {
    id
    name
    questions_aggregate {
      aggregate {
        count
      }
    }
  }
  categories_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useSearchCategoriesQuery__
 *
 * To run a query within a React component, call `useSearchCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCategoriesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSearchCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<SearchCategoriesQuery, SearchCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCategoriesQuery, SearchCategoriesQueryVariables>(SearchCategoriesDocument, options);
      }
export function useSearchCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCategoriesQuery, SearchCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCategoriesQuery, SearchCategoriesQueryVariables>(SearchCategoriesDocument, options);
        }
export type SearchCategoriesQueryHookResult = ReturnType<typeof useSearchCategoriesQuery>;
export type SearchCategoriesLazyQueryHookResult = ReturnType<typeof useSearchCategoriesLazyQuery>;
export type SearchCategoriesQueryResult = Apollo.QueryResult<SearchCategoriesQuery, SearchCategoriesQueryVariables>;
export const GetCategoryByIdDocument = gql`
    query GetCategoryById($id: uuid!) {
  categories_by_pk(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useGetCategoryByIdQuery__
 *
 * To run a query within a React component, call `useGetCategoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCategoryByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCategoryByIdQuery, GetCategoryByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoryByIdQuery, GetCategoryByIdQueryVariables>(GetCategoryByIdDocument, options);
      }
export function useGetCategoryByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryByIdQuery, GetCategoryByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoryByIdQuery, GetCategoryByIdQueryVariables>(GetCategoryByIdDocument, options);
        }
export type GetCategoryByIdQueryHookResult = ReturnType<typeof useGetCategoryByIdQuery>;
export type GetCategoryByIdLazyQueryHookResult = ReturnType<typeof useGetCategoryByIdLazyQuery>;
export type GetCategoryByIdQueryResult = Apollo.QueryResult<GetCategoryByIdQuery, GetCategoryByIdQueryVariables>;
export const Insert_CategoryDocument = gql`
    mutation Insert_Category($object: categories_insert_input!) {
  insert_categories_one(object: $object) {
    id
    name
  }
}
    `;
export type Insert_CategoryMutationFn = Apollo.MutationFunction<Insert_CategoryMutation, Insert_CategoryMutationVariables>;

/**
 * __useInsert_CategoryMutation__
 *
 * To run a mutation, you first call `useInsert_CategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_CategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCategoryMutation, { data, loading, error }] = useInsert_CategoryMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsert_CategoryMutation(baseOptions?: Apollo.MutationHookOptions<Insert_CategoryMutation, Insert_CategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Insert_CategoryMutation, Insert_CategoryMutationVariables>(Insert_CategoryDocument, options);
      }
export type Insert_CategoryMutationHookResult = ReturnType<typeof useInsert_CategoryMutation>;
export type Insert_CategoryMutationResult = Apollo.MutationResult<Insert_CategoryMutation>;
export type Insert_CategoryMutationOptions = Apollo.BaseMutationOptions<Insert_CategoryMutation, Insert_CategoryMutationVariables>;
export const Update_Categories_By_PkDocument = gql`
    mutation update_categories_by_pk($pk_columns: categories_pk_columns_input!, $_set: categories_set_input!) {
  update_categories_by_pk(pk_columns: $pk_columns, _set: $_set) {
    name
    id
  }
}
    `;
export type Update_Categories_By_PkMutationFn = Apollo.MutationFunction<Update_Categories_By_PkMutation, Update_Categories_By_PkMutationVariables>;

/**
 * __useUpdate_Categories_By_PkMutation__
 *
 * To run a mutation, you first call `useUpdate_Categories_By_PkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Categories_By_PkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoriesByPkMutation, { data, loading, error }] = useUpdate_Categories_By_PkMutation({
 *   variables: {
 *      pk_columns: // value for 'pk_columns'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdate_Categories_By_PkMutation(baseOptions?: Apollo.MutationHookOptions<Update_Categories_By_PkMutation, Update_Categories_By_PkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_Categories_By_PkMutation, Update_Categories_By_PkMutationVariables>(Update_Categories_By_PkDocument, options);
      }
export type Update_Categories_By_PkMutationHookResult = ReturnType<typeof useUpdate_Categories_By_PkMutation>;
export type Update_Categories_By_PkMutationResult = Apollo.MutationResult<Update_Categories_By_PkMutation>;
export type Update_Categories_By_PkMutationOptions = Apollo.BaseMutationOptions<Update_Categories_By_PkMutation, Update_Categories_By_PkMutationVariables>;
export const Delete_Categories_By_PkDocument = gql`
    mutation delete_categories_by_pk($id: uuid!) {
  delete_categories_by_pk(id: $id) {
    id
    name
  }
}
    `;
export type Delete_Categories_By_PkMutationFn = Apollo.MutationFunction<Delete_Categories_By_PkMutation, Delete_Categories_By_PkMutationVariables>;

/**
 * __useDelete_Categories_By_PkMutation__
 *
 * To run a mutation, you first call `useDelete_Categories_By_PkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Categories_By_PkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoriesByPkMutation, { data, loading, error }] = useDelete_Categories_By_PkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDelete_Categories_By_PkMutation(baseOptions?: Apollo.MutationHookOptions<Delete_Categories_By_PkMutation, Delete_Categories_By_PkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_Categories_By_PkMutation, Delete_Categories_By_PkMutationVariables>(Delete_Categories_By_PkDocument, options);
      }
export type Delete_Categories_By_PkMutationHookResult = ReturnType<typeof useDelete_Categories_By_PkMutation>;
export type Delete_Categories_By_PkMutationResult = Apollo.MutationResult<Delete_Categories_By_PkMutation>;
export type Delete_Categories_By_PkMutationOptions = Apollo.BaseMutationOptions<Delete_Categories_By_PkMutation, Delete_Categories_By_PkMutationVariables>;
export const Merge_Category_MutationDocument = gql`
    mutation Merge_Category_Mutation($fromCategory: uuid!, $toCategory: uuid!, $isDeleteFromCategory: Boolean) {
  mergeCategory(
    fromCategory: $fromCategory
    toCategory: $toCategory
    isDeleteFromCategory: $isDeleteFromCategory
  ) {
    affectedQuestionCount
  }
}
    `;
export type Merge_Category_MutationMutationFn = Apollo.MutationFunction<Merge_Category_MutationMutation, Merge_Category_MutationMutationVariables>;

/**
 * __useMerge_Category_MutationMutation__
 *
 * To run a mutation, you first call `useMerge_Category_MutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMerge_Category_MutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeCategoryMutationMutation, { data, loading, error }] = useMerge_Category_MutationMutation({
 *   variables: {
 *      fromCategory: // value for 'fromCategory'
 *      toCategory: // value for 'toCategory'
 *      isDeleteFromCategory: // value for 'isDeleteFromCategory'
 *   },
 * });
 */
export function useMerge_Category_MutationMutation(baseOptions?: Apollo.MutationHookOptions<Merge_Category_MutationMutation, Merge_Category_MutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Merge_Category_MutationMutation, Merge_Category_MutationMutationVariables>(Merge_Category_MutationDocument, options);
      }
export type Merge_Category_MutationMutationHookResult = ReturnType<typeof useMerge_Category_MutationMutation>;
export type Merge_Category_MutationMutationResult = Apollo.MutationResult<Merge_Category_MutationMutation>;
export type Merge_Category_MutationMutationOptions = Apollo.BaseMutationOptions<Merge_Category_MutationMutation, Merge_Category_MutationMutationVariables>;
export const GetEventHostsDocument = gql`
    query GetEventHosts($where: eventHosts_bool_exp, $limit: Int, $offset: Int) {
  eventHosts(limit: $limit, offset: $offset, where: $where, order_by: {type: asc}) {
    id
    type
    notes
    pay
    event_id
    user_id
    host_id
    host {
      id
      nickname
      googleAccountEmail
      rates
    }
  }
}
    `;

/**
 * __useGetEventHostsQuery__
 *
 * To run a query within a React component, call `useGetEventHostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventHostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventHostsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetEventHostsQuery(baseOptions?: Apollo.QueryHookOptions<GetEventHostsQuery, GetEventHostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventHostsQuery, GetEventHostsQueryVariables>(GetEventHostsDocument, options);
      }
export function useGetEventHostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventHostsQuery, GetEventHostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventHostsQuery, GetEventHostsQueryVariables>(GetEventHostsDocument, options);
        }
export type GetEventHostsQueryHookResult = ReturnType<typeof useGetEventHostsQuery>;
export type GetEventHostsLazyQueryHookResult = ReturnType<typeof useGetEventHostsLazyQuery>;
export type GetEventHostsQueryResult = Apollo.QueryResult<GetEventHostsQuery, GetEventHostsQueryVariables>;
export const AddEventHostDocument = gql`
    mutation AddEventHost($object: eventHosts_insert_input!) {
  insert_eventHosts_one(object: $object) {
    id
  }
}
    `;
export type AddEventHostMutationFn = Apollo.MutationFunction<AddEventHostMutation, AddEventHostMutationVariables>;

/**
 * __useAddEventHostMutation__
 *
 * To run a mutation, you first call `useAddEventHostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEventHostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEventHostMutation, { data, loading, error }] = useAddEventHostMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useAddEventHostMutation(baseOptions?: Apollo.MutationHookOptions<AddEventHostMutation, AddEventHostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEventHostMutation, AddEventHostMutationVariables>(AddEventHostDocument, options);
      }
export type AddEventHostMutationHookResult = ReturnType<typeof useAddEventHostMutation>;
export type AddEventHostMutationResult = Apollo.MutationResult<AddEventHostMutation>;
export type AddEventHostMutationOptions = Apollo.BaseMutationOptions<AddEventHostMutation, AddEventHostMutationVariables>;
export const UpdateEventHostDocument = gql`
    mutation UpdateEventHost($pk_columns: eventHosts_pk_columns_input!, $_set: eventHosts_set_input!) {
  update_eventHosts_by_pk(pk_columns: $pk_columns, _set: $_set) {
    id
  }
}
    `;
export type UpdateEventHostMutationFn = Apollo.MutationFunction<UpdateEventHostMutation, UpdateEventHostMutationVariables>;

/**
 * __useUpdateEventHostMutation__
 *
 * To run a mutation, you first call `useUpdateEventHostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventHostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventHostMutation, { data, loading, error }] = useUpdateEventHostMutation({
 *   variables: {
 *      pk_columns: // value for 'pk_columns'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateEventHostMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventHostMutation, UpdateEventHostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventHostMutation, UpdateEventHostMutationVariables>(UpdateEventHostDocument, options);
      }
export type UpdateEventHostMutationHookResult = ReturnType<typeof useUpdateEventHostMutation>;
export type UpdateEventHostMutationResult = Apollo.MutationResult<UpdateEventHostMutation>;
export type UpdateEventHostMutationOptions = Apollo.BaseMutationOptions<UpdateEventHostMutation, UpdateEventHostMutationVariables>;
export const RemoveEventHostDocument = gql`
    mutation RemoveEventHost($id: uuid!) {
  delete_eventHosts_by_pk(id: $id) {
    id
  }
}
    `;
export type RemoveEventHostMutationFn = Apollo.MutationFunction<RemoveEventHostMutation, RemoveEventHostMutationVariables>;

/**
 * __useRemoveEventHostMutation__
 *
 * To run a mutation, you first call `useRemoveEventHostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEventHostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEventHostMutation, { data, loading, error }] = useRemoveEventHostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveEventHostMutation(baseOptions?: Apollo.MutationHookOptions<RemoveEventHostMutation, RemoveEventHostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveEventHostMutation, RemoveEventHostMutationVariables>(RemoveEventHostDocument, options);
      }
export type RemoveEventHostMutationHookResult = ReturnType<typeof useRemoveEventHostMutation>;
export type RemoveEventHostMutationResult = Apollo.MutationResult<RemoveEventHostMutation>;
export type RemoveEventHostMutationOptions = Apollo.BaseMutationOptions<RemoveEventHostMutation, RemoveEventHostMutationVariables>;
export const GetEventsDocument = gql`
    query GetEvents($where: events_bool_exp, $limit: Int, $offset: Int) {
  events(
    limit: $limit
    offset: $offset
    where: $where
    order_by: {eventDateTime: asc}
  ) {
    id
    account_id
    eventDateTime
    eventQuestions_aggregate {
      aggregate {
        count
      }
    }
    location_id
    location {
      id
      city
      name
      state
    }
    name
    org_id
    status
    cost
    status
    region_id
    region {
      id
      name
    }
  }
  events_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetEventsQuery__
 *
 * To run a query within a React component, call `useGetEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetEventsQuery(baseOptions?: Apollo.QueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
      }
export function useGetEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
        }
export type GetEventsQueryHookResult = ReturnType<typeof useGetEventsQuery>;
export type GetEventsLazyQueryHookResult = ReturnType<typeof useGetEventsLazyQuery>;
export type GetEventsQueryResult = Apollo.QueryResult<GetEventsQuery, GetEventsQueryVariables>;
export const GetEventByIdDocument = gql`
    query GetEventById($id: uuid!) {
  events_by_pk(id: $id) {
    account {
      name
      id
    }
    eventDateTime
    eventQuestions(order_by: {sortOrder: asc}) {
      sortOrder
      question {
        category {
          name
          id
        }
        id
        question
        answer
      }
    }
    id
    name
    location {
      id
      name
    }
    status
    region_id
    cost
    region {
      id
      name
    }
  }
}
    `;

/**
 * __useGetEventByIdQuery__
 *
 * To run a query within a React component, call `useGetEventByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventByIdQuery(baseOptions: Apollo.QueryHookOptions<GetEventByIdQuery, GetEventByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventByIdQuery, GetEventByIdQueryVariables>(GetEventByIdDocument, options);
      }
export function useGetEventByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventByIdQuery, GetEventByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventByIdQuery, GetEventByIdQueryVariables>(GetEventByIdDocument, options);
        }
export type GetEventByIdQueryHookResult = ReturnType<typeof useGetEventByIdQuery>;
export type GetEventByIdLazyQueryHookResult = ReturnType<typeof useGetEventByIdLazyQuery>;
export type GetEventByIdQueryResult = Apollo.QueryResult<GetEventByIdQuery, GetEventByIdQueryVariables>;
export const GetEventQuestionsDocument = gql`
    query GetEventQuestions($where: eventQuestions_bool_exp) {
  eventQuestions(where: $where) {
    created_at
    event_id
    id
    notes
    org_id
    question_id
    updated_at
    sortOrder
    event {
      id
      name
      eventDateTime
      cost
      status
    }
    question {
      id
      category {
        name
      }
      question
    }
  }
}
    `;

/**
 * __useGetEventQuestionsQuery__
 *
 * To run a query within a React component, call `useGetEventQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventQuestionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetEventQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<GetEventQuestionsQuery, GetEventQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventQuestionsQuery, GetEventQuestionsQueryVariables>(GetEventQuestionsDocument, options);
      }
export function useGetEventQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventQuestionsQuery, GetEventQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventQuestionsQuery, GetEventQuestionsQueryVariables>(GetEventQuestionsDocument, options);
        }
export type GetEventQuestionsQueryHookResult = ReturnType<typeof useGetEventQuestionsQuery>;
export type GetEventQuestionsLazyQueryHookResult = ReturnType<typeof useGetEventQuestionsLazyQuery>;
export type GetEventQuestionsQueryResult = Apollo.QueryResult<GetEventQuestionsQuery, GetEventQuestionsQueryVariables>;
export const ListFiltersDocument = gql`
    query ListFilters($limit: Int, $offset: Int) {
  filters(limit: $limit, offset: $offset) {
    created_at
    filter
    gql
    id
    name
    org_id
    owner_id
    updated_at
  }
}
    `;

/**
 * __useListFiltersQuery__
 *
 * To run a query within a React component, call `useListFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFiltersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListFiltersQuery(baseOptions?: Apollo.QueryHookOptions<ListFiltersQuery, ListFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListFiltersQuery, ListFiltersQueryVariables>(ListFiltersDocument, options);
      }
export function useListFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListFiltersQuery, ListFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListFiltersQuery, ListFiltersQueryVariables>(ListFiltersDocument, options);
        }
export type ListFiltersQueryHookResult = ReturnType<typeof useListFiltersQuery>;
export type ListFiltersLazyQueryHookResult = ReturnType<typeof useListFiltersLazyQuery>;
export type ListFiltersQueryResult = Apollo.QueryResult<ListFiltersQuery, ListFiltersQueryVariables>;
export const GetFilterDocument = gql`
    query GetFilter($id: uuid!) {
  filters_by_pk(id: $id) {
    created_at
    filter
    gql
    id
    name
    org_id
    owner_id
    updated_at
  }
}
    `;

/**
 * __useGetFilterQuery__
 *
 * To run a query within a React component, call `useGetFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFilterQuery(baseOptions: Apollo.QueryHookOptions<GetFilterQuery, GetFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilterQuery, GetFilterQueryVariables>(GetFilterDocument, options);
      }
export function useGetFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilterQuery, GetFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilterQuery, GetFilterQueryVariables>(GetFilterDocument, options);
        }
export type GetFilterQueryHookResult = ReturnType<typeof useGetFilterQuery>;
export type GetFilterLazyQueryHookResult = ReturnType<typeof useGetFilterLazyQuery>;
export type GetFilterQueryResult = Apollo.QueryResult<GetFilterQuery, GetFilterQueryVariables>;
export const CreateFilterMutationDocument = gql`
    mutation CreateFilterMutation($object: filters_insert_input!) {
  insert_filters_one(object: $object) {
    id
  }
}
    `;
export type CreateFilterMutationMutationFn = Apollo.MutationFunction<CreateFilterMutationMutation, CreateFilterMutationMutationVariables>;

/**
 * __useCreateFilterMutationMutation__
 *
 * To run a mutation, you first call `useCreateFilterMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFilterMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFilterMutationMutation, { data, loading, error }] = useCreateFilterMutationMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreateFilterMutationMutation(baseOptions?: Apollo.MutationHookOptions<CreateFilterMutationMutation, CreateFilterMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFilterMutationMutation, CreateFilterMutationMutationVariables>(CreateFilterMutationDocument, options);
      }
export type CreateFilterMutationMutationHookResult = ReturnType<typeof useCreateFilterMutationMutation>;
export type CreateFilterMutationMutationResult = Apollo.MutationResult<CreateFilterMutationMutation>;
export type CreateFilterMutationMutationOptions = Apollo.BaseMutationOptions<CreateFilterMutationMutation, CreateFilterMutationMutationVariables>;
export const UpdateFilterMutationDocument = gql`
    mutation UpdateFilterMutation($pk_columns: filters_pk_columns_input!, $_set: filters_set_input!) {
  update_filters_by_pk(pk_columns: $pk_columns, _set: $_set) {
    created_at
    filter
    gql
    id
    name
    org_id
    owner_id
    updated_at
  }
}
    `;
export type UpdateFilterMutationMutationFn = Apollo.MutationFunction<UpdateFilterMutationMutation, UpdateFilterMutationMutationVariables>;

/**
 * __useUpdateFilterMutationMutation__
 *
 * To run a mutation, you first call `useUpdateFilterMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFilterMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFilterMutationMutation, { data, loading, error }] = useUpdateFilterMutationMutation({
 *   variables: {
 *      pk_columns: // value for 'pk_columns'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateFilterMutationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFilterMutationMutation, UpdateFilterMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFilterMutationMutation, UpdateFilterMutationMutationVariables>(UpdateFilterMutationDocument, options);
      }
export type UpdateFilterMutationMutationHookResult = ReturnType<typeof useUpdateFilterMutationMutation>;
export type UpdateFilterMutationMutationResult = Apollo.MutationResult<UpdateFilterMutationMutation>;
export type UpdateFilterMutationMutationOptions = Apollo.BaseMutationOptions<UpdateFilterMutationMutation, UpdateFilterMutationMutationVariables>;
export const RemoveFilterMutationDocument = gql`
    mutation RemoveFilterMutation($id: uuid!) {
  delete_filters_by_pk(id: $id) {
    id
  }
}
    `;
export type RemoveFilterMutationMutationFn = Apollo.MutationFunction<RemoveFilterMutationMutation, RemoveFilterMutationMutationVariables>;

/**
 * __useRemoveFilterMutationMutation__
 *
 * To run a mutation, you first call `useRemoveFilterMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFilterMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFilterMutationMutation, { data, loading, error }] = useRemoveFilterMutationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveFilterMutationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFilterMutationMutation, RemoveFilterMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFilterMutationMutation, RemoveFilterMutationMutationVariables>(RemoveFilterMutationDocument, options);
      }
export type RemoveFilterMutationMutationHookResult = ReturnType<typeof useRemoveFilterMutationMutation>;
export type RemoveFilterMutationMutationResult = Apollo.MutationResult<RemoveFilterMutationMutation>;
export type RemoveFilterMutationMutationOptions = Apollo.BaseMutationOptions<RemoveFilterMutationMutation, RemoveFilterMutationMutationVariables>;
export const SearchHostsDocument = gql`
    query SearchHosts($limit: Int, $offset: Int, $where: hosts_bool_exp) {
  hosts(limit: $limit, offset: $offset, where: $where, order_by: {nickname: asc}) {
    id
    nickname
    googleAccountEmail
    rates
    isActive
    dateStarted
    dateEnded
    user_id
    eventHosts_aggregate {
      aggregate {
        count
      }
    }
  }
  hosts_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useSearchHostsQuery__
 *
 * To run a query within a React component, call `useSearchHostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchHostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchHostsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSearchHostsQuery(baseOptions?: Apollo.QueryHookOptions<SearchHostsQuery, SearchHostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchHostsQuery, SearchHostsQueryVariables>(SearchHostsDocument, options);
      }
export function useSearchHostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchHostsQuery, SearchHostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchHostsQuery, SearchHostsQueryVariables>(SearchHostsDocument, options);
        }
export type SearchHostsQueryHookResult = ReturnType<typeof useSearchHostsQuery>;
export type SearchHostsLazyQueryHookResult = ReturnType<typeof useSearchHostsLazyQuery>;
export type SearchHostsQueryResult = Apollo.QueryResult<SearchHostsQuery, SearchHostsQueryVariables>;
export const GetHostByIdDocument = gql`
    query GetHostById($id: uuid!) {
  hosts_by_pk(id: $id) {
    id
    nickname
    googleAccountEmail
    user_id
    rates
    eventHosts_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetHostByIdQuery__
 *
 * To run a query within a React component, call `useGetHostByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHostByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHostByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetHostByIdQuery(baseOptions: Apollo.QueryHookOptions<GetHostByIdQuery, GetHostByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHostByIdQuery, GetHostByIdQueryVariables>(GetHostByIdDocument, options);
      }
export function useGetHostByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHostByIdQuery, GetHostByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHostByIdQuery, GetHostByIdQueryVariables>(GetHostByIdDocument, options);
        }
export type GetHostByIdQueryHookResult = ReturnType<typeof useGetHostByIdQuery>;
export type GetHostByIdLazyQueryHookResult = ReturnType<typeof useGetHostByIdLazyQuery>;
export type GetHostByIdQueryResult = Apollo.QueryResult<GetHostByIdQuery, GetHostByIdQueryVariables>;
export const Insert_HostDocument = gql`
    mutation Insert_Host($object: hosts_insert_input!) {
  insert_hosts_one(object: $object) {
    id
    nickname
    googleAccountEmail
    user_id
    rates
  }
}
    `;
export type Insert_HostMutationFn = Apollo.MutationFunction<Insert_HostMutation, Insert_HostMutationVariables>;

/**
 * __useInsert_HostMutation__
 *
 * To run a mutation, you first call `useInsert_HostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_HostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertHostMutation, { data, loading, error }] = useInsert_HostMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsert_HostMutation(baseOptions?: Apollo.MutationHookOptions<Insert_HostMutation, Insert_HostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Insert_HostMutation, Insert_HostMutationVariables>(Insert_HostDocument, options);
      }
export type Insert_HostMutationHookResult = ReturnType<typeof useInsert_HostMutation>;
export type Insert_HostMutationResult = Apollo.MutationResult<Insert_HostMutation>;
export type Insert_HostMutationOptions = Apollo.BaseMutationOptions<Insert_HostMutation, Insert_HostMutationVariables>;
export const Update_Hosts_By_PkDocument = gql`
    mutation update_hosts_by_pk($pk_columns: hosts_pk_columns_input!, $_set: hosts_set_input!) {
  update_hosts_by_pk(pk_columns: $pk_columns, _set: $_set) {
    id
    nickname
    googleAccountEmail
    user_id
    rates
  }
}
    `;
export type Update_Hosts_By_PkMutationFn = Apollo.MutationFunction<Update_Hosts_By_PkMutation, Update_Hosts_By_PkMutationVariables>;

/**
 * __useUpdate_Hosts_By_PkMutation__
 *
 * To run a mutation, you first call `useUpdate_Hosts_By_PkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Hosts_By_PkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHostsByPkMutation, { data, loading, error }] = useUpdate_Hosts_By_PkMutation({
 *   variables: {
 *      pk_columns: // value for 'pk_columns'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdate_Hosts_By_PkMutation(baseOptions?: Apollo.MutationHookOptions<Update_Hosts_By_PkMutation, Update_Hosts_By_PkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_Hosts_By_PkMutation, Update_Hosts_By_PkMutationVariables>(Update_Hosts_By_PkDocument, options);
      }
export type Update_Hosts_By_PkMutationHookResult = ReturnType<typeof useUpdate_Hosts_By_PkMutation>;
export type Update_Hosts_By_PkMutationResult = Apollo.MutationResult<Update_Hosts_By_PkMutation>;
export type Update_Hosts_By_PkMutationOptions = Apollo.BaseMutationOptions<Update_Hosts_By_PkMutation, Update_Hosts_By_PkMutationVariables>;
export const Delete_Hosts_By_PkDocument = gql`
    mutation delete_hosts_by_pk($id: uuid!) {
  delete_hosts_by_pk(id: $id) {
    id
    nickname
    googleAccountEmail
    rates
  }
}
    `;
export type Delete_Hosts_By_PkMutationFn = Apollo.MutationFunction<Delete_Hosts_By_PkMutation, Delete_Hosts_By_PkMutationVariables>;

/**
 * __useDelete_Hosts_By_PkMutation__
 *
 * To run a mutation, you first call `useDelete_Hosts_By_PkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Hosts_By_PkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHostsByPkMutation, { data, loading, error }] = useDelete_Hosts_By_PkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDelete_Hosts_By_PkMutation(baseOptions?: Apollo.MutationHookOptions<Delete_Hosts_By_PkMutation, Delete_Hosts_By_PkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_Hosts_By_PkMutation, Delete_Hosts_By_PkMutationVariables>(Delete_Hosts_By_PkDocument, options);
      }
export type Delete_Hosts_By_PkMutationHookResult = ReturnType<typeof useDelete_Hosts_By_PkMutation>;
export type Delete_Hosts_By_PkMutationResult = Apollo.MutationResult<Delete_Hosts_By_PkMutation>;
export type Delete_Hosts_By_PkMutationOptions = Apollo.BaseMutationOptions<Delete_Hosts_By_PkMutation, Delete_Hosts_By_PkMutationVariables>;
export const GetLocationsDocument = gql`
    query GetLocations($limit: Int, $offset: Int, $where: locations_bool_exp) {
  locations(limit: $limit, offset: $offset, where: $where, order_by: {name: asc}) {
    id
    name
    address
    city
    state
    zip
    region {
      id
      name
    }
    region_id
  }
  locations_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetLocationsQuery__
 *
 * To run a query within a React component, call `useGetLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, options);
      }
export function useGetLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, options);
        }
export type GetLocationsQueryHookResult = ReturnType<typeof useGetLocationsQuery>;
export type GetLocationsLazyQueryHookResult = ReturnType<typeof useGetLocationsLazyQuery>;
export type GetLocationsQueryResult = Apollo.QueryResult<GetLocationsQuery, GetLocationsQueryVariables>;
export const GetLocationByIdDocument = gql`
    query GetLocationById($id: uuid!) {
  locations_by_pk(id: $id) {
    id
    name
    region {
      id
      name
    }
    region_id
  }
}
    `;

/**
 * __useGetLocationByIdQuery__
 *
 * To run a query within a React component, call `useGetLocationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLocationByIdQuery(baseOptions: Apollo.QueryHookOptions<GetLocationByIdQuery, GetLocationByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationByIdQuery, GetLocationByIdQueryVariables>(GetLocationByIdDocument, options);
      }
export function useGetLocationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationByIdQuery, GetLocationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationByIdQuery, GetLocationByIdQueryVariables>(GetLocationByIdDocument, options);
        }
export type GetLocationByIdQueryHookResult = ReturnType<typeof useGetLocationByIdQuery>;
export type GetLocationByIdLazyQueryHookResult = ReturnType<typeof useGetLocationByIdLazyQuery>;
export type GetLocationByIdQueryResult = Apollo.QueryResult<GetLocationByIdQuery, GetLocationByIdQueryVariables>;
export const Insert_QuestionDocument = gql`
    mutation Insert_Question($object: questions_insert_input!) {
  insert_questions_one(object: $object) {
    id
    question
    answer
  }
}
    `;
export type Insert_QuestionMutationFn = Apollo.MutationFunction<Insert_QuestionMutation, Insert_QuestionMutationVariables>;

/**
 * __useInsert_QuestionMutation__
 *
 * To run a mutation, you first call `useInsert_QuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_QuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertQuestionMutation, { data, loading, error }] = useInsert_QuestionMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsert_QuestionMutation(baseOptions?: Apollo.MutationHookOptions<Insert_QuestionMutation, Insert_QuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Insert_QuestionMutation, Insert_QuestionMutationVariables>(Insert_QuestionDocument, options);
      }
export type Insert_QuestionMutationHookResult = ReturnType<typeof useInsert_QuestionMutation>;
export type Insert_QuestionMutationResult = Apollo.MutationResult<Insert_QuestionMutation>;
export type Insert_QuestionMutationOptions = Apollo.BaseMutationOptions<Insert_QuestionMutation, Insert_QuestionMutationVariables>;
export const Insert_LocationDocument = gql`
    mutation Insert_Location($object: locations_insert_input!) {
  insert_locations_one(object: $object) {
    id
    name
  }
}
    `;
export type Insert_LocationMutationFn = Apollo.MutationFunction<Insert_LocationMutation, Insert_LocationMutationVariables>;

/**
 * __useInsert_LocationMutation__
 *
 * To run a mutation, you first call `useInsert_LocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_LocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLocationMutation, { data, loading, error }] = useInsert_LocationMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsert_LocationMutation(baseOptions?: Apollo.MutationHookOptions<Insert_LocationMutation, Insert_LocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Insert_LocationMutation, Insert_LocationMutationVariables>(Insert_LocationDocument, options);
      }
export type Insert_LocationMutationHookResult = ReturnType<typeof useInsert_LocationMutation>;
export type Insert_LocationMutationResult = Apollo.MutationResult<Insert_LocationMutation>;
export type Insert_LocationMutationOptions = Apollo.BaseMutationOptions<Insert_LocationMutation, Insert_LocationMutationVariables>;
export const Update_Questions_By_PkDocument = gql`
    mutation update_questions_by_pk($pk_columns: questions_pk_columns_input!, $_set: questions_set_input!) {
  update_questions_by_pk(pk_columns: $pk_columns, _set: $_set) {
    question
    answer
    org_id
    category {
      name
      id
    }
    category_id
    created_at
    id
    isTimeSensitive
    updated_at
    questionType {
      name
      id
    }
    questionTags {
      id
      tag {
        id
        name
      }
    }
    type_id
    status
  }
}
    `;
export type Update_Questions_By_PkMutationFn = Apollo.MutationFunction<Update_Questions_By_PkMutation, Update_Questions_By_PkMutationVariables>;

/**
 * __useUpdate_Questions_By_PkMutation__
 *
 * To run a mutation, you first call `useUpdate_Questions_By_PkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Questions_By_PkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionsByPkMutation, { data, loading, error }] = useUpdate_Questions_By_PkMutation({
 *   variables: {
 *      pk_columns: // value for 'pk_columns'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdate_Questions_By_PkMutation(baseOptions?: Apollo.MutationHookOptions<Update_Questions_By_PkMutation, Update_Questions_By_PkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_Questions_By_PkMutation, Update_Questions_By_PkMutationVariables>(Update_Questions_By_PkDocument, options);
      }
export type Update_Questions_By_PkMutationHookResult = ReturnType<typeof useUpdate_Questions_By_PkMutation>;
export type Update_Questions_By_PkMutationResult = Apollo.MutationResult<Update_Questions_By_PkMutation>;
export type Update_Questions_By_PkMutationOptions = Apollo.BaseMutationOptions<Update_Questions_By_PkMutation, Update_Questions_By_PkMutationVariables>;
export const Insert_Notebooks_OneDocument = gql`
    mutation insert_notebooks_one($object: notebooks_insert_input!) {
  insert_notebooks_one(object: $object) {
    id
    owner_id
    name
    notebooks_notebookQuestions {
      id
      question_id
      notebookQuestions_question {
        id
        question
        answer
        category {
          id
          name
        }
        created_at
        updated_at
        category_id
      }
    }
    created_at
    updated_at
  }
}
    `;
export type Insert_Notebooks_OneMutationFn = Apollo.MutationFunction<Insert_Notebooks_OneMutation, Insert_Notebooks_OneMutationVariables>;

/**
 * __useInsert_Notebooks_OneMutation__
 *
 * To run a mutation, you first call `useInsert_Notebooks_OneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Notebooks_OneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertNotebooksOneMutation, { data, loading, error }] = useInsert_Notebooks_OneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsert_Notebooks_OneMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Notebooks_OneMutation, Insert_Notebooks_OneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Insert_Notebooks_OneMutation, Insert_Notebooks_OneMutationVariables>(Insert_Notebooks_OneDocument, options);
      }
export type Insert_Notebooks_OneMutationHookResult = ReturnType<typeof useInsert_Notebooks_OneMutation>;
export type Insert_Notebooks_OneMutationResult = Apollo.MutationResult<Insert_Notebooks_OneMutation>;
export type Insert_Notebooks_OneMutationOptions = Apollo.BaseMutationOptions<Insert_Notebooks_OneMutation, Insert_Notebooks_OneMutationVariables>;
export const Update_Notebooks_By_PkDocument = gql`
    mutation update_notebooks_by_pk($pk_columns: notebooks_pk_columns_input!, $_set: notebooks_set_input!) {
  update_notebooks_by_pk(pk_columns: $pk_columns, _set: $_set) {
    id
    owner_id
    name
    isArchived
    notebooks_notebookQuestions {
      id
      question_id
      notebookQuestions_question {
        id
        question
        answer
        category {
          id
          name
        }
        created_at
        updated_at
        category_id
      }
    }
    created_at
    updated_at
    isStarred
  }
}
    `;
export type Update_Notebooks_By_PkMutationFn = Apollo.MutationFunction<Update_Notebooks_By_PkMutation, Update_Notebooks_By_PkMutationVariables>;

/**
 * __useUpdate_Notebooks_By_PkMutation__
 *
 * To run a mutation, you first call `useUpdate_Notebooks_By_PkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Notebooks_By_PkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotebooksByPkMutation, { data, loading, error }] = useUpdate_Notebooks_By_PkMutation({
 *   variables: {
 *      pk_columns: // value for 'pk_columns'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdate_Notebooks_By_PkMutation(baseOptions?: Apollo.MutationHookOptions<Update_Notebooks_By_PkMutation, Update_Notebooks_By_PkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_Notebooks_By_PkMutation, Update_Notebooks_By_PkMutationVariables>(Update_Notebooks_By_PkDocument, options);
      }
export type Update_Notebooks_By_PkMutationHookResult = ReturnType<typeof useUpdate_Notebooks_By_PkMutation>;
export type Update_Notebooks_By_PkMutationResult = Apollo.MutationResult<Update_Notebooks_By_PkMutation>;
export type Update_Notebooks_By_PkMutationOptions = Apollo.BaseMutationOptions<Update_Notebooks_By_PkMutation, Update_Notebooks_By_PkMutationVariables>;
export const Update_NotebookQuestions_ManyDocument = gql`
    mutation update_notebookQuestions_many($updates: [notebookQuestions_updates!]!) {
  update_notebookQuestions_many(updates: $updates) {
    affected_rows
  }
}
    `;
export type Update_NotebookQuestions_ManyMutationFn = Apollo.MutationFunction<Update_NotebookQuestions_ManyMutation, Update_NotebookQuestions_ManyMutationVariables>;

/**
 * __useUpdate_NotebookQuestions_ManyMutation__
 *
 * To run a mutation, you first call `useUpdate_NotebookQuestions_ManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_NotebookQuestions_ManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotebookQuestionsManyMutation, { data, loading, error }] = useUpdate_NotebookQuestions_ManyMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdate_NotebookQuestions_ManyMutation(baseOptions?: Apollo.MutationHookOptions<Update_NotebookQuestions_ManyMutation, Update_NotebookQuestions_ManyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_NotebookQuestions_ManyMutation, Update_NotebookQuestions_ManyMutationVariables>(Update_NotebookQuestions_ManyDocument, options);
      }
export type Update_NotebookQuestions_ManyMutationHookResult = ReturnType<typeof useUpdate_NotebookQuestions_ManyMutation>;
export type Update_NotebookQuestions_ManyMutationResult = Apollo.MutationResult<Update_NotebookQuestions_ManyMutation>;
export type Update_NotebookQuestions_ManyMutationOptions = Apollo.BaseMutationOptions<Update_NotebookQuestions_ManyMutation, Update_NotebookQuestions_ManyMutationVariables>;
export const Insert_NotebookQuestions_OneDocument = gql`
    mutation insert_notebookQuestions_one($object: notebookQuestions_insert_input!) {
  insert_notebookQuestions_one(object: $object) {
    id
    question_id
    sortOrder
    notebookQuestions_question {
      answer
      question
      id
      created_at
      updated_at
      category {
        name
        id
      }
    }
  }
}
    `;
export type Insert_NotebookQuestions_OneMutationFn = Apollo.MutationFunction<Insert_NotebookQuestions_OneMutation, Insert_NotebookQuestions_OneMutationVariables>;

/**
 * __useInsert_NotebookQuestions_OneMutation__
 *
 * To run a mutation, you first call `useInsert_NotebookQuestions_OneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_NotebookQuestions_OneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertNotebookQuestionsOneMutation, { data, loading, error }] = useInsert_NotebookQuestions_OneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsert_NotebookQuestions_OneMutation(baseOptions?: Apollo.MutationHookOptions<Insert_NotebookQuestions_OneMutation, Insert_NotebookQuestions_OneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Insert_NotebookQuestions_OneMutation, Insert_NotebookQuestions_OneMutationVariables>(Insert_NotebookQuestions_OneDocument, options);
      }
export type Insert_NotebookQuestions_OneMutationHookResult = ReturnType<typeof useInsert_NotebookQuestions_OneMutation>;
export type Insert_NotebookQuestions_OneMutationResult = Apollo.MutationResult<Insert_NotebookQuestions_OneMutation>;
export type Insert_NotebookQuestions_OneMutationOptions = Apollo.BaseMutationOptions<Insert_NotebookQuestions_OneMutation, Insert_NotebookQuestions_OneMutationVariables>;
export const Delete_NotebookQuestions_By_PkDocument = gql`
    mutation delete_notebookQuestions_by_pk($id: uuid!) {
  delete_notebookQuestions_by_pk(id: $id) {
    id
  }
}
    `;
export type Delete_NotebookQuestions_By_PkMutationFn = Apollo.MutationFunction<Delete_NotebookQuestions_By_PkMutation, Delete_NotebookQuestions_By_PkMutationVariables>;

/**
 * __useDelete_NotebookQuestions_By_PkMutation__
 *
 * To run a mutation, you first call `useDelete_NotebookQuestions_By_PkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_NotebookQuestions_By_PkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotebookQuestionsByPkMutation, { data, loading, error }] = useDelete_NotebookQuestions_By_PkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDelete_NotebookQuestions_By_PkMutation(baseOptions?: Apollo.MutationHookOptions<Delete_NotebookQuestions_By_PkMutation, Delete_NotebookQuestions_By_PkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_NotebookQuestions_By_PkMutation, Delete_NotebookQuestions_By_PkMutationVariables>(Delete_NotebookQuestions_By_PkDocument, options);
      }
export type Delete_NotebookQuestions_By_PkMutationHookResult = ReturnType<typeof useDelete_NotebookQuestions_By_PkMutation>;
export type Delete_NotebookQuestions_By_PkMutationResult = Apollo.MutationResult<Delete_NotebookQuestions_By_PkMutation>;
export type Delete_NotebookQuestions_By_PkMutationOptions = Apollo.BaseMutationOptions<Delete_NotebookQuestions_By_PkMutation, Delete_NotebookQuestions_By_PkMutationVariables>;
export const Update_Locations_By_PkDocument = gql`
    mutation update_locations_by_pk($pk_columns: locations_pk_columns_input!, $_set: locations_set_input!) {
  update_locations_by_pk(pk_columns: $pk_columns, _set: $_set) {
    name
    address
    city
    state
    zip
    id
  }
}
    `;
export type Update_Locations_By_PkMutationFn = Apollo.MutationFunction<Update_Locations_By_PkMutation, Update_Locations_By_PkMutationVariables>;

/**
 * __useUpdate_Locations_By_PkMutation__
 *
 * To run a mutation, you first call `useUpdate_Locations_By_PkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Locations_By_PkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationsByPkMutation, { data, loading, error }] = useUpdate_Locations_By_PkMutation({
 *   variables: {
 *      pk_columns: // value for 'pk_columns'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdate_Locations_By_PkMutation(baseOptions?: Apollo.MutationHookOptions<Update_Locations_By_PkMutation, Update_Locations_By_PkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_Locations_By_PkMutation, Update_Locations_By_PkMutationVariables>(Update_Locations_By_PkDocument, options);
      }
export type Update_Locations_By_PkMutationHookResult = ReturnType<typeof useUpdate_Locations_By_PkMutation>;
export type Update_Locations_By_PkMutationResult = Apollo.MutationResult<Update_Locations_By_PkMutation>;
export type Update_Locations_By_PkMutationOptions = Apollo.BaseMutationOptions<Update_Locations_By_PkMutation, Update_Locations_By_PkMutationVariables>;
export const Delete_Locations_By_PkDocument = gql`
    mutation delete_locations_by_pk($id: uuid!) {
  delete_locations_by_pk(id: $id) {
    id
    name
  }
}
    `;
export type Delete_Locations_By_PkMutationFn = Apollo.MutationFunction<Delete_Locations_By_PkMutation, Delete_Locations_By_PkMutationVariables>;

/**
 * __useDelete_Locations_By_PkMutation__
 *
 * To run a mutation, you first call `useDelete_Locations_By_PkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Locations_By_PkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationsByPkMutation, { data, loading, error }] = useDelete_Locations_By_PkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDelete_Locations_By_PkMutation(baseOptions?: Apollo.MutationHookOptions<Delete_Locations_By_PkMutation, Delete_Locations_By_PkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_Locations_By_PkMutation, Delete_Locations_By_PkMutationVariables>(Delete_Locations_By_PkDocument, options);
      }
export type Delete_Locations_By_PkMutationHookResult = ReturnType<typeof useDelete_Locations_By_PkMutation>;
export type Delete_Locations_By_PkMutationResult = Apollo.MutationResult<Delete_Locations_By_PkMutation>;
export type Delete_Locations_By_PkMutationOptions = Apollo.BaseMutationOptions<Delete_Locations_By_PkMutation, Delete_Locations_By_PkMutationVariables>;
export const Insert_Events_OneDocument = gql`
    mutation insert_events_one($object: events_insert_input!) {
  insert_events_one(object: $object) {
    id
    account {
      id
      name
      region_id
    }
    name
    org_id
    eventDateTime
    location {
      city
      org_id
      state
    }
    location_id
  }
}
    `;
export type Insert_Events_OneMutationFn = Apollo.MutationFunction<Insert_Events_OneMutation, Insert_Events_OneMutationVariables>;

/**
 * __useInsert_Events_OneMutation__
 *
 * To run a mutation, you first call `useInsert_Events_OneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Events_OneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertEventsOneMutation, { data, loading, error }] = useInsert_Events_OneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsert_Events_OneMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Events_OneMutation, Insert_Events_OneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Insert_Events_OneMutation, Insert_Events_OneMutationVariables>(Insert_Events_OneDocument, options);
      }
export type Insert_Events_OneMutationHookResult = ReturnType<typeof useInsert_Events_OneMutation>;
export type Insert_Events_OneMutationResult = Apollo.MutationResult<Insert_Events_OneMutation>;
export type Insert_Events_OneMutationOptions = Apollo.BaseMutationOptions<Insert_Events_OneMutation, Insert_Events_OneMutationVariables>;
export const Update_Events_By_PkDocument = gql`
    mutation update_events_by_pk($pk_columns: events_pk_columns_input!, $_set: events_set_input!) {
  update_events_by_pk(pk_columns: $pk_columns, _set: $_set) {
    id
    account_id
    eventDateTime
    eventQuestions_aggregate {
      aggregate {
        count
      }
    }
    location_id
    location {
      id
      city
      name
      state
    }
    name
    org_id
    status
  }
}
    `;
export type Update_Events_By_PkMutationFn = Apollo.MutationFunction<Update_Events_By_PkMutation, Update_Events_By_PkMutationVariables>;

/**
 * __useUpdate_Events_By_PkMutation__
 *
 * To run a mutation, you first call `useUpdate_Events_By_PkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Events_By_PkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventsByPkMutation, { data, loading, error }] = useUpdate_Events_By_PkMutation({
 *   variables: {
 *      pk_columns: // value for 'pk_columns'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdate_Events_By_PkMutation(baseOptions?: Apollo.MutationHookOptions<Update_Events_By_PkMutation, Update_Events_By_PkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_Events_By_PkMutation, Update_Events_By_PkMutationVariables>(Update_Events_By_PkDocument, options);
      }
export type Update_Events_By_PkMutationHookResult = ReturnType<typeof useUpdate_Events_By_PkMutation>;
export type Update_Events_By_PkMutationResult = Apollo.MutationResult<Update_Events_By_PkMutation>;
export type Update_Events_By_PkMutationOptions = Apollo.BaseMutationOptions<Update_Events_By_PkMutation, Update_Events_By_PkMutationVariables>;
export const Delete_Events_By_PkDocument = gql`
    mutation delete_events_by_pk($id: uuid!) {
  delete_events_by_pk(id: $id) {
    id
    id
    name
    org_id
    eventDateTime
  }
}
    `;
export type Delete_Events_By_PkMutationFn = Apollo.MutationFunction<Delete_Events_By_PkMutation, Delete_Events_By_PkMutationVariables>;

/**
 * __useDelete_Events_By_PkMutation__
 *
 * To run a mutation, you first call `useDelete_Events_By_PkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Events_By_PkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventsByPkMutation, { data, loading, error }] = useDelete_Events_By_PkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDelete_Events_By_PkMutation(baseOptions?: Apollo.MutationHookOptions<Delete_Events_By_PkMutation, Delete_Events_By_PkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_Events_By_PkMutation, Delete_Events_By_PkMutationVariables>(Delete_Events_By_PkDocument, options);
      }
export type Delete_Events_By_PkMutationHookResult = ReturnType<typeof useDelete_Events_By_PkMutation>;
export type Delete_Events_By_PkMutationResult = Apollo.MutationResult<Delete_Events_By_PkMutation>;
export type Delete_Events_By_PkMutationOptions = Apollo.BaseMutationOptions<Delete_Events_By_PkMutation, Delete_Events_By_PkMutationVariables>;
export const Insert_AccountDocument = gql`
    mutation Insert_Account($object: accounts_insert_input!) {
  insert_accounts_one(object: $object) {
    id
    name
  }
}
    `;
export type Insert_AccountMutationFn = Apollo.MutationFunction<Insert_AccountMutation, Insert_AccountMutationVariables>;

/**
 * __useInsert_AccountMutation__
 *
 * To run a mutation, you first call `useInsert_AccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_AccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertAccountMutation, { data, loading, error }] = useInsert_AccountMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsert_AccountMutation(baseOptions?: Apollo.MutationHookOptions<Insert_AccountMutation, Insert_AccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Insert_AccountMutation, Insert_AccountMutationVariables>(Insert_AccountDocument, options);
      }
export type Insert_AccountMutationHookResult = ReturnType<typeof useInsert_AccountMutation>;
export type Insert_AccountMutationResult = Apollo.MutationResult<Insert_AccountMutation>;
export type Insert_AccountMutationOptions = Apollo.BaseMutationOptions<Insert_AccountMutation, Insert_AccountMutationVariables>;
export const Update_Accounts_By_PkDocument = gql`
    mutation update_accounts_by_pk($pk_columns: accounts_pk_columns_input!, $_set: accounts_set_input!) {
  update_accounts_by_pk(pk_columns: $pk_columns, _set: $_set) {
    name
    id
  }
}
    `;
export type Update_Accounts_By_PkMutationFn = Apollo.MutationFunction<Update_Accounts_By_PkMutation, Update_Accounts_By_PkMutationVariables>;

/**
 * __useUpdate_Accounts_By_PkMutation__
 *
 * To run a mutation, you first call `useUpdate_Accounts_By_PkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Accounts_By_PkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountsByPkMutation, { data, loading, error }] = useUpdate_Accounts_By_PkMutation({
 *   variables: {
 *      pk_columns: // value for 'pk_columns'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdate_Accounts_By_PkMutation(baseOptions?: Apollo.MutationHookOptions<Update_Accounts_By_PkMutation, Update_Accounts_By_PkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_Accounts_By_PkMutation, Update_Accounts_By_PkMutationVariables>(Update_Accounts_By_PkDocument, options);
      }
export type Update_Accounts_By_PkMutationHookResult = ReturnType<typeof useUpdate_Accounts_By_PkMutation>;
export type Update_Accounts_By_PkMutationResult = Apollo.MutationResult<Update_Accounts_By_PkMutation>;
export type Update_Accounts_By_PkMutationOptions = Apollo.BaseMutationOptions<Update_Accounts_By_PkMutation, Update_Accounts_By_PkMutationVariables>;
export const Delete_Accounts_By_PkDocument = gql`
    mutation delete_accounts_by_pk($id: uuid!) {
  delete_accounts_by_pk(id: $id) {
    id
    name
  }
}
    `;
export type Delete_Accounts_By_PkMutationFn = Apollo.MutationFunction<Delete_Accounts_By_PkMutation, Delete_Accounts_By_PkMutationVariables>;

/**
 * __useDelete_Accounts_By_PkMutation__
 *
 * To run a mutation, you first call `useDelete_Accounts_By_PkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Accounts_By_PkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountsByPkMutation, { data, loading, error }] = useDelete_Accounts_By_PkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDelete_Accounts_By_PkMutation(baseOptions?: Apollo.MutationHookOptions<Delete_Accounts_By_PkMutation, Delete_Accounts_By_PkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_Accounts_By_PkMutation, Delete_Accounts_By_PkMutationVariables>(Delete_Accounts_By_PkDocument, options);
      }
export type Delete_Accounts_By_PkMutationHookResult = ReturnType<typeof useDelete_Accounts_By_PkMutation>;
export type Delete_Accounts_By_PkMutationResult = Apollo.MutationResult<Delete_Accounts_By_PkMutation>;
export type Delete_Accounts_By_PkMutationOptions = Apollo.BaseMutationOptions<Delete_Accounts_By_PkMutation, Delete_Accounts_By_PkMutationVariables>;
export const Delete_EventQuestions_By_PkDocument = gql`
    mutation delete_eventQuestions_by_pk($id: uuid!) {
  delete_eventQuestions_by_pk(id: $id) {
    id
  }
}
    `;
export type Delete_EventQuestions_By_PkMutationFn = Apollo.MutationFunction<Delete_EventQuestions_By_PkMutation, Delete_EventQuestions_By_PkMutationVariables>;

/**
 * __useDelete_EventQuestions_By_PkMutation__
 *
 * To run a mutation, you first call `useDelete_EventQuestions_By_PkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_EventQuestions_By_PkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventQuestionsByPkMutation, { data, loading, error }] = useDelete_EventQuestions_By_PkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDelete_EventQuestions_By_PkMutation(baseOptions?: Apollo.MutationHookOptions<Delete_EventQuestions_By_PkMutation, Delete_EventQuestions_By_PkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_EventQuestions_By_PkMutation, Delete_EventQuestions_By_PkMutationVariables>(Delete_EventQuestions_By_PkDocument, options);
      }
export type Delete_EventQuestions_By_PkMutationHookResult = ReturnType<typeof useDelete_EventQuestions_By_PkMutation>;
export type Delete_EventQuestions_By_PkMutationResult = Apollo.MutationResult<Delete_EventQuestions_By_PkMutation>;
export type Delete_EventQuestions_By_PkMutationOptions = Apollo.BaseMutationOptions<Delete_EventQuestions_By_PkMutation, Delete_EventQuestions_By_PkMutationVariables>;
export const DeleteEventQuestionsByQIdDocument = gql`
    mutation DeleteEventQuestionsByQId($where: eventQuestions_bool_exp!) {
  delete_eventQuestions(where: $where) {
    affected_rows
  }
}
    `;
export type DeleteEventQuestionsByQIdMutationFn = Apollo.MutationFunction<DeleteEventQuestionsByQIdMutation, DeleteEventQuestionsByQIdMutationVariables>;

/**
 * __useDeleteEventQuestionsByQIdMutation__
 *
 * To run a mutation, you first call `useDeleteEventQuestionsByQIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventQuestionsByQIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventQuestionsByQIdMutation, { data, loading, error }] = useDeleteEventQuestionsByQIdMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteEventQuestionsByQIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEventQuestionsByQIdMutation, DeleteEventQuestionsByQIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEventQuestionsByQIdMutation, DeleteEventQuestionsByQIdMutationVariables>(DeleteEventQuestionsByQIdDocument, options);
      }
export type DeleteEventQuestionsByQIdMutationHookResult = ReturnType<typeof useDeleteEventQuestionsByQIdMutation>;
export type DeleteEventQuestionsByQIdMutationResult = Apollo.MutationResult<DeleteEventQuestionsByQIdMutation>;
export type DeleteEventQuestionsByQIdMutationOptions = Apollo.BaseMutationOptions<DeleteEventQuestionsByQIdMutation, DeleteEventQuestionsByQIdMutationVariables>;
export const MyMutationDocument = gql`
    mutation MyMutation($objects: [eventQuestions_insert_input!]!) {
  insert_eventQuestions(objects: $objects) {
    affected_rows
  }
}
    `;
export type MyMutationMutationFn = Apollo.MutationFunction<MyMutationMutation, MyMutationMutationVariables>;

/**
 * __useMyMutationMutation__
 *
 * To run a mutation, you first call `useMyMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMyMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [myMutationMutation, { data, loading, error }] = useMyMutationMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useMyMutationMutation(baseOptions?: Apollo.MutationHookOptions<MyMutationMutation, MyMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MyMutationMutation, MyMutationMutationVariables>(MyMutationDocument, options);
      }
export type MyMutationMutationHookResult = ReturnType<typeof useMyMutationMutation>;
export type MyMutationMutationResult = Apollo.MutationResult<MyMutationMutation>;
export type MyMutationMutationOptions = Apollo.BaseMutationOptions<MyMutationMutation, MyMutationMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($pk_columns: users_pk_columns_input!, $_set: users_set_input) {
  update_users_by_pk(pk_columns: $pk_columns, _set: $_set) {
    id
    email
    settings
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      pk_columns: // value for 'pk_columns'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetNotebooksByIdDocument = gql`
    query GetNotebooksById($id: uuid!) {
  notebooks_by_pk(id: $id) {
    id
    owner_id
    name
    isArchived
    notebooks_notebookQuestions(order_by: {sortOrder: asc}) {
      id
      question_id
      sortOrder
      notebookQuestions_question {
        id
        question
        answer
        category {
          id
          name
        }
        created_at
        updated_at
        category_id
      }
    }
    isStarred
  }
}
    `;

/**
 * __useGetNotebooksByIdQuery__
 *
 * To run a query within a React component, call `useGetNotebooksByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotebooksByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotebooksByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNotebooksByIdQuery(baseOptions: Apollo.QueryHookOptions<GetNotebooksByIdQuery, GetNotebooksByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotebooksByIdQuery, GetNotebooksByIdQueryVariables>(GetNotebooksByIdDocument, options);
      }
export function useGetNotebooksByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotebooksByIdQuery, GetNotebooksByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotebooksByIdQuery, GetNotebooksByIdQueryVariables>(GetNotebooksByIdDocument, options);
        }
export type GetNotebooksByIdQueryHookResult = ReturnType<typeof useGetNotebooksByIdQuery>;
export type GetNotebooksByIdLazyQueryHookResult = ReturnType<typeof useGetNotebooksByIdLazyQuery>;
export type GetNotebooksByIdQueryResult = Apollo.QueryResult<GetNotebooksByIdQuery, GetNotebooksByIdQueryVariables>;
export const SubscribeNotebooksDocument = gql`
    subscription SubscribeNotebooks($where: notebooks_bool_exp, $limit: Int, $offset: Int, $order_by: [notebooks_order_by!]) {
  notebooks(where: $where, limit: $limit, order_by: $order_by, offset: $offset) {
    id
    owner_id
    name
    isArchived
    notebooks_notebookQuestions {
      id
      question_id
      sortOrder
      notebookQuestions_question {
        id
        question
        answer
        category {
          id
          name
        }
        created_at
        updated_at
        category_id
      }
    }
    updated_at
    isStarred
  }
}
    `;

/**
 * __useSubscribeNotebooksSubscription__
 *
 * To run a query within a React component, call `useSubscribeNotebooksSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeNotebooksSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeNotebooksSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeNotebooksSubscription(baseOptions?: Apollo.SubscriptionHookOptions<SubscribeNotebooksSubscription, SubscribeNotebooksSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeNotebooksSubscription, SubscribeNotebooksSubscriptionVariables>(SubscribeNotebooksDocument, options);
      }
export type SubscribeNotebooksSubscriptionHookResult = ReturnType<typeof useSubscribeNotebooksSubscription>;
export type SubscribeNotebooksSubscriptionResult = Apollo.SubscriptionResult<SubscribeNotebooksSubscription>;
export const SubscribeNotebookByPkDocument = gql`
    subscription SubscribeNotebookByPk($id: uuid!) {
  notebooks_by_pk(id: $id) {
    id
    owner_id
    name
    isArchived
    notebooks_notebookQuestions(order_by: {sortOrder: asc}) {
      id
      question_id
      sortOrder
      notebookQuestions_question {
        id
        question
        answer
        category {
          id
          name
        }
        created_at
        updated_at
        category_id
      }
    }
    isStarred
  }
}
    `;

/**
 * __useSubscribeNotebookByPkSubscription__
 *
 * To run a query within a React component, call `useSubscribeNotebookByPkSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeNotebookByPkSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeNotebookByPkSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubscribeNotebookByPkSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeNotebookByPkSubscription, SubscribeNotebookByPkSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeNotebookByPkSubscription, SubscribeNotebookByPkSubscriptionVariables>(SubscribeNotebookByPkDocument, options);
      }
export type SubscribeNotebookByPkSubscriptionHookResult = ReturnType<typeof useSubscribeNotebookByPkSubscription>;
export type SubscribeNotebookByPkSubscriptionResult = Apollo.SubscriptionResult<SubscribeNotebookByPkSubscription>;
export const GetUserDocument = gql`
    query GetUser($id: String!) {
  users_by_pk(id: $id) {
    email
    id
    settings
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetAccountLocationsDocument = gql`
    query GetAccountLocations {
  locations(limit: 10) {
    id
    name
    address
    city
    state
    zip
  }
}
    `;

/**
 * __useGetAccountLocationsQuery__
 *
 * To run a query within a React component, call `useGetAccountLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountLocationsQuery, GetAccountLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountLocationsQuery, GetAccountLocationsQueryVariables>(GetAccountLocationsDocument, options);
      }
export function useGetAccountLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountLocationsQuery, GetAccountLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountLocationsQuery, GetAccountLocationsQueryVariables>(GetAccountLocationsDocument, options);
        }
export type GetAccountLocationsQueryHookResult = ReturnType<typeof useGetAccountLocationsQuery>;
export type GetAccountLocationsLazyQueryHookResult = ReturnType<typeof useGetAccountLocationsLazyQuery>;
export type GetAccountLocationsQueryResult = Apollo.QueryResult<GetAccountLocationsQuery, GetAccountLocationsQueryVariables>;
export const GetAccountLocationByIdDocument = gql`
    query GetAccountLocationById($id: uuid!) {
  locations_by_pk(id: $id) {
    address
    city
    name
    state
    updated_at
    zip
    id
  }
}
    `;

/**
 * __useGetAccountLocationByIdQuery__
 *
 * To run a query within a React component, call `useGetAccountLocationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountLocationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountLocationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountLocationByIdQuery(baseOptions: Apollo.QueryHookOptions<GetAccountLocationByIdQuery, GetAccountLocationByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountLocationByIdQuery, GetAccountLocationByIdQueryVariables>(GetAccountLocationByIdDocument, options);
      }
export function useGetAccountLocationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountLocationByIdQuery, GetAccountLocationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountLocationByIdQuery, GetAccountLocationByIdQueryVariables>(GetAccountLocationByIdDocument, options);
        }
export type GetAccountLocationByIdQueryHookResult = ReturnType<typeof useGetAccountLocationByIdQuery>;
export type GetAccountLocationByIdLazyQueryHookResult = ReturnType<typeof useGetAccountLocationByIdLazyQuery>;
export type GetAccountLocationByIdQueryResult = Apollo.QueryResult<GetAccountLocationByIdQuery, GetAccountLocationByIdQueryVariables>;
export const GetAccountsDocument = gql`
    query GetAccounts($limit: Int, $offset: Int, $where: accounts_bool_exp) {
  accounts(limit: $limit, offset: $offset, where: $where, order_by: {name: asc}) {
    id
    name
    region {
      created_at
      id
      name
      org_id
      updated_at
    }
    region_id
  }
  accounts_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAccountsQuery__
 *
 * To run a query within a React component, call `useGetAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAccountsQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, options);
      }
export function useGetAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, options);
        }
export type GetAccountsQueryHookResult = ReturnType<typeof useGetAccountsQuery>;
export type GetAccountsLazyQueryHookResult = ReturnType<typeof useGetAccountsLazyQuery>;
export type GetAccountsQueryResult = Apollo.QueryResult<GetAccountsQuery, GetAccountsQueryVariables>;
export const GetRegionsDocument = gql`
    query GetRegions($limit: Int, $offset: Int, $where: regions_bool_exp) {
  regions(limit: $limit, offset: $offset, where: $where, order_by: {name: asc}) {
    id
    name
  }
  regions_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetRegionsQuery__
 *
 * To run a query within a React component, call `useGetRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetRegionsQuery(baseOptions?: Apollo.QueryHookOptions<GetRegionsQuery, GetRegionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegionsQuery, GetRegionsQueryVariables>(GetRegionsDocument, options);
      }
export function useGetRegionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegionsQuery, GetRegionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegionsQuery, GetRegionsQueryVariables>(GetRegionsDocument, options);
        }
export type GetRegionsQueryHookResult = ReturnType<typeof useGetRegionsQuery>;
export type GetRegionsLazyQueryHookResult = ReturnType<typeof useGetRegionsLazyQuery>;
export type GetRegionsQueryResult = Apollo.QueryResult<GetRegionsQuery, GetRegionsQueryVariables>;
export const GetAccountDocument = gql`
    query GetAccount($id: uuid!) {
  accounts_by_pk(id: $id) {
    id
    name
    region {
      created_at
      id
      name
      org_id
      updated_at
    }
    region_id
  }
}
    `;

/**
 * __useGetAccountQuery__
 *
 * To run a query within a React component, call `useGetAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountQuery(baseOptions: Apollo.QueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, options);
      }
export function useGetAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, options);
        }
export type GetAccountQueryHookResult = ReturnType<typeof useGetAccountQuery>;
export type GetAccountLazyQueryHookResult = ReturnType<typeof useGetAccountLazyQuery>;
export type GetAccountQueryResult = Apollo.QueryResult<GetAccountQuery, GetAccountQueryVariables>;
export const GetQuestionsDocument = gql`
    query GetQuestions($where: questions_bool_exp, $limit: Int, $offset: Int) {
  questions(limit: $limit, offset: $offset, where: $where) {
    id
    question
    isTimeSensitive
    updated_at
    answer
    org_id
    created_at
    category {
      name
      id
    }
    category_id
    questionType {
      name
      id
    }
    questionTags {
      id
      tag {
        id
        name
      }
    }
    type_id
    status
    eventQuestions_aggregate(where: {event: {status: {_eq: "COMPLETED"}}}) {
      aggregate {
        count
      }
    }
  }
  questions_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetQuestionsQuery__
 *
 * To run a query within a React component, call `useGetQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<GetQuestionsQuery, GetQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionsQuery, GetQuestionsQueryVariables>(GetQuestionsDocument, options);
      }
export function useGetQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionsQuery, GetQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionsQuery, GetQuestionsQueryVariables>(GetQuestionsDocument, options);
        }
export type GetQuestionsQueryHookResult = ReturnType<typeof useGetQuestionsQuery>;
export type GetQuestionsLazyQueryHookResult = ReturnType<typeof useGetQuestionsLazyQuery>;
export type GetQuestionsQueryResult = Apollo.QueryResult<GetQuestionsQuery, GetQuestionsQueryVariables>;
export const GetCategoriesDocument = gql`
    query GetCategories($where: categories_bool_exp) {
  categories(where: $where) {
    id
    name
  }
}
    `;

/**
 * __useGetCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
      }
export function useGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
        }
export type GetCategoriesQueryHookResult = ReturnType<typeof useGetCategoriesQuery>;
export type GetCategoriesLazyQueryHookResult = ReturnType<typeof useGetCategoriesLazyQuery>;
export type GetCategoriesQueryResult = Apollo.QueryResult<GetCategoriesQuery, GetCategoriesQueryVariables>;
export const GetQuestionTypesDocument = gql`
    query GetQuestionTypes {
  questionTypes {
    id
    name
  }
}
    `;

/**
 * __useGetQuestionTypesQuery__
 *
 * To run a query within a React component, call `useGetQuestionTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQuestionTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetQuestionTypesQuery, GetQuestionTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionTypesQuery, GetQuestionTypesQueryVariables>(GetQuestionTypesDocument, options);
      }
export function useGetQuestionTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionTypesQuery, GetQuestionTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionTypesQuery, GetQuestionTypesQueryVariables>(GetQuestionTypesDocument, options);
        }
export type GetQuestionTypesQueryHookResult = ReturnType<typeof useGetQuestionTypesQuery>;
export type GetQuestionTypesLazyQueryHookResult = ReturnType<typeof useGetQuestionTypesLazyQuery>;
export type GetQuestionTypesQueryResult = Apollo.QueryResult<GetQuestionTypesQuery, GetQuestionTypesQueryVariables>;
export const GetNotebooksDocument = gql`
    query GetNotebooks($where: notebooks_bool_exp, $limit: Int, $offset: Int, $order_by: [notebooks_order_by!]) {
  notebooks(where: $where, limit: $limit, order_by: $order_by, offset: $offset) {
    id
    owner_id
    name
    isArchived
    notebooks_notebookQuestions {
      id
      question_id
      sortOrder
      notebookQuestions_question {
        id
        question
        answer
        category {
          id
          name
        }
        created_at
        updated_at
        category_id
      }
    }
    updated_at
    isStarred
  }
}
    `;

/**
 * __useGetNotebooksQuery__
 *
 * To run a query within a React component, call `useGetNotebooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotebooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotebooksQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetNotebooksQuery(baseOptions?: Apollo.QueryHookOptions<GetNotebooksQuery, GetNotebooksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotebooksQuery, GetNotebooksQueryVariables>(GetNotebooksDocument, options);
      }
export function useGetNotebooksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotebooksQuery, GetNotebooksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotebooksQuery, GetNotebooksQueryVariables>(GetNotebooksDocument, options);
        }
export type GetNotebooksQueryHookResult = ReturnType<typeof useGetNotebooksQuery>;
export type GetNotebooksLazyQueryHookResult = ReturnType<typeof useGetNotebooksLazyQuery>;
export type GetNotebooksQueryResult = Apollo.QueryResult<GetNotebooksQuery, GetNotebooksQueryVariables>;
export const GetNotebooksAggregateDocument = gql`
    query GetNotebooksAggregate($where: notebooks_bool_exp) {
  notebooks_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetNotebooksAggregateQuery__
 *
 * To run a query within a React component, call `useGetNotebooksAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotebooksAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotebooksAggregateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetNotebooksAggregateQuery(baseOptions?: Apollo.QueryHookOptions<GetNotebooksAggregateQuery, GetNotebooksAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotebooksAggregateQuery, GetNotebooksAggregateQueryVariables>(GetNotebooksAggregateDocument, options);
      }
export function useGetNotebooksAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotebooksAggregateQuery, GetNotebooksAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotebooksAggregateQuery, GetNotebooksAggregateQueryVariables>(GetNotebooksAggregateDocument, options);
        }
export type GetNotebooksAggregateQueryHookResult = ReturnType<typeof useGetNotebooksAggregateQuery>;
export type GetNotebooksAggregateLazyQueryHookResult = ReturnType<typeof useGetNotebooksAggregateLazyQuery>;
export type GetNotebooksAggregateQueryResult = Apollo.QueryResult<GetNotebooksAggregateQuery, GetNotebooksAggregateQueryVariables>;
export const InsertQuestionTagsMutationDocument = gql`
    mutation InsertQuestionTagsMutation($objects: [questionTags_insert_input!]!) {
  insert_questionTags(objects: $objects) {
    affected_rows
  }
}
    `;
export type InsertQuestionTagsMutationMutationFn = Apollo.MutationFunction<InsertQuestionTagsMutationMutation, InsertQuestionTagsMutationMutationVariables>;

/**
 * __useInsertQuestionTagsMutationMutation__
 *
 * To run a mutation, you first call `useInsertQuestionTagsMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertQuestionTagsMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertQuestionTagsMutationMutation, { data, loading, error }] = useInsertQuestionTagsMutationMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertQuestionTagsMutationMutation(baseOptions?: Apollo.MutationHookOptions<InsertQuestionTagsMutationMutation, InsertQuestionTagsMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertQuestionTagsMutationMutation, InsertQuestionTagsMutationMutationVariables>(InsertQuestionTagsMutationDocument, options);
      }
export type InsertQuestionTagsMutationMutationHookResult = ReturnType<typeof useInsertQuestionTagsMutationMutation>;
export type InsertQuestionTagsMutationMutationResult = Apollo.MutationResult<InsertQuestionTagsMutationMutation>;
export type InsertQuestionTagsMutationMutationOptions = Apollo.BaseMutationOptions<InsertQuestionTagsMutationMutation, InsertQuestionTagsMutationMutationVariables>;
export const DeleteQuestionTagsMutationDocument = gql`
    mutation DeleteQuestionTagsMutation($where: questionTags_bool_exp!) {
  delete_questionTags(where: $where) {
    affected_rows
  }
}
    `;
export type DeleteQuestionTagsMutationMutationFn = Apollo.MutationFunction<DeleteQuestionTagsMutationMutation, DeleteQuestionTagsMutationMutationVariables>;

/**
 * __useDeleteQuestionTagsMutationMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionTagsMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionTagsMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionTagsMutationMutation, { data, loading, error }] = useDeleteQuestionTagsMutationMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteQuestionTagsMutationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuestionTagsMutationMutation, DeleteQuestionTagsMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuestionTagsMutationMutation, DeleteQuestionTagsMutationMutationVariables>(DeleteQuestionTagsMutationDocument, options);
      }
export type DeleteQuestionTagsMutationMutationHookResult = ReturnType<typeof useDeleteQuestionTagsMutationMutation>;
export type DeleteQuestionTagsMutationMutationResult = Apollo.MutationResult<DeleteQuestionTagsMutationMutation>;
export type DeleteQuestionTagsMutationMutationOptions = Apollo.BaseMutationOptions<DeleteQuestionTagsMutationMutation, DeleteQuestionTagsMutationMutationVariables>;
export const GetLatestQuestionsDocument = gql`
    subscription GetLatestQuestions($where: questions_bool_exp) {
  questions(where: $where) {
    id
    question
    isTimeSensitive
    updated_at
    answer
    org_id
    created_at
    category {
      name
      id
    }
    category_id
    questionType {
      name
      id
    }
    questionTags {
      id
      tag {
        id
        name
      }
    }
    type_id
    status
    eventQuestions_aggregate(where: {event: {status: {_eq: "COMPLETED"}}}) {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetLatestQuestionsSubscription__
 *
 * To run a query within a React component, call `useGetLatestQuestionsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestQuestionsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestQuestionsSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetLatestQuestionsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetLatestQuestionsSubscription, GetLatestQuestionsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetLatestQuestionsSubscription, GetLatestQuestionsSubscriptionVariables>(GetLatestQuestionsDocument, options);
      }
export type GetLatestQuestionsSubscriptionHookResult = ReturnType<typeof useGetLatestQuestionsSubscription>;
export type GetLatestQuestionsSubscriptionResult = Apollo.SubscriptionResult<GetLatestQuestionsSubscription>;
export const SearchTagsDocument = gql`
    query SearchTags($where: tags_bool_exp, $limit: Int) {
  tags(where: $where, limit: $limit) {
    id
    name
  }
}
    `;

/**
 * __useSearchTagsQuery__
 *
 * To run a query within a React component, call `useSearchTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTagsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchTagsQuery(baseOptions?: Apollo.QueryHookOptions<SearchTagsQuery, SearchTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchTagsQuery, SearchTagsQueryVariables>(SearchTagsDocument, options);
      }
export function useSearchTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchTagsQuery, SearchTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchTagsQuery, SearchTagsQueryVariables>(SearchTagsDocument, options);
        }
export type SearchTagsQueryHookResult = ReturnType<typeof useSearchTagsQuery>;
export type SearchTagsLazyQueryHookResult = ReturnType<typeof useSearchTagsLazyQuery>;
export type SearchTagsQueryResult = Apollo.QueryResult<SearchTagsQuery, SearchTagsQueryVariables>;
export const SuggestTagsDocument = gql`
    query SuggestTags($question: String!) {
  suggestQuestionTags(question: $question) {
    tags
  }
}
    `;

/**
 * __useSuggestTagsQuery__
 *
 * To run a query within a React component, call `useSuggestTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestTagsQuery({
 *   variables: {
 *      question: // value for 'question'
 *   },
 * });
 */
export function useSuggestTagsQuery(baseOptions: Apollo.QueryHookOptions<SuggestTagsQuery, SuggestTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuggestTagsQuery, SuggestTagsQueryVariables>(SuggestTagsDocument, options);
      }
export function useSuggestTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuggestTagsQuery, SuggestTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuggestTagsQuery, SuggestTagsQueryVariables>(SuggestTagsDocument, options);
        }
export type SuggestTagsQueryHookResult = ReturnType<typeof useSuggestTagsQuery>;
export type SuggestTagsLazyQueryHookResult = ReturnType<typeof useSuggestTagsLazyQuery>;
export type SuggestTagsQueryResult = Apollo.QueryResult<SuggestTagsQuery, SuggestTagsQueryVariables>;
export const InsertTagsMutationDocument = gql`
    mutation InsertTagsMutation($objects: [tags_insert_input!]!) {
  insert_tags(objects: $objects) {
    affected_rows
    returning {
      id
      name
    }
  }
}
    `;
export type InsertTagsMutationMutationFn = Apollo.MutationFunction<InsertTagsMutationMutation, InsertTagsMutationMutationVariables>;

/**
 * __useInsertTagsMutationMutation__
 *
 * To run a mutation, you first call `useInsertTagsMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTagsMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTagsMutationMutation, { data, loading, error }] = useInsertTagsMutationMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertTagsMutationMutation(baseOptions?: Apollo.MutationHookOptions<InsertTagsMutationMutation, InsertTagsMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertTagsMutationMutation, InsertTagsMutationMutationVariables>(InsertTagsMutationDocument, options);
      }
export type InsertTagsMutationMutationHookResult = ReturnType<typeof useInsertTagsMutationMutation>;
export type InsertTagsMutationMutationResult = Apollo.MutationResult<InsertTagsMutationMutation>;
export type InsertTagsMutationMutationOptions = Apollo.BaseMutationOptions<InsertTagsMutationMutation, InsertTagsMutationMutationVariables>;