import React, { useMemo } from "react";
import { GetLocationsQuery } from "src/generated/graphql";
import { useSuspenseQuery } from "@apollo/client";
import { GET_LOCATIONS } from "src/gql";
import Select from "react-select";

interface LocationsSelectProps {
  value: string | undefined;
  onValueSelect: (id: string) => void;
}

const LocationsSelect: React.FC<LocationsSelectProps> = ({
  value,
  onValueSelect,
}) => {
  const { data: locationsData } = useSuspenseQuery<GetLocationsQuery>(
    GET_LOCATIONS,
    {
      fetchPolicy: "cache-first",
    }
  );

  const locations = useMemo(() => {
    const l: typeof locationsData.locations = JSON.parse(
      JSON.stringify(locationsData.locations)
    );
    return l.map((location) => ({ label: location.name, value: location.id }));
  }, [locationsData]);

  const selectedValue = useMemo(() => {
    const l: typeof locationsData.locations = JSON.parse(
      JSON.stringify(locationsData.locations)
    );

    const selectedLocation = l.find((location) => location.id === value);

    if (!selectedLocation) {
      return null;
    }

    return { label: selectedLocation?.name, value: selectedLocation?.id };
  }, [locationsData, value]);

  const onChange = (option: { label: string; value: string } | null) => {
    if (option) {
      onValueSelect(option.value);
    } else {
      onValueSelect("");
    }
  };

  return (
    <Select
      className="w-48"
      name="locations"
      onChange={onChange}
      options={locations}
      placeholder="Select a location"
      value={selectedValue}
      isSearchable
    />
  );
};

export default LocationsSelect;
