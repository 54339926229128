import { Trash } from "lucide-react";
import React from "react";
import { GetEventHostsQuery } from "src/generated/graphql";
import {
  Button,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../RadixWrapper";

type HostEditTableProps = {
  eventHosts: GetEventHostsQuery["eventHosts"];
  onHostTypeChange: (
    host: GetEventHostsQuery["eventHosts"][number]["host"],
    type: string
  ) => void;
  onHostPayChange: (hostId: string, pay: string) => void;
  onRemoveHost: (hostId: string) => void;
};

const HostEditTable = ({
  eventHosts,
  onHostTypeChange,
  onHostPayChange,
  onRemoveHost,
}: HostEditTableProps) => {
  // const handleRemoveHost = (index: number) => {
  // setHosts((currentHosts: Hosts[]) =>
  //   currentHosts.filter((_, i) => i !== index)
  // );
  // };

  // const totalHostCost = hosts.reduce((sum, host) => sum + host.cost, 0);
  // const eventProfit = totalCost - totalHostCost;

  return (
    <Table className="mt-4 min-w-full table-auto">
      <TableHeader className="bg-gray-200">
        <TableRow>
          <TableHead className="w-4/12 px-4 py-2">Host Name</TableHead>
          <TableHead className="w-4/12 px-4 py-2">Role</TableHead>
          <TableHead className="w-2/12 px-4 py-2">Cost</TableHead>
          <TableHead className="w-2/12 px-4 py-2 text-center">
            Actions
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {eventHosts?.map((eventHost) => (
          <TableRow key={eventHost.id} className="border-b">
            <TableCell className="w-4/12">{eventHost.host.nickname}</TableCell>
            <TableCell className="w-4/12">
              <Select
                value={eventHost.type}
                onValueChange={(value: string) =>
                  onHostTypeChange(eventHost.host, value)
                }
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Host Type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="HOST">Host</SelectItem>
                  <SelectItem value="SCORING">Scoring Assistant</SelectItem>
                </SelectContent>
              </Select>
            </TableCell>
            <TableCell className="w-2/12">
              <Input
                type="number"
                placeholder="$0.00"
                value={eventHost.pay || 0}
                onChange={(e) =>
                  onHostPayChange(eventHost.host_id, e.target.value)
                }
              />
            </TableCell>
            <TableCell className="w-2/12 text-center">
              <Button
                type="button"
                variant="ghost"
                size="icon"
                onClick={() => onRemoveHost(eventHost.host_id)}
              >
                <Trash className="h-4 w-4 text-red-500" />
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      {/* <TableFooter>
        <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>
            Host Cost:{" "}
            {totalHostCost >= 0 ? (
              `$${totalHostCost.toLocaleString()}`
            ) : (
              <span style={{ color: "red" }}>
                ${totalHostCost.toLocaleString()}
              </span>
            )}
          </TableCell>
          <TableCell>
            Event Profit:{" "}
            {eventProfit >= 0 ? (
              `$${eventProfit.toLocaleString()}`
            ) : (
              <span style={{ color: "red" }}>
                ${eventProfit.toLocaleString()}
              </span>
            )}
          </TableCell>
        </TableRow>
      </TableFooter> */}
    </Table>
  );
};

export default HostEditTable;
