import React from "react";
import {
  AdminCreateUserCommandInput,
  AdminCreateUserCommand,
  CognitoIdentityProviderClient,
} from "@aws-sdk/client-cognito-identity-provider";
import { fetchAuthSession } from "aws-amplify/auth";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "../RadixWrapper";
import { useToast } from "../RadixWrapper/UseToast";
import { useAuth } from "src/hooks/useAuth";

type InviteUserFormProps = {
  onClose: () => void;
};

const FormSchema = z.object({
  email: z.string().email("Enter a valid email address"),
});

export function InviteUserForm({ onClose }: InviteUserFormProps) {
  const { user } = useAuth();
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: "",
    },
  });

  const { toast } = useToast();

  const initiateSignUp = async (data: z.infer<typeof FormSchema>) => {
    toast({
      description: "Creating user...",
    });

    const { credentials } = await fetchAuthSession();
    const client = new CognitoIdentityProviderClient({
      credentials,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const input: AdminCreateUserCommandInput = {
      UserAttributes: [
        {
          Name: "email",
          Value: data.email,
        },
        {
          Name: "custom:organizationId",
          Value: user?.orgId,
        },
      ],
      Username: data.email,
      UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_IDc as string,
    };

    const command = new AdminCreateUserCommand(input);
    await client.send(command);

    toast({
      description: `User ${data.email} created successfully`,
    });

    onClose();
  };

  return (
    <SheetContent>
      <SheetHeader>
        <SheetTitle>Invite a new user</SheetTitle>
        <SheetDescription className="flex flex-col gap-2">
          <p>Enter the new user&apos;s email address.</p>
          <p>
            Cognito will generate a password for the user automatically and
            email it to them.
          </p>
          <p>
            When the user signs in, they will be prompted to change their
            password.
          </p>
        </SheetDescription>
      </SheetHeader>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(initiateSignUp)}
          className="space-y-8 pt-6"
        >
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input placeholder="zuck@facebook.com" {...field} />
                </FormControl>
                <FormDescription>
                  This will be the &quot;username&quot; they sign in with
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit">Create Account</Button>
        </form>
      </Form>
    </SheetContent>
  );
}
