import React, { useMemo } from "react";
import { GetAccountsQuery } from "src/generated/graphql";
import { useSuspenseQuery } from "@apollo/client";
import { GET_ACCOUNTS } from "src/gql";
import Select from "react-select";

interface AccountsSelectProps {
  value: string | undefined;
  onValueSelect: (id: string) => void;
}

const AccountsSelect: React.FC<AccountsSelectProps> = ({
  value,
  onValueSelect,
}) => {
  const { data: accountsData } = useSuspenseQuery<GetAccountsQuery>(
    GET_ACCOUNTS,
    {
      fetchPolicy: "cache-first",
    }
  );

  const accounts = useMemo(() => {
    const a: typeof accountsData.accounts = JSON.parse(
      JSON.stringify(accountsData.accounts)
    );
    return a.map((account) => ({ label: account.name, value: account.id }));
  }, [accountsData]);

  const selectedValue = useMemo(() => {
    const a: typeof accountsData.accounts = JSON.parse(
      JSON.stringify(accountsData.accounts)
    );

    const selectedAccount = a.find((account) => account.id === value);

    if (!selectedAccount) {
      return null;
    }

    return { label: selectedAccount?.name, value: selectedAccount?.id };
  }, [accountsData, value]);

  const onChange = (option: { label: string; value: string } | null) => {
    if (option) {
      onValueSelect(option.value);
    } else {
      onValueSelect("");
    }
  };

  return (
    <Select
      className="w-52"
      name="accounts"
      onChange={onChange}
      options={accounts}
      placeholder="Select an account"
      value={selectedValue}
      isSearchable
    />
  );
};

export default AccountsSelect;
