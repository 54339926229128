import React from "react";
import {
  Skeleton,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../RadixWrapper";
import { Table } from "lucide-react";

function EventsTableRowSkeleton() {
  return (
    <TableRow>
      <TableCell>
        <Skeleton className="h-9 w-full" />
      </TableCell>
      <TableCell>
        <Skeleton className="h-9 w-24" />
      </TableCell>
    </TableRow>
  );
}

export function EventsTableSkeleton() {
  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-full">Name</TableHead>
            <TableHead className="w-full">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {Array(10)
            .fill(0)
            .map((item, index) => (
              <EventsTableRowSkeleton key={index} />
            ))}
        </TableBody>
      </Table>
    </div>
  );
}
