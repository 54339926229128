import React from "react";
import { Skeleton } from "src/components/RadixWrapper";

export default function FormFieldSkeleton() {
  return (
    <div className="rounded-md border">
      <Skeleton className="h-9 w-full" />
    </div>
  );
}
