import React, { useState, useEffect, createContext } from "react";
import { Amplify } from "aws-amplify";
import {
  confirmResetPassword,
  confirmSignIn,
  fetchAuthSession,
  resetPassword,
  signIn,
  signOut,
} from "aws-amplify/auth";
import { AMPLIFY_AUTH } from "src/config/default";
import { UserSettings } from "src/types/Settings";
import { useApolloClient } from "@apollo/client";
import { GET_USER_WITH_EMAIL, Update_User } from "src/gql";
import { useToast } from "src/components/RadixWrapper/UseToast";

type SignedInUserType = {
  email: string;
  id: string;
  orgId: string;
  roles: string[];
  settings: UserSettings;
};

type HasuraClaimsType = {
  "x-hasura-user-id": string;
  "x-hasura-default-role": string;
  "x-hasura-allowed-roles": string[];
  "x-hasura-org-id": string;
};

type AuthContextType = {
  confirmForgotPassword: (
    username: string,
    newPassword: string,
    confirmationCode: string
  ) => Promise<boolean>;
  confirmSignIn: (password: string) => Promise<boolean>;
  forgotPassword: (username: string) => Promise<void>;
  isAuthenticated: boolean;
  loading: boolean;
  signIn: (
    username: string,
    password: string
  ) => Promise<boolean | "FORCERESETPASSWORD">;
  signOut: () => Promise<void>;
  updateUser: (attributes: { settings: UserSettings }) => Promise<void>;
  user: SignedInUserType | null;
};

Amplify.configure({
  Auth: AMPLIFY_AUTH,
});

export const AuthContext = createContext<AuthContextType | null>(null);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<SignedInUserType | null>(null);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const apolloClient = useApolloClient();
  const { toast } = useToast();

  const fetchAdditionalUserInfo = async (userId: string) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_USER_WITH_EMAIL,
        variables: {
          id: userId,
        },
        fetchPolicy: "network-only",
      });

      console.log({ getuserWithEmail: JSON.parse(JSON.stringify(data)) });

      if (data && data.users_by_pk) {
        return data.users_by_pk;

        return true;
      } else {
        console.log("Could not fetch user details from database (hasura)");
      }

      return false;
    } catch (err) {
      console.log("Error fetching additional user data", err);
      setUser(null);
      setIsSignedIn(false);
      setLoading(false);

      return false;
    }
  };

  const getCurrentUser = async () => {
    try {
      const { userSub, tokens } = await fetchAuthSession();

      let roles: string[];
      let orgId: string;

      if (userSub && tokens?.idToken) {
        const claims: HasuraClaimsType = JSON.parse(
          tokens.idToken.payload["https://hasura.io/jwt/claims"] as string
        );

        roles = claims["x-hasura-allowed-roles"];
        orgId = claims["x-hasura-org-id"];

        const userInfo = await fetchAdditionalUserInfo(userSub);

        if (userInfo) {
          setUser({
            email: userInfo.email as string,
            id: userSub,
            orgId,
            roles,
            settings: userInfo.settings as UserSettings,
          });

          setIsSignedIn(true);
          setLoading(false);

          return;
        }
      }

      console.log(
        "We could fetch details about current logged in user from Cognito OR from database"
      );

      setUser(null);
      setIsSignedIn(false);
      setLoading(false);
    } catch (err) {
      console.log("Error fetching current user from cognito", err);

      setUser(null);
      setIsSignedIn(false);
      setLoading(false);
    }
  };

  // On component load, check if user is already authenticated
  useEffect(() => {
    (async () => {
      await getCurrentUser();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSignIn = async (
    username: string,
    password: string
  ): Promise<boolean | "FORCERESETPASSWORD"> => {
    try {
      const { isSignedIn, nextStep, ...etc } = await signIn({
        username,
        password,
      });

      console.log("sign in o/p", { isSignedIn, nextStep, etc });

      console.log("Next step is:", nextStep.signInStep);

      if (isSignedIn) {
        await getCurrentUser();

        return true;
      } else if (
        nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
      ) {
        // This step occurs when admin user has created the user with a temporary password
        // and they are thus required to change it
        return "FORCERESETPASSWORD";
      }

      toast({
        title: "Sign in failed",
        description: `The next step is ${nextStep}`,
      });

      return false;
    } catch (err) {
      console.log("Sign in failed with the following error", err);

      if (err instanceof Error) {
        toast({
          variant: "destructive",
          title: "Sign in Failed",
          description: err.message,
        });
      } else {
        toast({
          variant: "destructive",
          title: "Sign in failed",
          description:
            "An error occurred during sign in. Check console for errors.",
        });
      }

      return false;
    }
  };

  const handleConfirmSignIn = async (password: string) => {
    const { isSignedIn, nextStep } = await confirmSignIn({
      challengeResponse: password,
    });

    console.log({ isSignedIn, nextStep });

    if (isSignedIn) {
      await getCurrentUser();

      return true;
    }

    toast({
      title: "Force reset password failed",
      description: `The next step is ${nextStep}`,
    });

    return false;
  };

  const handleSignOut = async () => {
    await signOut();

    setUser(null);
    setIsSignedIn(false);

    toast({
      title: "Success",
      description: "You have signed out successfully",
    });
  };

  const updateCurrentUser = async ({
    settings,
  }: {
    settings: UserSettings;
  }) => {
    await apolloClient.mutate({
      mutation: Update_User,
      variables: {
        pk_columns: { id: user?.id },
        _set: { settings },
      },
    });

    setUser((prev) => {
      if (!prev) {
        return null;
      }

      return {
        ...prev,
        settings,
      };
    });
  };

  const forgotPassword = async (username: string) => {
    await resetPassword({ username });

    toast({
      title: "Success",
      description: "Password reset link sent",
    });
  };

  const confirmForgotPassword = async (
    username: string,
    newPassword: string,
    confirmationCode: string
  ) => {
    try {
      await confirmResetPassword({
        username,
        newPassword,
        confirmationCode,
      });

      toast({
        title: "Success",
        description: "Password reset successfully",
      });

      return true;
    } catch (err) {
      console.log(
        "Reset password with code failed with the following error",
        err
      );

      if (err instanceof Error) {
        toast({
          variant: "destructive",
          title: "Reset password failed",
          description: err.message,
        });
      } else {
        toast({
          variant: "destructive",
          title: "Reset password failed",
          description:
            "An error occurred during resetting your password. Check console for errors.",
        });
      }

      return false;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        confirmForgotPassword,
        confirmSignIn: handleConfirmSignIn,
        forgotPassword,
        isAuthenticated: isSignedIn,
        loading,
        signIn: handleSignIn,
        signOut: handleSignOut,
        updateUser: updateCurrentUser,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
