import { useMutation } from "@apollo/client";
import { PaginationState } from "@tanstack/react-table";
import { Plus, Search, ShieldQuestion } from "lucide-react";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Outlet, useLocation } from "react-router-dom";
import { AccountForm, AccountsList } from "src/components/Accounts";
import RightSidebar, {
  RightSidebarNavItem,
} from "src/components/Common/RightSidebar";
import { Button, Input } from "src/components/RadixWrapper";
import { useToast } from "src/components/RadixWrapper/UseToast";
import { Accounts, useGetAccountsQuery } from "src/generated/graphql";
import { Update_Account_Mutation } from "src/gql";
import { setAccounts, useAppDispatch } from "src/store";

const ITEMS_PER_PAGE = 10;

type FilterType = {
  keyword: string;
};

// enum SIDEBAR_IDS {
//   FILTER = "filter",
//   NOTEBOOK = "notebook",
//   COLUMN_VISIBILITY = "column_visibility",
// }

const SidebarItems: RightSidebarNavItem[] = [
  {
    id: "DUMMY",
    icon: <ShieldQuestion />,
    tooltip: "Dummy action",
  },
];

export const AccountsPage = () => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: ITEMS_PER_PAGE,
  });
  const [accountsList, setAccountsList] = useState<Accounts[]>([]);
  const [showFormDialog, setShowFormDialog] = useState(false);
  const [searchTimeOutId, setSearchTimeOutId] = useState<NodeJS.Timeout | null>(
    null
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filters, setFilters] = useState<FilterType>({
    keyword: "",
    // category_id: undefined,
  });
  // const [isFilterOpen, setisFilterOpen] = useState(false);
  // const [isNMOpen, setIsNMOpen] = useState(false); // NM => Notebook Manager
  // const [isCVOpen, setIsCVOpen] = useState(false); // CV => Column Visibility

  const location = useLocation();

  const { toast } = useToast();

  const {
    data: AccountsData,
    loading: loadingAccounts,
    fetchMore,
  } = useGetAccountsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: ITEMS_PER_PAGE,
      offset: 0,
      where: {
        name: {
          _ilike: `%${filters.keyword}%`,
        },
      },
    },
  });

  const [updateAccount] = useMutation(Update_Account_Mutation);

  const dispatch = useAppDispatch();

  // const { pubSubInstance } = usePubSubInstance();

  // const listener = useMemo(
  //   () => ({
  //     status: (statusEvent: Pubnub.StatusEvent) => {
  //       if (statusEvent.category === "PNConnectedCategory") {
  //         console.log("Connected");
  //       }
  //     },
  //     message: async (event: Pubnub.MessageEvent) => {
  //       if (event.message.type === "editing_form") {
  //         dispatch(
  //           triggerEdit({
  //             isEditing: true,
  //             account: event.message.account,
  //           })
  //         );
  //       }

  //       if (event.message.type === "editing_canceled") {
  //         dispatch(editingCanceled({ id: event?.message?.account?.id }));
  //       }

  //       if (event.message.type === "account_updated") {
  //         dispatch(editingCanceled({ id: event?.message?.account?.id }));

  //         setAccountsList((old) => {
  //           return old.map((row) => {
  //             if (row.id === event.message.account.id) {
  //               return event.message.account as AccountsInterface;
  //             }

  //             return row;
  //           });
  //         });
  //       }
  //     },
  //   }),
  //   [dispatch]
  // );

  // useEffect(() => {
  //   pubSubInstance?.addListener(listener);

  //   pubSubInstance?.subscribe({
  //     withPresence: true,
  //     channels: ["text_channel"],
  //   });

  //   return () => {
  //     pubSubInstance?.removeListener(listener);
  //     pubSubInstance?.unsubscribeAll();
  //   };
  // }, [pubSubInstance, listener]);

  useEffect(() => {
    const term = location.search.replace("?", "");

    if (term.length > 0) {
      setFilters((prev) => ({ ...prev, keyword: term }));
    }
  }, [location.search]);

  useEffect(() => {
    dispatch(
      setAccounts({
        accounts: AccountsData?.accounts,
        accounts_aggregate: AccountsData?.accounts_aggregate,
      })
    );
  }, [AccountsData, dispatch]);

  useEffect(() => {
    fetchMore({
      variables: {
        limit: pageSize,
        offset: pageIndex * pageSize,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult || !fetchMoreResult.accounts) return prev;
        return Object.assign(
          {},
          {
            accounts: [...fetchMoreResult.accounts],
            accounts_aggregate: prev.accounts_aggregate,
          }
        );
      },
    });
  }, [fetchMore, pageIndex, pageSize]);

  // Keyboard shortcuts
  // useHotkeys("ctrl+f", () => setisFilterOpen(true), { preventDefault: true }, [
  //   isFilterOpen,
  // ]);
  // useHotkeys("ctrl+b", () => setIsNMOpen(true), { preventDefault: true }, [
  //   isNMOpen,
  // ]);

  useHotkeys("mod+m", () => toggleAddAccountModal(), {
    preventDefault: true,
  });

  // TODO - It seems the types we currently have in AccountsInterface
  // TODO - and the types returned from the API do not match
  // TODO - Most likely recently updated
  // TODO - Bandaid until I can get bandwidth to inspect it more
  useEffect(() => {
    if (AccountsData?.accounts) {
      const items: Accounts[] = AccountsData?.accounts.map(
        (acct) =>
          ({
            id: acct.id,
            name: acct.name,
            region_id: acct.region_id,
          } as Accounts)
      );

      setAccountsList(items);
    }
  }, [AccountsData]);

  const handleSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    if (searchTimeOutId) {
      clearTimeout(searchTimeOutId);
    }

    const timeOutId = setTimeout(() => {
      setFilters((prev) => ({ ...prev, keyword: event.target.value }));
    }, 500);

    setSearchTimeOutId(timeOutId);
  };

  const toggleAddAccountModal = () => {
    setShowFormDialog((prev) => !prev);
  };

  const onDeleteRow = async () => {};
  const onUpdateRow = async (params: Record<string, unknown>) => {
    const { answer, category, account, accountId } = params as Record<
      string,
      string
    >;

    toast({
      title: "Updating the account with the following values:",
      description: (
        <pre className="mt-2 w-[340px] whitespace-pre-wrap rounded-md bg-slate-950 p-4">
          <code className="text-white">
            {JSON.stringify(
              {
                id: accountId,
                category_id: category,
                account,
                answer,
              },
              null,
              2
            )}
          </code>
        </pre>
      ),
    });

    try {
      // const { data } = await updateAccount({
      await updateAccount({
        variables: {
          pk_columns: { id: accountId },
          _set: {
            answer,
            account,
            category_id: category,
          },
        },
      });

      toast({
        title: "Success",
        description: "Account updated successfully",
      });

      // pubSubInstance?.publish({
      //   channel: "text_channel",
      //   message: {
      //     account: data.update_accounts_by_pk,
      //     type: "account_updated",
      //   },
      // });
    } catch (err) {
      console.log(err);

      toast({
        variant: "destructive",
        title: "FAILED",
        description: `Could not update the account. Try again.`,
      });
    }
  };

  // const onFilterChange = (id: string, value: string | undefined) => {
  //   if (value?.length === 0) {
  //     value = undefined;
  //   }

  //   setFilters((prev) => ({ ...prev, [id]: value }));
  // };

  // const onResetFilters = () => {
  //   setFilters((prev) => ({
  //     ...prev,
  //     category_id: undefined,
  //   }));
  // };

  // const onSidebarItemClick = (id: string) => {
  const onSidebarItemClick = () => {
    //   if (id === SIDEBAR_IDS.FILTER) {
    //     setisFilterOpen(true);
    //   } else if (id === SIDEBAR_IDS.NOTEBOOK) {
    //     setIsNMOpen(true);
    //   } else if (id === SIDEBAR_IDS.COLUMN_VISIBILITY) {
    //     setIsCVOpen(true);
    //   }
  };

  return (
    <div className="relative w-full pr-20">
      {/* <Dialog open={isFilterOpen} onOpenChange={setisFilterOpen}>
        <DialogContent>
          <FilterAccounts
            filters={filters}
            onValueChange={onFilterChange}
            onReset={onResetFilters}
          />
        </DialogContent>
      </Dialog> */}

      <div className="flex w-full flex-col p-4">
        <section className="mb-8">
          <div className="flex w-full items-center justify-between">
            <h1 className="text-xl font-bold">Accounts</h1>
            <Button
              className="cursor-pointer"
              onClick={toggleAddAccountModal}
              title="Add a new Account (ctrl+n)"
            >
              <Plus className="mr-2 h-4 w-4" /> Add Account
            </Button>
          </div>

          <section className="mt-4 flex justify-end rounded-t-lg bg-mauve-2 p-2">
            <div className="relative w-64">
              <Search className="absolute bottom-0 left-3 top-0 my-auto h-4 w-4 text-gray-500" />
              <Input
                className="pl-12 pr-4"
                placeholder="Search for accounts"
                onChange={handleSearch}
              />
            </div>
          </section>

          <AccountsList
            accounts={accountsList}
            pageIndex={pageIndex}
            pageSize={pageSize}
            totalCount={AccountsData?.accounts_aggregate?.aggregate?.count || 0}
            onPaginationChange={setPagination}
            loadingData={loadingAccounts}
            onDeleteRow={onDeleteRow}
            onUpdateRow={onUpdateRow}
            // isCVOpen={isCVOpen}
            // onCVOpenChange={setIsCVOpen}
          />
        </section>

        <AccountForm
          showAccount={showFormDialog}
          toggleShowAccount={toggleAddAccountModal}
        />
      </div>

      {/* <Dialog open={isNMOpen} onOpenChange={setIsNMOpen}>
        <DialogContent className="max-w-7xl">
          <Suspense fallback={<NotebookListSkeleton />}>
            <ListNotebooks />
          </Suspense>
        </DialogContent>
      </Dialog> */}

      <Outlet />

      <RightSidebar items={SidebarItems} onItemClick={onSidebarItemClick} />
    </div>
  );
};
