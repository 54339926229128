import { gql } from "@apollo/client";

export interface filters_insert_input {
  filter: string;
  gql: string;
  name: string;
  owner_id: string;
  org_id: string;
}

export interface filters_pk_columns_input {
  id: string;
}

export interface filter_set_input {
  filter: string;
  gql: string;
  name: string;
}

export const LIST_FILTERS = gql`
  query ListFilters($limit: Int, $offset: Int) {
    filters(limit: $limit, offset: $offset) {
      created_at
      filter
      gql
      id
      name
      org_id
      owner_id
      updated_at
    }
  }
`;

export const GET_FILTER = gql`
  query GetFilter($id: uuid!) {
    filters_by_pk(id: $id) {
      created_at
      filter
      gql
      id
      name
      org_id
      owner_id
      updated_at
    }
  }
`;

export const Create_Filter_Mutation = gql`
  mutation CreateFilterMutation($object: filters_insert_input!) {
    insert_filters_one(object: $object) {
      id
    }
  }
`;

export const Update_Filter_Mutation = gql`
  mutation UpdateFilterMutation(
    $pk_columns: filters_pk_columns_input!
    $_set: filters_set_input!
  ) {
    update_filters_by_pk(pk_columns: $pk_columns, _set: $_set) {
      created_at
      filter
      gql
      id
      name
      org_id
      owner_id
      updated_at
    }
  }
`;

export const Remove_Filter_Mutation = gql`
  mutation RemoveFilterMutation($id: uuid!) {
    delete_filters_by_pk(id: $id) {
      id
    }
  }
`;
