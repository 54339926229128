import { gql } from "@apollo/client";

export const GET_LOCATIONS = gql`
  query GetLocations($limit: Int, $offset: Int, $where: locations_bool_exp) {
    locations(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { name: asc }
    ) {
      id
      name
      address
      city
      state
      zip
      region {
        id
        name
      }
      region_id
    }
    locations_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_LOCATION_BY_ID = gql`
  query GetLocationById($id: uuid!) {
    locations_by_pk(id: $id) {
      id
      name
      region {
        id
        name
      }
      region_id
    }
  }
`;
