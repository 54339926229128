import React, { Suspense } from "react";
import { Link, useParams } from "react-router-dom";
import { Button } from "../RadixWrapper";
import { useSuspenseQuery } from "@apollo/client";
import { GET_ACCOUNT_BY_ID } from "src/gql";
import { Accounts } from "src/generated/graphql";
import clsx from "clsx";
import { AccountInfoSkeleton } from "./Skeleton";

type GetAccountByIdResponseType = {
  accounts_by_pk: Accounts;
};

type DefinitionItemType = {
  type: string;
  data: string;
  isEven?: boolean;
};

function DefinitionItem({ type, data, isEven }: DefinitionItemType) {
  return (
    <div
      className={clsx({
        "grid grid-cols-3 gap-4 px-3 py-6": true,
        "bg-gray-50": isEven,
      })}
    >
      <dt className="text-sm font-medium leading-6 text-gray-900">{type}</dt>
      <dd className="col-span-2 mt-0 text-sm leading-6 text-gray-700">
        {data}
      </dd>
    </div>
  );
}

function AccountInfo() {
  const { id } = useParams();

  const { data } = useSuspenseQuery<GetAccountByIdResponseType>(
    GET_ACCOUNT_BY_ID,
    {
      variables: {
        id,
      },
    }
  );

  const account = data.accounts_by_pk;

  return (
    <section>
      <dl>
        <DefinitionItem type="Name" data={account.name} />
        <DefinitionItem type="Region" data={account.region.name} isEven />
      </dl>
    </section>
  );
}

export default function AccountDetails() {
  return (
    <div className="container">
      <div className="my-4 flex w-full items-center justify-between">
        <h1 className="text-xl font-bold">Account Details</h1>
        <Button className="cursor-pointer">
          <Link to="/accounts">View all accounts</Link>
        </Button>
      </div>

      <Suspense fallback={<AccountInfoSkeleton />}>
        <AccountInfo />
      </Suspense>
    </div>
  );
}
