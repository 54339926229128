import React from "react";
import { Skeleton } from "src/components/RadixWrapper";

export function EventListSkeleton() {
  return (
    <table className="w-full table-fixed border-separate border-spacing-2">
      <tbody>
        <tr>
          <td className="w-4">
            <Skeleton className="h-6" />
          </td>
          <td className="w-32">
            <Skeleton className="h-6" />
          </td>
          <td>
            <Skeleton className="h-6" />
          </td>
          <td className="w-24">
            <Skeleton className="h-6" />
          </td>
        </tr>
        <tr>
          <td className="w-4">
            <Skeleton className="h-6" />
          </td>
          <td className="w-32">
            <Skeleton className="h-6" />
          </td>
          <td>
            <Skeleton className="h-6" />
          </td>
          <td className="w-24">
            <Skeleton className="h-6" />
          </td>
        </tr>
        <tr>
          <td className="w-4">
            <Skeleton className="h-6" />
          </td>
          <td className="w-32">
            <Skeleton className="h-6" />
          </td>
          <td>
            <Skeleton className="h-6" />
          </td>
          <td className="w-24">
            <Skeleton className="h-6" />
          </td>
        </tr>
        <tr>
          <td className="w-4">
            <Skeleton className="h-6" />
          </td>
          <td className="w-32">
            <Skeleton className="h-6" />
          </td>
          <td>
            <Skeleton className="h-6" />
          </td>
          <td className="w-24">
            <Skeleton className="h-6" />
          </td>
        </tr>
      </tbody>
    </table>
  );
}
