import { useMutation } from "@apollo/client";
import { PaginationState } from "@tanstack/react-table";
import { Plus, Search, ShieldQuestion } from "lucide-react";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Outlet, useLocation } from "react-router-dom";
import { CategoryForm, CategoriesList } from "src/components/Categories";
import MergeCategoriesDialog from "src/components/Categories/MergeDialog";
import RightSidebar, {
  RightSidebarNavItem,
} from "src/components/Common/RightSidebar";
import { Button, Input } from "src/components/RadixWrapper";
import { useToast } from "src/components/RadixWrapper/UseToast";
import { Categories, useSearchCategoriesQuery } from "src/generated/graphql";
import { Update_Category_Mutation } from "src/gql";
import { removeSingleCategory, setCategories, useAppDispatch } from "src/store";

const ITEMS_PER_PAGE = 10;

type FilterType = {
  keyword: string;
};

// enum SIDEBAR_IDS {
//   FILTER = "filter",
//   NOTEBOOK = "notebook",
//   COLUMN_VISIBILITY = "column_visibility",
// }

const SidebarItems: RightSidebarNavItem[] = [
  {
    id: "DUMMY",
    icon: <ShieldQuestion />,
    tooltip: "Dummy action",
  },
];

export const CategoriesPage = () => {
  const [showMergeDialog, setShowMergeDialog] = useState(false);
  const [mergeFromCategory, setMergeFromCategory] = useState<Categories | null>(
    null
  );
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: ITEMS_PER_PAGE,
  });
  const [categoriesList, setCategoriesList] = useState<Categories[]>([]);
  const [showFormDialog, setShowFormDialog] = useState(false);
  const [searchTimeOutId, setSearchTimeOutId] = useState<NodeJS.Timeout | null>(
    null
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filters, setFilters] = useState<FilterType>({
    keyword: "",
    // category_id: undefined,
  });
  // const [isFilterOpen, setisFilterOpen] = useState(false);
  // const [isNMOpen, setIsNMOpen] = useState(false); // NM => Notebook Manager
  // const [isCVOpen, setIsCVOpen] = useState(false); // CV => Column Visibility

  const location = useLocation();

  const { toast } = useToast();

  const {
    data: CategoriesData,
    loading: loadingCategories,
    fetchMore,
  } = useSearchCategoriesQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: ITEMS_PER_PAGE,
      offset: 0,
      where: {
        name: {
          _ilike: `%${filters.keyword}%`,
        },
      },
    },
  });

  const [updateCategory] = useMutation(Update_Category_Mutation);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const term = location.search.replace("?", "");

    if (term.length > 0) {
      setFilters((prev) => ({ ...prev, keyword: term }));
    }
  }, [location.search]);

  useEffect(() => {
    dispatch(
      setCategories({
        categories: CategoriesData?.categories,
        categories_info: CategoriesData?.categories_aggregate,
      })
    );
  }, [CategoriesData, dispatch]);

  useEffect(() => {
    fetchMore({
      variables: {
        limit: pageSize,
        offset: pageIndex * pageSize,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult || !fetchMoreResult.categories) return prev;
        return {
          ...prev,
          categories: [...fetchMoreResult.categories],
          categories_info: prev.categories_aggregate,
        };
      },
    });
  }, [fetchMore, pageIndex, pageSize]);

  // Keyboard shortcuts
  // useHotkeys("ctrl+f", () => setisFilterOpen(true), { preventDefault: true }, [
  //   isFilterOpen,
  // ]);
  // useHotkeys("ctrl+b", () => setIsNMOpen(true), { preventDefault: true }, [
  //   isNMOpen,
  // ]);

  useHotkeys("mod+m", () => toggleAddCategoryModal(), {
    preventDefault: true,
  });

  // TODO - It seems the types we currently have in CategoriesInterface
  // TODO - and the types returned from the API do not match
  // TODO - Most likely recently updated
  // TODO - Bandaid until I can get bandwidth to inspect it more
  useEffect(() => {
    if (CategoriesData?.categories) {
      const items: Categories[] = CategoriesData?.categories.map(
        (o) =>
          ({
            id: o.id,
            name: o.name,
            questions_aggregate: {
              aggregate: {
                count: o.questions_aggregate.aggregate?.count,
              },
            },
          } as Categories)
      );

      //   setCategoriesList(items);
      setCategoriesList(items);
    }
  }, [CategoriesData]);

  const handleSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    if (searchTimeOutId) {
      clearTimeout(searchTimeOutId);
    }

    const timeOutId = setTimeout(() => {
      setFilters((prev) => ({ ...prev, keyword: event.target.value }));
    }, 500);

    setSearchTimeOutId(timeOutId);
  };

  const toggleAddCategoryModal = () => {
    setShowFormDialog((prev) => !prev);
  };

  const onDeleteRow = async () => {};
  const onUpdateRow = async (params: Record<string, unknown>) => {
    const { name, categoryId } = params as Record<string, string>;

    toast({
      title: "Updating the category with the following values:",
      description: (
        <pre className="mt-2 w-[340px] whitespace-pre-wrap rounded-md bg-slate-950 p-4">
          <code className="text-white">
            {JSON.stringify(
              {
                id: categoryId,
                name,
              },
              null,
              2
            )}
          </code>
        </pre>
      ),
    });

    try {
      // const { data } = await updateCategory({
      await updateCategory({
        variables: {
          pk_columns: { id: categoryId },
          _set: {
            name,
          },
        },
      });

      toast({
        title: "Success",
        description: "Category updated successfully",
      });

      // pubSubInstance?.publish({
      //   channel: "text_channel",
      //   message: {
      //     category: data.update_categories_by_pk,
      //     type: "category_updated",
      //   },
      // });
    } catch (err) {
      console.log(err);

      toast({
        variant: "destructive",
        title: "FAILED",
        description: `Could not update the category. Try again.`,
      });
    }
  };

  // const onFilterChange = (id: string, value: string | undefined) => {
  //   if (value?.length === 0) {
  //     value = undefined;
  //   }

  //   setFilters((prev) => ({ ...prev, [id]: value }));
  // };

  // const onResetFilters = () => {
  //   setFilters((prev) => ({
  //     ...prev,
  //     category_id: undefined,
  //   }));
  // };

  // const onSidebarItemClick = (id: string) => {
  const onSidebarItemClick = () => {
    //   if (id === SIDEBAR_IDS.FILTER) {
    //     setisFilterOpen(true);
    //   } else if (id === SIDEBAR_IDS.NOTEBOOK) {
    //     setIsNMOpen(true);
    //   } else if (id === SIDEBAR_IDS.COLUMN_VISIBILITY) {
    //     setIsCVOpen(true);
    //   }
  };

  const onTriggerMergeDialog = (id: string) => {
    const category = categoriesList.find((c) => c.id === id);

    if (category) {
      setMergeFromCategory(category);
      setShowMergeDialog(true);
    }
  };

  const handleCategoryDeletion = () => {
    setCategoriesList((prev) =>
      prev.filter((p) => p.id !== mergeFromCategory?.id)
    );
    dispatch(removeSingleCategory({ id: mergeFromCategory?.id }));
  };

  return (
    <div className="relative w-full pr-20">
      {/* <Dialog open={isFilterOpen} onOpenChange={setisFilterOpen}>
        <DialogContent>
          <FilterCategories
            filters={filters}
            onValueChange={onFilterChange}
            onReset={onResetFilters}
          />
        </DialogContent>
      </Dialog> */}

      <div className="flex w-full flex-col p-4">
        <section className="mb-8">
          <div className="flex w-full items-center justify-between">
            <h1 className="text-xl font-bold">Categories</h1>
            <Button
              className="cursor-pointer"
              onClick={toggleAddCategoryModal}
              title="Add a new Category (ctrl+m)"
            >
              <Plus className="mr-2 h-4 w-4" /> Add Category
            </Button>
          </div>

          <section className="mt-4 flex justify-end rounded-t-lg bg-mauve-2 p-2">
            <div className="relative w-64">
              <Search className="absolute bottom-0 left-3 top-0 my-auto h-4 w-4 text-gray-500" />
              <Input
                className="pl-12 pr-4"
                placeholder="Search for categories"
                onChange={handleSearch}
              />
            </div>
          </section>

          <CategoriesList
            categories={categoriesList}
            pageIndex={pageIndex}
            pageSize={pageSize}
            totalCount={
              CategoriesData?.categories_aggregate?.aggregate?.count || 0
            }
            onPaginationChange={setPagination}
            loadingData={loadingCategories}
            onDeleteRow={onDeleteRow}
            onUpdateRow={onUpdateRow}
            openMergeDialog={onTriggerMergeDialog}
          />
        </section>

        <CategoryForm
          showCategory={showFormDialog}
          toggleShowCategory={toggleAddCategoryModal}
        />
      </div>

      {/* <Dialog open={isNMOpen} onOpenChange={setIsNMOpen}>
        <DialogContent className="max-w-7xl">
          <Suspense fallback={<NotebookListSkeleton />}>
            <ListNotebooks />
          </Suspense>
        </DialogContent>
      </Dialog> */}

      <Outlet />

      <MergeCategoriesDialog
        category={mergeFromCategory}
        open={showMergeDialog}
        onOpenChange={setShowMergeDialog}
        onDeleteCategory={handleCategoryDeletion}
      />

      <RightSidebar items={SidebarItems} onItemClick={onSidebarItemClick} />
    </div>
  );
};
