import { gql } from "@apollo/client";

export const Insert_Question_Tags_Mutation = gql`
  mutation InsertQuestionTagsMutation($objects: [questionTags_insert_input!]!) {
    insert_questionTags(objects: $objects) {
      affected_rows
    }
  }
`;

export const Delete_Question_Tags_Mutation = gql`
  mutation DeleteQuestionTagsMutation($where: questionTags_bool_exp!) {
    delete_questionTags(where: $where) {
      affected_rows
    }
  }
`;
