import React from "react";
import { X } from "lucide-react";
import dateFormat from "dateformat";
import { Button, ScrollArea } from "src/components/RadixWrapper";
import { QueryItem } from "./types";
import { useAutoAnimate } from "@formkit/auto-animate/react";

export function FlexibleQueryMaker<T extends QueryItem>(props: {
  entity: T[];
  updateEntity: (items: T[]) => void;
  label: string;
  isEvent?: boolean;
}) {
  const { entity, updateEntity, label, isEvent = false } = props;
  const [parent] = useAutoAnimate();

  const handleItemDrop = async (event: React.DragEvent<HTMLUListElement>) => {
    event.preventDefault();

    const newItem: T = JSON.parse(event.dataTransfer.getData("text/plain"));

    const exists = entity.some((item) => item.id === newItem.id);

    if (exists) {
      return;
    }

    updateEntity([...entity, newItem]);
  };

  const removeItem = (id: string | undefined) => {
    if (id) {
      updateEntity(entity.filter((item) => item.id !== id));
    }
  };

  return (
    <div className="min-h-72 w-96">
      <p className="mb-2 flex h-20 items-center justify-center">
        <code className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
          {label}
        </code>
      </p>

      <ScrollArea
        className="h-52 w-full rounded-md border bg-blue-100 py-2"
        type="hover"
      >
        <ul
          className="h-52"
          onDrop={handleItemDrop}
          onDragOver={(e) => e.preventDefault()}
          ref={parent}
        >
          {entity.length === 0 && (
            <p className="flex items-center justify-center p-4 text-xs text-gray-500">
              Drop items here
            </p>
          )}
          {entity.map((i) => {
            return (
              <li
                key={i.id}
                className="flex items-center justify-between border-b border-blue-400 px-4 py-1"
              >
                <div>
                  <p>{i.name}</p>
                  <p className="text-sm text-muted-foreground">
                    {isEvent && i.eventDateTime
                      ? dateFormat(i.eventDateTime, "mm/dd/yyyy")
                      : null}
                  </p>
                </div>
                <Button
                  variant="ghost"
                  size="icon"
                  className="group"
                  onClick={() => removeItem(i.id)}
                >
                  <X className="h-4 w-4 group-hover:scale-125" />
                </Button>
              </li>
            );
          })}
        </ul>
      </ScrollArea>
    </div>
  );
}
