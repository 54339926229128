import React, { useMemo } from "react";
import { GetRegionsQuery } from "src/generated/graphql";
import { useSuspenseQuery } from "@apollo/client";
import { GET_REGIONS } from "src/gql";
import Select from "react-select";

interface RegionsSelectProps {
  value: string | undefined;
  onValueSelect: (id: string) => void;
}

const RegionsSelect: React.FC<RegionsSelectProps> = ({
  value,
  onValueSelect,
}) => {
  const { data: regionsData } = useSuspenseQuery<GetRegionsQuery>(GET_REGIONS, {
    fetchPolicy: "cache-first",
  });

  const regions = useMemo(() => {
    const r: typeof regionsData.regions = JSON.parse(
      JSON.stringify(regionsData.regions)
    );
    return r.map((region) => ({ label: region.name, value: region.id }));
  }, [regionsData]);

  const selectedValue = useMemo(() => {
    const r: typeof regionsData.regions = JSON.parse(
      JSON.stringify(regionsData.regions)
    );

    const selectedRegion = r.find((region) => region.id === value);

    if (!selectedRegion) {
      return null;
    }

    return { label: selectedRegion?.name, value: selectedRegion?.id };
  }, [regionsData, value]);

  const onChange = (option: { label: string; value: string } | null) => {
    if (option) {
      onValueSelect(option.value);
    } else {
      onValueSelect("");
    }
  };

  return (
    <Select
      className="w-44"
      name="regions"
      onChange={onChange}
      options={regions}
      placeholder="Select a region"
      value={selectedValue}
      isSearchable
    />
  );
};

export default RegionsSelect;
