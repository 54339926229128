import {
  NoteBookQuestionInterface,
  NotebookInterface,
} from "src/types/Notebook";

export enum NOTEBOOK_ACTIONS {
  EDIT_NOTEBOOK_NAME,
  NEW_QUESTION_DROPPED,
  NOTEBOOK_ARCHIVED,
  NOTEBOOK_NAME_EDITED,
  NOTEBOOK_NAME_EDIT_CANCELLED,
  NOTEBOOK_LOADED,
  NOTEBOOK_QUESTION_DELETED,
  NOTEBOOK_QUESTIONS_REORDERED,
  NOTEBOOK_FAVORITE_TOGGLE,
}

export type NotebookStateType = {
  notebook: NotebookInterface | null;
  isEditingNotebookName: boolean;
};

export type NotebookActionType =
  | {
      type: NOTEBOOK_ACTIONS.NOTEBOOK_LOADED;
      notebook: NotebookInterface;
    }
  | {
      type:
        | NOTEBOOK_ACTIONS.EDIT_NOTEBOOK_NAME
        | NOTEBOOK_ACTIONS.NOTEBOOK_NAME_EDIT_CANCELLED
        | NOTEBOOK_ACTIONS.NOTEBOOK_ARCHIVED;
    }
  | {
      type: NOTEBOOK_ACTIONS.NOTEBOOK_NAME_EDITED;
      newName: NotebookInterface["name"];
    }
  | {
      type: NOTEBOOK_ACTIONS.NOTEBOOK_QUESTIONS_REORDERED;
      questions: NoteBookQuestionInterface[];
    }
  | {
      type:
        | NOTEBOOK_ACTIONS.NEW_QUESTION_DROPPED
        | NOTEBOOK_ACTIONS.NOTEBOOK_QUESTION_DELETED;
      question: NoteBookQuestionInterface;
    }
  | {
      type: NOTEBOOK_ACTIONS.NOTEBOOK_FAVORITE_TOGGLE;
      isStarred: boolean;
    };

export function notebookReducer(
  state: NotebookStateType,
  action: NotebookActionType
): NotebookStateType {
  switch (action.type) {
    case NOTEBOOK_ACTIONS.NOTEBOOK_LOADED:
      return {
        ...state,
        notebook: action.notebook,
      };
    case NOTEBOOK_ACTIONS.EDIT_NOTEBOOK_NAME:
      return {
        ...state,
        isEditingNotebookName: true,
      };
    case NOTEBOOK_ACTIONS.NOTEBOOK_NAME_EDIT_CANCELLED:
      return {
        ...state,
        isEditingNotebookName: false,
      };
    case NOTEBOOK_ACTIONS.NOTEBOOK_NAME_EDITED: {
      if (!action.newName) {
        return {
          ...state,
          isEditingNotebookName: false,
        };
      }

      return {
        ...state,
        notebook: {
          ...state.notebook,
          notebooks_notebookQuestions: state.notebook
            ?.notebooks_notebookQuestions
            ? state.notebook.notebooks_notebookQuestions
            : [],
          name: action.newName,
        },
        isEditingNotebookName: false,
      };
    }
    case NOTEBOOK_ACTIONS.NOTEBOOK_QUESTIONS_REORDERED:
      return {
        ...state,
        notebook: {
          ...state.notebook,
          notebooks_notebookQuestions: action.questions,
        },
      };
    case NOTEBOOK_ACTIONS.NEW_QUESTION_DROPPED: {
      if (!state.notebook) {
        return {
          ...state,
        };
      }

      const updatedNotebook = {
        ...state.notebook,
        notebooks_notebookQuestions: [
          action.question,
          ...state.notebook.notebooks_notebookQuestions,
        ],
      };

      return {
        ...state,
        notebook: updatedNotebook,
      };
    }
    case NOTEBOOK_ACTIONS.NOTEBOOK_QUESTION_DELETED: {
      if (!state.notebook) {
        return {
          ...state,
        };
      }

      const updatedQuestions =
        state.notebook.notebooks_notebookQuestions.filter(
          (q) => q.id !== action.question.id
        );
      const updatedNotebook = {
        ...state.notebook,
        notebooks_notebookQuestions: [...updatedQuestions],
      };

      return {
        ...state,
        notebook: updatedNotebook,
      };
    }
    case NOTEBOOK_ACTIONS.NOTEBOOK_ARCHIVED: {
      if (!state.notebook) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        notebook: {
          ...state.notebook,
          isArchived: true,
        },
      };
    }
    case NOTEBOOK_ACTIONS.NOTEBOOK_FAVORITE_TOGGLE: {
      if (!state.notebook) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        notebook: {
          ...state.notebook,
          isStarred: action.isStarred,
        },
      };
    }
  }
}
