import { createSlice } from "@reduxjs/toolkit";
import { Categories } from "src/generated/graphql";
export interface CategoryData {
  id: string | null;
}

export interface CategoriestateInterface {
  isEditing: boolean;
  editingCategories: Categories[];
  categories?: Categories[];
}

const initialState: CategoriestateInterface = {
  isEditing: false,
  editingCategories: [],
  categories: [],
};

const categorieslice = createSlice({
  name: "category",
  initialState,
  reducers: {
    triggerEditCategory(state, action) {
      state.isEditing = action?.payload?.isEditing;
      state.editingCategories.push(action?.payload?.category);
    },
    editingCategoryCanceled(state, action) {
      state.editingCategories = state.editingCategories.filter(
        (category) => category.id !== action.payload.id
      );
    },
    setCategories(state, action) {
      state.categories = action.payload.categories;
    },
    resetCategoriestate(state) {
      state.editingCategories = [];
      state.isEditing = false;
      state.categories = [];
    },
    removeSingleCategory(state, action) {
      if (action.payload.id) {
        state.categories = state.categories?.filter(
          (category) => category.id !== action.payload.id
        );
      }
    },
  },
});

export const {
  triggerEditCategory,
  editingCategoryCanceled,
  setCategories,
  resetCategoriestate,
  removeSingleCategory,
} = categorieslice.actions;
export const categoryReducer = categorieslice.reducer;
