import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "src/hooks/useAuth";
import {
  AdminDisableUserCommand,
  AdminDisableUserCommandInput,
  AdminEnableUserCommand,
  AdminEnableUserCommandInput,
  CognitoIdentityProviderClient,
  ListUsersCommand,
  ListUsersCommandInput,
  UserType,
} from "@aws-sdk/client-cognito-identity-provider";
import { fetchAuthSession } from "aws-amplify/auth";
import { AMPLIFY_AUTH } from "src/config/default";
import { Button, Sheet, SheetTrigger } from "src/components/RadixWrapper";
import { Plus, ShieldQuestion } from "lucide-react";
import RightSidebar, {
  RightSidebarNavItem,
} from "src/components/Common/RightSidebar";
import { ApplicationUsersTable } from "src/components/ApplicationUsers";
import { ApplicationUsers } from "src/types/ApplicationUsers";
import { useToast } from "src/components/RadixWrapper/UseToast";
import { InviteUserForm } from "src/components/ApplicationUsers/InviteUser";

const SidebarItems: RightSidebarNavItem[] = [
  {
    id: "DUMMY",
    icon: <ShieldQuestion />,
    tooltip: "Dummy action",
  },
];

function getCognitoAttributeForUser(
  user: UserType,
  attributeName: string
): unknown {
  const attribute = user.Attributes?.find((a) => a.Name === attributeName);

  if (attribute) {
    return attribute.Value ?? "N/A";
  }

  return "N/A";
}

export const Users = () => {
  const [loadingUsers, setLoadingUser] = useState(true);
  const [cognitoUser, setCognitoUsers] = useState<ApplicationUsers[]>(
    [] as ApplicationUsers[]
  );
  const [showInviteUserSheet, setShowInviteUserSheet] = useState(false);
  const { user } = useAuth();

  const navigate = useNavigate();

  const { toast } = useToast();

  const getUserByUsername = (username: string) => {
    const u = cognitoUser.find((c) => c.id === username);

    return u;
  };

  const fetchCognitoUsers = async () => {
    const { credentials } = await fetchAuthSession();
    const client = new CognitoIdentityProviderClient({
      credentials,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const input: ListUsersCommandInput = {
      UserPoolId: AMPLIFY_AUTH?.Cognito.userPoolId,
      Limit: 60, // maximum
    };

    const command = new ListUsersCommand(input);
    const response = await client.send(command);

    return response.Users;
  };

  const toggleUserActive = async (username: string, checked: boolean) => {
    const u = getUserByUsername(username);

    if (u) {
      if (u.id === user?.id) {
        toast({
          variant: "destructive",
          description: "Cannot deactivate your own account",
        });

        return;
      }

      const { credentials } = await fetchAuthSession();
      const client = new CognitoIdentityProviderClient({
        credentials,
        region: process.env.REACT_APP_AWS_REGION,
      });

      if (!checked && u.enabled) {
        toast({
          description: `Deactivating user with email ${u.email}`,
        });

        const input: AdminDisableUserCommandInput = {
          UserPoolId: AMPLIFY_AUTH?.Cognito.userPoolId,
          Username: username,
        };

        const command = new AdminDisableUserCommand(input);
        await client.send(command);
      } else if (checked && !u.enabled) {
        toast({
          description: `Activating user with email ${u.email}`,
        });

        const input: AdminEnableUserCommandInput = {
          UserPoolId: AMPLIFY_AUTH?.Cognito.userPoolId,
          Username: username,
        };

        const command = new AdminEnableUserCommand(input);
        await client.send(command);
      } else {
        return;
      }

      setCognitoUsers((prev) => {
        return prev.map((p) => {
          if (p.id === username) {
            return {
              ...p,
              enabled: !p.enabled,
            };
          }

          return {
            ...p,
          };
        });
      });

      toast({
        title: "Success",
        description: `User with email ${
          checked ? "activated" : "deactivated"
        } successfully`,
      });
    }
  };

  useEffect(() => {
    (async () => {
      const data = await fetchCognitoUsers();

      const modifiedUsers: ApplicationUsers[] =
        data?.map((u) => {
          return {
            id: u.Username ?? "N/A",
            email: getCognitoAttributeForUser(u, "email") as string,
            emailVerified: getCognitoAttributeForUser(
              u,
              "email_verified"
            ) as boolean,
            enabled: u.Enabled ?? false,
            status: u.UserStatus ?? "N/A",
          };
        }) ?? ([] as ApplicationUsers[]);

      setCognitoUsers(modifiedUsers);

      setLoadingUser(false);
    })();
  }, []);

  if (!user?.roles.includes(process.env.REACT_APP_ADMIN_ROLE_NAME as string)) {
    navigate("/home");
  }

  return (
    <div className="relative w-full pr-20">
      <div className="flex w-full flex-col p-4">
        <section>
          <div className="mb-8 flex w-full items-center justify-between">
            <h1 className="text-xl font-bold">Application Users</h1>
            <Sheet
              open={showInviteUserSheet}
              onOpenChange={setShowInviteUserSheet}
            >
              <SheetTrigger asChild>
                <Button
                  className="cursor-pointer"
                  title="Create a new Question (ctrl+m)"
                  onClick={() => {}}
                >
                  <Plus className="mr-2 h-4 w-4" /> Invite User
                </Button>
              </SheetTrigger>
              <InviteUserForm onClose={() => setShowInviteUserSheet(false)} />
            </Sheet>
          </div>

          <ApplicationUsersTable
            users={cognitoUser}
            loadingUsers={loadingUsers}
            toggleUserActive={toggleUserActive}
          />
        </section>
        {/* 
        <CreateEditQuestion
          showQuestion={showQDialog}
          toggleShowQuestion={toggleAddQuestionModal}
        /> */}
      </div>

      <RightSidebar items={SidebarItems} />
    </div>
  );
};
