import React from "react";
import styles from "./loader.module.css";

export default function PageLoader() {
  return (
    <div className="flex h-96 w-full items-center justify-center">
      <span className={`${styles.loader} ml-[-225px] inline-block`}></span>
    </div>
  );
}
