import React, { useMemo } from "react";
import { useSuspenseQuery } from "@apollo/client";
import { Get_Categories } from "src/gql";
import Select from "react-select";
import { GetCategoriesQuery } from "src/generated/graphql";

type CategoryFilterType = {
  value: string | undefined;
  onValueSelect: (id: string, value: string, label?: string) => void;
  filterCategoryId?: string;
};

export function CategoryFilter({
  value,
  onValueSelect,
  filterCategoryId,
}: CategoryFilterType) {
  const { data: categoriesData } = useSuspenseQuery<GetCategoriesQuery>(
    Get_Categories,
    {
      variables: {
        where: {
          id: {
            _neq: filterCategoryId,
          },
        },
      },
    }
  );

  const categories = useMemo(() => {
    const c: typeof categoriesData.categories = JSON.parse(
      JSON.stringify(categoriesData.categories)
    );
    const sortedCategories = c.sort((a, b) =>
      a.name.localeCompare(b.name, "en", { sensitivity: "base" })
    );
    return sortedCategories.map((sc) => ({ label: sc.name, value: sc.id }));
  }, [categoriesData]);

  const selectedValue = useMemo(() => {
    const c: typeof categoriesData.categories = JSON.parse(
      JSON.stringify(categoriesData.categories)
    );

    const selectedCategory = c.find((i) => i.id === value);

    if (!selectedCategory) {
      return null;
    }

    return { label: selectedCategory?.name, value: selectedCategory?.id };
  }, [categoriesData, value]);

  const onChange = (option: { label: string; value: string } | null) => {
    if (option) {
      onValueSelect("category_id", option.value, option.label);
    } else {
      onValueSelect("category_id", "");
    }
  };

  return (
    <Select
      className="w-50"
      name="category"
      onChange={onChange}
      options={categories}
      placeholder="All categories"
      value={selectedValue}
      isSearchable
      isClearable
    />
  );
}
