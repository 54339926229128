import { useMutation } from "@apollo/client";
import { PaginationState } from "@tanstack/react-table";
import { Plus, Search, ShieldQuestion } from "lucide-react";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Outlet, useLocation } from "react-router-dom";
import { LocationForm, LocationsList } from "src/components/Locations";
import RightSidebar, {
  RightSidebarNavItem,
} from "src/components/Common/RightSidebar";
import { Button, Input } from "src/components/RadixWrapper";
import { useToast } from "src/components/RadixWrapper/UseToast";
import { Locations, useGetLocationsQuery } from "src/generated/graphql";
import { Update_Location_Mutation } from "src/gql";
import { setLocations, useAppDispatch } from "src/store";

const ITEMS_PER_PAGE = 10;

type FilterType = {
  keyword: string;
};

// enum SIDEBAR_IDS {
//   FILTER = "filter",
//   NOTEBOOK = "notebook",
//   COLUMN_VISIBILITY = "column_visibility",
// }

const SidebarItems: RightSidebarNavItem[] = [
  {
    id: "DUMMY",
    icon: <ShieldQuestion />,
    tooltip: "Dummy action",
  },
];

export const LocationsPage = () => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: ITEMS_PER_PAGE,
  });
  const [locationsList, setLocationsList] = useState<Locations[]>([]);
  const [showFormDialog, setShowFormDialog] = useState(false);
  const [searchTimeOutId, setSearchTimeOutId] = useState<NodeJS.Timeout | null>(
    null
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filters, setFilters] = useState<FilterType>({
    keyword: "",
    // category_id: undefined,
  });
  // const [isFilterOpen, setisFilterOpen] = useState(false);
  // const [isNMOpen, setIsNMOpen] = useState(false); // NM => Notebook Manager
  // const [isCVOpen, setIsCVOpen] = useState(false); // CV => Column Visibility

  const location = useLocation();

  const { toast } = useToast();

  const {
    data: LocationsData,
    loading: loadingLocations,
    fetchMore,
  } = useGetLocationsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: ITEMS_PER_PAGE,
      offset: 0,
      where: {
        name: {
          _ilike: `%${filters.keyword}%`,
        },
      },
    },
  });

  const [updateLocation] = useMutation(Update_Location_Mutation);

  const dispatch = useAppDispatch();

  // const { pubSubInstance } = usePubSubInstance();

  // const listener = useMemo(
  //   () => ({
  //     status: (statusEvent: Pubnub.StatusEvent) => {
  //       if (statusEvent.category === "PNConnectedCategory") {
  //         console.log("Connected");
  //       }
  //     },
  //     message: async (event: Pubnub.MessageEvent) => {
  //       if (event.message.type === "editing_form") {
  //         dispatch(
  //           triggerEdit({
  //             isEditing: true,
  //             location: event.message.location,
  //           })
  //         );
  //       }

  //       if (event.message.type === "editing_canceled") {
  //         dispatch(editingCanceled({ id: event?.message?.location?.id }));
  //       }

  //       if (event.message.type === "location_updated") {
  //         dispatch(editingCanceled({ id: event?.message?.location?.id }));

  //         setLocationsList((old) => {
  //           return old.map((row) => {
  //             if (row.id === event.message.location.id) {
  //               return event.message.location as LocationsInterface;
  //             }

  //             return row;
  //           });
  //         });
  //       }
  //     },
  //   }),
  //   [dispatch]
  // );

  // useEffect(() => {
  //   pubSubInstance?.addListener(listener);

  //   pubSubInstance?.subscribe({
  //     withPresence: true,
  //     channels: ["text_channel"],
  //   });

  //   return () => {
  //     pubSubInstance?.removeListener(listener);
  //     pubSubInstance?.unsubscribeAll();
  //   };
  // }, [pubSubInstance, listener]);

  useEffect(() => {
    const term = location.search.replace("?", "");

    if (term.length > 0) {
      setFilters((prev) => ({ ...prev, keyword: term }));
    }
  }, [location.search]);

  useEffect(() => {
    dispatch(
      setLocations({
        locations: LocationsData?.locations,
        locations_info: LocationsData?.locations_aggregate,
      })
    );
  }, [LocationsData, dispatch]);

  useEffect(() => {
    fetchMore({
      variables: {
        limit: pageSize,
        offset: pageIndex * pageSize,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult || !fetchMoreResult.locations) return prev;
        return {
          ...prev,
          locations: [...fetchMoreResult.locations],
          locations_aggregate: prev.locations_aggregate,
        };
      },
    });
  }, [fetchMore, pageIndex, pageSize]);

  // Keyboard shortcuts
  // useHotkeys("ctrl+f", () => setisFilterOpen(true), { preventDefault: true }, [
  //   isFilterOpen,
  // ]);
  // useHotkeys("ctrl+b", () => setIsNMOpen(true), { preventDefault: true }, [
  //   isNMOpen,
  // ]);

  useHotkeys("mod+m", () => toggleAddLocationModal(), {
    preventDefault: true,
  });

  // TODO - It seems the types we currently have in LocationsInterface
  // TODO - and the types returned from the API do not match
  // TODO - Most likely recently updated
  // TODO - Bandaid until I can get bandwidth to inspect it more
  useEffect(() => {
    if (LocationsData?.locations) {
      const items: Locations[] = LocationsData?.locations.map(
        (o) =>
          ({
            id: o.id,
            name: o.name,
            address: o.address,
            city: o.city,
            state: o.state,
            zip: o.zip,
            // region_id: o.region_id,
          } as Locations)
      );

      //   setLocationsList(items);
      setLocationsList(items);
    }
  }, [LocationsData]);

  const handleSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    if (searchTimeOutId) {
      clearTimeout(searchTimeOutId);
    }

    const timeOutId = setTimeout(() => {
      setFilters((prev) => ({ ...prev, keyword: event.target.value }));
    }, 500);

    setSearchTimeOutId(timeOutId);
  };

  const toggleAddLocationModal = () => {
    setShowFormDialog((prev) => !prev);
  };

  const onDeleteRow = async () => {};
  const onUpdateRow = async (params: Record<string, unknown>) => {
    const { answer, category, location, locationId } = params as Record<
      string,
      string
    >;

    toast({
      title: "Updating the location with the following values:",
      description: (
        <pre className="mt-2 w-[340px] whitespace-pre-wrap rounded-md bg-slate-950 p-4">
          <code className="text-white">
            {JSON.stringify(
              {
                id: locationId,
                category_id: category,
                location,
                answer,
              },
              null,
              2
            )}
          </code>
        </pre>
      ),
    });

    try {
      // const { data } = await updateLocation({
      await updateLocation({
        variables: {
          pk_columns: { id: locationId },
          _set: {
            answer,
            location,
            category_id: category,
          },
        },
      });

      toast({
        title: "Success",
        description: "Location updated successfully",
      });

      // pubSubInstance?.publish({
      //   channel: "text_channel",
      //   message: {
      //     location: data.update_locations_by_pk,
      //     type: "location_updated",
      //   },
      // });
    } catch (err) {
      console.log(err);

      toast({
        variant: "destructive",
        title: "FAILED",
        description: `Could not update the location. Try again.`,
      });
    }
  };

  // const onFilterChange = (id: string, value: string | undefined) => {
  //   if (value?.length === 0) {
  //     value = undefined;
  //   }

  //   setFilters((prev) => ({ ...prev, [id]: value }));
  // };

  // const onResetFilters = () => {
  //   setFilters((prev) => ({
  //     ...prev,
  //     category_id: undefined,
  //   }));
  // };

  // const onSidebarItemClick = (id: string) => {
  const onSidebarItemClick = () => {
    //   if (id === SIDEBAR_IDS.FILTER) {
    //     setisFilterOpen(true);
    //   } else if (id === SIDEBAR_IDS.NOTEBOOK) {
    //     setIsNMOpen(true);
    //   } else if (id === SIDEBAR_IDS.COLUMN_VISIBILITY) {
    //     setIsCVOpen(true);
    //   }
  };

  return (
    <div className="relative w-full pr-20">
      {/* <Dialog open={isFilterOpen} onOpenChange={setisFilterOpen}>
        <DialogContent>
          <FilterLocations
            filters={filters}
            onValueChange={onFilterChange}
            onReset={onResetFilters}
          />
        </DialogContent>
      </Dialog> */}

      <div className="flex w-full flex-col p-4">
        <section className="mb-8">
          <div className="flex w-full items-center justify-between">
            <h1 className="text-xl font-bold">Locations</h1>
            <Button
              className="cursor-pointer"
              onClick={toggleAddLocationModal}
              title="Add a new Location (ctrl+m)"
            >
              <Plus className="mr-2 h-4 w-4" /> Add Location
            </Button>
          </div>

          <section className="mt-4 flex justify-end rounded-t-lg bg-mauve-2 p-2">
            <div className="relative w-64">
              <Search className="absolute bottom-0 left-3 top-0 my-auto h-4 w-4 text-gray-500" />
              <Input
                className="pl-12 pr-4"
                placeholder="Search for locations"
                onChange={handleSearch}
              />
            </div>
          </section>

          <LocationsList
            locations={locationsList}
            pageIndex={pageIndex}
            pageSize={pageSize}
            totalCount={
              LocationsData?.locations_aggregate.aggregate?.count || 0
            }
            onPaginationChange={setPagination}
            loadingData={loadingLocations}
            onDeleteRow={onDeleteRow}
            onUpdateRow={onUpdateRow}
            // isCVOpen={isCVOpen}
            // onCVOpenChange={setIsCVOpen}
          />
        </section>

        <LocationForm
          showLocation={showFormDialog}
          toggleShowLocation={toggleAddLocationModal}
        />
      </div>

      {/* <Dialog open={isNMOpen} onOpenChange={setIsNMOpen}>
        <DialogContent className="max-w-7xl">
          <Suspense fallback={<NotebookListSkeleton />}>
            <ListNotebooks />
          </Suspense>
        </DialogContent>
      </Dialog> */}

      <Outlet />

      <RightSidebar items={SidebarItems} onItemClick={onSidebarItemClick} />
    </div>
  );
};
