import { gql } from "@apollo/client";

export const SEARCH_CATEGORIES = gql`
  query SearchCategories(
    $limit: Int
    $offset: Int
    $where: categories_bool_exp
  ) {
    categories(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { name: asc }
    ) {
      id
      name
      questions_aggregate {
        aggregate {
          count
        }
      }
    }
    categories_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_CATEGORY_BY_ID = gql`
  query GetCategoryById($id: uuid!) {
    categories_by_pk(id: $id) {
      id
      name
    }
  }
`;

export const Create_Category_Mutation = gql`
  mutation Insert_Category($object: categories_insert_input!) {
    insert_categories_one(object: $object) {
      id
      name
    }
  }
`;

export const Update_Category_Mutation = gql`
  mutation update_categories_by_pk(
    $pk_columns: categories_pk_columns_input!
    $_set: categories_set_input!
  ) {
    update_categories_by_pk(pk_columns: $pk_columns, _set: $_set) {
      name
      id
    }
  }
`;

export const Delete_Category_Mutation = gql`
  mutation delete_categories_by_pk($id: uuid!) {
    delete_categories_by_pk(id: $id) {
      id
      name
    }
  }
`;

export const Merge_Category_Mutation = gql`
  mutation Merge_Category_Mutation(
    $fromCategory: uuid!
    $toCategory: uuid!
    $isDeleteFromCategory: Boolean
  ) {
    mergeCategory(
      fromCategory: $fromCategory
      toCategory: $toCategory
      isDeleteFromCategory: $isDeleteFromCategory
    ) {
      affectedQuestionCount
    }
  }
`;
