import { createSlice } from "@reduxjs/toolkit";
import { Accounts } from "src/generated/graphql";
import { AggregateInfoInterface } from "src/types/AggregateInfo";
export interface AccountData {
  id: string | null;
}

export interface AccountStateInterface {
  isEditing: boolean;
  editingAccounts: Accounts[];
  accounts?: Accounts[];
  accounts_info?: AggregateInfoInterface;
}

const initialState: AccountStateInterface = {
  isEditing: false,
  editingAccounts: [],
  accounts: [],
  accounts_info: { aggregate: { count: 0 } },
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    triggerEditAccount(state, action) {
      state.isEditing = action?.payload?.isEditing;
      state.editingAccounts.push(action?.payload?.account);
    },
    editingAccountCanceled(state, action) {
      state.editingAccounts = state.editingAccounts.filter(
        (account) => account.id !== action.payload.id
      );
    },
    setAccounts(state, action) {
      state.accounts = action.payload.accounts;
      state.accounts_info = action.payload.accounts_info;
    },
    resetAccountState(state) {
      state.editingAccounts = [];
      state.isEditing = false;
      state.accounts = [];
    },
  },
});

export const {
  triggerEditAccount,
  editingAccountCanceled,
  setAccounts,
  resetAccountState,
} = accountSlice.actions;
export const accountReducer = accountSlice.reducer;
