import React, { Suspense, useState } from "react";
import { ListFiltersSkeleton } from "../Skeleton";
import { ListFilters } from "./List";
import { CreateEditFilter } from "./CreateEdit";

export enum FILTER_MODES {
  CREATE,
  EDIT,
  LIST,
}

export function Filter() {
  const [mode, setMode] = useState(FILTER_MODES.LIST);
  const [filterId, setFilterId] = useState("");

  const changeMode = (m: FILTER_MODES, id?: string) => {
    if (m === FILTER_MODES.CREATE) {
      setFilterId("");
    } else if (m === FILTER_MODES.EDIT && id) {
      setFilterId(id);
    } else {
      setFilterId("");
    }
    setMode(m);
  };

  return (
    <>
      {mode === FILTER_MODES.LIST && (
        <Suspense fallback={<ListFiltersSkeleton />}>
          <ListFilters onChangeMode={changeMode} />
        </Suspense>
      )}
      {mode === FILTER_MODES.CREATE && (
        <CreateEditFilter onChangeMode={changeMode} />
      )}
      {mode === FILTER_MODES.EDIT && (
        <CreateEditFilter onChangeMode={changeMode} id={filterId} />
      )}
    </>
  );
}
