import { createSlice } from "@reduxjs/toolkit";
import { Locations } from "src/generated/graphql";
import { AggregateInfoInterface } from "src/types/AggregateInfo";
export interface LocationData {
  id: string | null;
}

export interface LocationStateInterface {
  isEditing: boolean;
  editingLocations: Locations[];
  locations?: Locations[];
  locations_info?: AggregateInfoInterface;
}

const initialState: LocationStateInterface = {
  isEditing: false,
  editingLocations: [],
  locations: [],
  locations_info: { aggregate: { count: 0 } },
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    triggerEditLocation(state, action) {
      state.isEditing = action?.payload?.isEditing;
      state.editingLocations.push(action?.payload?.location);
    },
    editingLocationCanceled(state, action) {
      state.editingLocations = state.editingLocations.filter(
        (location) => location.id !== action.payload.id
      );
    },
    setLocations(state, action) {
      state.locations = action.payload.locations;
      state.locations_info = action.payload.locations_info;
    },
    resetLocationState(state) {
      state.editingLocations = [];
      state.isEditing = false;
      state.locations = [];
    },
  },
});

export const {
  triggerEditLocation,
  editingLocationCanceled,
  setLocations,
  resetLocationState,
} = locationSlice.actions;
export const locationReducer = locationSlice.reducer;
