import { useMutation } from "@apollo/client";
import React from "react";
import { QuickCreateEntity } from "src/components/Common";
import { useToast } from "src/components/RadixWrapper/UseToast";
import { GetRegionsQuery } from "src/generated/graphql";
import { Create_Location_Mutation, GET_LOCATIONS, GET_REGIONS } from "src/gql";
import { QCFormFieldType } from "src/types/QuickCreate";
import { z } from "zod";
import FormFieldSkeleton from "./Skeleton";

type NewLocationQuickCreateType = {
  isOpen: boolean;
  onOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
};

const newLocationSchema = z.object({
  name: z.string().min(1),
  address: z.string().min(1),
  city: z.string().min(1),
  state: z.string().min(1),
  zip: z.string().min(1),
  region_id: z.string().min(1),
});

const formFields: QCFormFieldType[] = [
  {
    name: "name",
    label: "Provide the location name",
    placeholder: "eg. Disney World",
    type: "text",
  },
  {
    name: "address",
    label: "What's the address",
    placeholder: "eg. Times Square, Manhattan",
    type: "text",
  },
  {
    name: "city",
    label: "Which city is it in?",
    placeholder: "eg. Barcelona",
    type: "text",
  },
  {
    name: "state",
    label: "And the state?",
    placeholder: "eg. North Carolina",
    type: "text",
  },
  {
    name: "zip",
    label: "Provide the postal code",
    placeholder: "eg. 12345",
    type: "text",
  },
  {
    name: "region_id",
    label: "Which region to associate the location to?",
    placeholder: "Select a region",
    query: GET_REGIONS,
    queryParser: (data: unknown) => {
      const getRegionsResponse: GetRegionsQuery = data as GetRegionsQuery;

      return getRegionsResponse.regions.map((r) => ({
        label: r.name,
        value: r.id,
      }));
    },
    suspenseFallback: <FormFieldSkeleton />,
    type: "select",
  },
];

export default function NewLocationQuickCreate({
  isOpen,
  onOpenChange,
}: NewLocationQuickCreateType) {
  const [createLocationMutation, { loading }] = useMutation(
    Create_Location_Mutation
  );

  const { toast } = useToast();

  const onCreate = async (values: z.infer<typeof newLocationSchema>) => {
    toast({
      description: `Quickly creating a location with name ${values.name}`,
    });

    try {
      await createLocationMutation({
        variables: {
          object: {
            address: values.address,
            city: values.city,
            name: values.name,
            region_id: values.region_id,
            state: values.state,
            zip: values.zip,
          },
        },
        refetchQueries: [{ query: GET_LOCATIONS }],
      });

      toast({
        description: `Location created successfully`,
      });
    } catch (err) {
      console.log(err);

      toast({
        variant: "destructive",
        description: `Location creation failed. Please check console logs for error details`,
      });

      return false;
    }

    return true;
  };

  return (
    <QuickCreateEntity
      creating={loading}
      defaultValues={{
        address: "",
        city: "",
        name: "",
        region_id: "",
        state: "",
        zip: "",
      }}
      entityName="Location"
      formFields={formFields}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      onSubmit={onCreate}
      schema={newLocationSchema}
    />
  );
}
