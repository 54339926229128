import React, { useMemo } from "react";
import { useSuspenseQuery } from "@apollo/client";
import { LIST_FILTERS } from "src/gql";
import Select from "react-select";
import { ListFiltersQuery } from "src/generated/graphql";
import { ComplexFilterInterface } from "src/types/Filter";

type ComplexFilterType = {
  value: ComplexFilterInterface | undefined;
  onValueSelect: (
    id: string,
    value: ComplexFilterInterface | undefined
  ) => void;
};

export function ComplexFilter({ value, onValueSelect }: ComplexFilterType) {
  const { data: complexFilterData } = useSuspenseQuery<ListFiltersQuery>(
    LIST_FILTERS,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const complexFilters = useMemo(() => {
    const f: ComplexFilterInterface[] = JSON.parse(
      JSON.stringify(complexFilterData.filters)
    );
    // console.log("f", f);
    const sortedComplexFilters = f.sort((a, b) =>
      a.name.localeCompare(b.name, "en", { sensitivity: "base" })
    );
    return sortedComplexFilters.map((scf) => ({
      label: scf.name,
      value: scf.id,
    }));
  }, [complexFilterData]);

  const selectedValue = useMemo(() => {
    const f: ComplexFilterInterface[] = JSON.parse(
      JSON.stringify(complexFilterData.filters)
    );

    const selectedComplexFilter = f.find((i) => i.id === value?.id);

    if (!selectedComplexFilter) {
      return null;
    }

    return {
      label: selectedComplexFilter?.name,
      value: selectedComplexFilter?.id,
    };
  }, [complexFilterData, value]);

  const onChange = (option: { label: string; value: string } | null) => {
    if (option) {
      const f: ComplexFilterInterface[] = JSON.parse(
        JSON.stringify(complexFilterData.filters)
      );

      const selectedComplexFilter = f.find((i) => i.id === option.value);

      if (selectedComplexFilter) {
        onValueSelect("complexFilter", selectedComplexFilter);
      }
    } else {
      onValueSelect("complexFilter", undefined);
    }
  };

  return (
    <Select
      className="w-50"
      name="complexFilter"
      onChange={onChange}
      options={complexFilters}
      placeholder="All questions"
      value={selectedValue}
      isSearchable
      isClearable
    />
  );
}
