import { createSlice } from "@reduxjs/toolkit";
import { Hosts } from "src/generated/graphql";
import { AggregateInfoInterface } from "src/types/AggregateInfo";
export interface HostData {
  id: string | null;
}

export interface HostStateInterface {
  isEditing: boolean;
  editingHosts: Hosts[];
  hosts?: Hosts[];
  hosts_info?: AggregateInfoInterface;
}

const initialState: HostStateInterface = {
  isEditing: false,
  editingHosts: [],
  hosts: [],
  hosts_info: { aggregate: { count: 0 } },
};

const hostSlice = createSlice({
  name: "host",
  initialState,
  reducers: {
    triggerEditHost(state, action) {
      state.isEditing = action?.payload?.isEditing;
      state.editingHosts.push(action?.payload?.host);
    },
    editingHostCanceled(state, action) {
      state.editingHosts = state.editingHosts.filter(
        (host) => host.id !== action.payload.id
      );
    },
    setHosts(state, action) {
      state.hosts = action.payload.hosts;
      state.hosts_info = action.payload.hosts_info;
    },
    resetHostState(state) {
      state.editingHosts = [];
      state.isEditing = false;
      state.hosts = [];
    },
  },
});

export const {
  triggerEditHost,
  editingHostCanceled,
  setHosts,
  resetHostState,
} = hostSlice.actions;
export const hostReducer = hostSlice.reducer;
