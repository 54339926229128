import { useMutation } from "@apollo/client";
import { PaginationState } from "@tanstack/react-table";
import { Plus, Search, ShieldQuestion } from "lucide-react";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Outlet, useLocation } from "react-router-dom";
import RightSidebar, {
  RightSidebarNavItem,
} from "src/components/Common/RightSidebar";
import { HostForm, HostsList } from "src/components/Hosts";
import { Button, Input } from "src/components/RadixWrapper";
import { useToast } from "src/components/RadixWrapper/UseToast";
import { Hosts, useSearchHostsQuery } from "src/generated/graphql";
import { Update_Host_Mutation } from "src/gql";
import { setHosts, useAppDispatch } from "src/store";

const ITEMS_PER_PAGE = 10;

type FilterType = {
  keyword: string;
};

export type CustomHostWithEventCount = Hosts & {
  eventCount: number;
};

// enum SIDEBAR_IDS {
//   FILTER = "filter",
//   NOTEBOOK = "notebook",
//   COLUMN_VISIBILITY = "column_visibility",
// }

const SidebarItems: RightSidebarNavItem[] = [
  {
    id: "DUMMY",
    icon: <ShieldQuestion />,
    tooltip: "Dummy action",
  },
];

export const HostsPage = () => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: ITEMS_PER_PAGE,
  });
  const [hostsList, setHostsList] = useState<CustomHostWithEventCount[]>([]);
  const [showFormDialog, setShowFormDialog] = useState(false);
  const [searchTimeOutId, setSearchTimeOutId] = useState<NodeJS.Timeout | null>(
    null
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filters, setFilters] = useState<FilterType>({
    keyword: "",
    // category_id: undefined,
  });
  // const [isFilterOpen, setisFilterOpen] = useState(false);
  // const [isNMOpen, setIsNMOpen] = useState(false); // NM => Notebook Manager
  // const [isCVOpen, setIsCVOpen] = useState(false); // CV => Column Visibility

  const location = useLocation();

  const { toast } = useToast();

  const {
    data: HostsData,
    loading: loadingHosts,
    fetchMore,
  } = useSearchHostsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: ITEMS_PER_PAGE,
      offset: 0,
      where: {
        nickname: {
          _ilike: `%${filters.keyword}%`,
        },
      },
    },
  });

  const [updateHost] = useMutation(Update_Host_Mutation);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const term = location.search.replace("?", "");

    if (term.length > 0) {
      setFilters((prev) => ({ ...prev, keyword: term }));
    }
  }, [location.search]);

  useEffect(() => {
    dispatch(
      setHosts({
        hosts: HostsData?.hosts,
        hosts_aggregate: HostsData?.hosts_aggregate,
      })
    );
  }, [HostsData, dispatch]);

  useEffect(() => {
    fetchMore({
      variables: {
        limit: pageSize,
        offset: pageIndex * pageSize,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult || !fetchMoreResult.hosts) return prev;
        return Object.assign(
          {},
          {
            hosts: [...fetchMoreResult.hosts],
            hosts_aggregate: prev.hosts_aggregate,
          }
        );
      },
    });
  }, [fetchMore, pageIndex, pageSize]);

  // Keyboard shortcuts
  // useHotkeys("ctrl+f", () => setisFilterOpen(true), { preventDefault: true }, [
  //   isFilterOpen,
  // ]);
  // useHotkeys("ctrl+b", () => setIsNMOpen(true), { preventDefault: true }, [
  //   isNMOpen,
  // ]);

  useHotkeys("mod+m", () => toggleAddHostModal(), {
    preventDefault: true,
  });

  // TODO - It seems the types we currently have in HostsInterface
  // TODO - and the types returned from the API do not match
  // TODO - Most likely recently updated
  // TODO - Bandaid until I can get bandwidth to inspect it more
  useEffect(() => {
    if (HostsData?.hosts) {
      const items: CustomHostWithEventCount[] = HostsData?.hosts.map(
        (o) =>
          ({
            id: o.id,
            nickname: o.nickname,
            googleAccountEmail: o.googleAccountEmail,
            user_id: o.user_id,
            eventCount: o.eventHosts_aggregate.aggregate?.count,
          } as CustomHostWithEventCount)
      );

      setHostsList(items);
    }
  }, [HostsData]);

  const handleSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    if (searchTimeOutId) {
      clearTimeout(searchTimeOutId);
    }

    const timeOutId = setTimeout(() => {
      setFilters((prev) => ({ ...prev, keyword: event.target.value }));
    }, 500);

    setSearchTimeOutId(timeOutId);
  };

  const toggleAddHostModal = () => {
    setShowFormDialog((prev) => !prev);
  };

  const onDeleteRow = async () => {};
  const onUpdateRow = async (params: Record<string, unknown>) => {
    const { answer, category, host, hostId } = params as Record<string, string>;

    toast({
      title: "Updating the host with the following values:",
      description: (
        <pre className="mt-2 w-[340px] whitespace-pre-wrap rounded-md bg-slate-950 p-4">
          <code className="text-white">
            {JSON.stringify(
              {
                id: hostId,
                category_id: category,
                host,
                answer,
              },
              null,
              2
            )}
          </code>
        </pre>
      ),
    });

    try {
      // const { data } = await updateHost({
      await updateHost({
        variables: {
          pk_columns: { id: hostId },
          _set: {
            answer,
            host,
            category_id: category,
          },
        },
      });

      toast({
        title: "Success",
        description: "Host updated successfully",
      });

      // pubSubInstance?.publish({
      //   channel: "text_channel",
      //   message: {
      //     host: data.update_hosts_by_pk,
      //     type: "host_updated",
      //   },
      // });
    } catch (err) {
      console.log(err);

      toast({
        variant: "destructive",
        title: "FAILED",
        description: `Could not update the host. Try again.`,
      });
    }
  };

  // const onFilterChange = (id: string, value: string | undefined) => {
  //   if (value?.length === 0) {
  //     value = undefined;
  //   }

  //   setFilters((prev) => ({ ...prev, [id]: value }));
  // };

  // const onResetFilters = () => {
  //   setFilters((prev) => ({
  //     ...prev,
  //     category_id: undefined,
  //   }));
  // };

  // const onSidebarItemClick = (id: string) => {
  const onSidebarItemClick = () => {
    //   if (id === SIDEBAR_IDS.FILTER) {
    //     setisFilterOpen(true);
    //   } else if (id === SIDEBAR_IDS.NOTEBOOK) {
    //     setIsNMOpen(true);
    //   } else if (id === SIDEBAR_IDS.COLUMN_VISIBILITY) {
    //     setIsCVOpen(true);
    //   }
  };

  return (
    <div className="relative w-full pr-20">
      {/* <Dialog open={isFilterOpen} onOpenChange={setisFilterOpen}>
        <DialogContent>
          <FilterHosts
            filters={filters}
            onValueChange={onFilterChange}
            onReset={onResetFilters}
          />
        </DialogContent>
      </Dialog> */}

      <div className="flex w-full flex-col p-4">
        <section className="mb-8">
          <div className="flex w-full items-center justify-between">
            <h1 className="text-xl font-bold">Hosts</h1>
            <Button
              className="cursor-pointer"
              onClick={toggleAddHostModal}
              title="Add a new Host (ctrl+n)"
            >
              <Plus className="mr-2 h-4 w-4" /> Add Host
            </Button>
          </div>

          <section className="mt-4 flex justify-end rounded-t-lg bg-mauve-2 p-2">
            <div className="relative w-64">
              <Search className="absolute bottom-0 left-3 top-0 my-auto h-4 w-4 text-gray-500" />
              <Input
                className="pl-12 pr-4"
                placeholder="Search for hosts"
                onChange={handleSearch}
              />
            </div>
          </section>

          <HostsList
            hosts={hostsList}
            pageIndex={pageIndex}
            pageSize={pageSize}
            totalCount={HostsData?.hosts_aggregate?.aggregate?.count || 0}
            onPaginationChange={setPagination}
            loadingData={loadingHosts}
            onDeleteRow={onDeleteRow}
            onUpdateRow={onUpdateRow}
            // isCVOpen={isCVOpen}
            // onCVOpenChange={setIsCVOpen}
          />
        </section>

        <HostForm
          showHost={showFormDialog}
          toggleShowHost={toggleAddHostModal}
        />
      </div>

      {/* <Dialog open={isNMOpen} onOpenChange={setIsNMOpen}>
        <DialogContent className="max-w-7xl">
          <Suspense fallback={<NotebookListSkeleton />}>
            <ListNotebooks />
          </Suspense>
        </DialogContent>
      </Dialog> */}

      <Outlet />

      <RightSidebar items={SidebarItems} onItemClick={onSidebarItemClick} />
    </div>
  );
};
