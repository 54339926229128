import React, { useMemo } from "react";
import Select from "react-select";

const questionStatuses = [
  { status: "DRAFT", name: "Draft" },
  { status: "ACTIVE", name: "Active" },
  { status: "RETIRED", name: "Retired" },
];
type StatusFilterType = {
  value: string | undefined;
  onValueSelect: (id: string, value: string) => void;
};

export function StatusFilter({ value, onValueSelect }: StatusFilterType) {
  const selectedValue = useMemo(() => {
    const selectedStatus = questionStatuses.find((i) => i.status === value);

    if (!selectedStatus) {
      return null;
    }

    return { label: selectedStatus?.name, value: selectedStatus?.status };
  }, [value]);

  const onChange = (option: { label: string; value: string } | null) => {
    if (option) {
      onValueSelect("status", option.value);
    } else {
      onValueSelect("status", "");
    }
  };

  return (
    <Select
      className="w-50"
      name="type"
      onChange={onChange}
      options={questionStatuses.map((sc) => ({
        label: sc.name,
        value: sc.status,
      }))}
      placeholder="All statuses"
      value={selectedValue}
      isSearchable
      isClearable
    />
  );
}
