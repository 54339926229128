import React, { useState } from "react";
import {
  Button,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/RadixWrapper";
import { Copy, Pencil, Plus, Trash2 } from "lucide-react";
import { useMutation, useSuspenseQuery } from "@apollo/client";
import {
  CreateFilterMutationMutation,
  CreateFilterMutationMutationVariables,
  ListFiltersQuery,
} from "src/generated/graphql";
import {
  Create_Filter_Mutation,
  LIST_FILTERS,
  Remove_Filter_Mutation,
} from "src/gql";
import { FILTER_MODES } from "./";
import clsx from "clsx";
import { useToast } from "src/components/RadixWrapper/UseToast";
import { useAuth } from "src/hooks/useAuth";

type ListFiltersType = {
  onChangeMode: (arg0: FILTER_MODES, arg1?: string) => void;
};

const FILTERS_PER_PAGE = 5;

export function ListFilters({ onChangeMode }: ListFiltersType) {
  const [page, setPage] = useState(0);
  const [createFilterMutation] = useMutation<
    CreateFilterMutationMutation,
    CreateFilterMutationMutationVariables
  >(Create_Filter_Mutation);
  const [removeFilterMutation] = useMutation(Remove_Filter_Mutation);
  const { toast } = useToast();
  const { user } = useAuth();

  const { data: filtersData, refetch } = useSuspenseQuery<ListFiltersQuery>(
    LIST_FILTERS,
    {
      variables: {
        limit: FILTERS_PER_PAGE,
        offset: page * FILTERS_PER_PAGE,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const previousPage = () => {
    setPage((prev) => {
      if (prev === 0) {
        return 0;
      }

      return prev - 1;
    });
  };

  const nextPage = () => {
    if (filtersData.filters.length === FILTERS_PER_PAGE) {
      setPage((prev) => prev + 1);
    }
  };

  const copyFilter = async (filter: (typeof filtersData.filters)[0]) => {
    if (
      window.confirm(
        `Are you sure you want to create a copy of the filter named "${filter.name}"`
      )
    ) {
      toast({
        description: "Creating a copy of the filter...",
      });

      try {
        const { data, errors } = await createFilterMutation({
          variables: {
            object: {
              filter: filter.filter,
              gql: filter.gql,
              name: `Copy of ${filter.name}`,
              owner_id: user?.id,
              org_id: user?.orgId,
            },
          },
        });

        if (errors) {
          console.log(errors);
          throw new Error("An error occured when creating the filter");
        }

        toast({
          description: "Creation of the filter's copy was successfull.",
        });

        onChangeMode(FILTER_MODES.EDIT, data?.insert_filters_one?.id);
      } catch (err) {
        toast({
          variant: "destructive",
          description: "Creating a copy of the filter failed",
        });
      }
    }
  };

  const removeFilter = async (filter: (typeof filtersData.filters)[0]) => {
    if (
      window.confirm(
        `Are you sure you want to delete the filter named "${filter.name}"`
      )
    ) {
      toast({
        description: "Deleting the filter...",
      });

      try {
        const { errors } = await removeFilterMutation({
          variables: {
            id: filter.id,
          },
        });

        if (errors) {
          console.log(errors);
          throw new Error("An error occured when deleting the filter");
        }

        toast({
          description: "The filter was deleted successfully.",
        });

        await refetch();
      } catch (err) {
        toast({
          variant: "destructive",
          description: "Deleting the filter failed",
        });
      }
    }
  };

  return (
    <>
      <DialogHeader>
        <DialogTitle>Filters</DialogTitle>
      </DialogHeader>

      <div>
        <Button
          variant="outline"
          onClick={() => onChangeMode(FILTER_MODES.CREATE)}
        >
          <Plus className="mr-2 h-4 w-4" />
          Create Filter
        </Button>
      </div>

      <section className="flex w-full flex-col gap-8">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-3/4">Name</TableHead>
              <TableHead className="w-1/4 text-center">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filtersData.filters.map((f, i) => {
              return (
                <TableRow key={i}>
                  <TableCell className="w-3/4">{f.name}</TableCell>
                  <TableCell className="flex justify-between gap-4 text-black">
                    <Button
                      className="hover:bg-primary"
                      variant="ghost"
                      size="icon"
                      onClick={() => copyFilter(f)}
                    >
                      <Copy className="h-4 w-4" />
                    </Button>
                    <Button
                      className="hover:bg-primary"
                      variant="ghost"
                      size="icon"
                      onClick={() => onChangeMode(FILTER_MODES.EDIT, f.id)}
                    >
                      <Pencil className="h-4 w-4" />
                    </Button>
                    <Button
                      className="hover:bg-primary"
                      variant="ghost"
                      size="icon"
                      onClick={() => removeFilter(f)}
                    >
                      <Trash2 className="h-4 w-4" />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
            {filtersData.filters.length === 0 && (
              <TableRow className="h-56">
                <TableCell colSpan={2} className="text-center text-gray-400">
                  No filters exist. Go back a page or create more filters.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </section>
      <DialogFooter
        className={clsx("sm:justify-start", {
          hidden: filtersData.filters.length === 0,
        })}
      >
        <Pagination>
          <PaginationContent>
            <PaginationItem>
              <PaginationPrevious href="#" onClick={previousPage} />
            </PaginationItem>

            <PaginationItem>
              <PaginationNext href="#" onClick={nextPage} />
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      </DialogFooter>
    </>
  );
}
