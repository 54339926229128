import React from "react";
import { Label, RadioGroup, RadioGroupItem } from "src/components/RadixWrapper";
import { TIMELINE } from "./types";
import clsx from "clsx";

type PeriodSelectorType = {
  value?: TIMELINE;
  onValueChange: (arg0: string) => void;
  isVisible: boolean;
  prefix: string;
};

export function PeriodSelector({
  value,
  onValueChange,
  isVisible,
  prefix,
}: PeriodSelectorType) {
  return (
    <div className={clsx({ invisible: !isVisible })}>
      <Label>Since</Label>
      <RadioGroup
        className="grid-flow-col"
        value={value}
        onValueChange={onValueChange}
      >
        <div className="flex items-center space-x-2">
          <RadioGroupItem value={TIMELINE.ONEYEAR} id={`${prefix}__one-year`} />
          <Label htmlFor={`${prefix}__one-year`}>One Year</Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem
            value={TIMELINE.THREEYEARS}
            id={`${prefix}__three-years`}
          />
          <Label htmlFor={`${prefix}__three-years`}>Three Years</Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem value={TIMELINE.EVER} id={`${prefix}__ever`} />
          <Label htmlFor={`${prefix}__ever`}>Ever</Label>
        </div>
      </RadioGroup>
    </div>
  );
}
