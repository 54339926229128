import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { Plus, Search, Settings } from "lucide-react";
import { EventList } from "../../components/Events";
import {
  useAppDispatch,
  setEvents,
  usePubSubInstance,
  triggerEditEvent,
  editingEventCanceled,
} from "src/store";
import RightSidebar, {
  RightSidebarNavItem,
} from "src/components/Common/RightSidebar";
import { Button, Input } from "src/components/RadixWrapper";
import { Link } from "react-router-dom";
import { PaginationState } from "@tanstack/react-table";
import { EventInterface } from "src/types/Event";
import { useGetEventsQuery } from "src/generated/graphql";
import Pubnub from "pubnub";
import { useAuth } from "src/hooks/useAuth";
import { Delete_Event_Mutation } from "src/gql";
import { useMutation } from "@apollo/client";
import { useToast } from "src/components/RadixWrapper/UseToast";

const ITEMS_PER_PAGE = 10;

enum SIDEBAR_IDS {
  COLUMN_VISIBILITY = "column_visibility",
}

const SidebarItems: RightSidebarNavItem[] = [
  {
    id: SIDEBAR_IDS.COLUMN_VISIBILITY,
    icon: <Settings />,
    tooltip: "Toggle column visibility",
  },
];

export const Events = () => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: ITEMS_PER_PAGE,
  });
  const [eventsList, setEventsList] = useState<EventInterface[]>([]);
  const [searchTimeOutId, setSearchTimeOutId] = useState<NodeJS.Timeout | null>(
    null
  );
  const [searchText, setSearchText] = useState("");
  const [isCVOpen, setIsCVOpen] = useState(false); // CV => Column Visibility

  const { user } = useAuth();
  const { toast } = useToast();

  const [deleteEventMutation] = useMutation(Delete_Event_Mutation);

  const {
    data: EventsData,
    loading: loadingQuestions,
    refetch,
    fetchMore,
  } = useGetEventsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: ITEMS_PER_PAGE,
      offset: 0,
      where: {
        name: {
          _ilike: `%${searchText}%`,
        },
        org_id: { _eq: user?.orgId },
      },
    },
  });

  const dispatch = useAppDispatch();

  const { pubSubInstance } = usePubSubInstance();

  const listener = useMemo(
    () => ({
      status: (statusEvent: Pubnub.StatusEvent) => {
        if (statusEvent.category === "PNConnectedCategory") {
          console.log("Connected");
        }
      },
      message: async (event: Pubnub.MessageEvent) => {
        if (event.message.type === "editing_event_form") {
          dispatch(
            triggerEditEvent({
              isEditing: true,
              event: event.message.event,
            })
          );
        }

        if (event.message.type === "editing_event_canceled") {
          dispatch(editingEventCanceled({ id: event.message.event.id }));
        }

        if (event.message.type === "event_updated") {
          dispatch(editingEventCanceled({ id: event.message.event.id }));

          setEventsList((old) => {
            return old.map((row) => {
              if (row.id === event.message.event.id) {
                return event.message.event as EventInterface;
              }

              return row;
            });
          });
        }
      },
    }),
    [dispatch]
  );

  useEffect(() => {
    pubSubInstance?.addListener(listener);

    pubSubInstance?.subscribe({
      withPresence: true,
      channels: ["text_channel"],
    });

    return () => {
      pubSubInstance?.removeListener(listener);
      pubSubInstance?.unsubscribeAll();
    };
  }, [pubSubInstance, listener]);

  useEffect(() => {
    if (EventsData?.events) {
      dispatch(
        setEvents({
          events: EventsData.events,
          events_info: EventsData.events_aggregate,
        })
      );
    }
  }, [EventsData, dispatch]);

  useEffect(() => {
    fetchMore({
      variables: {
        limit: pageSize,
        offset: pageIndex * pageSize,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult || !fetchMoreResult.events) return prev;
        return Object.assign(
          {},
          {
            events: [...fetchMoreResult.events],
            events_aggregate: prev.events_aggregate, // Add this line to include the missing property
          }
        );
      },
    });
  }, [fetchMore, pageIndex, pageSize]);

  useEffect(() => {
    if (EventsData?.events) {
      // ! This re-formatting is necessary because TanStack Table deals only with primitive
      // ! data types. PostgreSQL uses "timestamptz" for date, and typescript associates this
      // ! with "Date" type in javascript. Date type is NOT a primitive type. Hence, this formatting
      const items: EventInterface[] = EventsData.events.map((e) => ({
        ...e,
        eventDateTime: new Date(e.eventDateTime ?? new Date()).toISOString(),
        cost: String(e.cost), //TODO: I don't like this
      }));
      setEventsList(items);
    }
  }, [EventsData]);

  const handleSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    if (searchTimeOutId) {
      clearTimeout(searchTimeOutId);
    }
    const timeOutId = setTimeout(() => {
      setSearchText(event.target.value);
    }, 500);
    setSearchTimeOutId(timeOutId);
  };

  const handleDelete = async (id: string, name: string) => {
    if (
      window.confirm(
        `Are you sure you want to delete the event with name "${name}"`
      )
    ) {
      toast({
        description: `Deleting event with name "${name}"...`,
      });

      await deleteEventMutation({
        variables: {
          id,
        },
      });

      refetch();

      toast({
        description: `Event with name "${name}" deleted successfully`,
      });
    }
  };

  const onSidebarItemClick = (id: string) => {
    if (id === SIDEBAR_IDS.COLUMN_VISIBILITY) {
      setIsCVOpen(true);
    }
  };

  return (
    <div className="relative w-full pr-20">
      <div className="flex w-full flex-col p-4">
        <section className="mb-8">
          <div className="flex w-full items-center justify-between">
            <h1 className="text-xl font-bold">Manage Events</h1>
            <Button className="cursor-pointer" title="Add a new event" asChild>
              <Link to="/events/new">
                <Plus className="mr-2 h-4 w-4" /> Add New Event
              </Link>
            </Button>
          </div>

          <section className="mt-4 flex justify-end gap-2 rounded-t-lg bg-mauve-2 p-2">
            <div className="relative w-64">
              <Search className="absolute bottom-0 left-3 top-0 my-auto h-4 w-4 text-gray-500" />
              <Input
                className="bg-white pl-12 pr-4"
                placeholder="Search for questions"
                onChange={handleSearch}
              />
            </div>
          </section>

          <EventList
            events={eventsList}
            pageIndex={pageIndex}
            pageSize={pageSize}
            totalCount={EventsData?.events_aggregate.aggregate?.count || 0}
            onPaginationChange={setPagination}
            loadingData={loadingQuestions}
            onDeleteRow={handleDelete}
            isCVOpen={isCVOpen}
            onCVOpenChange={setIsCVOpen}
          />
        </section>
      </div>

      <RightSidebar items={SidebarItems} onItemClick={onSidebarItemClick} />
    </div>
  );
};

export default Events;
