/**
 * ! DO NOT USE Sheets component here. I faced the following issues:
 * ! - If I add Select component in the form, the sheet would open, then close and then open again
 * ! - I could not disable event propagation if I clicked on any of the action column buttons (Note that
 * !   the entire row in Location table also has a click handler on it - both would trigger). This is how
 * !   Portals behave apparently, but event.stopPropogation() did not help
 */
import { useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import clsx from "clsx";
import { XCircle } from "lucide-react";
import React from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from "src/components/RadixWrapper";
import { Locations } from "src/generated/graphql";
import { Create_Location_Mutation, Update_Location_Mutation } from "src/gql";
import * as z from "zod";
import { useToast } from "../RadixWrapper/UseToast";

const formSchema = z.object({
  name: z.string().min(1),
  address: z.string().min(1),
  city: z.string().min(1),
  state: z.string().min(1),
  zip: z.string().min(1),
  region: z.string().min(1),
});

type CreateLocationFormType = {
  showLocation: boolean;
  toggleShowLocation: () => void;
  location?: Locations;
};

export function LocationForm({
  showLocation,
  toggleShowLocation,
  location,
}: CreateLocationFormType) {
  const { toast } = useToast();
  const [createLocationMutation] = useMutation(Create_Location_Mutation);
  const [updateLocation] = useMutation(Update_Location_Mutation);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: location
      ? {
          name: location.name,
          address: location.address || "",
          city: location.city,
          state: location.state,
          zip: location.zip,
          region: location.region?.id || undefined,
        }
      : {
          name: "",
          address: "",
          city: "",
          state: "",
          zip: "",
          region: undefined,
        },
  });
  // const { pubSubInstance } = usePubSubInstance();

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    console.log("Blah");
    toggleShowLocation();

    toast({
      title: location
        ? "Updating the location with the following values:"
        : "Creating a location with the following values:",
      description: (
        <pre className="mt-2 w-[340px] whitespace-pre-wrap rounded-md bg-slate-950 p-4">
          <code className="text-white">{JSON.stringify(values, null, 2)}</code>
        </pre>
      ),
    });

    try {
      if (location) {
        await updateLocation({
          variables: {
            pk_columns: { id: location.id },
            _set: {
              name: values.name,
              address: values.address,
              city: values.city,
              state: values.state,
              zip: values.zip,
              region: values.region,
            },
          },
        });

        // pubSubInstance?.publish({
        //   channel: "text_channel",
        //   message: {
        //     location: data.update_locations_by_pk,
        //     type: "location_updated",
        //   },
        // });
      } else {
        await createLocationMutation({
          variables: {
            object: {
              name: values.name,
              address: values.address,
              city: values.city,
              state: values.state,
              zip: values.zip,
              region: values.region,
            },
          },
        });
      }

      toast({
        title: "SUCCESS",
        description: location
          ? "Location updated successfully"
          : "New location created successfully",
      });

      form.reset();
    } catch (err) {
      if (location) {
        console.log("An error occurred during location update");
        console.log(err);

        toast({
          variant: "destructive",
          title: "FAILED",
          description: `Could not update the location. Try again.`,
        });
      } else {
        console.log("An error occurred during location creation");
        console.log(err);

        toast({
          variant: "destructive",
          title: "FAILED",
          description: `Could not create the location. Try again.`,
        });
      }
    }
  };

  return (
    <div
      className={clsx({
        "fixed inset-0 z-10 h-full w-full": true,
        invisible: !showLocation,
      })}
    >
      <div
        onClick={toggleShowLocation}
        className={clsx({
          "absolute inset-0 h-full w-full bg-gray-900": true,
          "transition-all duration-500 ease-out": true,
          "opacity-0": !showLocation,
          "opacity-50": showLocation,
        })}
      ></div>
      <div
        className={clsx({
          "absolute right-0 h-full w-96 bg-white": true,
          "transition-all duration-300 ease-out": true,
          "translate-x-full": !showLocation,
        })}
      >
        <div className="flex flex-col space-y-2 px-6 pt-6">
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-semibold text-foreground">
              {location ? "Edit Location" : "Create Location"}
            </h2>
            <Button
              variant="ghost"
              size="icon"
              className="rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 disabled:pointer-events-none"
              onClick={toggleShowLocation}
            >
              <XCircle className="h-4 w-4" />
            </Button>
          </div>
          <p className="text-sm text-muted-foreground">
            Here you can{" "}
            {location ? "edit an existing location" : "create a new location"}
          </p>
        </div>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-8 p-6"
          >
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Enter the location name</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="eg. Phil's Discount Furniture"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="address"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Address</FormLabel>
                  <FormControl>
                    <Input placeholder="123 Main St." {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="city"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>City</FormLabel>
                  <FormControl>
                    <Input placeholder="Anywheretown" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="state"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>State</FormLabel>
                  <FormControl>
                    <Input placeholder="CT" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="zip"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Zip</FormLabel>
                  <FormControl>
                    <Input placeholder="06456" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* <FormField
              control={form.control}
              name="region"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Region</FormLabel>
                  <FormControl>
                    <RegionFilter {...field} onValueSelect={field.onChange} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            /> */}

            <Button type="submit">
              {location ? "Save" : "Create Location"}
            </Button>
          </form>
        </Form>
      </div>
    </div>
  );
}
