import React, { Suspense } from "react";
import {
  Checkbox,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../RadixWrapper";
import { ApplicationUsers } from "src/types/ApplicationUsers";
import { ApplicationUsersTableSkeleton } from "./Skeleton";
import { useMutation, useSuspenseQuery } from "@apollo/client";
import {
  Add_Role_For_User_Mutation,
  Get_Unique_Roles_Query,
  Get_User_Roles_Query,
  Remove_Role_For_User_Mutation,
} from "src/gql/application-users";
import { useToast } from "../RadixWrapper/UseToast";

type ApplicationUsersTableProps = {
  users: ApplicationUsers[];
  loadingUsers: boolean;
  toggleUserActive: (username: string, checked: boolean) => Promise<void>;
};

type ApplicationUserRoleProps = {
  username: string;
  email: string;
};

type GetUniqueRolesResponse = {
  roles: {
    name: string;
  }[];
};

type GetUserRolesResponse = {
  users_by_pk: {
    id: string;
    users_roles: {
      id: string;
      name: string;
    }[];
  };
};

function ApplicationUserRole({ username, email }: ApplicationUserRoleProps) {
  const { data: uniqueRoles } = useSuspenseQuery<GetUniqueRolesResponse>(
    Get_Unique_Roles_Query
  );
  const { data: userRoles, refetch } = useSuspenseQuery<GetUserRolesResponse>(
    Get_User_Roles_Query,
    {
      variables: {
        id: username,
      },
    }
  );

  const [addRoleToUser] = useMutation(Add_Role_For_User_Mutation);
  const [removeRoleFromUser] = useMutation(Remove_Role_For_User_Mutation);
  const { toast } = useToast();

  const onRoleChange = async (
    newCheckedState: boolean,
    id?: string,
    name?: string
  ) => {
    if (newCheckedState) {
      if (!name) {
        throw new Error("Name of role needed to add role to user");
      }

      toast({
        description: `Adding role ${name} to user ${email}...`,
      });

      await addRoleToUser({
        variables: {
          userId: userRoles.users_by_pk.id,
          name: name,
        },
      });

      toast({
        description: `Role ${name} addedd successfully to user ${email}`,
      });
    } else {
      if (!id) {
        throw new Error("id of role needed to remove it from the user");
      }

      toast({
        description: `Removing role from user ${email}...`,
      });

      await removeRoleFromUser({
        variables: {
          id: id,
        },
      });

      toast({
        description: `Role removed from user ${email} successfully`,
      });
    }

    await refetch();
  };

  const getExistingUserRoleId = (
    roles: { id: string; name: string }[],
    roleName: string
  ) => {
    const index = roles.findIndex((r) => r.name === roleName);

    if (index !== -1) {
      return roles[index].id;
    }
  };

  return (
    <ul className="flex flex-col gap-2">
      {uniqueRoles.roles.map((ur) => (
        <li key={ur.name} className="flex items-center gap-2">
          <Checkbox
            id={`${username}-${ur.name}`}
            checked={
              userRoles.users_by_pk?.users_roles.findIndex(
                (r) => r.name === ur.name
              ) !== -1
            }
            onCheckedChange={(checked: boolean) =>
              onRoleChange(
                checked,
                getExistingUserRoleId(
                  userRoles.users_by_pk?.users_roles,
                  ur.name
                ),
                ur.name
              )
            }
          />
          <label
            htmlFor={`${username}-${ur.name}`}
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            {ur.name}
          </label>
        </li>
      ))}
    </ul>
  );
}

export function ApplicationUsersTable({
  users,
  loadingUsers,
  toggleUserActive,
}: ApplicationUsersTableProps) {
  if (loadingUsers) {
    return <ApplicationUsersTableSkeleton />;
  }

  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Email</TableHead>
            <TableHead>Verified email</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>Active</TableHead>
            <TableHead>Roles</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {users.map((u) => (
            <TableRow key={u.email}>
              <TableCell>{u.email}</TableCell>
              <TableCell>{u.emailVerified ? "Yes" : "No"}</TableCell>
              <TableCell>{u.status}</TableCell>
              <TableCell>
                <Switch
                  checked={u.enabled}
                  onCheckedChange={(checked: boolean) =>
                    toggleUserActive(u.id, checked)
                  }
                />
              </TableCell>
              <TableCell>
                <Suspense fallback={<Skeleton className="h-9 w-full" />}>
                  <ApplicationUserRole username={u.id} email={u.email} />
                </Suspense>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
