import React from "react";
export const Page404 = () => {
  // render content

  return (
    <div>
      <h1>404</h1>
      <div>Page not found...</div>
    </div>
  );
};
