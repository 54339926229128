import React from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from "src/components/RadixWrapper";
import { useAuth } from "src/hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useToast } from "src/components/RadixWrapper/UseToast";

const FormSchema = z.object({
  password: z.string().min(10),
});

export function ForceChangePassword() {
  const { confirmSignIn } = useAuth();
  const navigate = useNavigate();
  const { toast } = useToast();

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      password: "",
    },
  });

  const changePassword = async (data: z.infer<typeof FormSchema>) => {
    toast({
      description: "Changing your password...",
    });

    const success = await confirmSignIn(data.password);

    if (success) {
      toast({
        description: "Password changes successfully",
      });

      navigate("/home");
    }
  };

  return (
    <div className="container flex justify-center">
      <Card className="w-[350px]">
        <CardHeader>
          <CardTitle>Change your password</CardTitle>
          <CardDescription>Set a new password for your account</CardDescription>
        </CardHeader>
        <CardContent>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(changePassword)}
              className="space-y-8 pt-6"
            >
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>New password</FormLabel>
                    <FormControl>
                      <Input type="password" {...field} />
                    </FormControl>
                    <FormDescription>
                      You will have to sign in with this new password and not
                      the one sent to your email
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button type="submit">Change Password</Button>
            </form>
          </Form>
        </CardContent>
      </Card>
    </div>
  );
}
